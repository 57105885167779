import * as Sentry from '@sentry/react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { createRoot, hydrateRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { pdfjs } from 'react-pdf'
import { BrowserRouter } from 'react-router-dom'
import { AntdTheme } from '@/presentation/common/components/AntdTheme.tsx'
import { BlankLayout } from '@/presentation/common/layout/BlankLayout.tsx'
import { AppRouter } from '@/presentation/common/router/router.tsx'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'ag-grid-community/styles/ag-grid.css'
import './presentation/common/styles/app.scss'

Sentry.init({
  dsn          : 'https://4a8c767ddf4200c6af8fb27dbe3bca09@o4507458352709632.ingest.us.sentry.io/4507458353889280',
  integrations : [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate         : 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets  : [ 'localhost', /^https:\/\/api\.mf-erp\.com/ ],
  // Session Replay
  replaysSessionSampleRate : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate : 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('America/Santiago')

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

const container = document.getElementById('root')

const FullApp = () => (
  <IntlProvider locale="es">
    <AntdTheme>
      <BlankLayout>
        <BrowserRouter>
          <AppRouter/>
        </BrowserRouter>
      </BlankLayout>
    </AntdTheme>
  </IntlProvider>
)

if (import.meta.hot || !container?.innerText) {
  const root = createRoot(container!)
  root.render(<FullApp />)
}
else {
  hydrateRoot(container!, <FullApp />)
}
