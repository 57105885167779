import { Input, InputProps } from 'antd'
import { ChangeEvent, FC } from 'react'
import voca from 'voca'

type Props = InputProps

export const UsernameInput: FC<Props> = (props) => {
  const formatValue = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.({
      ...event,
      target: {
        ...event.target,
        value: voca.replaceAll(event.target.value, ' ', '_').toLowerCase().trim(),
      },
    })
  }

  return (
    <Input {...props} onChange={formatValue}/>
  )
}
