import { Button, Form, Input, InputNumber } from 'antd'
import numeral from 'numeral'
import { FC, useEffect } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { FormProps } from '@/presentation/payStates/forms/types.ts'
import { PreviewPayStatesForTableResult } from '@/presentation/payStates/hooks/usePreviewPayState.ts'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'
import 'numeral/locales/es'

numeral.locale('es')

type UpdatePayStateResumeFormFields = {
  id: string,
  item?: string,
  quantity?: number,
  unitPrice?: number,
  total: number,
}

export const UpdatePayStateResumeForm: FC<FormProps> = ({ onCancel, onFinish }) => {
  const { updateResumeItem } = usePayStatePreviewStore()

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<PreviewPayStatesForTableResult['resume']['detail'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const [ form ] = Form.useForm()
  const quantity = Form.useWatch('quantity', form)
  const unitPrice = Form.useWatch('unitPrice', form)

  const onSubmit = async (values: UpdatePayStateResumeFormFields) => {
    const editedValues = getModifiedFields(initialValues, values) as UpdatePayStateResumeFormFields

    updateResumeItem({
      id        : initialValues.id,
      item      : editedValues.item ?? initialValues.item,
      quantity  : editedValues.quantity ?? initialValues.quantity,
      unitPrice : editedValues.unitPrice ?? initialValues.unitPrice,
      total     : editedValues.total ?? initialValues.total,
    })

    onFinish?.call(this)
  }

  useEffect(() => {
    form.setFieldsValue({
      total: (quantity ?? 0) * (unitPrice ?? 0),
    })
  }, [ quantity, unitPrice ])

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <FormSection title="Datos del registro">
        <Form.Item
          label="Descripción"
          name="item"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Unidades"
          name="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>

        <Form.Item
          label="Monto Unitario"
          name="unitPrice"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>

        <Form.Item
          label="Monto Total"
          name="total"
          rules={[{ required: false }]}
        >
          <InputNumber disabled formatter={(value) => numeral(value).format('$0,0')}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit">Actualizar</Button>
      </div>
    </Form>
  )
}
