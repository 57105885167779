import { Modal } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { MaintainerModalProps } from '@/presentation/common/components/maintainer/types.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'

export const MaintainerModal: FC<MaintainerModalProps> = ({ children, open, title }) => {
  const { isMobile, isTablet, isDesktopAndAbove } = useMQ()
  const width = cx({
    '100%' : isMobile,
    '70%'  : isTablet,
    '50%'  : isDesktopAndAbove,
  })

  return (
    <Modal
      title={title || 'Mantenedor'}
      open={open}
      centered
      destroyOnClose
      closable={false}
      footer={null}
      width={width}
      className="app-maintainer-modal"
    >
      {children}
    </Modal>
  )
}
