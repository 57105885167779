import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { UseQueryItemsProps } from '@/presentation/common/hooks/crud/v2/types.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { errorHandler } from '@/presentation/common/utils/errorHandler.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'

export const useQueryItems = <TResponse>({ messages, callable }: UseQueryItemsProps<TResponse>) => {
  const status = useUiStatus()
  const [ lastUpdate, setLastUpdate ] = useState<Date>(new Date())
  const [ lastPathParams, setLastPathParams ] = useState<QueryOptions['pathParams']>()
  const [ lastQueryParams, setLastQueryParams ] = useState<QueryOptions['params']>({
    search : undefined,
    page   : 1,
    limit  : 10,
  })

  const refresh = useCallback(async (newQueryParams?: QueryOptions['params'], newPathParams?: QueryOptions['pathParams']) => {
    return await query({
      params     : { ...(lastQueryParams ? lastQueryParams : {}), ...(newQueryParams ? newQueryParams : {}) } || {},
      pathParams : lastPathParams || newPathParams ? { ...(lastPathParams ? lastPathParams : {}), ...(newPathParams ? newPathParams : {}) } : undefined,
    })
  }, [ lastQueryParams ])

  const query = async ({
    pathParams,
    params = {},
  }: Partial<QueryOptions>) => {
    status.resetStatus()

    // actualizar solo si ha cambiado, esto evita que los useEffects se recarguen constantemente al cambiar el estado y por ende el metodo refresh cambia
    const modifiedPathParams = getModifiedFields(lastPathParams ?? {}, pathParams ?? {}) as Record<any, any>
    if (Object.keys(modifiedPathParams).length === 0) {
      setLastPathParams(pathParams)
    }

    // actualizar solo si ha cambiado, esto evita que los useEffects se recarguen constantemente al cambiar el estado y por ende el metodo refresh cambia
    const modifiedParams = getModifiedFields(lastQueryParams, params) as Record<any, any>
    if (Object.keys(modifiedParams).length === 0) {
      setLastQueryParams({
        ...params,
        search : params.search === '' ? undefined : params.search,
        page   : params.page || 1,
        limit  : params.limit || 10,
      })
    }

    try {
      status.setStatus(UiStateStatus.LOADING)
      status.setMessage(messages.loading ?? 'Cargando')

      const itemsResult = await callable({
        pathParams,
        params: {
          ...params,
          search : params.search === '' ? undefined : params.search,
          page   : params.page || 1,
          limit  : params.limit || 10,
        },
      })

      status.setStatus(UiStateStatus.SUCCESS)
      status.setMessage(messages.success ?? 'Consulta exitosa')
      setLastUpdate(new Date())
      return itemsResult as TResponse
    }
    catch (error) {
      const message = errorHandler({
        error,
        messages: messages,
      })

      status.setStatus(UiStateStatus.ERROR)
      status.setMessage(message)
    }
  }

  return {
    message   : status.message,
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    lastUpdate,
    query,
    refresh,
  }
}
