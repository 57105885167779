import { useMaintenancesMaintainerStore } from '@/presentation/maintenance/store/maintenancesMaintainers.ts'

export const useTabs = () => {
  const { selectedMaintenanceId } = useMaintenancesMaintainerStore()

  const tabsSetup = {
    maintenances : { id: 'maintenances', label: 'Mantenciones' },
    products     : { id: 'products', label: 'Productos ocupados' },
  }

  const visibleTabs: Record<string, boolean> = {
    maintenances : true,
    products     : false,
  }

  if (selectedMaintenanceId) {
    visibleTabs.products = true
  }
  else {
    visibleTabs.products = false
  }

  const tabs = Object.values(tabsSetup).filter(tab => visibleTabs[tab.id])

  return {
    tabs,
  }
}
