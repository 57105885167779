import { Button, Form, Input, InputNumber } from 'antd'
import { FC, useMemo } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { MaintenanceType } from '@/domain/models/maintenanceType.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { MaintenanceClassSelector } from '@/presentation/machinery/components/MaintenanceClassSelector.tsx'
import { TypeSelector } from '@/presentation/machinery/components/TypeSelector.tsx'
import { CreateMachineryFormProps } from '@/presentation/machinery/forms/types.ts'
import { useCreateMachinery } from '@/presentation/machinery/hooks/useCreateMachinery.ts'

type CreateMachineryFormFields = {
  type: string
  code: string
  brand: string
  model: string
  year: number
  serialNumber: string
  truckVolume?: number
  maintenanceType?: string
}

export const CreateMachineryForm: FC<CreateMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm<CreateMachineryFormFields>()
  const machineryTypeValue = Form.useWatch('type', form)
  const machineryType = useMemo(() =>
    machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : null, [ machineryTypeValue ])

  const onSubmit = async (values: CreateMachineryFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      type            : MachineryType.fromString(values.type).value,
      code            : values.code,
      brand           : values.brand,
      model           : values.model,
      year            : values.year,
      serialNumber    : values.serialNumber,
      truckVolume     : values.truckVolume ?? null,
      maintenanceType : values.maintenanceType ? MaintenanceType.fromString(values.maintenanceType).value : null,
    })
    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos de la maquinaria">
        <Form.Item
          label="Tipo"
          name="type"
          rules={[{ required: true }]}
        >
          <TypeSelector/>
        </Form.Item>

        <Form.Item
          label="Código"
          name="code"
          rules={[{ required: true }]}
          normalize={(value: string) => value.toUpperCase()}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Marca"
          name="brand"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Modelo"
          name="model"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Año"
          name="year"
          rules={[{ required: true }]}
        >
          <InputNumber className="w-full"/>
        </Form.Item>

        <Form.Item
          label="Matrícula"
          name="serialNumber"
          rules={[{ required: true }]}
          normalize={(value: string) => value.toUpperCase()}
        >
          <Input/>
        </Form.Item>

        {machineryType?.hasVolume() && (
          <Form.Item
            label='Volumen m³'
            name="truckVolume"
          >
            <InputNumber min={0}/>
          </Form.Item>
        )}
      </FormSection>

      {machineryType && machineryType.allowedMaintenances().length > 0 && (
        <FormSection title="Mantenimiento">
          <Form.Item
            label="Clase de mantenimiento"
            name="maintenanceType"
            labelCol={{ span: 6 }}
            rules={[{ required: true }]}
            normalize={(value: string) => value ? value : null}
          >
            <MaintenanceClassSelector machineryType={machineryType}/>
          </Form.Item>
        </FormSection>
      )}

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
