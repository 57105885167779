import { Layout } from 'antd'
import { CSSProperties, FC } from 'react'
import { BlankLayoutProps } from '@/presentation/common/layout/types.ts'

export const BlankLayout: FC<BlankLayoutProps> = ({ children }) => {
  const styles: CSSProperties = {
    height: '100%',
  }

  return (
    <Layout style={styles}>
      <Layout.Content>
        {children}
      </Layout.Content>
    </Layout>
  )
}
