import { SignatureStatus, SignatureStatusValue } from '@/domain/models/signatureStatus.ts'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellBadge } from '@/presentation/common/components/maintainer/table/cells/MTCellBadge.tsx'

export const MTCellSignedStatus = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  return (
    <MTCellBadge {...{
      cellNode: {
        ...cellNode,
        formattedValue: cellNode.value ? SignatureStatus.fromString(SignatureStatusValue.SIGNED).label() : SignatureStatus.fromString(SignatureStatusValue.NOT_SIGNED).label(),
      },
      node,
      column,
      api,
    }}
    className={cellNode.value ? 'bg-emerald-50 w-[100px] text-center' : 'bg-red-50 w-[100px] text-center'}
    />
  )
}
