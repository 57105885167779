import { Avatar, Col, Row, Typography } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { UserRole } from '@/domain/models/userRole.ts'
import { HeaderSettingsButton } from '@/presentation/common/components/HeaderSettingsButton.tsx'
import { ToggleSidebar } from '@/presentation/common/components/ToggleSidebar.tsx'
import { HeaderProps } from '@/presentation/common/components/types.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { fullName } from '@/presentation/common/utils/fullName.ts'

const { Title } = Typography

export const Header: FC<HeaderProps> = ({ renderSidebarToggle, fullMode }) => {
  const sessionStore = useSessionStore()
  const { isTabletAndBelow } = useMQ()

  const avatarClasses = cx({
    'bg-secondary-light': !sessionStore.tenant?.picture,
  })

  return (
    <Row
      align='middle'
      gutter={[ 16, 16 ]}
      className="py-1 px-5 w-full !m-0 leading-normal bg-surface border-b-2 border-primary-dark"
    >
      {renderSidebarToggle && (fullMode || isTabletAndBelow) && (
        <Col>
          <ToggleSidebar/>
        </Col>
      )}

      <Col>
        <Avatar className={avatarClasses} shape="square" size={40}
          src={sessionStore.tenant?.picture}>E</Avatar>
      </Col>

      {!isTabletAndBelow && (
        <Col>
          <Title ellipsis={true} level={5} className="!m-0 !text-base font-semibold !leading-none">
            {sessionStore.tenant?.name?.toUpperCase() || ''}
          </Title>
          <span className="text-sm font-light">
            {`@${sessionStore.tenant?.cdn || ''}`}
          </span>
        </Col>
      )}

      <Col flex={1}/>

      <Col>
        <h4 className="!m-0 !text-base font-semibold !leading-none text-end">
          {sessionStore.user ? fullName(sessionStore.user.firstName, sessionStore.user.lastName).toUpperCase() : ''}
        </h4>
        <span className="block text-end text-sm font-light">
          {sessionStore.user?.role ? UserRole.fromString(sessionStore.user?.role).label() : ''}
        </span>
      </Col>

      <Col>
        <HeaderSettingsButton/>
      </Col>
    </Row>
  )
}
