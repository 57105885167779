import {
  CreateClientRequest,
  DeleteManyClientsRequest,
  UpdateClientRequest,
} from '@/api/clients/request.types.ts'
import {
  CreateClientResponse,
  DeleteManyClientsResponse,
  DownloadExcelResumeResponse,
  QueryClientsResponse,
  UpdateClientResponse,
} from '@/api/clients/response.types.ts'
import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'

export type ClientsApiClient = {
  query: (options: QueryOptions) => Promise<QueryClientsResponse>
  create: (req: CreateClientRequest) => Promise<CreateClientResponse>
  update: (req: UpdateClientRequest) => Promise<UpdateClientResponse>
  deleteMany: (req: DeleteManyClientsRequest) => Promise<DeleteManyClientsResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useClientsApiClient = (): ClientsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryClientsResponse, QueryParams>({
    path   : '/clients',
    params : options.params,
  }),

  create: async (body: CreateClientRequest) => await postMapping<CreateClientResponse, CreateClientRequest>({
    path : '/clients',
    body : body,
  }),

  update: async (req: UpdateClientRequest) => await putMapping<UpdateClientResponse, UpdateClientRequest['body']>({
    path : `/clients/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyClientsRequest) => await deleteMapping<DeleteManyClientsResponse, DeleteManyClientsRequest>({
    path : '/clients',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/clients/excel',
  }),
})
