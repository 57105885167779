import { CreateMaintenanceProductRequest } from '@/api/maintenanceProducts/request.types.ts'
import { CreateMaintenanceProductResponse } from '@/api/maintenanceProducts/response.types.ts'
import { useMaintenanceProductsApiClient } from '@/api/maintenanceProducts/useMaintenanceProductsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateMaintenanceProduct = () => {
  const repository = useMaintenanceProductsApiClient()
  const createItem = useCreateItem<CreateMaintenanceProductRequest, CreateMaintenanceProductResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar el producto',
      alreadyExists : 'Ya existe un registro con el mismo producto e inventario',
      success       : 'Producto registrado con éxito',
      loading       : 'Registrando producto',
    },
  })

  return {
    ...createItem,
  }
}
