import { CreateUserRequest } from '@/api/users/request.types.ts'
import { CreateUserResponse } from '@/api/users/response.types.ts'
import { useUsersApiClient } from '@/api/users/useUsersApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateUser = () => {
  const repository = useUsersApiClient()
  const createUser = useCreateItem<CreateUserRequest, CreateUserResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el usuario',
      alreadyExists : 'Ya existe un usuario con el mismo usuario',
      success       : 'Usuario creado con éxito',
      loading       : 'Creando usuario',
    },
  })

  return {
    ...createUser,
  }
}
