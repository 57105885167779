import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DecreaseInventoryStockForm } from '@/presentation/inventoryProductsStocks/forms/DecreaseInventoryStockForm.tsx'
import { IncreaseInventoryStockForm } from '@/presentation/inventoryProductsStocks/forms/IncreaseInventoryStockForm.tsx'
import { InventoryStocksActionsProps } from '@/presentation/inventoryProductsStocks/forms/types.ts'
import { UpdateInventoryStockForm } from '@/presentation/inventoryProductsStocks/forms/UpdateInventoryStockForm.tsx'
import { useInventoryProductsStocksStore } from '@/presentation/inventoryProductsStocks/store/inventoryProductsStocks.ts'

export const InventoryStocksActions: FC<InventoryStocksActionsProps> = ({ onAction }) => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { selectedInventoryId } = useInventoryProductsStocksStore()
  const { canCreate: canIncrease, canUpdate } = useCrudAuth()
  const canDecrease = selectionCount === 1
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'INCREASE' | 'UPDATE' | 'DECREASE' | null>(null)

  const isIncrease = option === 'INCREASE'
  const isUpdate = option === 'UPDATE'
  const isDecrease = option === 'DECREASE'

  const title = isIncrease ? 'Ingresar stock' : isUpdate ? 'Actualizar stock' : 'Descontar stock'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.call(this)
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isIncrease && <IncreaseInventoryStockForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDecrease && <DecreaseInventoryStockForm onCancel={closeModal} onFinish={onFinish}/>}
        {isUpdate && <UpdateInventoryStockForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canIncrease && selectedInventoryId && (
        <SidebarButton label="Ingresar stock" type="POST" onClick={() => {
          setOption('INCREASE')
          setOpen(true)
        }}/>
      )}

      {canDecrease && selectedInventoryId && (
        <SidebarButton label="Descontar stock" type="DELETE" onClick={() => {
          setOption('DECREASE')
          setOpen(true)
        }}/>
      )}

      {canUpdate && selectedInventoryId && (
        <SidebarButton label="Actualizar stock" type="PUT" onClick={() => {
          setOption('UPDATE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
