export enum SubscriptionStatusValue {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export class SubscriptionStatus {
  readonly value!: SubscriptionStatusValue

  constructor(value: SubscriptionStatusValue) {
    this.value = value
  }

  static fromString(value: string): SubscriptionStatus {
    if (!this.toList().includes(value as SubscriptionStatusValue)) {
      throw new Error(`Invalid SubscriptionStatus: ${value}`)
    }

    return new SubscriptionStatus(value as SubscriptionStatusValue)
  }

  static toList(): SubscriptionStatusValue[] {
    return Object.values(SubscriptionStatusValue)
  }

  static toEntries(): [SubscriptionStatusValue, SubscriptionStatus][] {
    return this.toList().map((value) => [ value, new SubscriptionStatus(value) ])
  }

  static readonly labels: Record<SubscriptionStatusValue, string> = {
    ACTIVE   : 'Activo',
    INACTIVE : 'Inactivo',
  }

  label(): string {
    return SubscriptionStatus.labels[this.value]
  }
}
