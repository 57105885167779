import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { CountrySelector } from '@/presentation/common/components/CountrySelector.tsx'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { UpdateInventoryFormProps } from '@/presentation/inventories/forms/types.ts'
import { QueryInventoriesForTableResult } from '@/presentation/inventories/hooks/useQueryInventoriesForTable.ts'
import { useUpdateInventory } from '@/presentation/inventories/hooks/useUpdateInventory.ts'

type UpdateInventoryFormFields = Partial<{
  name: string,
  description?: string,
  location: {
    country?: string,
    city?: string,
    address?: string,
  },
  contact: {
    name?: string,
    email?: string,
    phone?: string,
  },
}>

export const UpdateInventoryForm: FC<UpdateInventoryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateInventoryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateInventory()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryInventoriesForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateInventoryFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateInventoryFormFields

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        name        : editedValues.name,
        description : editedValues.description ?? initialValues.description,
        location    : editedValues.location ? {
          country : editedValues.location?.country ?? null,
          city    : editedValues.location?.city ?? null,
          address : editedValues.location?.address ?? null,
        } : initialValues.location,
        contact: editedValues.contact ? {
          name  : editedValues.contact?.name ?? null,
          email : editedValues.contact?.email ?? null,
          phone : editedValues.contact?.phone ?? null,
        } : initialValues.contact,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del espacio">
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="description"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <FormSection title="Ubicación">
            <Form.Item
              label="País"
              name={[ 'location', 'country' ]}
              rules={[{ required: false }]}
            >
              <CountrySelector/>
            </Form.Item>

            <Form.Item
              label="Ciudad"
              name={[ 'location', 'city' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Dirección"
              name={[ 'location', 'address' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <FormSection title="Contacto">
            <Form.Item
              label="Nombre"
              name={[ 'contact', 'name' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Correo electrónico"
              name={[ 'contact', 'email' ]}
              rules={[{ required: false }, { type: 'email' }]}
            >
              <Input type="email"/>
            </Form.Item>

            <Form.Item
              label="Teléfono"
              name={[ 'contact', 'phone' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
