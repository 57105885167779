import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateRentalReceiverRequest,
  DeleteManyRentalsReceiversRequest,
  QueryRentalReceiversRequest,
  UpdateRentalReceiverRequest,
} from '@/api/rentalsReceivers/request.types.ts'
import {
  CreateRentalReceiverResponse,
  DeleteManyRentalsReceiversResponse,
  QueryRentalsReceiversResponse,
  UpdateRentalReceiverResponse,
} from '@/api/rentalsReceivers/response.types.ts'

export type RentalsReceiversApiClient = {
  query: (options: QueryOptions<QueryRentalReceiversRequest>) => Promise<QueryRentalsReceiversResponse>
  create: (req: CreateRentalReceiverRequest) => Promise<CreateRentalReceiverResponse>
  update: (req: UpdateRentalReceiverRequest) => Promise<UpdateRentalReceiverResponse>
  deleteMany: (req: DeleteManyRentalsReceiversRequest) => Promise<DeleteManyRentalsReceiversResponse>
}

export const useRentalsReceiversApiClient = (): RentalsReceiversApiClient => ({
  query: async (options: QueryOptions<QueryRentalReceiversRequest>) => await getMapping<QueryRentalsReceiversResponse, QueryParams>({
    path   : `/rentals/${options.pathParams?.rentalId}/receivers`,
    params : options.params,
  }),

  create: async (req: CreateRentalReceiverRequest) => await postMapping<CreateRentalReceiverResponse, CreateRentalReceiverRequest['body']>({
    path : `/rentals/${req.query.rentalId}/receivers`,
    body : req.body,
  }),

  update: async (req: UpdateRentalReceiverRequest) => await putMapping<UpdateRentalReceiverResponse, UpdateRentalReceiverRequest['body']>({
    path : `/rentals/${req.query.rentalId}/receivers/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyRentalsReceiversRequest) => await deleteMapping<DeleteManyRentalsReceiversResponse, DeleteManyRentalsReceiversRequest>({
    path : '/rentals/receivers',
    body : req,
  }),
})
