import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryFuelRegistriesResponse } from '@/api/fuelRegistries/response.types.ts'
import { useFuelRegistriesApiClient } from '@/api/fuelRegistries/useFuelRegistriesApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryFuelRegistriesForTableResult = PaginatedData<{
  id: string
  type: string
  date: string
  time: string
  machinery: {
    id: string | null
    type: string
    code: string | null
    brand: string | null
    model: string | null
    year: number | null
    serialNumber: string | null
    description: string | null
  } | null
  equipment: string | null
  hourmeter: number | null
  liters: number
  guideNumber: number | null
}>

export const useQueryFuelRegistriesForTable = () => {
  const repository = useFuelRegistriesApiClient()
  const queryItemsHook = useQueryItems<QueryFuelRegistriesResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron registros de suministro de combustible',
      loading : 'Cargando registros',
      success : 'Registros cargados',
    },
  })

  const [ data, setData ] = useState<QueryFuelRegistriesForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryFuelRegistriesResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id        : item.id.toString(),
        type      : item.type,
        date      : item.date,
        time      : item.time,
        machinery : item.machinery ? {
          id           : item.machinery.id?.toString() ?? null,
          type         : item.machinery.type,
          code         : item.machinery.code ?? null,
          brand        : item.machinery.brand ?? null,
          model        : item.machinery.model ?? null,
          year         : item.machinery.year ?? null,
          serialNumber : item.machinery.serialNumber ?? null,
          description  : item.machinery.description ?? null,
        } : null,
        equipment   : item.equipment ?? null,
        hourmeter   : item.hourmeter ?? null,
        liters      : item.liters,
        guideNumber : item.guideNumber ?? null,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
