import numeral from 'numeral'
import { FC, ReactNode, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { HR } from '@/presentation/common/components/HR.tsx'
import 'numeral/locales/es'
import { Typography } from 'antd'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import classNames from 'classnames'

numeral.locale('es')

type Props = {
  title: ReactNode,
  columnDefs: DataGridColumnDefs[],
  rowData: any[],
  rentalType: RentalTypeValue,
  summary: {
    total: number,
    vat: number,
    totalWithVat: number,
  },
}

export const PayStatesDetailsTable: FC<Props> = ({ title, columnDefs, rowData, summary, rentalType }) => {
  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const tableConfig = useMemo(() => ({
    defaultColDef,
    columnDefs,
    rowHeight    : 50,
    rowData      : rowData || [],
    rowSelection : 'multiple',
  } as DataGridApiConfig), [ rowData, columnDefs ])

  const classNamePill = classNames('py-0.5 px-2 font-semibold rounded-sm text-base', {
    'bg-orange-400 text-white' : rentalType === RentalTypeValue.INTERNAL,
    'bg-purple-400 text-white' : rentalType === RentalTypeValue.EXTERNAL,
  })

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <Typography.Title level={5} className="px-1">{title}</Typography.Title>
        <span className={classNamePill}>
          {RentalType.fromString(rentalType).label().toUpperCase()}S
        </span>
      </div>

      <DataGrid config={tableConfig}/>

      <section className="app-card mt-1 px-4 py-2 flex flex-col items-end bg-tertiary-main">
        <div className="w-full text-white"><span className="text-xs">Desglose de Totales</span></div>
        <HR className="w-full opacity-30"/>
        <div className="text-white">
          <span className="text-sm">Neto</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{numeral(summary.total).format('$0,0')}</span>
        </div>
        <HR className="w-full border-dashed opacity-30"/>
        <div className="text-white">
          <span className="text-sm">IVA 19%</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{numeral(summary.vat).format('$0,0')}</span>
        </div>
        <HR className="w-full border-dashed opacity-30"/>
        <div className="text-white">
          <span className="text-sm">Total</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{numeral(summary.totalWithVat).format('$0,0')}</span>
        </div>
        <HR className="w-full opacity-30"/>
      </section>
    </div>
  )
}
