import { Typography } from 'antd'
import { FC } from 'react'
import { FormLayoutProps } from '@/presentation/common/layout/types.ts'

export const FormLayout: FC<FormLayoutProps> = ({ title, children }) => {
  return (
    <div className="flex flex-col app-def-pb">
      <section
        className="flex flex-col gap-2 sticky top-0 z-[100] app-def-px app-def-pt pb-3 mb-2 border-b border-outline bg-background"
      >
        <div className="flex flex-wrap justify-end items-center gap-2">
          <div className="flex-1">
            <Typography.Title level={4} className="!m-0 break-normal">{title || ''}</Typography.Title>
          </div>
        </div>
      </section>

      <section className="app-def-px-small">
        {children}
      </section>
    </div>
  )
}
