import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

export const useCrudAuth = () => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())

  const displayUpdate = selectionCount === 1
  const displayDelete = selectionCount > 0

  return {
    canRead   : true,
    canCreate : true,
    canUpdate : displayUpdate,
    canDelete : displayDelete,
  }
}
