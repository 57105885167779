import { DeleteManyFuelRegistriesRequest } from '@/api/fuelRegistries/request.types.ts'
import { DeleteManyFuelRegistriesResponse } from '@/api/fuelRegistries/response.types.ts'
import { useFuelRegistriesApiClient } from '@/api/fuelRegistries/useFuelRegistriesApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteFuelRegistries = () => {
  const repository = useFuelRegistriesApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyFuelRegistriesRequest, DeleteManyFuelRegistriesResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los registros',
      success : 'Registros eliminados',
      loading : 'Eliminando registros',
    },
  })

  return {
    ...deleteItems,
  }
}
