import dayjs, { Dayjs } from 'dayjs'

type DisabledTimes = (date: Dayjs) => ({
  disabledHours?: () => number[];
  disabledMinutes?: (hour: number) => number[];
  disabledSeconds?: (hour: number, minute: number) => number[];
})

export const hideTimesAfterNow: DisabledTimes = (date: Dayjs) => {
  const now = dayjs()
  const isAfterNow = date.isAfter(now)

  if (isAfterNow) {
    return {
      disabledHours   : () => [],
      disabledMinutes : () => [],
      disabledSeconds : () => [],
    }
  }

  return {
    disabledHours: () => {
      const hours = now.hour()
      return Array.from(Array(hours).keys())
    },
    disabledMinutes: (hour: number) => {
      if (hour === now.hour()) {
        const minutes = now.minute()
        return Array.from(Array(minutes).keys())
      }
      return []
    },
    disabledSeconds: (hour: number, minute: number) => {
      if (hour === now.hour() && minute === now.minute()) {
        const seconds = now.second()
        return Array.from(Array(seconds).keys())
      }
      return []
    },
  }
}

export const hideHoursAfterNow = () => {
  const hours = []
  const currentHour = dayjs().hour()

  for (let i = currentHour + 1; i <= 24; i++) {
    hours.push(i)
  }

  return hours
}

export const hideMinutesAfterNow = (selectedHour: number) => {
  const minutes = []
  const currentMinute = dayjs().minute()
  if (selectedHour === dayjs().hour()) {
    for (let i = currentMinute + 1; i <= 60; i++) {
      minutes.push(i)
    }
  }
  return minutes
}

export const hideSecondsAfterNow = (selectedHour: number, selectedMinute: number) => {
  const seconds = []
  const currentSecond = dayjs().second()
  if (selectedHour === dayjs().hour() && selectedMinute === dayjs().minute()) {
    for (let i = currentSecond + 1; i <= 60; i++) {
      seconds.push(i)
    }
  }
  return seconds
}
