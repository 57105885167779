import { Typography } from 'antd'
import classNames from 'classnames'
import { FC } from 'react'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalType } from '@/domain/models/rentalType.ts'
import { UserRole, UserRoleValue } from '@/domain/models/userRole.ts'
import { WorkingDay } from '@/domain/models/workingDay.ts'
import {
  QueryMachineryUseRecordsForDashboardResult,
} from '@/presentation/machineryUseDashboard/hooks/useQueryMachineryUseRecordsForDashboard.ts'

type Props = {
  record: QueryMachineryUseRecordsForDashboardResult['items'][0]
}

export const MachineryUseCard: FC<Props> = ({ record }) => {
  const className = classNames('flex align-middle w-full gap-4 p-3 border border-tertiary-light rounded-md bg-surface overflow-hidden min-w-[300px] min-h-[100px]', {
    '!bg-error-min': record.hadIncidences,
  })

  return (
    <div className={className}>
      <section className="flex flex-col gap-1.5 flex-1 overflow-hidden">
        {/* MACHINERY */}
        <div className="flex-1 flex flex-nowrap gap-1.5 items-center">
          <div className="flex-1">
            {record.hadIncidences && (<i className="ri-flag-2-fill text-red-500 cursor-default text-[1.2rem] pl-2"></i>)}
            <Typography.Text className="text-sm select-text font-medium pl-2">{record.machinery}</Typography.Text>
          </div>

          {record.status === 'pending' && (
            <div className="py-1 px-2 font-semibold font-mono text-xs border bg-orange-50 text-orange-500 border-orange-500 rounded-sm inline-block select-text">
              PENDIENTE
            </div>
          )}

          {record.status === 'done' && (
            <div className="py-1 px-2 font-semibold font-mono text-xs border bg-emerald-50 text-emerald-500 border-emerald-500 rounded-sm inline-block select-text">
              REALIZADA
            </div>
          )}

          <div className="py-1 px-2 font-semibold font-mono text-xs border text-slate-500 border-slate-500 rounded-sm inline-block select-text">
            {RentalType.fromString(record.rental.type).label().toUpperCase()}
          </div>
        </div>

        {/* OPERATOR */}
        <div className="flex-1 flex flex-wrap gap-1.5 items-center">
          <div className="flex-1 flex flex-nowrap gap-1.5 items-center">
            <div className="min-w-[135px]">
              <div
                className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-text">
                {new UserRole(UserRoleValue.MACHINE_OPERATOR).label().toUpperCase()}
              </div>
            </div>

            <Typography.Text className="text-sm select-text whitespace-nowrap">{record.operator}</Typography.Text>
          </div>

          <div className="flex-1 flex flex-nowrap gap-1.5">
            <div className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-text">
              OBRA
            </div>

            <Typography.Text className="text-sm select-text whitespace-nowrap">{record.rental.building ?? '-'}</Typography.Text>
          </div>
        </div>

        {/* CONDITIONS */}
        <div className="flex flex-nowrap items-center gap-1.5">
          <div className="min-w-[135px]">
            <div className="py-1 px-2 bg-emerald-50 font-mono text-xs rounded-md inline-block select-text">
              {RentalConditionType.fromString(record.rentalCondition.type).label()?.toUpperCase()}
            </div>
          </div>

          {record.truckVolume && (
            <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Volumen:</b> {record.truckVolume ?? '-'}</Typography.Text>
          )}

          { record.rentalCondition.type === RentalConditionTypeValue.TRAVELS ||
            record.rentalCondition.type === RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO && (
              <>
                <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Viajes:</b> {record.machineryConditionValues.totalTravels  ?? '-'}</Typography.Text>
                <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Carga:</b> {record.rentalCondition.load  || '-'}</Typography.Text>
              </>
            )
          }

          {record.rentalCondition.type === RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD && (
            <>
              <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Viajes:</b> {record.machineryConditionValues.totalTravels  ?? '-'}</Typography.Text>
            </>
          )}

          {record.rentalCondition.type === RentalConditionTypeValue.WORKING_DAY && (
            <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Jornada:</b> {
              record.machineryConditionValues.workingDay ? WorkingDay.fromString(record.machineryConditionValues.workingDay).label() : '-'
            }</Typography.Text>
          )}

          {record.rentalCondition.type === RentalConditionTypeValue.HOURS && (
            <>
              <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Horómetro inicial:</b> {record.machineryConditionValues.startHourmeter ?? '-'}</Typography.Text>
              <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Horómetro final:</b> {record.machineryConditionValues.endHourmeter ?? '-'}</Typography.Text>
              <Typography.Text className="text-sm select-text whitespace-nowrap"><b>Total horas:</b> {record.machineryConditionValues.totalHours ?? '-'}</Typography.Text>
            </>
          )}
        </div>
      </section>
    </div>
  )
}
