import { FC, useEffect, useMemo, useState } from 'react'
import { MaintenanceStatus } from '@/domain/models/maintenanceStatus.ts'
import { MaintenanceType } from '@/domain/models/maintenanceType.ts'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MTCellMachinery } from '@/presentation/machinery/components/MTCellMachinery.tsx'
import { MTCellMaintenanceStatus } from '@/presentation/maintenance/components/MTCellMaintenanceStatus.tsx'
import { MaintenanceActions } from '@/presentation/maintenance/forms/MaintenanceActions.tsx'
import {
  QueryMaintenancesForTableResult,
  useQueryMaintenancesForTable,
} from '@/presentation/maintenance/hooks/useQueryMaintenancesForTable.ts'
import { useMaintenancesMaintainerStore } from '@/presentation/maintenance/store/maintenancesMaintainers.ts'

const MaintenancesTable: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, setSelectedMaintenanceId } = useMaintenancesMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryMaintenancesForTable()

  // ayuda a mantener la selección cuando se cambia de tab, cuando el tab vuelva a ser maintenances, se configura la selección del mantenedor con esta variable
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'status',
      headerName              : 'Estado',
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      comparator              : stringComparator,
      formatValue             : ({ value }: { value: string }) => MaintenanceStatus.fromString(value).label(),
      cellRenderer            : (cellNode, node, column, api) => <MTCellMaintenanceStatus {...{ cellNode, node, column, api }}/>,
    },
    {
      field        : 'machinery',
      headerName   : 'Maquinaria',
      comparator   : stringComparator,
      cellRenderer : (cellNode, node, column, api) => <MTCellMachinery {...{ cellNode, node, column, api }}/>,
      formatValue  : ({ value }: { value: QueryMaintenancesForTableResult['items'][0]['machinery']}) =>
        `${value.code ?? ''} ${value.brand ?? ''} ${value.model ?? ''} ${value.serialNumber ?? ''} ${value.type}`.trim(),
      width: 300,
    },
    {
      field       : 'machinery.maintenanceType',
      headerName  : 'Clase',
      comparator  : stringComparator,
      formatValue : ({ value }: { value: string | null}) => value ? MaintenanceType.fromString(value).label() : '',
    },
    {
      field      : 'step',
      headerName : 'Tramo',
      comparator : numberComparator,
    },
    {
      field      : 'hourmeter',
      headerName : 'Horómetro',
      comparator : numberComparator,
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 76,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      setCurrentSelection(selectedRows)
      setSelectionV2(selectedRows)
      setSelectedMaintenanceId(selectedRows.length === 1 ? selectedRows[0]?.data.id : null)
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <MaintenanceActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'maintenances') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // first load
  useEffect(() => {
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'maintenances') {
      reset()
      setTitle('Mantenciones')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'maintenances') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'maintenances' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}

export default MaintenancesTable
