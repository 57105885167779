import { Tooltip } from 'antd'
import { FC } from 'react'
import { MaintainerDownloadExcelProps } from '@/presentation/common/components/maintainer/types.ts'

export const MaintainerDownloadExcel: FC<MaintainerDownloadExcelProps> = ({ onClick }) => {
  return (
    <Tooltip placement="left" title="Excel">
      <button className="h-[32px] w-[32px]" onClick={onClick}>
        <i className="ri-file-excel-line"></i>
      </button>
    </Tooltip>
  )
}
