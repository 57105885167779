import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, useEffect } from 'react'
import { FuelRegistryOperationType, FuelRegistryOperationTypeValue } from '@/domain/models/fuelOperationType.ts'

export const FuelOperationTypeSelector: FC<SelectProps> = (props) => {
  const value = props.value || FuelRegistryOperationTypeValue.REFILL

  useEffect(() => {
    props.onChange?.(value, FuelRegistryOperationType.fromString(value) as never)
  }, [])

  return (
    <Select
      placeholder="Seleccione un tipo de operación"
      {...props}
      value={value}
    >
      {FuelRegistryOperationType.toEntries().map(([ key, value ]) => (
        <Select.Option key={key} value={value.value}>{value.label()}</Select.Option>
      ))}
    </Select>
  )
}
