import { MaintenanceTypeValue } from '@/domain/models/maintenanceType.ts'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'

export enum MachineryTypeValue {
  MINI_EXCAVADORA = 'MINI_EXCAVADORA',
  MINI_RETROEXCAVADORA = 'MINI_RETROEXCAVADORA',
  MINI_CARGADOR = 'MINI_CARGADOR',
  CARGADOR_FRONTAL = 'CARGADOR_FRONTAL',
  EXCAVADORA = 'EXCAVADORA',
  RETROEXCAVADORA = 'RETROEXCAVADORA',
  RODILLO = 'RODILLO',
  BULLDOZER = 'BULLDOZER',
  MOTONIVELADORA = 'MOTONIVELADORA',
  CAMION_TOLVA = 'CAMION_TOLVA',
  CAMION_ALJIBE = 'CAMION_ALJIBE',
  CAMION_PLUMA = 'CAMION_PLUMA',
  CAMA_BAJA = 'CAMA_BAJA',
  CAMIONETA = 'CAMIONETA',
}

export class MachineryType {
  readonly value!: MachineryTypeValue

  constructor(value: MachineryTypeValue) {
    this.value = value
  }

  static fromString(value: string): MachineryType {
    if (!this.toList().includes(value as MachineryTypeValue)) {
      throw new Error(`Invalid MachineryType: ${value}`)
    }

    return new MachineryType(value as MachineryTypeValue)
  }

  static toList(): MachineryTypeValue[] {
    return Object.values(MachineryTypeValue)
  }

  static toEntries(): [MachineryTypeValue, MachineryType][] {
    return this.toList().map((value) => [ value, new MachineryType(value) ])
  }

  static getMachineryCategoryTypes(): MachineryTypeValue[] {
    return [
      MachineryTypeValue.MINI_EXCAVADORA,
      MachineryTypeValue.MINI_RETROEXCAVADORA,
      MachineryTypeValue.MINI_CARGADOR,
      MachineryTypeValue.CARGADOR_FRONTAL,
      MachineryTypeValue.EXCAVADORA,
      MachineryTypeValue.RETROEXCAVADORA,
      MachineryTypeValue.RODILLO,
      MachineryTypeValue.BULLDOZER,
      MachineryTypeValue.MOTONIVELADORA,
    ]
  }

  static getTruckCategoryTypes(): MachineryTypeValue[] {
    return [
      MachineryTypeValue.CAMION_TOLVA,
      MachineryTypeValue.CAMION_ALJIBE,
      MachineryTypeValue.CAMION_PLUMA,
      MachineryTypeValue.CAMA_BAJA,
    ]
  }

  static readonly labels: Record<MachineryTypeValue, string> = {
    MINI_EXCAVADORA      : 'Mini excavadora',
    MINI_RETROEXCAVADORA : 'Mini retroexcavadora',
    MINI_CARGADOR        : 'Mini cargador',
    CARGADOR_FRONTAL     : 'Cargador frontal',
    EXCAVADORA           : 'Excavadora',
    RETROEXCAVADORA      : 'Retroexcavadora',
    RODILLO              : 'Rodillo',
    BULLDOZER            : 'Bulldozer',
    MOTONIVELADORA       : 'Motoniveladora',
    CAMION_TOLVA         : 'Camión tolva',
    CAMION_ALJIBE        : 'Camión aljibe',
    CAMION_PLUMA         : 'Camión pluma',
    CAMA_BAJA            : 'Cama baja',
    CAMIONETA            : 'Camioneta',
  }

  label(): string {
    return MachineryType.labels[this.value]
  }

  hasVolume(): boolean {
    switch (this.value) {
      case MachineryTypeValue.CAMION_TOLVA:
      case MachineryTypeValue.CAMION_ALJIBE:
      case MachineryTypeValue.CAMION_PLUMA:
      case MachineryTypeValue.CAMA_BAJA:
        return true
      default:
        return false
    }
  }

  allowedMaintenances(): MaintenanceTypeValue[] {
    switch (this.value) {
      case MachineryTypeValue.MINI_EXCAVADORA:
      case MachineryTypeValue.MINI_RETROEXCAVADORA:
      case MachineryTypeValue.MINI_CARGADOR:
      case MachineryTypeValue.CARGADOR_FRONTAL:
      case MachineryTypeValue.EXCAVADORA:
      case MachineryTypeValue.RETROEXCAVADORA:
      case MachineryTypeValue.RODILLO:
      case MachineryTypeValue.BULLDOZER:
      case MachineryTypeValue.MOTONIVELADORA:
        return [
          MaintenanceTypeValue.CLASS_A,
          MaintenanceTypeValue.CLASS_B,
        ]
      case MachineryTypeValue.CAMA_BAJA:
      case MachineryTypeValue.CAMION_TOLVA:
      case MachineryTypeValue.CAMION_ALJIBE:
      case MachineryTypeValue.CAMION_PLUMA:
        return [
          MaintenanceTypeValue.CLASS_C,
          MaintenanceTypeValue.CLASS_D,
        ]
      default:
        return []
    }
  }

  allowedRentalConditionTypes(): RentalConditionTypeValue[] {
    switch (this.value) {
      case MachineryTypeValue.MINI_EXCAVADORA:
      case MachineryTypeValue.MINI_RETROEXCAVADORA:
      case MachineryTypeValue.MINI_CARGADOR:
      case MachineryTypeValue.CARGADOR_FRONTAL:
      case MachineryTypeValue.EXCAVADORA:
      case MachineryTypeValue.RETROEXCAVADORA:
      case MachineryTypeValue.RODILLO:
      case MachineryTypeValue.BULLDOZER:
      case MachineryTypeValue.MOTONIVELADORA:
        return [
          RentalConditionTypeValue.HOURS,
        ]
      case MachineryTypeValue.CAMA_BAJA:
        return [
          RentalConditionTypeValue.TRAVELS,
          RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD,
        ]
      case MachineryTypeValue.CAMION_TOLVA:
        return [
          RentalConditionTypeValue.TRAVELS,
          RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD,
          RentalConditionTypeValue.WORKING_DAY,
          RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO,
        ]
      case MachineryTypeValue.CAMION_ALJIBE:
        return [
          RentalConditionTypeValue.TRAVELS,
          RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD,
          RentalConditionTypeValue.WORKING_DAY,
        ]
      case MachineryTypeValue.CAMION_PLUMA:
        return [
          RentalConditionTypeValue.TRAVELS,
          RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD,
          RentalConditionTypeValue.WORKING_DAY,
          RentalConditionTypeValue.HOURS,
        ]
      default:
        return []
    }
  }
}
