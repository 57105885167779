import { useState } from 'react'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useDownloadExcelFile } from '@/presentation/common/hooks/crud/v2/useDownloadExcelFile.ts'

export const useDownloadTenantsExcel = () => {
  const repository = useTenantsApiClient()
  const query = useDownloadExcelFile<void>({
    filename : 'tenants.xlsx',
    callable : repository.downloadExcelResume,
    messages : {
      default : 'Error al generar archivo',
      loading : 'Generando archivo',
      success : 'Archivo generado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async () => {
    const response = await query.submit()
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
