import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export const MTCellUseRecordIncidencesFlag = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
      className: '!px-0',
    }}>
      <>
        {cellNode.value && (
          <span className="text-red-500 flex justify-center">
            <i className="ri-flag-2-fill cursor-default text-[1.2rem]"></i>
          </span>
        )}
      </>
    </MTCellBase>
  )
}
