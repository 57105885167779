import { Button, Form } from 'antd'
import { FC } from 'react'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { LoadEppProductsTemplateFormProps } from '@/presentation/epp/forms/types.ts'
import { useLoadEppProductsTemplateProducts } from '@/presentation/epp/hooks/useLoadEppProductsTemplateProducts.ts'
import { useEppsMaintainerStore } from '@/presentation/epp/store/eppsMaintainers.ts'
import { ProductsTemplateSelector } from '@/presentation/productsTemplates/components/ProductsTemplateSelector.tsx'

type LoadProductsTemplateFormFields = {
  template: string
}

export const LoadEppProductsTemplateForm: FC<LoadEppProductsTemplateFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'LoadEppProductsTemplateForm-alert'

  const { submit, isSuccess, isLoading, isError, message } = useLoadEppProductsTemplateProducts()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedEppId } = useEppsMaintainerStore()

  const onSubmit = async (values: LoadProductsTemplateFormFields) => {
    if (isLoading || !selectedEppId) {
      return
    }

    const response = await submit({
      query: {
        eppId: selectedEppId,
      },
      body: {
        template: values.template,
      },
    })
    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Plantilla"
        name="template"
        rules={[{ required: true }]}
      >
        <ProductsTemplateSelector/>
      </Form.Item>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Cargar productos</Button>
      </div>
    </Form>
  )
}
