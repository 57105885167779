import { Button, Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { MachineryTypeSelector } from '@/presentation/machinery/components/MachineryTypeSelector.tsx'
import { RentalMachineryConditionsFormChunk } from '@/presentation/rentals/forms/RentalMachineryConditionsFormChunk.tsx'
import { CreateRentalExternalMachineryFormProps } from '@/presentation/rentals/forms/types.ts'
import { useCreateRentalMachinery } from '@/presentation/rentals/hooks/useCreateRentalMachinery.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

type CreateRentalExternalMachineryFormFields = {
  machinery: {
    type: MachineryTypeValue,
    description: string,
    truckVolume: number | undefined,
  },
  operator: string,
  rentalCondition: {
    type: RentalConditionTypeValue,
    minHours        : number | undefined,
    amountPerHour   : number | undefined,
    amountPerDay    : number | undefined,
    load            : string | undefined,
    loadOrigin      : string | undefined,
    amountPerTravel : number | undefined,
  },
}

export const CreateRentalExternalMachineryForm: FC<CreateRentalExternalMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateRentalExternalMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateRentalMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedRentalId, selectedRentalType } = useRentalsMaintainerStore()

  const [ form ] = Form.useForm()
  const machineryTypeValue = Form.useWatch([ 'machinery', 'type' ], form)
  const machineryType = machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : undefined

  const onSubmit = async (values: CreateRentalExternalMachineryFormFields) => {
    if (isLoading || !selectedRentalId || !selectedRentalType) {
      return
    }

    const response = await submit({
      query: {
        rentalId: selectedRentalId,
      },
      body: {
        rentalType : selectedRentalType,
        machinery  : {
          type        : MachineryType.fromString(values.machinery.type).value,
          description : values.machinery.description,
          truckVolume : values.machinery.truckVolume || null,
        },
        operator        : values.operator,
        rentalCondition : {
          type            : RentalConditionType.fromString(values.rentalCondition.type).value,
          minHours        : values.rentalCondition.minHours || null,
          amountPerHour   : values.rentalCondition.amountPerHour || null,
          amountPerDay    : values.rentalCondition.amountPerDay || null,
          load            : values.rentalCondition.load || null,
          loadOrigin      : values.rentalCondition.loadOrigin || null,
          amountPerTravel : values.rentalCondition.amountPerTravel || null,
        },
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Maquinaria arrendada">
        <Form.Item
          name={[ 'machinery', 'type' ]}
          label='Tipo de maquinaria'
          rules={[{ required: true }]}
        >
          <MachineryTypeSelector excludePickup/>
        </Form.Item>

        <Form.Item
          name={[ 'machinery', 'description' ]}
          label='Nombre de maquinaria'
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        {machineryType?.hasVolume() && (
          <Form.Item
            name={[ 'machinery', 'truckVolume' ]}
            label='Volumen m³'
            rules={[{ required: false }]}
          >
            <InputNumber min={0}/>
          </Form.Item>
        )}

        <Form.Item
          name="operator"
          label='Operador'
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <RentalMachineryConditionsFormChunk form={form} machineryType={machineryType} />
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
