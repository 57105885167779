import { ApiError } from '@/api/common/apiError.ts'
import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'
import { AlreadyExistsException } from '@/domain/exceptions/alreadyExistsException.ts'
import { TenantIsNotActiveException } from '@/domain/exceptions/tenantIsNotActiveException.ts'
import { UnknownErrorException } from '@/domain/exceptions/unknownErrorException.ts'

export const apiErrorMapper = (error: ApiErrorResponse): void => {
  if (error.internalStatusCode === ApiError.TENANT_IS_NOT_ACTIVE) {
    throw new TenantIsNotActiveException()
  }
  else if (error.internalStatusCode === ApiError.ENTITY_ALREADY_EXISTS) {
    throw new AlreadyExistsException(error)
  }

  throw new UnknownErrorException(error)
}
