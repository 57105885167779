import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { UsernameInput } from '@/presentation/common/components/UsernameInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'
import { RoleSelector } from '@/presentation/users/components/RoleSelector.tsx'
import { CreateUserFormProps } from '@/presentation/users/forms/types.ts'
import { useCreateUser } from '@/presentation/users/hooks/useCreateUser.ts'

type CreateUserFormFields = {
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  role: UserRoleValue,
  signature?: string,
  dni?: string,
}

export const CreateUserForm: FC<CreateUserFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateUserForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateUser()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateUserFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      username  : values.username,
      firstName : values.firstName,
      lastName  : values.lastName,
      email     : values.email,
      role      : values.role,
      signature : values.signature ?? null,
      dni       : values.dni ?? null,
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del usuario">
        <Form.Item
          label="Usuario"
          name="username"
          rules={[{ required: true }]}
        >
          <UsernameInput/>
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Apellido"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="RUT"
          name="dni"
          rules={[
            { required: false },
            { validator: rutValidator },
          ]}
        >
          <RutInput/>
        </Form.Item>

        <Form.Item
          label="Correo de contacto"
          name="email"
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Rol"
          name="role"
          rules={[{ required: true }]}
        >
          <RoleSelector/>
        </Form.Item>
      </FormSection>

      <FormSection title="Firma">
        <Form.Item
          label="Firma digital"
          name="signature"
          rules={[{ required: false }]}
        >
          <SignPad/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
