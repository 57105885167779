import { FC } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { routes } from '@/presentation/common/router/routes.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

type Props = NonNullable<unknown>

/**
 * This component is used to redirect to home if the user is authenticated
 * @constructor
 */
export const PublicNotVisibleRoute: FC<Props> = () => {
  const session = useSessionStore()
  const isAuthenticated = session.accessToken !== null
  return isAuthenticated ? <Navigate to={routes.modules}/> : <Outlet/>
}
