import { Typography } from 'antd'
import numeral from 'numeral'
import { useEffect, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { HR } from '@/presentation/common/components/HR.tsx'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { PayStateResumeActions } from '@/presentation/payStates/forms/PayStateResumeActions.tsx'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'
import 'numeral/locales/es'

numeral.locale('es')

export const PayStatesResumeTable = () => {
  const { setActionsSlot, setSelectionV2 } = useMaintainerStore()
  const { data } = usePayStatePreviewStore()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'item',
      headerName              : 'Descripción',
      comparator              : stringComparator,
      headerCheckboxSelection : false,
      checkboxSelection       : true,
    },
    {
      field              : 'quantity',
      headerName         : 'Unidades',
      width              : 120,
      comparator         : numberComparator,
      cellRendererParams : {
        justify: 'center',
      },
    },
    {
      field              : 'unitPrice',
      headerName         : 'Monto Unitario',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
    {
      field              : 'total',
      headerName         : 'Monto Total',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    defaultColDef,
    columnDefs,
    rowHeight         : 50,
    rowData           : data?.resume.detail || [],
    rowSelection      : 'single',
    onSelectionChange : (_selectedRow, selectedRows) => {
      setSelectionV2(selectedRows)
    },
  } as DataGridApiConfig), [ data?.resume.detail ])

  const actionsComponent = useMemo(() => <PayStateResumeActions/>, [])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  return (
    <div className="flex flex-col">
      <Typography.Title level={5} className="px-1">Resumen</Typography.Title>

      <DataGrid config={tableConfig}/>

      <section className="app-card mt-1 px-4 py-2 flex flex-col items-end bg-tertiary-main">
        <div className="w-full text-white"><span className="text-xs">Desglose de Totales</span></div>
        <HR className="w-full opacity-30"/>
        <div className="text-white">
          <span className="text-sm">Neto</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{data ? numeral(data.resume.summary.total).format('$0,0') : ''}</span>
        </div>
        <HR className="w-full border-dashed opacity-30"/>
        <div className="text-white">
          <span className="text-sm">IVA 19%</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{data ? numeral(data.resume.summary.vat).format('$0,0') : ''}</span>
        </div>
        <HR className="w-full border-dashed opacity-30"/>
        <div className="text-white">
          <span className="text-sm">Total</span>
          <span className="text-sm font-medium min-w-[150px] inline-block text-end">{data ? numeral(data.resume.summary.totalWithVat).format('$0,0') : ''}</span>
        </div>
        <HR className="w-full opacity-30"/>
      </section>
    </div>
  )
}
