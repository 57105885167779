import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'

export class BaseException extends Error {
  originalError?: ApiErrorResponse

  constructor(message: string, { originalError }: { meta?: object, originalError?: ApiErrorResponse } = {}) {
    super(message)
    this.originalError = originalError
  }
}
