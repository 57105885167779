import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

export const dateComparator = (valueA: string | Date, valueB: string | Date) => {
  const valueALabel = dayjs(valueA, 'DD-MM-YYYY')
  const valueBLabel = dayjs(valueB, 'DD-MM-YYYY')
  return valueALabel.isBefore(valueBLabel) ? -1 : valueALabel.isAfter(valueBLabel) ? 1 : 0
}
