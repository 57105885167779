import { Button, Form, InputNumber } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { UpdateMaintenanceProductFormProps } from '@/presentation/maintenance/forms/types.ts'
import {
  QueryMaintenanceProductsForTableResult,
} from '@/presentation/maintenance/hooks/useQueryMaintenanceProductsForTable.ts'
import { useUpdateMaintenanceProduct } from '@/presentation/maintenance/hooks/useUpdateMaintenanceProduct.ts'

type UpdateMaintenanceProductFormFields = {
  quantity?: number
}

export const UpdateMaintenanceProductForm: FC<UpdateMaintenanceProductFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateMaintenanceProductForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateMaintenanceProduct()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMaintenanceProductsForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateMaintenanceProductFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateMaintenanceProductFormFields

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        quantity: editedValues.quantity,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del producto">
            <Form.Item
              label="Cantidad"
              name="quantity"
              rules={[{ required: true }]}
            >
              <InputNumber min={0}/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
