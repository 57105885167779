import { FC } from 'react'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

type Props = {
  cellNode: CellNode
  node: RowNode
  column: DataGridColumnDefs
  api: DataGridApi
  className?: string
}

export const MTCellBadge: FC<Props> = ({ className, ...props }) => {
  const cellValue = props.cellNode.formattedValue ?? props.cellNode.value

  return (
    <MTCellBase {...props}>
      <div className={`py-1 px-3 pt-[0.33rem] text-sm rounded-md inline-block select-all ${className}`}>
        {cellValue}
      </div>
    </MTCellBase>
  )
}
