import { Button, DatePicker, Form } from 'antd'
import { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { useDownloadReport } from '@/presentation/machineryUsageReport/hooks/useDownloadReport.ts'

type MachineryUsageReportFormFields = {
  date: Dayjs
}

const MachineryUsageReport: FC = () => {
  const initialValues = useMemo(() => ({
    date: getLocalDateStartOf(),
  }), [])

  const messageApiKey = 'MachineryUsageReport-alert'
  const { submit, isLoading, isError, message, isSuccess, lastUpdate, data: file } = useDownloadReport()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: MachineryUsageReportFormFields) => {
    await submit({
      date: getLocalDateStartOf({ date: values.date }).format(),
    })
  }

  useEffect(() => {
    if (!isLoading && file) {
      window.open(URL.createObjectURL(file))
    }
  }, [ lastUpdate, file ])

  return (
    <FormLayout title="Estado de maquinarias">
      <section className="app-card">
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Filtros">
            <Form.Item
              label="Fecha"
              name="date"
              rules={[{ required: true }]}
            >
              <DatePicker className="w-full" format="DD-MM-YYYY"/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Obtener reporte</Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}

export default MachineryUsageReport
