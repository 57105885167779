import { FC, useContext, useEffect } from 'react'
import { SidebarContext } from '@/presentation/common/store/apiContext/SidebarContext.tsx'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const ToggleSidebar: FC = () => {
  const sidebarCtx = useContext(SidebarContext)
  const sidebarRightCtx = useContext(SidebarRightContext)

  useEffect(() => {
    sidebarCtx.forceClose()
    sidebarRightCtx.forceClose()
  }, [])

  return (
    <div onClick={() => {
      sidebarCtx.toggle()
      sidebarRightCtx.forceClose()
    }}>
      {sidebarCtx.isOpen ? (
        <i className="ri-menu-fold-line"></i>
      ) : (
        <i className="ri-menu-unfold-line"></i>
      )}
    </div>
  )
}
