import { QueryOptions } from '@/api/common/types.ts'
import { QueryProductsTemplatesResponse } from '@/api/productsTemplates/response.types.ts'
import { useProductsTemplatesApiClient } from '@/api/productsTemplates/useProductsTemplatesApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryProductsTemplatesOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryProductsTemplatesForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    description: string
  }
}

export const useQueryProductsTemplatesForSelector = () => {
  const repository = useProductsTemplatesApiClient()
  const queryItemsHook = useQueryItems<QueryProductsTemplatesResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron plantillas para mantención',
      success : 'Plantillas para mantención encontradas',
      loading : 'Buscando plantillas',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryProductsTemplatesForSelectorResult>()

  const query = async (options: QueryProductsTemplatesOptions = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryProductsTemplatesOptions, response?: QueryProductsTemplatesResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        value : item.id.toString(),
        label : item.description,
        data  : {
          id          : item.id.toString(),
          description : item.description,
        },
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
