import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useState } from 'react'
import { useQueryClientsForSelector } from '@/presentation/clients/hooks/useQueryClientsForSelector.ts'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'

export const ClientSelector: FC<SelectProps> = (props) => {
  const { data, query, hasMorePages, lastPage, isLoading } = useQueryClientsForSelector()
  const [ lastSearch, setLastSearch ] = useState<string>()

  const limit = 100

  useEffect(() => {
    query({
      params: {
        page: 1,
        limit,
      },
    })
  }, [])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
          },
        })
      }
    }
  }

  return (
    <Select
      placeholder="Seleccione un cliente"
      allowClear
      showSearch
      onSearch={onSearch}
      onPopupScroll={onScroll}
      filterOption={false}
      loading={isLoading}
      {...props}
      options={data.map((client) => ({
        label: (
          <div>
            <span className="font-medium mr-1.5 font-mono text-sm">{client.dni}</span>
            {client.name}
          </div>
        ),
        value: client.id,
      }))}
    />
  )
}
