import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateInventoryRequest,
  DeleteManyInventoriesRequest,
  UpdateInventoryRequest,
} from '@/api/inventories/request.types.ts'
import {
  CreateInventoryResponse,
  DeleteManyInventoriesResponse,
  DownloadExcelResumeResponse,
  QueryInventoriesResponse,
  UpdateInventoryResponse,
} from '@/api/inventories/response.types.ts'

export type InventoriesApiClient = {
  query: (options: QueryOptions) => Promise<QueryInventoriesResponse>
  create: (req: CreateInventoryRequest) => Promise<CreateInventoryResponse>
  update: (req: UpdateInventoryRequest) => Promise<UpdateInventoryResponse>
  deleteMany: (req: DeleteManyInventoriesRequest) => Promise<DeleteManyInventoriesResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useInventoriesApiClient = (): InventoriesApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryInventoriesResponse, QueryParams>({
    path   : '/inventories',
    params : options.params,
  }),

  create: async (body: CreateInventoryRequest) => await postMapping<CreateInventoryResponse, CreateInventoryRequest>({
    path : '/inventories',
    body : body,
  }),

  update: async (req: UpdateInventoryRequest) => await putMapping<UpdateInventoryResponse, UpdateInventoryRequest['body']>({
    path : `/inventories/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyInventoriesRequest) => await deleteMapping<DeleteManyInventoriesResponse, DeleteManyInventoriesRequest>({
    path : '/inventories',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/inventories/excel',
  }),
})
