import { QueryOptions } from '@/api/common/types.ts'
import { QueryRentalsResponse } from '@/api/rentals/response.types.ts'
import { useRentalsApiClient } from '@/api/rentals/useRentalsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

export type QueryRentalsBuildingsFilters = {
  since?: string
  until?: string
  client: string
}

type QueryRentalsBuildingsOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryRentalsBuildingsForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    type: string
    client: {
      id: string
      name: string
    }
    constructionManager: {
      id: string
      fullName: string
    } | null
    building: string
    address: string
    externalCompany: string | null
    startDate: string
    endDate: string
  }
}

export const useQueryRentalsBuildingsForSelector = (filters: QueryRentalsBuildingsFilters) => {
  const repository = useRentalsApiClient()
  const queryItemsHook = useQueryItems<QueryRentalsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron arriendos',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryRentalsBuildingsForSelectorResult>()

  const query = async (options: QueryRentalsBuildingsOptions = {}) => {
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        ...filters,
      },
    })
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryRentalsBuildingsOptions, response?: QueryRentalsResponse) => {
    if (!response) {
      return resetCache()
    }

    const itemsWithoutBuildingsDuplicates = response.items.filter((item, index, self) => {
      return self.findIndex((i) => i.building.trim().toUpperCase() === item.building.trim().toUpperCase()) === index
    })

    addPageToCache(response.page ?? 1, {
      ...response,
      items: itemsWithoutBuildingsDuplicates.map((item) => {
        return {
          value : item.building.trim().toUpperCase(),
          label : item.building,
          data  : { ...item },
        }
      }),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
