import { Typography } from 'antd'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export type MTCellProductValue = {
  id: string
  sku?: string | null
  name: string
}

export const MTCellProductCard = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const product = cellNode.value as MTCellProductValue

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <div
        className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[300px]">
        <section className="flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-nowrap items-baseline gap-2">
            {!!product.sku && (
              <div className="flex-1">
                <Typography.Text className="font-medium uppercase select-text">{product.sku}</Typography.Text>
              </div>
            )}
            <div
              className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-all">
              {product.id}
            </div>
          </div>

          <Typography.Text className="text-sm uppercase select-text">{product.name}</Typography.Text>
        </section>
      </div>
    </MTCellBase>
  )
}
