import { Modal, Typography } from 'antd'
import { FC, MouseEvent, useState } from 'react'
import { useLogout } from '@/presentation/common/hooks/useLogout.ts'

export const LogoutButton: FC = () => {
  const { logout } = useLogout()
  const [ open, setOpen ] = useState(false)
  const [ isClosingSession, setIsClosingSession ] = useState(false)

  const handleLogout = (e: MouseEvent) => {
    e.preventDefault()
    showModal()
  }

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const onConfirmLogout = async () => {
    setIsClosingSession(true)
    await logout()
    setIsClosingSession(false)
    hideModal()
  }

  return (
    <>
      <Modal
        title="Confirmación"
        open={open}
        onOk={onConfirmLogout}
        onCancel={hideModal}
        closable={false}
        maskClosable={false}
        confirmLoading={isClosingSession}
      >
        <p>¿Está seguro de querer cerrar la sesión?</p>
      </Modal>

      <Typography.Link onClick={handleLogout}>Cerrar sesión</Typography.Link>
    </>
  )
}
