import { QueryOptions } from '@/api/common/types.ts'
import { QueryProductsResponse } from '@/api/products/response.types.ts'
import { useProductsApiClient } from '@/api/products/useProductsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryProductsOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryProductsForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    name: string
    description: string | null
    sku: string | null
    sizes: {
      height: string | null
      width: string | null
      depth: string | null
    }
    weight: string | null
    volume: string | null
    unitsPerPackage: number | null
  }
}

export const useQueryProductsForSelector = () => {
  const repository = useProductsApiClient()
  const queryItemsHook = useQueryItems<QueryProductsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryProductsForSelectorResult>()

  const query = async (options: QueryProductsOptions = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryProductsOptions, response?: QueryProductsResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        value : item.id.toString(),
        label : `${item.sku ?? ''} ${item.name}`.trim(),
        data  : {
          id          : item.id.toString(),
          name        : item.name,
          description : item.description ?? null,
          sku         : item.sku ?? null,
          sizes       : {
            height : item.sizes?.height ?? null,
            width  : item.sizes?.width ?? null,
            depth  : item.sizes?.depth ?? null,
          },
          weight          : item.weight ?? null,
          volume          : item.volume ?? null,
          unitsPerPackage : item.unitsPerPackage ?? null,
        },
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
