function traverseAndFlatten(currentNode: Record<string, any>, target: Record<string, any>, flattenedKey?: string) {
  for (const key in currentNode) {
    let newKey
    if (flattenedKey === undefined) {
      newKey = key
    }
    else {
      newKey = flattenedKey + '.' + key
    }

    const value = currentNode[key]
    if (value && typeof value === 'object') {
      traverseAndFlatten(value, target, newKey)
    }
    else {
      target[newKey] = value
    }
  }
}

export function flatten(obj: Record<string, any>) {
  const flattenedObject = {}
  traverseAndFlatten(obj, flattenedObject, undefined)
  return flattenedObject
}
