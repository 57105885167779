import { DeleteManyProductsTemplateProductsRequest } from '@/api/productsTemplateProducts/request.types.ts'
import { DeleteManyProductsTemplateProductsResponse } from '@/api/productsTemplateProducts/response.types.ts'
import {
  useProductsTemplateProductsApiClient,
} from '@/api/productsTemplateProducts/useProductsTemplateProductsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteProductsTemplatesProducts = () => {
  const repository = useProductsTemplateProductsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyProductsTemplateProductsRequest, DeleteManyProductsTemplateProductsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los productos',
      success : 'Productos eliminados',
      loading : 'Eliminando productos',
    },
  })

  return {
    ...deleteItems,
  }
}
