import classNames from 'classnames'
import { SubscriptionStatus, SubscriptionStatusValue } from '@/domain/models/subscriptionStatus.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { QuerySubscriptionsForTableResult } from '@/presentation/subscriptions/hooks/useQuerySubscriptionsForTable.ts'

export const MTCellSubscriptionStatus = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const status = cellNode.value as QuerySubscriptionsForTableResult['items'][0]['subscriptionStatus']

  const className = classNames('flex min-w-[100px] items-center gap-2 py-2 px-3 rounded-full text-sm font-medium leading-none', {
    'bg-success-min' : status === SubscriptionStatusValue.ACTIVE,
    'bg-error-min'   : status === SubscriptionStatusValue.INACTIVE,
  })

  const pointerClassName = classNames('w-2 h-2 mb-0.5 rounded-full', {
    'bg-success-main' : status === SubscriptionStatusValue.ACTIVE,
    'bg-error-main'   : status === SubscriptionStatusValue.INACTIVE,
  })

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <section className="flex justify-center w-full">
        <div className={className}>
          <div className={pointerClassName}></div>

          <div className="flex-1 flex justify-center">
            {SubscriptionStatus.fromString(status).label().toUpperCase()}
          </div>
        </div>
      </section>
    </MTCellBase>
  )
}
