import { Button, Form } from 'antd'
import { FC } from 'react'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { LoadProductsTemplateFormProps } from '@/presentation/maintenance/forms/types.ts'
import { useLoadProductsTemplateProducts } from '@/presentation/maintenance/hooks/useLoadProductsTemplateProducts.ts'
import { useMaintenancesMaintainerStore } from '@/presentation/maintenance/store/maintenancesMaintainers.ts'
import { ProductsTemplateSelector } from '@/presentation/productsTemplates/components/ProductsTemplateSelector.tsx'

type LoadProductsTemplateFormFields = {
  template: string
}

export const LoadProductsTemplateForm: FC<LoadProductsTemplateFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'LoadProductsTemplateForm-alert'

  const { submit, isSuccess, isLoading, isError, message } = useLoadProductsTemplateProducts()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedMaintenanceId } = useMaintenancesMaintainerStore()

  const onSubmit = async (values: LoadProductsTemplateFormFields) => {
    if (isLoading || !selectedMaintenanceId) {
      return
    }

    const response = await submit({
      query: {
        maintenanceId: selectedMaintenanceId,
      },
      body: {
        template: values.template,
      },
    })
    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <Form.Item
        label="Plantilla"
        name="template"
        rules={[{ required: true }]}
      >
        <ProductsTemplateSelector/>
      </Form.Item>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Cargar productos</Button>
      </div>
    </Form>
  )
}
