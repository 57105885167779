import { UpdateProductsTemplateRequest } from '@/api/productsTemplates/request.types.ts'
import { UpdateProductsTemplateResponse } from '@/api/productsTemplates/response.types.ts'
import { useProductsTemplatesApiClient } from '@/api/productsTemplates/useProductsTemplatesApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateProductsTemplate = () => {
  const repository = useProductsTemplatesApiClient()
  const updateItem = useUpdateItem<UpdateProductsTemplateRequest, UpdateProductsTemplateResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el plantilla para mantención',
      alreadyExists : 'Ya existe una plantilla para mantención',
      success       : 'Plantilla para mantención actualizada con éxito',
      loading       : 'Actualizando plantilla para mantención',
    },
  })

  return {
    ...updateItem,
  }
}
