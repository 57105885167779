import { Button, DatePicker, Form } from 'antd'
import { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo } from 'react'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { getLocalDateEndOf } from '@/presentation/common/utils/getLocalDateEndOf.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { useDownloadReport } from '@/presentation/eppReport/hooks/useDownloadReport.ts'
import { UserSelector } from '@/presentation/users/components/UserSelector.tsx'

type EppReportFormFields = {
  dates: [ Dayjs, Dayjs ]
  operator: string
}

const EppReport: FC = () => {
  const initialValues = useMemo(() => ({
    dates: [ getLocalDateStartOf({ startOf: 'month' }), getLocalDateEndOf({ endOf: 'month' }) ],
  }), [])

  const messageApiKey = 'EppReport-alert'
  const { submit, isLoading, isError, message, isSuccess, lastUpdate, data: file } = useDownloadReport()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: EppReportFormFields) => {
    await submit({
      since    : getLocalDateStartOf({ date: values.dates[0] }).format(),
      until    : getLocalDateEndOf({ date: values.dates[1] }).format(),
      operator : values.operator,
    })
  }

  useEffect(() => {
    if (!isLoading && file) {
      window.open(URL.createObjectURL(file))
    }
  }, [ lastUpdate, file ])

  return (
    <FormLayout title="EPPs del operador">
      <section className="app-card">
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Filtros">
            <Form.Item
              label="Rango de fechas"
              name="dates"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker className="w-full" format="DD-MM-YYYY"/>
            </Form.Item>

            <Form.Item
              label="Operador"
              name="operator"
              rules={[{ required: true }]}
            >
              <UserSelector role={UserRoleValue.MACHINE_OPERATOR}/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Obtener reporte</Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}

export default EppReport
