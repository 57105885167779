import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface InventoryProductsStocksState {
  selectedInventoryId: string | null,
  setSelectedInventoryId: (value: string | null) => void,
  resetStore: () => void,
}

export const useInventoryProductsStocksStore = create(persist<InventoryProductsStocksState>(
  (set) => ({
    selectedInventoryId: null,

    resetStore: () => set({
      selectedInventoryId: null,
    }),

    setSelectedInventoryId: (selectedInventoryId: string | null) => set({ selectedInventoryId }),
  }),
  {
    name: 'inventoryProductsStocks',
  },
))
