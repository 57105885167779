import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryRentalMachineryRequest } from '@/api/rentalsMachinery/request.types.ts'
import { QueryRentalsMachineryResponse } from '@/api/rentalsMachinery/response.types.ts'
import { useRentalsMachineryApiClient } from '@/api/rentalsMachinery/useRentalsMachineryApiClient.ts'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryRentalsMachineryForTableResult = PaginatedData<{
  id: string
  rentalType: RentalTypeValue
  machinery: {
    id: string | null
    type: MachineryTypeValue
    code: string | null
    brand: string | null
    model: string | null
    serialNumber: string | null
    description: string | null
    truckVolume: number | null
  }
  operator: {
    id: string | null
    fullName: string
  }
  rentalCondition: {
    type: RentalConditionTypeValue
    minHours: number | null
    amountPerHour: number | null
    amountPerDay: number | null
    load: string | null
    loadOrigin: string | null
    amountPerTravel: number | null
  }
}>

export const useQueryRentalsMachineryForTable = (rentalId: string | null) => {
  const repository = useRentalsMachineryApiClient()
  const queryItemsHook = useQueryItems<QueryRentalsMachineryResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron maquinarias',
    },
  })

  const [ data, setData ] = useState<QueryRentalsMachineryForTableResult>()

  const refresh = useCallback(async (rentalId: string | null) => {
    const response = await queryItemsHook.refresh({ rentalId })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<QueryRentalMachineryRequest>> = {}) => {
    if (!rentalId) {
      return
    }
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        rentalId,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryRentalsMachineryResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map(({ machinery, ...item }) => {
        const machineryType = MachineryType.fromString(machinery.type)
        return {
          id         : item.id.toString(),
          rentalType : RentalType.fromString(item.rentalType).value,
          machinery  : {
            id           : machinery.id,
            type         : machineryType.value,
            code         : machinery.code ?? null,
            description  : machinery.description ?? null,
            brand        : machinery.brand ?? null,
            model        : machinery.model ?? null,
            serialNumber : machinery.serialNumber ?? null,
            truckVolume  : machinery.truckVolume ?? null,
          },
          operator: {
            id       : item.operator.id,
            fullName : item.operator.fullName,
          },
          rentalCondition: {
            type            : RentalConditionType.fromString(item.rentalCondition.type).value,
            minHours        : item.rentalCondition.minHours,
            amountPerHour   : item.rentalCondition.amountPerHour,
            amountPerDay    : item.rentalCondition.amountPerDay,
            load            : item.rentalCondition.load,
            loadOrigin      : item.rentalCondition.loadOrigin,
            amountPerTravel : item.rentalCondition.amountPerTravel,
          },
        }
      }),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
