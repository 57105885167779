import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { CreateClientFormProps } from '@/presentation/clients/forms/types.ts'
import { useCreateClient } from '@/presentation/clients/hooks/useCreateClient.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'

type CreateClientFormFields = {
  name: string,
  paymentCondition: string,
  contacts: string[],
  billing: {
    dni: string,
    name: string,
    activity: string,
    address: string,
    phone: string,
  },
}

export const CreateClientForm: FC<CreateClientFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateClientForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateClient()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateClientFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit(values)

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del cliente">
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Condición de pago"
          name="paymentCondition"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <FormSection title="Receptores de Estado de Pago">
        <Form.List name="contacts">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <Form.Item
                  {...field}
                  label='Correo electrónico'
                  rules={[{ required: true }]}
                >
                  <div className="flex gap-2">
                    <Input type="email"/>
                    <Button danger type="text" onClick={() => remove(field.name)}><i
                      className="ri-close-circle-line"/></Button>
                  </div>
                </Form.Item>
              ))}

              <div className="flex mb-5">
                <Button size="small" block onClick={() => add('')}>Agregar correo de recepción</Button>
              </div>
            </>
          )}
        </Form.List>
      </FormSection>

      <FormSection title="Datos de facturación">
        <Form.Item
          label="RUT"
          name={[ 'billing', 'dni' ]}
          rules={[
            { required: true },
            { validator: rutValidator },
          ]}
        >
          <RutInput/>
        </Form.Item>

        <Form.Item
          label="Nombre"
          name={[ 'billing', 'name' ]}
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Giro"
          name={[ 'billing', 'activity' ]}
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name={[ 'billing', 'address' ]}
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Teléfono"
          name={[ 'billing', 'phone' ]}
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
