import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthApiClient } from '@/api/auth/useAuthApiClient.ts'
import { ApiError } from '@/api/common/apiError.ts'
import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { routes } from '@/presentation/common/router/routes.ts'

const initialTimeout = 30
let timer = initialTimeout

export const useForgotPassword = () => {
  const auth = useAuthApiClient()
  const navigate = useNavigate()
  const status = useUiStatus()
  const [ sendEmailAgainTimeout, setSendEmailAgainTimeout ] = useState(initialTimeout)
  const [ isTimeoutRunning, setIsTimeoutRunning ] = useState(false)

  const goToLogin = () => navigate(routes.login)

  const runSendEmailAgainTimeout = () => {
    setIsTimeoutRunning(true)

    const interval = setInterval(() => {
      if (timer <= 0) {
        timer = initialTimeout
        clearInterval(interval)
        setSendEmailAgainTimeout(initialTimeout)
        setIsTimeoutRunning(false)
      }
      else {
        timer--
        setSendEmailAgainTimeout(timer)
      }
    }, 1000)
  }

  const recoverPassword = async (email: string, cdn: string) => {
    try {
      status.setStatus(UiStateStatus.LOADING)
      setSendEmailAgainTimeout(initialTimeout)

      await auth.forgotPassword({ email, cdn })

      status.setStatus(UiStateStatus.SUCCESS)

      runSendEmailAgainTimeout()
    }
    catch (error) {
      const apiError = error as ApiErrorResponse

      if (apiError.internalStatusCode === ApiError.TENANT_IS_NOT_ACTIVE) {
        status.setMessage('Su empresa no se encuentra activa, por favor comuníquese con el administrador')
      }
      else if (apiError.internalStatusCode === ApiError.ENTITY_NOT_FOUND && apiError.meta?.entity === 'TENANT') {
        status.setMessage('El CDN ingresado no existe, por favor verifique e intente nuevamente')
      }
      else if (apiError.internalStatusCode === ApiError.ENTITY_NOT_FOUND && apiError.meta?.entity === 'USER') {
        status.setMessage('El correo electrónico ingresado no existe, por favor verifique e intente nuevamente')
      }
      else {
        status.setMessage('Ocurrió un error al intentar recuperar contraseña, por favor intente nuevamente')
        console.error(error)
      }

      status.setStatus(UiStateStatus.ERROR)
    }
  }

  return {
    data: {
      initialTimeout,
      sendEmailAgainTimeout,
      isTimeoutRunning,
    },
    message   : status.message,
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    goToLogin,
    recoverPassword,
  }
}
