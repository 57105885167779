import { CreateProductsTemplateRequest } from '@/api/productsTemplates/request.types.ts'
import { CreateProductsTemplateResponse } from '@/api/productsTemplates/response.types.ts'
import { useProductsTemplatesApiClient } from '@/api/productsTemplates/useProductsTemplatesApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateProductsTemplate = () => {
  const repository = useProductsTemplatesApiClient()
  const createItem = useCreateItem<CreateProductsTemplateRequest, CreateProductsTemplateResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear la plantilla para mantención',
      alreadyExists : 'Ya existe una plantilla para mantención',
      success       : 'Plantilla para mantención creada con éxito',
      loading       : 'Creando plantilla para mantención',
    },
  })

  return {
    ...createItem,
  }
}
