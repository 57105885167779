import { Button, Form, InputNumber } from 'antd'
import { FC } from 'react'
import { QueryInventoryProductsStocksResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { InventorySelector } from '@/presentation/inventories/components/InventorySelector.tsx'
import { IncreaseInventoryStockFormProps } from '@/presentation/inventoryProductsStocks/forms/types.ts'
import { useIncrementInventoryStock } from '@/presentation/inventoryProductsStocks/hooks/useIncrementInventoryStock.ts'
import { useInventoryProductsStocksStore } from '@/presentation/inventoryProductsStocks/store/inventoryProductsStocks.ts'
import { ProductSelector } from '@/presentation/products/components/ProductSelector.tsx'

type IncreaseInventoryStockFormFields = {
  inventory: string
  product: string
  quantity: number
}

export const IncreaseInventoryStockForm: FC<IncreaseInventoryStockFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'IncreaseInventoryStockForm-alert'
  const { selectedInventoryId } = useInventoryProductsStocksStore()
  const { submit, isSuccess, isLoading, isError, message } = useIncrementInventoryStock()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryInventoryProductsStocksResponse['items'][0]> = selectionNodes[0]

  const initialValues = {
    ...(selectedNode?.data || {}),
    inventory : selectedInventoryId,
    product   : selectedNode?.data?.product?.id,
    quantity  : 1,
  }

  const onSubmit = async (values: IncreaseInventoryStockFormFields) => {
    if (isLoading || !selectedInventoryId) {
      return
    }

    const response = await submit({
      ...values,
      inventory: selectedInventoryId,
    })
    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      initialValues={initialValues}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del stock">
        <Form.Item
          label="Inventario"
          name="inventory"
          rules={[{ required: true }]}
        >
          <InventorySelector disabled={true}/>
        </Form.Item>

        <Form.Item
          label="Producto"
          name="product"
          rules={[{ required: true }]}
        >
          <ProductSelector disabled={!!initialValues.product}/>
        </Form.Item>

        <Form.Item
          label="Cantidad a ingresar"
          name="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Incrementar</Button>
      </div>
    </Form>
  )
}
