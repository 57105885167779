import { Typography } from 'antd'
import { RentalType } from '@/domain/models/rentalType.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import {
  QueryMachineryUseRecordsForTableResult,
} from '@/presentation/machineryUseRecords/hooks/useQueryMachineryUseRecordsForTable.ts'

export const MTCellUseRecordRental = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const data = cellNode.value as QueryMachineryUseRecordsForTableResult['items'][0]['rental']

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      {data && (
        <div
          className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[300px]">
          <section className="flex flex-col gap-1.5 flex-1 overflow-hidden">
            <div className="flex flex-nowrap items-baseline gap-2">
              <div className="flex-1 flex flex-nowrap gap-1.5 items-center">
                <div
                  className="py-1 px-2 bg-blue-50 font-mono text-xs rounded-md inline-block select-text">
                  {RentalType.fromString(data.type).label()}
                </div>

                <Typography.Text className="text-sm select-text">{data.building}</Typography.Text>
              </div>
              <div
                className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-all">
                {data.id}
              </div>
            </div>

            <div className="flex flex-col gap-2.5">
              <Typography.Text className="text-sm select-text"><b>Cliente:</b> {data.client.name}</Typography.Text>
              {data.externalCompany && <Typography.Text className="text-sm select-text"><b>Compañía externa:</b> {data.externalCompany}</Typography.Text>}
            </div>
          </section>
        </div>
      )}
    </MTCellBase>
  )
}
