import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateEppRequest,
  DeleteManyEppsRequest,
  DownloadPdfResumeRequest,
  UpdateEppRequest,
} from '@/api/epps/request.types.ts'
import {
  CreateEppResponse,
  DeleteManyEppsResponse,
  DownloadPdfResumeResponse,
  QueryEppsResponse,
  UpdateEppResponse,
} from '@/api/epps/response.types.ts'

export type EppsApiClient = {
  query: (options: QueryOptions) => Promise<QueryEppsResponse>
  create: (req: CreateEppRequest) => Promise<CreateEppResponse>
  update: (req: UpdateEppRequest) => Promise<UpdateEppResponse>
  deleteMany: (req: DeleteManyEppsRequest) => Promise<DeleteManyEppsResponse>
  downloadPdfResume: (req: DownloadPdfResumeRequest) => Promise<DownloadPdfResumeResponse>
}

export const useEppsApiClient = (): EppsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryEppsResponse, QueryParams>({
    path   : '/epps',
    params : options.params,
  }),

  create: async (body: CreateEppRequest) => await postMapping<CreateEppResponse, CreateEppRequest>({
    path : '/epps',
    body : body,
  }),

  update: async (req: UpdateEppRequest) => await putMapping<UpdateEppResponse, UpdateEppRequest['body']>({
    path : `/epps/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyEppsRequest) => await deleteMapping<DeleteManyEppsResponse, DeleteManyEppsRequest>({
    path : '/epps',
    body : req,
  }),

  downloadPdfResume: async (req: DownloadPdfResumeRequest) => await getFileMapping<DownloadPdfResumeResponse, DownloadPdfResumeRequest>({
    path   : '/epps/pdf',
    params : req,
  }),
})
