import { create } from 'zustand'

export interface ProductsTemplatesMaintainerState {
  selectedTab: 'templates' | 'products',
  selectedTemplateId: string | null,

  setSelectedTab: (tab: 'templates' | 'products') => void,
  setSelectedTemplateId: (id: string | null) => void,
}

export const useProductsTemplatesMaintainerStore = create<ProductsTemplatesMaintainerState>()(
  (set, _get) => ({
    selectedTab        : 'templates',
    selectedTemplateId : null,

    setSelectedTab        : (tab: 'templates' | 'products') => set({ selectedTab: tab }),
    setSelectedTemplateId : (id: string | null) => set({ selectedTemplateId: id }),
  }),
)
