import { useContext, useEffect, useState } from 'react'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { SidebarContext } from '@/presentation/common/store/apiContext/SidebarContext.tsx'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const useActionsModal = () => {
  const { isTabletAndBelow } = useMQ()
  const sidebarCtx = useContext(SidebarContext)
  const sidebarRightCtx = useContext(SidebarRightContext)
  const [ open, setOpen ] = useState(false)

  useEffect(() => {
    if (open && isTabletAndBelow) {
      sidebarCtx.forceClose()
      sidebarRightCtx.forceClose()
    }
  }, [ open, isTabletAndBelow ])

  return {
    open,
    setOpen,
  }
}
