import { CreateFuelRegistryRequest } from '@/api/fuelRegistries/request.types.ts'
import { CreateFuelRegistryResponse } from '@/api/fuelRegistries/response.types.ts'
import { useFuelRegistriesApiClient } from '@/api/fuelRegistries/useFuelRegistriesApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateFuelRegistry = () => {
  const repository = useFuelRegistriesApiClient()
  const createItem = useCreateItem<CreateFuelRegistryRequest, CreateFuelRegistryResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el registro',
      alreadyExists : 'Ya existe un registro',
      success       : 'Registro creado con éxito',
      loading       : 'Creando registro',
    },
  })

  return {
    ...createItem,
  }
}
