import { Typography } from 'antd'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { QueryInventoriesForTableResult } from '@/presentation/inventories/hooks/useQueryInventoriesForTable.ts'

export const MTCellContact = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const data = node.data.contact as QueryInventoriesForTableResult['items'][0]['contact']

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <>
        {(data.name || data.email || data.phone) && (
          <div
            className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[100px]">
            <section className="flex flex-col flex-1 overflow-hidden">
              {data.name && (
                <Typography.Text className="text-sm uppercase font-semibold select-text px-0.5">{data.name}</Typography.Text>
              )}

              {data.email && (
                <Typography.Link
                  href={`mailto:${data.email}`}
                  className="text-sm lowercase select-text px-0.5"
                >
                  {data.email}
                </Typography.Link>
              )}

              {data.phone && (
                <Typography.Text className="text-sm lowercase select-text px-0.5">{data.phone}</Typography.Text>
              )}
            </section>
          </div>
        )}
      </>
    </MTCellBase>
  )
}
