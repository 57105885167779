/* eslint-disable prefer-promise-reject-errors */
import axios from 'axios'
import { useAuthApiClient } from '@/api/auth/useAuthApiClient.ts'
import { ApiError } from '@/api/common/apiError.ts'
import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'
import config from '@/config.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

export const client = axios.create({
  baseURL : config.apiUrl,
  timeout : 30000,
})

client.interceptors.request.use(async (config) => {
  const sessionStore = useSessionStore.getState()
  const token = sessionStore.accessToken

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    const sessionStore = useSessionStore.getState()
    const accessToken = sessionStore.accessToken
    const refreshToken = sessionStore.refreshToken

    if (error.code === 'ERR_NETWORK') {
      return Promise.reject({
        statusCode         : 500,
        message            : 'Cannot connect to server',
        internalStatusCode : ApiError.ERROR_NETWORK,
      } as ApiErrorResponse)
    }

    if (
      error.response?.status === 401 &&
      !originalRequest._retry && // avoid infinite loop
      refreshToken &&
      accessToken
    ) {
      originalRequest._retry = true

      const auth = useAuthApiClient()
      const response = await auth.refreshTokens({
        headers: {
          accessToken,
        },
        body: {
          refreshToken,
        },
      })

      // update tokens
      sessionStore.setAccessToken(response.access.token)
      sessionStore.setRefreshToken(response.refresh.token)

      // retry request with new token
      originalRequest.headers['Authorization'] = `Bearer ${response.access.token}`
      return await client(originalRequest)
    }

    if (error.response?.status === 404 && originalRequest.url === '/auth/refresh-tokens') {
      sessionStore.clearSession()
      sessionStore.setAccessToken(null)
      sessionStore.setRefreshToken(null)

      return Promise.reject({
        statusCode         : 500,
        message            : 'Cannot refresh token',
        internalStatusCode : ApiError.UNAUTHORIZED,
      } as ApiErrorResponse)
    }

    // in case of a non api error
    if (error.response == null) {
      return Promise.reject({
        statusCode         : 500,
        message            : error.message,
        internalStatusCode : error.code,
      } as ApiErrorResponse)
    }

    return Promise.reject(error.response?.data as ApiErrorResponse)
  },
)
