import { Card, Col, Image, Layout, Row } from 'antd'
import { CSSProperties, FC } from 'react'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { StartLayoutProps } from '@/presentation/common/layout/types.ts'

export const StartLayout: FC<StartLayoutProps> = ({ children }) => {
  const { isTabletAndBelow } = useMQ()

  const styles: CSSProperties = {
    minHeight: '100%',
  }

  const logoStyles: CSSProperties = {
    height: isTabletAndBelow ? '150px' : '200px',
  }

  return (
    <Layout style={styles}>
      <Layout.Content>
        <Row className="w-full mt-2" justify={'center'}>
          <Image src={'/MF_Logo.svg'} preview={false} style={logoStyles} />
        </Row>

        <Row className="h-full" justify={'center'}>
          <Col xs={23} sm={16} md={11} lg={9} xl={8}>
            <Card className="mt-2">
              {children}
            </Card>
          </Col>
        </Row>
      </Layout.Content>

      <Layout.Footer style={{ textAlign: 'center' }}>
                Todos los derechos reservados
      </Layout.Footer>
    </Layout>
  )
}
