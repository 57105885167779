import { Input } from 'antd'
import { ChangeEvent, FC } from 'react'
import { RutInputProps } from '@/presentation/common/components/types.ts'
import { formatRut, prettifyRut, removeSeparators } from '@/presentation/common/utils/rut.ts'

export const RutInput: FC<RutInputProps> = ({ value, onChange }) => {
  const onChangeHandler = (val: ChangeEvent<HTMLInputElement>) => {
    const rut = formatRut(removeSeparators(val.target.value))
    onChange?.(rut)
  }

  return (
    <Input value={prettifyRut(value || null)} onChange={onChangeHandler}/>
  )
}
