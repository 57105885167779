import { Button, Divider, Form, Input, message, Row, Typography } from 'antd'
import { FC, useEffect } from 'react'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { PasswordLabel } from '@/presentation/login/components/passwordLabel.tsx'
import { useLogin } from '@/presentation/login/hooks/useLogin.ts'
import { FormProps } from '@/presentation/login/types.ts'

const Login: FC = () => {
  const session = useSessionStore()
  const {
    isLoading,
    isSuccess,
    isError,
    message: error,
    login,
  } = useLogin()
  const [ messageApi, contextHolder ] = message.useMessage()

  const handleFormSubmit = async (values: FormProps) => {
    await login(values.username, values.password, values.cdn)
  }

  useEffect(() => {
    if (isError && error) {
      messageApi.open({
        type    : 'error',
        content : error,
      })
    }

    if (isSuccess) {
      messageApi.open({
        type    : 'success',
        content : 'Bienvenido!',
      })
    }
  }, [ error, isError, isSuccess, messageApi ])

  return (
    <>
      {contextHolder}

      <Row justify={'center'}>
        <Typography.Title level={4}>Ingrese a su cuenta</Typography.Title>
      </Row>

      <Form
        layout="vertical"
        initialValues={{ cdn: session.cdn }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Usuario"
          name="username"
          rules={[{ required: true }]}
        >
          <Input disabled={isLoading}/>
        </Form.Item>

        <Form.Item
          label={<PasswordLabel disabled={isLoading}/>}
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password disabled={isLoading}/>
        </Form.Item>

        <Form.Item
          label="CDN"
          name="cdn"
          rules={[{ required: true }]}
        >
          <Input disabled={isLoading}/>
        </Form.Item>

        <Divider/>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={isLoading}
          >
                        Ingresar
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default Login
