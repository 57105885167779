import { CreateMachineryRequest } from '@/api/machinery/request.types.ts'
import { CreateMachineryResponse } from '@/api/machinery/response.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateMachinery = () => {
  const repository = useMachineryApiClient()
  const createItem = useCreateItem<CreateMachineryRequest, CreateMachineryResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear la maquinaria',
      alreadyExists : 'Ya existe una maquinaria con el código o matrícula',
      success       : 'Maquinaria creada con éxito',
      loading       : 'Creando maquinaria',
    },
  })

  return {
    ...createItem,
  }
}
