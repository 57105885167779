import { SignatureStatus, SignatureStatusValue } from '@/domain/models/signatureStatus.ts'

export const signatureComparator = (valueA: boolean, valueB: boolean) => {
  const valueALabel = valueA ? SignatureStatus.fromString(SignatureStatusValue.SIGNED).label() : SignatureStatus.fromString(SignatureStatusValue.NOT_SIGNED).label()
  const valueBLabel = valueB ? SignatureStatus.fromString(SignatureStatusValue.SIGNED).label() : SignatureStatus.fromString(SignatureStatusValue.NOT_SIGNED).label()

  if (valueALabel?.toLowerCase() === valueBLabel?.toLowerCase()) {
    return 0
  }
  return (valueALabel?.toLowerCase() > valueBLabel?.toLowerCase()) ? 1 : -1
}
