import { Button, FormListFieldData, Input } from 'antd'
import { ChangeEvent, FC } from 'react'

export type ContactInputProps = {
  value?: string;
  onChange?: (value: string) => void;
  onRemove?: (name: FormListFieldData['name']) => void;
  field: FormListFieldData
}

export const ContactInput: FC<ContactInputProps> = ({ field, value, onChange, onRemove }) => {
  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value)
  }

  return (
    <div className="flex gap-2">
      <Input value={value} type="email" onChange={onChangeHandler}/>
      <Button danger type="text" onClick={() => onRemove?.(field.name)}><i
        className="ri-close-circle-line"/></Button>
    </div>
  )
}
