import { Alert, Button, Form, Input } from 'antd'
import { useContext, useEffect, useMemo } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import {
  confirmPassword,
  password as passwordValidator,
  rutValidator,
} from '@/presentation/common/utils/form/validators.ts'
import { useUpdateUserSettings } from '@/presentation/users/hooks/useUpdateUserSettings.ts'

type UpdateUserFormFields = {
  firstName?: string
  lastName?: string
  email?: string
  signature?: string | null
  password?: string
  dni?: string | null
}

const UserSettings = () => {
  const { render } = useContext(HelpContext)
  const { user, setUser } = useSessionStore()
  const messageApiKey = 'UserSettings-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateUserSettings()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm<UpdateUserFormFields>()
  const password = Form.useWatch('password', form)

  const initialValues: UpdateUserFormFields = useMemo(() => ({
    ...(user ? user : {}),
  }), [ user ])

  const onSubmit = async (values: UpdateUserFormFields) => {
    if (isLoading || !user?.id) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateUserFormFields

    const response = await submit({
      params: {
        id: user?.id,
      },
      body: {
        firstName : editedValues.firstName,
        lastName  : editedValues.lastName,
        email     : editedValues.email,
        signature : editedValues.signature,
        password  : !editedValues.password ? undefined : editedValues.password,
        dni       : editedValues.dni,
      },
    })

    if (response) {
      setUser(response as any)
      form.setFieldsValue({
        ...(user ? user : {}),
        ...response,
      })
    }
  }

  useEffect(() => {
    render(false)
  }, [])

  return (
    <FormLayout title="Configuraciones personales">
      <section className="app-card">
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la persona">
            <Form.Item<UpdateUserFormFields>
              label="Nombre"
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateUserFormFields>
              label="Apellido"
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="RUT"
              name="dni"
              rules={[
                { required: false },
                { validator: rutValidator },
              ]}
            >
              <RutInput/>
            </Form.Item>

            <Form.Item<UpdateUserFormFields>
              label="Correo electrónico"
              name="email"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Firma digital"
              name="signature"
              rules={[{ required: false }]}
            >
              <SignPad/>
            </Form.Item>
          </FormSection>

          <FormSection title="Datos del usuario">
            <Alert message="Dejar en blanco si no desea cambiar la contraseña." className="mb-5"/>

            <Form.Item
              label="Contraseña"
              extra="Establesca una contraseña de al menos 4 caracteres"
              name="password"
              rules={[
                { required: false },
                { validator: passwordValidator },
              ]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
              label="Confirmar contraseña"
              name="confirmPassword"
              rules={[
                { required: !!password },
                { validator: confirmPassword(password) },
              ]}
            >
              <Input.Password/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              disabled={isLoading}
            >
              Actualizar
            </Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}

export default UserSettings
