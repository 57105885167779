import { useState } from 'react'
import { GeneratePayStateRequest } from '@/api/payStates/request.types.ts'
import { usePayStatesApiClient } from '@/api/payStates/usePayStatesApiClient.ts'
import { useDownloadPdfFile } from '@/presentation/common/hooks/crud/v2/useDownloadPdfFile.ts'

export const useGeneratePayState = () => {
  const repository = usePayStatesApiClient()
  const query = useDownloadPdfFile<GeneratePayStateRequest>({
    filename : 'pay-state.pdf',
    callable : repository.generate,
    messages : {
      default : 'Error al generar el estado de cuenta',
      loading : 'Generando estado de cuenta',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (body: GeneratePayStateRequest) => {
    const response = await query.submit(body)
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
