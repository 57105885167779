import { DeleteManyInventoriesRequest } from '@/api/inventories/request.types.ts'
import { DeleteManyInventoriesResponse } from '@/api/inventories/response.types.ts'
import { useInventoriesApiClient } from '@/api/inventories/useInventoriesApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteInventories = () => {
  const repository = useInventoriesApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyInventoriesRequest, DeleteManyInventoriesResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los espacios',
      success : 'Espacios eliminados',
      loading : 'Eliminando espacios',
    },
  })

  return {
    ...deleteItems,
  }
}
