import { Col, Typography } from 'antd'
import { FC, useContext, useEffect } from 'react'
import { HR } from '@/presentation/common/components/HR.tsx'
import { useRoleAuth } from '@/presentation/common/hooks/useRoleAuth.ts'
import { modules } from '@/presentation/common/router/modules.tsx'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { ModuleCard } from '@/presentation/modules/components/ModuleCard.tsx'

const Modules: FC = () => {
  const { tenant } = useSessionStore()
  const { checkRoleAuth } = useRoleAuth()
  const helpCtx = useContext(HelpContext)

  useEffect(() => {
    helpCtx.render(true)
    helpCtx.setContent(<ModulesHelpInfo/>)
  }, [])

  return (
    <div className="flex justify-center flex-wrap gap-2 py-8 px-2 xl:max-w-[1280px] m-auto">
      {modules.map(({ id, title, links, allowedRoles, onlySysAdmin }) => (
        <>
          {checkRoleAuth(allowedRoles) && (!onlySysAdmin || tenant?.isSysAdmin) && (
            <Col key={id}>
              <ModuleCard id={id} title={title} links={links}/>
            </Col>
          )}
        </>
      ))}
    </div>
  )
}

const ModulesHelpInfo: FC = () => {
  return (
    <div className="py-2">
      <section>
        <Typography.Title level={5}>Pantalla de inicio</Typography.Title>
        <HR/>
        <Typography.Paragraph>
                    En esta página se centralizan todos los módulos de la aplicación, cada módulo contiene una lista de
                    funcionalidades que se pueden realizar.<br/><br/>

                    Para acceder a un módulo, se debe hacer click en cualquier enlace que se encuentra en la lista de
                    cada tarjeta.
        </Typography.Paragraph>
      </section>
    </div>
  )
}

export default Modules
