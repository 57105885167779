import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { FormProps } from '@/presentation/payStates/forms/types.ts'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'
import { QueryUsersForTableResult } from '@/presentation/users/hooks/useQueryUsersForTable.ts'

export const DeletePayStateResumeForm: FC<FormProps> = ({ onCancel, onFinish }) => {
  const { deleteResumeItem } = usePayStatePreviewStore()
  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = selectionNodes.map((node: RowNode<QueryUsersForTableResult['items'][0]>) => node.data.id)

  const onConfirmDelete = async () => {
    if (ids.length > 1 || ids.length === 0) {
      return
    }

    deleteResumeItem({ id: ids[0] })
    onFinish?.call(this)
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los registros seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}>Eliminar</Button>
      </div>
    </>
  )
}
