import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateMaintenanceProductRequest,
  DeleteManyMaintenanceProductsRequest,
  LoadMaintenanceTemplateProductsRequest,
  QueryMaintenanceProductsRequest,
  UpdateMaintenanceProductRequest,
} from '@/api/maintenanceProducts/request.types.ts'
import {
  CreateMaintenanceProductResponse,
  DeleteManyMaintenanceProductsResponse,
  LoadMaintenanceTemplateProductsResponse,
  QueryMaintenanceProductsResponse,
  UpdateMaintenanceProductResponse,
} from '@/api/maintenanceProducts/response.types.ts'

export type MaintenanceProductsApiClient = {
  query: (options: QueryOptions<QueryMaintenanceProductsRequest>) => Promise<QueryMaintenanceProductsResponse>
  create: (req: CreateMaintenanceProductRequest) => Promise<CreateMaintenanceProductResponse>
  update: (req: UpdateMaintenanceProductRequest) => Promise<UpdateMaintenanceProductResponse>
  deleteMany: (req: DeleteManyMaintenanceProductsRequest) => Promise<DeleteManyMaintenanceProductsResponse>
  loadTemplate: (req: LoadMaintenanceTemplateProductsRequest) => Promise<LoadMaintenanceTemplateProductsResponse>
}

export const useMaintenanceProductsApiClient = (): MaintenanceProductsApiClient => ({
  query: async (options: QueryOptions<QueryMaintenanceProductsRequest>) => await getMapping<QueryMaintenanceProductsResponse, QueryParams>({
    path   : `/maintenances/${options.pathParams?.maintenanceId}/products`,
    params : options.params,
  }),

  create: async (req: CreateMaintenanceProductRequest) => await postMapping<CreateMaintenanceProductResponse, CreateMaintenanceProductRequest['body']>({
    path : `/maintenances/${req.query.maintenanceId}/products`,
    body : req.body,
  }),

  update: async (req: UpdateMaintenanceProductRequest) => await putMapping<UpdateMaintenanceProductResponse, UpdateMaintenanceProductRequest['body']>({
    path : `/maintenances/products/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyMaintenanceProductsRequest) => await deleteMapping<DeleteManyMaintenanceProductsResponse, DeleteManyMaintenanceProductsRequest>({
    path : '/maintenances/products',
    body : req,
  }),

  loadTemplate: async (req: LoadMaintenanceTemplateProductsRequest) => await putMapping<LoadMaintenanceTemplateProductsResponse, LoadMaintenanceTemplateProductsRequest['body']>({
    path : `/maintenances/${req.query.maintenanceId}/products/template`,
    body : req.body,
  }),
})
