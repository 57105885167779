import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { confirmPassword } from '@/presentation/common/utils/form/validators.ts'
import { useRecoverPassword } from '@/presentation/recoverPassword/hooks/useRecoverPassword.ts'
import { FormProps } from '@/presentation/recoverPassword/types.ts'

const RecoverPassword: FC = () => {
  const [ form ] = Form.useForm()
  const [ messageApi, contextHolder ] = message.useMessage()
  const {
    isLoading,
    isError,
    isSuccess,
    message: error,
    goToLoginTimeout,
    isGoToLoginTimeoutRunning,
    goToLogin,
    recoverPassword,
  } = useRecoverPassword()
  const [ password, setPassword ] = useState<string>()

  const isDisabled = isLoading || isGoToLoginTimeoutRunning

  const handleFormSubmit = async (values: FormProps) => {
    await recoverPassword(values.password)
  }

  const handleFormFieldChange = () => {
    form.validateFields().catch(() => null)
  }

  useEffect(() => {
    if (isError && error) {
      messageApi.open({
        type    : 'error',
        content : error,
      })
    }

    if (isSuccess) {
      messageApi.open({
        type    : 'success',
        content : 'Contraseña restablecida correctamente',
      })
    }
  }, [ error, isError, isSuccess, messageApi ])

  return (
    <>
      {contextHolder}

      <Row justify="center" className="items-baseline">
        <Col>
          <i className="ri-arrow-left-line" onClick={() => goToLogin()}></i>
        </Col>
        <Col flex={1}>
          <Typography.Title level={4} className="text-center">Cambie su contraseña</Typography.Title>
        </Col>
      </Row>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        onValuesChange={handleFormFieldChange}
      >
        <Form.Item
          label="Contraseña"
          extra="Establesca una contraseña de al menos 8 caracteres que contenga al menos una letra y un número"
          name="password"
          rules={[{ required: true }]}
        >
          <Input.Password
            disabled={isDisabled}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="Confirmar contraseña"
          name="confirmPassword"
          rules={[
            { required: true },
            { validator: confirmPassword(password) },
          ]}
        >
          <Input.Password disabled={isDisabled}/>
        </Form.Item>

        <Divider/>

        {isGoToLoginTimeoutRunning && goToLoginTimeout > 0 &&
                    <Form.Item className="text-center">
                      <span>Será redireccionado automáticamente en {goToLoginTimeout} segundos</span>
                    </Form.Item>
        }

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={isDisabled}
          >
                        Restrablecer contraseña
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

export default RecoverPassword
