import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryMaintenancesResponse } from '@/api/maintenances/response.types.ts'
import { useMaintenancesApiClient } from '@/api/maintenances/useMaintenancesApiClient.ts'
import { MaintenanceStatus, MaintenanceStatusValue } from '@/domain/models/maintenanceStatus.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryMaintenancesForTableResult = PaginatedData<{
  id: string
  status: MaintenanceStatusValue
  correlative: number
  machinery: {
    id: string
    type: string
    code: string
    brand: string
    model: string
    year: number
    serialNumber: string
    maintenanceType: string | null
  }
  hourmeter: number
  step: number
  observations: string | null
  author: {
    id: string
    name: string
    role: string
  } | null
  date: string | null
}>

export const useQueryMaintenancesForTable = () => {
  const repository = useMaintenancesApiClient()
  const queryItemsHook = useQueryItems<QueryMaintenancesResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron mantenciones',
      loading : 'Buscando mantenciones',
      success : 'Mantenciones encontradas',
    },
  })

  const [ data, setData ] = useState<QueryMaintenancesForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryMaintenancesResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id          : item.id.toString(),
        status      : MaintenanceStatus.fromString(item.status).value,
        correlative : item.correlative,
        machinery   : {
          id              : item.machinery.id,
          type            : item.machinery.type,
          code            : item.machinery.code,
          brand           : item.machinery.brand,
          model           : item.machinery.model,
          year            : item.machinery.year,
          serialNumber    : item.machinery.serialNumber,
          maintenanceType : item.machinery.maintenanceType ?? null,
        },
        hourmeter    : item.hourmeter,
        step         : item.step,
        observations : item.observations ?? null,
        author       : item.author ? {
          id   : item.author.id.toString(),
          name : item.author.name,
          role : item.author.role,
        } : null,
        date: item.date ?? null,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
