import { Input } from 'antd'
import { ChangeEvent, FC, useState } from 'react'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

export const MaintainerSearch: FC = () => {
  const { setSearch } = useMaintainerStore()
  const [ lastSearchTimeout, setLastSearchTimeout ] = useState<number | NodeJS.Timeout>()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (lastSearchTimeout) {
      clearTimeout(lastSearchTimeout)
    }

    const timeout = setTimeout(() => {
      setSearch(e.target.value)
    }, 500)

    setLastSearchTimeout(timeout)
  }

  return (
    <Input
      placeholder="Buscar"
      size="small"
      suffix={<i className="ri-search-line icon-sm"/>}
      className="w-64"
      onChange={onChange}
    />
  )
}
