import { create } from 'zustand'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'

export interface RentalsMaintainerState {
  selectedTab: 'rentals' | 'machinery' | 'receivers',
  selectedRentalId: string | null,
  selectedRentalType: RentalTypeValue | null,

  setSelectedTab: (tab: 'rentals' | 'machinery' | 'receivers') => void,
  setSelectedRentalId: (id: string | null) => void,
  setSelectedRentalType: (type: RentalTypeValue | null) => void,
}

export const useRentalsMaintainerStore = create<RentalsMaintainerState>()(
  (set, _get) => ({
    selectedTab        : 'rentals',
    selectedRentalId   : null,
    selectedRentalType : null,

    setSelectedTab        : (tab: 'rentals' | 'machinery' | 'receivers') => set({ selectedTab: tab }),
    setSelectedRentalId   : (id: string | null) => set({ selectedRentalId: id }),
    setSelectedRentalType : (type: RentalTypeValue | null) => set({ selectedRentalType: type }),
  }),
)
