import { useAuthApiClient } from '@/api/auth/useAuthApiClient.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

export const useLogout = () => {
  const auth = useAuthApiClient()
  const sessionStore = useSessionStore.getState()

  const logout = async () => {
    try {
      await auth.logout({
        refreshToken: sessionStore.refreshToken || '',
      })
    }
    finally {
      sessionStore.clearSession()
    }
  }

  return {
    logout,
  }
}
