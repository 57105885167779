import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryInventoryProductsStocksResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import {
  useInventoryProductsStocksApiClient,
} from '@/api/inventoryProductsStocks/useInventoryProductsStocksApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'
import { MTCellProductValue } from '@/presentation/products/components/MTCellProductCard.tsx'

type QueryInventoryStocksForTableResult = PaginatedData<{
  id: string
  product: MTCellProductValue
  quantity: number
}>

export const useQueryInventoryStocksForTable = (selectedInventory: string | null) => {
  const repository = useInventoryProductsStocksApiClient()
  const queryItemsHook = useQueryItems<QueryInventoryProductsStocksResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron stocks',
      loading : 'Buscando stocks',
      success : 'Stocks encontrados',
    },
  })

  const [ data, setData ] = useState<QueryInventoryStocksForTableResult>()

  const refresh = useCallback(async (selectedInventory: string | null) => {
    const response = await queryItemsHook.refresh({ inventoryId: selectedInventory })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        inventoryId: selectedInventory,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryInventoryProductsStocksResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id      : item.id.toString(),
        product : {
          id   : item.product.id,
          name : item.product.name,
          sku  : item.product.sku,
        },
        quantity: item.quantity,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
