import { ReactNode, useContext } from 'react'
import { SidebarContext } from '@/presentation/common/store/apiContext/SidebarContext.tsx'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const useSidebarRightToggle = (trigger: symbol, content: ReactNode | null) => {
  const {
    isOpen,
    toggle: toggleSidebarRight,
    lastTriggerKey,
    setContent,
    setLastTriggerKey,
    forceClose,
  } = useContext(SidebarRightContext)

  const leftSidebarCtx = useContext(SidebarContext)

  const toggle = () => {
    if (leftSidebarCtx.isOpen) {
      leftSidebarCtx.forceClose()
    }

    if (lastTriggerKey !== trigger) {
      setLastTriggerKey(trigger)
      setContent(content)

      if (isOpen) {
        return
      }
    }

    if (isOpen) {
      setTimeout(() => {
        setContent(null)
      }, 300)
    }
    else {
      setContent(content)
    }

    toggleSidebarRight()
  }

  const refreshContent = () => {
    if (lastTriggerKey === trigger) {
      setContent(content)
    }
  }

  return {
    isOpen,
    toggle,
    refreshContent,
    forceClose,
  }
}
