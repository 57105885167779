import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryUsersResponse } from '@/api/users/response.types.ts'
import { useUsersApiClient } from '@/api/users/useUsersApiClient.ts'
import { UserRole, UserRoleValue } from '@/domain/models/userRole.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryUsersForTableResult = PaginatedData<{
  id: string
  username: string
  email: string
  role: UserRoleValue
  firstName: string
  lastName: string
  dni: string | null
  isActive: boolean
  signature: string | null
}>

export const useQueryUsersForTable = () => {
  const repository = useUsersApiClient()
  const queryItemsHook = useQueryItems<QueryUsersResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron usuarios',
    },
  })

  const [ data, setData ] = useState<QueryUsersForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryUsersResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id        : item.id.toString(),
        username  : item.username,
        email     : item.email,
        role      : UserRole.fromString(item.role).value,
        firstName : item.firstName,
        lastName  : item.lastName,
        dni       : item.dni,
        isActive  : item.isActive,
        signature : item.signature ?? null,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
