import { Typography } from 'antd'
import classNames from 'classnames'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { MaintenanceStatus, MaintenanceStatusValue } from '@/domain/models/maintenanceStatus.ts'
import { MaintenanceType, MaintenanceTypeValue } from '@/domain/models/maintenanceType.ts'
import {
  QueryMaintenancesForDashboardResult,
} from '@/presentation/maintenanceDashboard/hooks/useQueryMaintenancesForDashboard.ts'

type Props = {
  maintenance: QueryMaintenancesForDashboardResult['items'][0]
}

export const MaintenanceCard: FC<Props> = ({ maintenance }) => {
  const { code, brand, model, serialNumber, type, year, maintenanceType } = maintenance.machinery

  const machineryDescription = `${code ?? ''} - ${MachineryType.fromString(type).label()} ${brand ?? ''} ${model ?? ''} ${year ?? ''} (${serialNumber ?? ''})`.trim()

  const statusClassName = classNames('py-1 px-2 font-semibold font-mono text-xs border rounded-sm inline-block select-text', {
    'bg-emerald-50 text-emerald-500 border-emerald-500' : maintenance.status === MaintenanceStatusValue.DONE,
    'bg-orange-50 text-orange-500 border-orange-500'    : maintenance.status === MaintenanceStatusValue.PENDING,
  })

  return (
    <div className="flex align-middle w-full gap-4 p-3 border border-tertiary-light rounded-md bg-surface overflow-hidden min-w-[300px]">
      <section className="flex flex-col gap-1.5 flex-1 overflow-hidden">

        <div className="flex flex-nowrap gap-1.5 items-center">
          <div className="flex-1">
            <Typography.Text className="text-sm select-text uppercase font-medium">
              {machineryDescription}
            </Typography.Text>
          </div>

          <div className="min-w-[80px]">
            <div className={statusClassName}>
              {MaintenanceStatus.fromString(maintenance.status).label().toUpperCase()}
            </div>
          </div>
        </div>

        <div className="flex flex-nowrap gap-1.5 items-center">
          <div className="flex gap-1">
            <Typography.Text className="text-sm font-medium">
              Horómetro/Odómetro:
            </Typography.Text>
            <Typography.Text className="text-sm select-text">
              {maintenance.hourmeter} hrs/kms.
            </Typography.Text>
          </div>

          <div className="flex gap-1">
            <Typography.Text className="text-sm font-medium">
              Mantención:
            </Typography.Text>
            <Typography.Text className="text-sm select-text">
              {maintenance.step} hrs/kms.
            </Typography.Text>
          </div>

          <div className="flex gap-1">
            <Typography.Text className="text-sm font-medium">
                Clase:
            </Typography.Text>
            <Typography.Text className="text-sm select-text">
              {MaintenanceType.fromString(maintenanceType ?? MaintenanceTypeValue.CLASS_A).label()}
            </Typography.Text>
          </div>
        </div>
      </section>
    </div>
  )
}
