import { DeleteManyTenantsRequest } from '@/api/tenants/request.types.ts'
import { DeleteManyTenantsResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteTenants = () => {
  const repository = useTenantsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyTenantsRequest, DeleteManyTenantsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los inquilinos',
      success : 'Inquilinos eliminados',
      loading : 'Eliminando inquilinos',
    },
  })

  return {
    ...deleteItems,
  }
}
