import { FC, useEffect, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useAlert } from '@/presentation/common/hooks/useAlert.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { countryCodeToLabel } from '@/presentation/common/utils/countryCodeToLabel.ts'
import { MTCellContact } from '@/presentation/inventories/components/MTCellContact.tsx'
import { InventoriesActions } from '@/presentation/inventories/forms/InventoriesActions.tsx'
import { useDownloadInventoriesExcel } from '@/presentation/inventories/hooks/useDownloadInventoriesExcel.ts'
import { useQueryInventoriesForTable } from '@/presentation/inventories/hooks/useQueryInventoriesForTable.ts'

const Inventories: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryInventoriesForTable()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'name',
      headerName              : 'Nombre',
      comparator              : stringComparator,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
    },
    { field: 'description', headerName: 'Descripción', comparator: stringComparator },
    {
      field       : 'location',
      headerName  : 'Ubicación',
      comparator  : stringComparator,
      formatValue : ({ value }) => `${value?.address ?? ''}, ${value?.city ?? ''}, ${countryCodeToLabel(value?.country ?? '')}`,
    },
    {
      field        : 'contact',
      headerName   : 'Contacto',
      sortable     : false,
      cellRenderer : MTCellContact,
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 80,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <InventoriesActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    setTitle('Espacios')
    setActionsSlot(actionsComponent)
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // when search changes update query
  useEffect(() => {
    query({
      params: {
        search,
        page: 1,
      },
    })
  }, [ search ])

  // DOWNLOAD EXCEL
  const {
    submit: downloadFile,
    isLoading: isExcelLoading,
    isError: isExcelError,
    isSuccess: isExcelSuccess,
    message: excelMessage,
    data: excelFile,
    lastUpdate: lastSubmitTimestamp,
  } = useDownloadInventoriesExcel()

  const {
    showLoading: showExcelLoading,
    showError: showExcelError,
    closeAlert: closeExcelAlert,
  } = useAlert({ messageApiKey: 'InventoriesExcel-Alert' })

  // Mostrar alerta de excel
  useEffect(() => {
    if (isExcelLoading) {
      showExcelLoading(excelMessage)
    }
    else if (isExcelError) {
      showExcelError(excelMessage)
    }
    else {
      closeExcelAlert()
    }
  }, [ isExcelLoading, isExcelError, isExcelSuccess, excelMessage ])

  // Abrir excel cuando se descargue
  useEffect(() => {
    if (!isExcelLoading && excelFile) {
      window.open(URL.createObjectURL(excelFile))
    }
  }, [ lastSubmitTimestamp ])

  return (
    <Maintainer showExcelDownloadButton={false} onExcelDownloadButtonClick={downloadFile}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default Inventories
