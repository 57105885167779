import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { UsernameInput } from '@/presentation/common/components/UsernameInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { password, rutValidator } from '@/presentation/common/utils/form/validators.ts'
import { RoleSelector } from '@/presentation/users/components/RoleSelector.tsx'
import { UpdateUserFormProps } from '@/presentation/users/forms/types.ts'
import { QueryUsersForTableResult } from '@/presentation/users/hooks/useQueryUsersForTable.ts'
import { useUpdateUser } from '@/presentation/users/hooks/useUpdateUser.ts'

type UpdateUserFormFields = Partial<{
  username: string,
  firstName: string,
  lastName: string,
  email: string,
  role: UserRoleValue,
  signature?: string,
  dni?: string,
}>

export const UpdateUserForm: FC<UpdateUserFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateUserForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateUser()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryUsersForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateUserFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values)

    const response = await submit({
      params : { id: selectedNode?.data?.id },
      body   : {
        ...editedValues as UpdateUserFormFields,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del usuario">
            <Form.Item
              label="Usuario"
              name="username"
              rules={[{ required: true }]}
            >
              <UsernameInput/>
            </Form.Item>

            <Form.Item
              label="Nombre"
              name="firstName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Apellido"
              name="lastName"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="RUT"
              name="dni"
              rules={[
                { required: false },
                { validator: rutValidator },
              ]}
            >
              <RutInput/>
            </Form.Item>

            <Form.Item
              label="Correo de contacto"
              name="email"
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Rol"
              name="role"
              rules={[{ required: true }]}
            >
              <RoleSelector/>
            </Form.Item>

            <Form.Item
              label="Contraseña"
              extra="Establesca una contraseña de al menos 4 caracteres"
              name="password"
              rules={[
                { required: false },
                { validator: password },
              ]}
            >
              <Input.Password/>
            </Form.Item>
          </FormSection>

          <FormSection title="Firma">
            <Form.Item
              label="Firma digital"
              name="signature"
              rules={[{ required: false }]}
            >
              <SignPad/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
