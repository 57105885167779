import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { CountrySelector } from '@/presentation/common/components/CountrySelector.tsx'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { CreateInventoryFormProps } from '@/presentation/inventories/forms/types.ts'
import { useCreateInventory } from '@/presentation/inventories/hooks/useCreateInventory.ts'

type CreateInventoryFormFields = {
  name: string,
  description?: string,
  location: {
    country?: string,
    city?: string,
    address?: string,
  },
  contact: {
    name?: string,
    email?: string,
    phone?: string,
  },
}

export const CreateInventoryForm: FC<CreateInventoryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateInventoryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateInventory()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateInventoryFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      name        : values.name,
      description : values.description ?? null,
      location    : {
        country : values.location?.country ?? null,
        city    : values.location?.city ?? null,
        address : values.location?.address ?? null,
      },
      contact: {
        name  : values.contact?.name ?? null,
        email : values.contact?.email ?? null,
        phone : values.contact?.phone ?? null,
      },
    })

    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del espacio">
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <FormSection title="Ubicación">
        <Form.Item
          label="País"
          name={[ 'location', 'country' ]}
          rules={[{ required: false }]}
        >
          <CountrySelector/>
        </Form.Item>

        <Form.Item
          label="Ciudad"
          name={[ 'location', 'city' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name={[ 'location', 'address' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <FormSection title="Contacto">
        <Form.Item
          label="Nombre"
          name={[ 'contact', 'name' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name={[ 'contact', 'email' ]}
          rules={[{ required: false }, { type: 'email' }]}
        >
          <Input type="email"/>
        </Form.Item>

        <Form.Item
          label="Teléfono"
          name={[ 'contact', 'phone' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
