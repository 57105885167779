import { FC, useContext } from 'react'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const ToggleSidebarRight: FC = () => {
  const sidebarRightCtx = useContext(SidebarRightContext)

  return (
    <div onClick={sidebarRightCtx.toggle} className="flex justify-start py-2">
      {sidebarRightCtx.isOpen ? (
        <i className="ri-menu-unfold-line"></i>
      ) : (
        <i className="ri-menu-fold-line"></i>
      )}
    </div>
  )
}
