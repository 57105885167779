import { useState } from 'react'
import { DownloadMachineryUsageExcelRequest } from '@/api/machinery/request.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { useDownloadExcelFile } from '@/presentation/common/hooks/crud/v2/useDownloadExcelFile.ts'

export const useDownloadReport = () => {
  const repository = useMachineryApiClient()
  const query = useDownloadExcelFile<DownloadMachineryUsageExcelRequest>({
    filename : 'machinery-usage.xlsx',
    callable : repository.downloadMachineryUsageExcelResume,
    messages : {
      default : 'No se pudo descargar el archivo',
      loading : 'Obteniendo archivo',
      success : 'Archivo descargado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (req: DownloadMachineryUsageExcelRequest) => {
    const response = await query.submit(req)
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
