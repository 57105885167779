import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { SubscriptionStatus, SubscriptionStatusValue } from '@/domain/models/subscriptionStatus.ts'

const { Option } = Select

export const SubscriptionStateSelector: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Seleccione un estado"
      {...props}
    >
      {Object.entries(SubscriptionStatusValue).map(([ key, value ]) => (
        <Option key={key} value={value}>{SubscriptionStatus.fromString(value).label()}</Option>
      ))}
    </Select>
  )
}
