import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateProductsTemplateProductRequest,
  DeleteManyProductsTemplateProductsRequest,
  QueryProductsTemplateProductsRequest,
} from '@/api/productsTemplateProducts/request.types.ts'
import {
  CreateProductsTemplateProductResponse,
  DeleteManyProductsTemplateProductsResponse,
  QueryProductsTemplateProductsResponse,
} from '@/api/productsTemplateProducts/response.types.ts'

export type ProductsTemplateProductsApiClient = {
  query: (options: QueryOptions<void, QueryProductsTemplateProductsRequest>) => Promise<QueryProductsTemplateProductsResponse>
  create: (req: CreateProductsTemplateProductRequest) => Promise<CreateProductsTemplateProductResponse>
  deleteMany: (req: DeleteManyProductsTemplateProductsRequest) => Promise<DeleteManyProductsTemplateProductsResponse>
}

export const useProductsTemplateProductsApiClient = (): ProductsTemplateProductsApiClient => ({
  query: async (req: QueryOptions<void, QueryProductsTemplateProductsRequest>) => await getMapping<QueryProductsTemplateProductsResponse, QueryParams>({
    path   : '/products-templates/products',
    params : req.params,
  }),

  create: async (req: CreateProductsTemplateProductRequest) => await postMapping<CreateProductsTemplateProductResponse, CreateProductsTemplateProductRequest['body']>({
    path : `/products-templates/${req.query.templateId}/products`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyProductsTemplateProductsRequest) => await deleteMapping<DeleteManyProductsTemplateProductsResponse, DeleteManyProductsTemplateProductsRequest>({
    path : '/products-templates/products',
    body : req,
  }),
})
