import { DeleteManyRentalsMachineryRequest } from '@/api/rentalsMachinery/request.types.ts'
import { DeleteManyRentalsMachineryResponse } from '@/api/rentalsMachinery/response.types.ts'
import { useRentalsMachineryApiClient } from '@/api/rentalsMachinery/useRentalsMachineryApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteRentalsMachinery = () => {
  const repository = useRentalsMachineryApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyRentalsMachineryRequest, DeleteManyRentalsMachineryResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar las maquinarias',
      success : 'Maquinarias eliminadas',
      loading : 'Eliminando maquinarias',
    },
  })

  return {
    ...deleteItems,
  }
}
