import { Button, Form, Input, UploadFile } from 'antd'
import { FC, useState } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'
import { UploadAvatarImage } from '@/presentation/tenants/components/UploadAvatarImage.tsx'
import { CreateTenantFormProps } from '@/presentation/tenants/forms/types.ts'
import { useCreateTenant } from '@/presentation/tenants/hooks/useCreateTenant.ts'

type CreateTenantFormFields ={
  name: string,
  cdn: string,
  dni: string,
  activity: string,
  address: string,
  contactPhone: string,
  contactEmail: string,
  picture?: string,
}

export const CreateTenantForm: FC<CreateTenantFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateTenantForm-alert'
  const [ isUploadingPicture, setIsUploadingPicture ] = useState<boolean>(false)
  const [ fileList, setFileList ] = useState<UploadFile[]>([])
  const [ fileTmpPath, setFileTmpPath ] = useState<string | null>()
  const { submit, isSuccess, isLoading, isError, message } = useCreateTenant()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateTenantFormFields) => {
    if (isLoading || isUploadingPicture) {
      return
    }

    const response = await submit({
      ...values,
      picture: fileTmpPath ?? null,
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  const onPictureUploaded = ({ file }: { file: UploadFile }) => {
    setIsUploadingPicture(true)
    setFileList([ file ])

    if (file.status === 'done') {
      setFileTmpPath(file.response)
      setIsUploadingPicture(false)
    }

    if (file.status === 'removed') {
      setFileTmpPath(null)
      setFileList([])
      setIsUploadingPicture(false)
    }

    if (file.status === 'error') {
      setFileTmpPath(null)
      setIsUploadingPicture(false)
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos de la empresa">
        <Form.Item
          label="Logo"
          valuePropName="fileList"
          rules={[{ required: false }]}
        >
          <UploadAvatarImage fileList={fileList} onChange={onPictureUploaded}/>
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="CDN"
          name="cdn"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="RUT"
          name="dni"
          rules={[
            { required: true },
            { validator: rutValidator },
          ]}
        >
          <RutInput/>
        </Form.Item>

        <Form.Item
          label="Actividad económica"
          name="activity"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name="address"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Teléfono de contacto"
          name="contactPhone"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Correo de contacto"
          name="contactEmail"
          rules={[{ required: true }, { type: 'email' }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit"
          disabled={isLoading || isUploadingPicture}>Crear</Button>
      </div>
    </Form>
  )
}
