import { UpdateMachineryUseRecordRequest } from '@/api/machineryUseRecords/request.types.ts'
import { UpdateMachineryUseRecordResponse } from '@/api/machineryUseRecords/response.types.ts'
import { useMachineryUseRecordsApiClient } from '@/api/machineryUseRecords/useMachineryUseRecordsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateMachineryUseRecord = () => {
  const repository = useMachineryUseRecordsApiClient()
  const updateItem = useUpdateItem<UpdateMachineryUseRecordRequest, UpdateMachineryUseRecordResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el registro de uso',
      alreadyExists : 'Ya existe un registro de uso',
      success       : 'Registro de uso actualizado con éxito',
      loading       : 'Actualizando registro de uso',
    },
  })

  return {
    ...updateItem,
  }
}
