import { CreateEppRequest } from '@/api/epps/request.types.ts'
import { CreateEppResponse } from '@/api/epps/response.types.ts'
import { useEppsApiClient } from '@/api/epps/useEppsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateEpp = () => {
  const repository = useEppsApiClient()
  const updateItem = useCreateItem<CreateEppRequest, CreateEppResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar el EPP',
      alreadyExists : 'Ya existe un EPP',
      success       : 'EPP creado con éxito',
      loading       : 'Registrando EPP',
    },
  })

  return {
    ...updateItem,
  }
}
