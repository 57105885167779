import { CreateRentalReceiverRequest } from '@/api/rentalsReceivers/request.types.ts'
import { CreateRentalReceiverResponse } from '@/api/rentalsReceivers/response.types.ts'
import { useRentalsReceiversApiClient } from '@/api/rentalsReceivers/useRentalsReceiversApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateRentalReceiver = () => {
  const repository = useRentalsReceiversApiClient()
  const createItem = useCreateItem<CreateRentalReceiverRequest, CreateRentalReceiverResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar al receptor',
      alreadyExists : 'Ya existe un registro con este correo',
      success       : 'Receptor registrado con éxito',
      loading       : 'Registrando receptor',
    },
  })

  return {
    ...createItem,
  }
}
