import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteEppFormProps } from '@/presentation/epp/forms/types.ts'
import { useDeleteEpps } from '@/presentation/epp/hooks/useDeleteEpps.ts'
import { QueryEppsForTableResult } from '@/presentation/epp/hooks/useQueryEppsForTable.ts'

export const DeleteEppForm: FC<DeleteEppFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteEppForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteEpps()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = Object.values(selectionNodes).map((node: RowNode<QueryEppsForTableResult['items'][0]>) => node.data?.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.()
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los EPPs seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete} disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
