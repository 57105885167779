import { useState } from 'react'
import { DownloadConsumeReportRequest } from '@/api/fuelRegistries/request.types.ts'
import { useFuelRegistriesApiClient } from '@/api/fuelRegistries/useFuelRegistriesApiClient.ts'
import { useDownloadExcelFile } from '@/presentation/common/hooks/crud/v2/useDownloadExcelFile.ts'

export const useDownloadReport = () => {
  const repository = useFuelRegistriesApiClient()
  const query = useDownloadExcelFile<DownloadConsumeReportRequest>({
    filename : 'consume.xlsx',
    callable : repository.downloadConsumeResume,
    messages : {
      default : 'No se pudo descargar el archivo',
      loading : 'Obteniendo archivo',
      success : 'Archivo descargado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (req: DownloadConsumeReportRequest) => {
    const response = await query.submit(req)
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
