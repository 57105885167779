import { Typography } from 'antd'
import { FC, MouseEventHandler, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SidebarContentLinkListProps, SidebarContentLinkProps } from '@/presentation/common/components/types.ts'
import { useRoleAuth } from '@/presentation/common/hooks/useRoleAuth.ts'
import { createModuleTreeNodes, findLinkNodeByPath } from '@/presentation/common/router/modules.tsx'
import { routes } from '@/presentation/common/router/routes.ts'
import { ModuleLinkNode } from '@/presentation/common/router/types.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { SidebarContentContext } from '@/presentation/common/store/apiContext/SidebarContentContext.tsx'
import { SidebarContentLinkListContext } from '@/presentation/common/store/apiContext/SidebarContentLinkListContext.tsx'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

export const SidebarContent: FC = () => {
  const location = useLocation()
  const [ lastModulePath, setLastModulePath ] = useState<string | null>(null)
  const [ rootNode, setRootNode ] = useState<ModuleLinkNode | null>(null)
  const currentLinkNode = rootNode ? findLinkNodeByPath(location.pathname, rootNode) : null
  const currentLinkId = currentLinkNode ? currentLinkNode.current.id : null

  useEffect(() => {
    if (!lastModulePath && rootNode) {
      setLastModulePath(rootNode.current.path || null)
    }
  }, [ lastModulePath, rootNode ])

  useEffect(() => {
    if (!rootNode || !location.pathname.startsWith(lastModulePath || '')) {
      setRootNode(createModuleTreeNodes(location.pathname))
    }
  }, [ rootNode, location.pathname, lastModulePath ])

  return (
    <SidebarContentContext.Provider value={{ rootNode, currentLinkId }}>
      <SidebarContentGoToHomeButton/>
      <SidebarContentLinkList initialLinkNode={currentLinkNode}/>
    </SidebarContentContext.Provider>
  )
}

const SidebarContentLinkList: FC<SidebarContentLinkListProps> = ({ initialLinkNode }) => {
  const { tenant } = useSessionStore()
  const { checkRoleAuth } = useRoleAuth()
  const { rootNode, currentLinkId } = useContext(SidebarContentContext)
  const [ currentLinkNode, setCurrentLinkNode ] = useState<ModuleLinkNode | null>(initialLinkNode)
  const parentLinkNode = currentLinkNode?.parent || null
  const currentLevelLinksNodes = parentLinkNode ? parentLinkNode.children : []
  const linkNodesNotHidden = currentLevelLinksNodes.filter((node) => !node.current.hideInMenu)

  useEffect(() => {
    setCurrentLinkNode(initialLinkNode)
  }, [ initialLinkNode ])

  return (
    <SidebarContentLinkListContext.Provider value={{ setCurrentLinkNode, parentLinkNode }}>
      {parentLinkNode && parentLinkNode.current.id !== rootNode?.current.id && (
        <SidebarContentParentBackLink/>
      )}

      {linkNodesNotHidden.map((node: ModuleLinkNode, index) => (
        checkRoleAuth(node.current.allowedRoles) && (!node.current.onlySysAdmin || tenant?.isSysAdmin) && (
          <SidebarContentLink key={`${node.current.id}_${index}`} node={node} isActive={node.current.id === currentLinkId}/>
        )
      ))}
    </SidebarContentLinkListContext.Provider>
  )
}

const SidebarContentParentBackLink: FC = () => {
  const { parentLinkNode, setCurrentLinkNode } = useContext(SidebarContentLinkListContext)

  const goBack = () => {
    if (parentLinkNode) {
      setCurrentLinkNode(parentLinkNode)
    }
  }

  return (
    <>
      {parentLinkNode && (
        <div className="flex items-baseline bg-tertiary-min rounded-md p-2 my-2 cursor-pointer"
          onClick={goBack}>
          <i className="ri-arrow-left-line text-base font-semibold mr-2"/>
          <Typography.Text className="text-sm">
            {parentLinkNode.current.label}
          </Typography.Text>
        </div>
      )}
    </>
  )
}

const SidebarContentLink: FC<SidebarContentLinkProps> = ({ node, isActive }) => {
  const navigate = useNavigate()
  const { setCurrentLinkNode } = useContext(SidebarContentLinkListContext)
  const sidebarRightCtx = useContext(SidebarRightContext)
  const helpCtx = useContext(HelpContext)

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault()

    // caso cuando es un nodo padre y se accede a el para ver a sus hijos
    if (node.children && node.children.length > 0) {
      setCurrentLinkNode(node.children[0])
    } // este redirecciona a la vista
    else if (node.current.path) {
      sidebarRightCtx?.forceClose() // cierra barra lateral
      helpCtx?.render(false) // no muestra barra de ayuda
      navigate(node.current.path)
    }
  }

  return (
    <Typography.Link
      data-selected={isActive}
      onClick={handleClick}
      className={`
        block 
        rounded-md 
        p-2 my-1
        cursor-pointer
        !text-onSurface
        hover:bg-tertiary-min
        data-[selected=true]:bg-tertiary-min
        data-[selected=true]:font-medium
        !transition-all
        !duration-200
      `}
    >
      {node.current.label}
    </Typography.Link>
  )
}

const SidebarContentGoToHomeButton: FC = () => {
  const navigate = useNavigate()

  const goHome = () => {
    navigate(routes.modules)
  }

  return (
    <div className="flex items-baseline bg-primary-min rounded-md p-2 my-2 cursor-pointer"
      onClick={goHome}>
      <i className="ri-home-fill text-base font-semibold mr-2"/>
      <Typography.Text>
                Volver a la pantalla principal
      </Typography.Text>
    </div>
  )
}
