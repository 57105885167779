import { UpdateRentalRequest } from '@/api/rentals/request.types.ts'
import { UpdateRentalResponse } from '@/api/rentals/response.types.ts'
import { useRentalsApiClient } from '@/api/rentals/useRentalsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateRental = () => {
  const repository = useRentalsApiClient()
  const updateItem = useUpdateItem<UpdateRentalRequest, UpdateRentalResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el arriendo',
      alreadyExists : 'Ya existe un arriendo',
      success       : 'Arriendo actualizado con éxito',
      loading       : 'Actualizando arriendo',
    },
  })

  return {
    ...updateItem,
  }
}
