import { Navigate, Route, Routes } from 'react-router-dom'
import { ModulesLayout } from '@/presentation/common/layout/ModulesLayout.tsx'
import { StartLayout } from '@/presentation/common/layout/StartLayout.tsx'
import { modules } from '@/presentation/common/router/modules.tsx'
import { PrivateRoute } from '@/presentation/common/router/privateRoute.tsx'
import { PublicNotVisibleRoute } from '@/presentation/common/router/publicNotVisibleRoute.tsx'
import { routes } from '@/presentation/common/router/routes.ts'
import ForgotPassword from '@/presentation/forgotPassword/ForgotPassword.tsx'
import Login from '@/presentation/login/Login.tsx'
import Modules from '@/presentation/modules/Modules.tsx'
import { ModuleLink } from '@/presentation/modules/types.ts'
import RecoverPassword from '@/presentation/recoverPassword/RecoverPassword.tsx'

export const AppRouter = () => {
  return (
    <Routes>
      <Route path={routes.login} element={<StartLayout><PublicNotVisibleRoute/></StartLayout>}>
        <Route path={routes.login} element={<Login/>}/>
      </Route>
      <Route path={routes.forgotPassword} element={<StartLayout><PublicNotVisibleRoute/></StartLayout>}>
        <Route path={routes.forgotPassword} element={<ForgotPassword/>}/>
      </Route>
      <Route path={routes.recoverPassword} element={<StartLayout><PublicNotVisibleRoute/></StartLayout>}>
        <Route path={routes.recoverPassword} element={<RecoverPassword/>}/>
      </Route>

      <Route
        path={routes.modules}
        element={<ModulesLayout renderSidebar={false}><PrivateRoute/></ModulesLayout>}
      >
        <Route path={routes.modules} element={<Modules/>}/>
      </Route>

      {/* Modules */}
      {modules.map(module => (
        module.links.map((link: ModuleLink) => (
          flatLinks(link).map((link: ModuleLink) => (
            <Route
              key={link.id}
              path={link.path}
              element={link.layout?.call(this)}
            >
              <Route path={link.path} element={link.element?.call(this)}/>
            </Route>
          ))
        ))
      ))}

      <Route path={'*'} element={<Navigate to={routes.login}/>}/>
    </Routes>
  )
}

/**
 * Flat links from module
 * @param link
 */
const flatLinks = (link: ModuleLink) => {
  const links: ModuleLink[] = [ link ]

  if (link.links) {
    link.links.forEach((link: ModuleLink) => {
      links.push(...flatLinks(link))
    })
  }

  return links
}
