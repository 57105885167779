import { FC, useEffect, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { InventorySelector } from '@/presentation/inventories/components/InventorySelector.tsx'
import { InventoryStocksActions } from '@/presentation/inventoryProductsStocks/forms/InventoryStocksActions.tsx'
import {
  useQueryInventoryStocksForTable,
} from '@/presentation/inventoryProductsStocks/hooks/useQueryInventoryStocksForTable.ts'
import { useInventoryProductsStocksStore } from '@/presentation/inventoryProductsStocks/store/inventoryProductsStocks.ts'
import { MTCellProductCard } from '@/presentation/products/components/MTCellProductCard.tsx'
import { productComparator } from '@/presentation/products/utils/productComparator.ts'

const InventoryProductsStocks: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { selectedInventoryId, setSelectedInventoryId, resetStore } = useInventoryProductsStocksStore()
  const { data, query, refresh, isLoading } = useQueryInventoryStocksForTable(selectedInventoryId)

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'product',
      headerName              : 'Producto',
      comparator              : productComparator,
      cellRenderer            : MTCellProductCard,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
    },
    { field: 'quantity', headerName: 'Cantidad disponible', comparator: numberComparator },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 76,
    rowData       : !selectedInventoryId ? [] : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <InventoryStocksActions onAction={() => refresh(selectedInventoryId)}/>, [ refresh, selectedInventoryId ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    resetStore()
    setTitle('Stocks de Inventarios')
    setActionsSlot(actionsComponent)
  }, [])

  // when search or selectedInventory changes update query
  useEffect(() => {
    setSelectionV2([])

    if (selectedInventoryId != null) {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search, selectedInventoryId ])

  return (
    <Maintainer headerSlot={
      <div className="flex">
        <InventorySelector
          className="min-w-[250px]"
          size="small"
          onChange={setSelectedInventoryId}
        />
      </div>
    }>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default InventoryProductsStocks
