import { QueryRentalsMachineryForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsMachineryForTable.ts'

type Machinery = QueryRentalsMachineryForTableResult['items'][0]['machinery']

export const rentalMachinerySortComparator = (valueA: Machinery, valueB: Machinery) => {
  const descriptionA = `${valueA.code ?? ''} ${valueA.description ?? ''} ${valueA.type}`.trim()
  const descriptionB = `${valueB.code ?? ''} ${valueB.description ?? ''} ${valueB.type}`.trim()
  if (descriptionA.toLowerCase() === descriptionB.toLowerCase()) {
    return 0
  }
  return (descriptionA.toLowerCase() > descriptionB.toLowerCase()) ? 1 : -1
}
