import cx from 'classnames'
import { FC, useContext, useEffect } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { MachineryList } from '@/presentation/machineryUseDashboard/components/MachineryList.tsx'

export const MachineryUseDashboard: FC = () => {
  const { isTabletAndBelow, isDesktopAndAbove } = useMQ()
  const helpCtx = useContext(HelpContext)

  // hides help bar
  useEffect(() => {
    helpCtx?.render(false)
  }, [])

  const gridClasses = cx('grid h-full', {
    'grid-cols-1' : isTabletAndBelow,
    'grid-cols-2' : isDesktopAndAbove,
  })

  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col">
      <section className="flex-1 overflow-auto">
        <div className={gridClasses}>
          <MachineryList title="Maquinarias" machineryType={MachineryType.getMachineryCategoryTypes()} />
          <MachineryList title="Camiones" machineryType={MachineryType.getTruckCategoryTypes()} />
        </div>
      </section>
    </div>
  )
}
