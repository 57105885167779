import { Tooltip } from 'antd'
import { FC } from 'react'
import { MTHeaderHelpProps } from '@/presentation/common/components/maintainer/table/headers/types.ts'

export const MTHeaderHelp: FC<MTHeaderHelpProps> = (props) => {
  return (
    <div className="flex gap-1 items-center">
      {props.children || props.headerName}
      <Tooltip placement="top" title={props.helpText}>
        <i className="ri-question-fill"></i>
      </Tooltip>
    </div>
  )
}
