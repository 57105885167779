import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryEppProductsRequest } from '@/api/eppProducts/request.types.ts'
import { QueryEppProductsResponse } from '@/api/eppProducts/response.types.ts'
import { useEppProductsApiClient } from '@/api/eppProducts/useEppProductsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryEppProductsForTableResult = PaginatedData<{
  id: string
  product: {
    id: string
    name: string
    description: string | null
    sku: string | null
  }
  quantity: number
  inventory: {
    id: string
    name: string
  }
}>

export const useQueryEppProductsForTable = (eppId: string | null) => {
  const repository = useEppProductsApiClient()
  const queryItemsHook = useQueryItems<QueryEppProductsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const [ data, setData ] = useState<QueryEppProductsForTableResult>()

  const refresh = useCallback(async (eppId: string | null) => {
    const response = await queryItemsHook.refresh(undefined, { eppId })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<QueryEppProductsRequest>> = {}) => {
    if (!eppId) {
      return
    }
    const response = await queryItemsHook.query({
      ...options,
      pathParams: {
        eppId,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryEppProductsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id      : item.id.toString(),
        product : {
          id          : item.product.id,
          name        : item.product.name,
          description : item.product.description ?? null,
          sku         : item.product.sku ?? null,
        },
        quantity  : item.quantity,
        inventory : {
          id   : item.inventory.id,
          name : item.inventory.name,
        },
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
