import { UserRole } from '@/domain/models/userRole.ts'

export const roleSortComparator = (valueA: string, valueB: string) => {
  const roleA = UserRole.fromString(valueA).label()
  const roleB = UserRole.fromString(valueB).label()

  if (roleA.toLowerCase() === roleB.toLowerCase()) {
    return 0
  }
  return (roleA.toLowerCase() > roleB.toLowerCase()) ? 1 : -1
}
