type Product = {
  sku?: string
  name: string
}

export const productComparator = (valueA: Product, valueB: Product) => {
  const compA = `${valueA?.name} ${valueA?.sku ?? ''}`.trim()
  const compB = `${valueB?.name} ${valueB?.sku ?? ''}`.trim()
  
  if (compA?.toLowerCase() === compB?.toLowerCase()) {
    return 0
  }
  return (compA?.toLowerCase() > compB?.toLowerCase()) ? 1 : -1
}
