import { Button, Form, InputNumber, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { FC, useEffect, useState } from 'react'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { WorkingDaySelector } from '@/presentation/machineryUseRecords/components/WorkingDaySelector.tsx'
import { UpdateMachineryUseRecordFormProps } from '@/presentation/machineryUseRecords/forms/types.ts'
import {
  QueryMachineryUseRecordsForTableResult,
} from '@/presentation/machineryUseRecords/hooks/useQueryMachineryUseRecordsForTable.ts'
import { useUpdateMachineryUseRecord } from '@/presentation/machineryUseRecords/hooks/useUpdateMachineryUseRecord.ts'

type UpdateMachineryUseRecordFormFields = {
  startHourmeter?: number,
  endHourmeter?: number,
  totalHours?: number,
  totalTravels?: number,
  workingDay?: string,
  hadIncidences?: boolean,
  observations?: string,
  signature?: string,
  minHours?: number,
}

export const UpdateMachineryUseRecordForm: FC<UpdateMachineryUseRecordFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateMachineryUseRecordForm-alert'
  const { user } = useSessionStore()
  const { submit, isSuccess, isLoading, isError, message } = useUpdateMachineryUseRecord()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })
  const [ isSignActive, setIsSignActive ] = useState<boolean>(false)

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMachineryUseRecordsForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = {
    ...(selectedNode?.data || {}),
    startHourmeter : selectedNode?.data.machineryConditionValues.startHourmeter,
    endHourmeter   : selectedNode?.data.machineryConditionValues.endHourmeter,
    totalTravels   : selectedNode?.data.machineryConditionValues.totalTravels,
    workingDay     : selectedNode?.data.machineryConditionValues.workingDay,
    hadIncidences  : selectedNode?.data.hadIncidences,
    observations   : selectedNode?.data.observations,
    signature      : selectedNode?.data.signature,
    minHours       : selectedNode?.data.rentalCondition?.minHours,
  }

  const [ form ] = Form.useForm()
  const startHourmeter = Form.useWatch('startHourmeter', form)
  const endHourmeter = Form.useWatch('endHourmeter', form)
  const hadIncidences = Form.useWatch('hadIncidences', form)

  const onSubmit = async (values: UpdateMachineryUseRecordFormFields) => {
    if (isLoading || !selectedNode) {
      return
    }

    const modifiedSignature = getModifiedFields({
      signature: selectedNode?.data.signature,
    }, {
      signature: values.signature,
    }) as object

    const modifiedConditions = getModifiedFields(selectedNode?.data.machineryConditionValues, {
      startHourmeter : values.startHourmeter ?? null,
      endHourmeter   : values.endHourmeter ?? null,
      totalHours     : values.totalHours ?? null,
      workingDay     : values.workingDay ?? null,
      totalTravels   : values.totalTravels ?? null,
    }) as QueryMachineryUseRecordsForTableResult['items'][0]['machineryConditionValues']

    const rentalModifiedConditions = getModifiedFields(selectedNode?.data.rentalCondition, {
      minHours: values.minHours ?? null,
    }) as QueryMachineryUseRecordsForTableResult['items'][0]['rentalCondition']

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        machineryConditionValues: {
          ...selectedNode.data.machineryConditionValues,
          ...modifiedConditions,
        },
        observations    : values.observations,
        hadIncidences   : values.hadIncidences,
        rentalCondition : {
          ...selectedNode.data.rentalCondition,
          ...rentalModifiedConditions,
        },
        ...modifiedSignature,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  useEffect(() => {
    if (endHourmeter < startHourmeter) {
      form.setFieldValue('endHourmeter', undefined)
    }

    const totalHours = endHourmeter - startHourmeter
    form.setFieldValue('totalHours', isNaN(totalHours) ? undefined : totalHours)
  }, [ startHourmeter, endHourmeter ])

  return (
    <>
      {selectedNode && (
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 5 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Registro de utilización">
            {initialValues.rentalCondition.type === RentalConditionTypeValue.HOURS && (
              <>
                <Form.Item
                  label="Horómetro inicial"
                  name="startHourmeter"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0}/>
                </Form.Item>

                <Form.Item
                  label="Horómetro final"
                  name="endHourmeter"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={startHourmeter}/>
                </Form.Item>

                <Form.Item
                  label="Total de horas"
                  name="totalHours"
                  rules={[{ required: false }]}
                >
                  <InputNumber disabled={true}/>
                </Form.Item>
              </>
            )}

            {initialValues.rentalCondition.type === RentalConditionTypeValue.WORKING_DAY && (
              <Form.Item
                label="Jornada"
                name="workingDay"
                rules={[{ required: true }]}
              >
                <WorkingDaySelector/>
              </Form.Item>
            )}

            {(initialValues.rentalCondition.type === RentalConditionTypeValue.TRAVELS ||
              initialValues.rentalCondition.type === RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD ||
              initialValues.rentalCondition.type === RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO) && (
              <Form.Item
                label="Total de viajes"
                name="totalTravels"
                rules={[{ required: true }]}
              >
                <InputNumber min={0}/>
              </Form.Item>
            )}
          </FormSection>

          {user?.role === UserRoleValue.ADMIN && initialValues.rentalCondition.type === RentalConditionTypeValue.HOURS && (
            <FormSection title="Condiciones del arriendo">
              <Form.Item
                label="Horas mínimas"
                name="minHours"
                rules={[{ required: false }]}
              >
                <InputNumber min={0}/>
              </Form.Item>
            </FormSection>
          )}

          <FormSection title="Observaciones y Firma">
            <Form.Item
              label="¿Ocurrió alguna incidencia?"
              name="hadIncidences"
              help="Si tuvo una incidencia marque esta casilla para que el administrador sea notificado. Luego deje sus observaciones."
              valuePropName="checked"
              rules={[{ required: false }]}
            >
              <Switch checkedChildren="Si" unCheckedChildren="No"/>
            </Form.Item>

            <Form.Item
              label="Observaciones"
              name="observations"
              help="Máximo 500 caracteres"
              rules={[{ required: hadIncidences }]}
            >
              <TextArea maxLength={500}/>
            </Form.Item>

            {!initialValues.signature && (
              <Form.Item
                label={(
                  <span className="flex flex-wrap gap-1.5 items-center">
                    Firma digital <Switch checked={isSignActive} onChange={setIsSignActive} checkedChildren="Habilitada" unCheckedChildren="Desactivada"/>
                  </span>
                )}
                help="Al desactivar la firma digital, no significa que se elimina la firma, solo que no se podrá editar. Es decir, que este registro quedará como firmado. Si desea enviar el registro como no firmado, deberá eliminar la firma usando el botón dispuesto en la barra superiór."
                name="signature"
                rules={[{ required: false }]}
              >
                <SignPad disableUploadImage={true} disabled={!isSignActive}/>
              </Form.Item>
            )}
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
