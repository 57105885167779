import { SelectProps } from 'antd/es/select'
import { Dayjs } from 'dayjs'
import { FC } from 'react'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { RentalMachinerySelector } from '@/presentation/rentals/components/RentalMachinerySelector.tsx'

export const FuelRegistryMachinerySelector: FC<SelectProps & { date: Dayjs }> = ({ date, ...props }) => {
  return (
    <RentalMachinerySelector {...props} filters={{
      date: getLocalDateStartOf({ date }).format(),
    }} />
  )
}
