/* eslint-disable prefer-promise-reject-errors */

import { RuleObject, StoreValue } from 'rc-field-form/lib/interface'
import { checkRut } from '@/presentation/common/utils/rut.ts'

export const confirmPassword = (compareTo: StoreValue) => (_rule: RuleObject, value: StoreValue) => {
  if (!value || !compareTo) {
    return Promise.resolve()
  }

  if (value !== compareTo) {
    return Promise.reject('Las contraseñas no coinciden')
  }

  return Promise.resolve()
}

export const password = (_rule: RuleObject, value: StoreValue) => {
  if (!value) {
    return Promise.resolve()
  }

  if (value.length < 4) {
    return Promise.reject('La contraseña debe tener al menos 4 caracteres')
  }

  return Promise.resolve()
}

export const rutValidator = (_rule: RuleObject, value: StoreValue) => {
  if (!value) {
    return Promise.resolve()
  }

  if (!checkRut(value)) {
    return Promise.reject('El rut ingresado no es válido')
  }

  return Promise.resolve()
}
