import { Typography } from 'antd'
import { FC, useContext } from 'react'
import { useSidebarRightToggle } from '@/presentation/common/hooks/useSidebarRightToggle.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'

const helpBarSymbol = Symbol('helpBar')

export const HelpBar: FC = () => {
  const { content: helpContent } = useContext(HelpContext)
  const { toggle } = useSidebarRightToggle(helpBarSymbol, helpContent)

  return (
    <div className="flex justify-end gap-2 px-4 py-2 bg-surface border-b border-outline">
      <div
        className="flex items-center gap-1 bg-sky-100 p-1 rounded-md border border-sky-200 cursor-pointer"
        onClick={toggle}
      >
        <i className="ri-question-line"/> <Typography.Text
          className="font-semibold mr-[2px]">Ayuda</Typography.Text>
      </div>
    </div>
  )
}
