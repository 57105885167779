export enum UserRoleValue {
  ADMIN = 'ADMIN',
  MACHINE_OPERATOR = 'MACHINE_OPERATOR',
  CONSTRUCTION_MANAGER = 'CONSTRUCTION_MANAGER',
  FUEL_SUPPLIER = 'FUEL_SUPPLIER',
  MECHANIC = 'MECHANIC',
}

export class UserRole {
  readonly value!: UserRoleValue

  constructor(value: UserRoleValue) {
    this.value = value
  }

  static fromString(value: string): UserRole {
    if (!this.toList().includes(value as UserRoleValue)) {
      throw new Error(`Invalid UserRole: ${value}`)
    }

    return new UserRole(value as UserRoleValue)
  }

  static toList(): UserRoleValue[] {
    return Object.values(UserRoleValue)
  }

  static toEntries(): [UserRoleValue, UserRole][] {
    return this.toList().map((value) => [ value, new UserRole(value) ])
  }

  static readonly labels: Record<UserRoleValue, string> = {
    ADMIN                : 'Administrador',
    MACHINE_OPERATOR     : 'Operador de Máquina',
    CONSTRUCTION_MANAGER : 'Jefe de Obra',
    FUEL_SUPPLIER        : 'Suministrador de Combustible',
    MECHANIC             : 'Mecánico',
  }

  label(): string {
    return UserRole.labels[this.value]
  }
}
