import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryMachineryResponse } from '@/api/machinery/response.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { MaintenanceType, MaintenanceTypeValue } from '@/domain/models/maintenanceType.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryMachineryForTableResult = PaginatedData<{
  id: string
  type: MachineryTypeValue
  code: string
  brand: string
  model: string
  year: number
  serialNumber: string
  maintenanceType: MaintenanceTypeValue | null
  truckVolume: number | null
}>

export const useQueryMachineryForTable = () => {
  const repository = useMachineryApiClient()
  const queryItemsHook = useQueryItems<QueryMachineryResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron maquinarias',
    },
  })

  const [ data, setData ] = useState<QueryMachineryForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryMachineryResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id              : item.id.toString(),
        type            : MachineryType.fromString(item.type).value,
        code            : item.code,
        brand           : item.brand,
        model           : item.model,
        year            : item.year,
        serialNumber    : item.serialNumber,
        maintenanceType : item.maintenanceType ? MaintenanceType.fromString(item.maintenanceType).value : null,
        truckVolume     : item.truckVolume,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
