import { apiErrorWrapper } from '@/api/common/apiErrorWrapper.ts'
import { client } from '@/api/common/client.ts'
import { apiErrorMapper } from '@/api/common/utils/apiErrorMapper.ts'

type PutMappingProps<TRequest> = {
  path: string
  body: TRequest
}

export async function putMapping<TResponse, TRequest>({
  path,
  body,
}: PutMappingProps<TRequest>) {
  const { data, error } = await apiErrorWrapper(
    async () => await client.put(path, body),
  )
  if (error) {
    apiErrorMapper(error)
  }
  return data as TResponse
}
