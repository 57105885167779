import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useState } from 'react'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'
import {
  QueryRentalsBuildingsFilters,
  QueryRentalsBuildingsForSelectorResult,
  useQueryRentalsBuildingsForSelector,
} from '@/presentation/rentals/hooks/useQueryRentalsBuildingsForSelector.ts'

type Props = SelectProps & {
  filters: QueryRentalsBuildingsFilters,
  onCustomSelect?: (item?: QueryRentalsBuildingsForSelectorResult['data']) => void,
}

export const RentalBuildingSelector: FC<Props> = ({ filters, onCustomSelect, ...props }) => {
  const { data, query, hasMorePages, lastPage, isLoading } = useQueryRentalsBuildingsForSelector(filters)
  const [ lastSearch, setLastSearch ] = useState<string>()

  const limit = 100

  useEffect(() => {
    if (filters.client) {
      query({
        params: {
          page: 1,
          limit,
          ...filters,
        },
      })
    }
  }, [ filters ])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
        ...filters,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
            ...filters,
          },
        })
      }
    }
  }

  const handleOnSelect = (value: string) => {
    const item = data?.find((item) => item.value === value)
    onCustomSelect?.(item?.data)
  }

  const options = data?.map((item) => ({
    value : item.value,
    label : item.label,
  }))

  return (
    <Select
      placeholder="Seleccione una obra"
      allowClear
      showSearch
      onSearch={onSearch}
      onPopupScroll={onScroll}
      onSelect={handleOnSelect}
      filterOption={false}
      loading={isLoading}
      options={options}
      {...props}
    />
  )
}
