import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { CreateMaintenanceProductForm } from '@/presentation/maintenance/forms/CreateMaintenanceProductForm.tsx'
import { DeleteMaintenanceProductsForm } from '@/presentation/maintenance/forms/DeleteMaintenanceProductsForm.tsx'
import { LoadProductsTemplateForm } from '@/presentation/maintenance/forms/LoadProductsTemplateForm.tsx'
import { MaintenanceProductsActionsProps } from '@/presentation/maintenance/forms/types.ts'
import { UpdateMaintenanceProductForm } from '@/presentation/maintenance/forms/UpdateMaintenanceProductForm.tsx'

export const MaintenanceProductsActions: FC<MaintenanceProductsActionsProps> = ({ onAction }) => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canCreate, canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'TEMPLATE' | 'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'
  const isTemplate = option === 'TEMPLATE'

  let title = ''
  switch (option) {
    case 'TEMPLATE':
      title = 'Pre-cargar plantilla'
      break
    case 'POST':
      title = 'Registrar producto'
      break
    case 'PUT':
      title = 'Actualizar producto'
      break
    case 'DELETE':
      title = 'Eliminar selección'
      break
    default:
      break
  }

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.call(this)
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPost && <CreateMaintenanceProductForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && <UpdateMaintenanceProductForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteMaintenanceProductsForm onCancel={closeModal} onFinish={onFinish}/>}
        {isTemplate && <LoadProductsTemplateForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      <SidebarButton label="Precargar plantilla" type="TEMPLATE" onClick={() => {
        setOption('TEMPLATE')
        setOpen(true)
      }}/>

      {canCreate && (
        <SidebarButton label="Registrar producto" type="POST" onClick={() => {
          setOption('POST')
          setOpen(true)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar producto" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
