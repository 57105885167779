import { FC, useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellSignedStatus } from '@/presentation/common/components/maintainer/table/cells/MTCellSignedStatus.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { dateComparator } from '@/presentation/common/components/table/comparators/dateComparator.ts'
import { signatureComparator } from '@/presentation/common/components/table/comparators/signatureComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { onlyDateFormatter } from '@/presentation/common/utils/dateFormatter.ts'
import { EppActions } from '@/presentation/epp/forms/EppActions.tsx'
import { useQueryEppsForTable } from '@/presentation/epp/hooks/useQueryEppsForTable.ts'
import { useEppsMaintainerStore } from '@/presentation/epp/store/eppsMaintainers.ts'

const EppsTable: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, setSelectedEppId, setIsSigned } = useEppsMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryEppsForTable()

  // ayuda a mantener la selección cuando se cambia de tab, cuando el tab vuelva a ser epps, se configura la selección del mantenedor con esta variable
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'operator',
      headerName              : 'Operador',
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      comparator              : stringComparator,
    },
    {
      field       : 'date',
      headerName  : 'Fecha',
      comparator  : dateComparator,
      formatValue : ({ value }) => onlyDateFormatter(value),
      width       : 140,
    },
    {
      field        : 'isSigned',
      headerName   : 'Estado',
      width        : 140,
      comparator   : signatureComparator,
      cellRenderer : MTCellSignedStatus,
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      setCurrentSelection(selectedRows)
      setSelectionV2(selectedRows)
      setSelectedEppId(selectedRows.length === 1 ? selectedRows[0]?.data.id : null)
      setIsSigned(selectedRows.length === 1 ? selectedRows[0]?.data.isSigned : false)
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <EppActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'epps') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // first load
  useEffect(() => {
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'epps') {
      reset()
      setTitle('EPPs')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'epps') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'epps' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}

export default EppsTable
