import { FC, useEffect, useMemo } from 'react'
import { FuelRegistryOperationType, FuelRegistryOperationTypeValue } from '@/domain/models/fuelOperationType.ts'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MTCellBadge } from '@/presentation/common/components/maintainer/table/cells/MTCellBadge.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { dateComparator } from '@/presentation/common/components/table/comparators/dateComparator.ts'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { onlyDateFormatter } from '@/presentation/common/utils/dateFormatter.ts'
import { timeFormatter } from '@/presentation/common/utils/timeFormatter.ts'
import { FuelRegistriesActions } from '@/presentation/fuelRegistries/forms/FuelRegistriesActions.tsx'
import {
  QueryFuelRegistriesForTableResult,
  useQueryFuelRegistriesForTable,
} from '@/presentation/fuelRegistries/hooks/useQueryFuelRegistriesForTable.ts'
import {
  MTCellRentalMachinery,
  MTCellRentalMachineryData,
} from '@/presentation/rentals/components/MTCellRentalMachinery.tsx'

const FuelRegistries: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading, lastUpdate } = useQueryFuelRegistriesForTable()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable           : true,
    resizable          : true,
    suppressMovable    : true,
    autoHeaderHeight   : true,
    minWidth           : 100,
    cellRendererParams : { align: 'center' },
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'type',
      headerName              : 'Operación',
      comparator              : stringComparator,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      width                   : 165,
      formatValue             : ({ value }) => FuelRegistryOperationType.fromString(value).label(),
      cellRenderer            : (cellNode, node, column, api) => (
        <MTCellBadge {...{ cellNode, node, column, api }} className={'bg-blue-50 uppercase min-w-[100px] text-center'}/>
      ),
    },
    { field: 'date', headerName: 'Fecha', width: 100, comparator: dateComparator, formatValue: ({ value }) => onlyDateFormatter(value) },
    { field: 'time', headerName: 'Hora', width: 70, minWidth: 70, comparator: stringComparator, formatValue: ({ value }) => timeFormatter(value) },
    {
      field        : 'machinery',
      headerName   : 'Maquinaria / equipo',
      sortable     : false,
      cellRenderer : (cellNode, node : RowNode<QueryFuelRegistriesForTableResult['items'][0]>, column, api) =>
        node.data.type === FuelRegistryOperationTypeValue.REFILL ||
        node.data.type === FuelRegistryOperationTypeValue.REFILL_PICKUP ?
          <MTCellRentalMachinery {...{
            cellNode,
            node: {
              ...node,
              data: {
                rentalType : node.data.machinery?.id ? RentalTypeValue.INTERNAL : RentalTypeValue.EXTERNAL,
                machinery  : node.data.machinery ? {
                  id           : node.data.machinery.id,
                  type         : node.data.machinery.type ? MachineryType.fromString(node.data.machinery.type).value : null,
                  code         : node.data.machinery.code,
                  brand        : node.data.machinery.brand,
                  model        : node.data.machinery.model,
                  serialNumber : node.data.machinery.serialNumber,
                  description  : node.data.machinery.description,
                  truckVolume  : null,
                } : {
                  id           : null,
                  type         : null,
                  code         : null,
                  brand        : null,
                  model        : null,
                  serialNumber : null,
                  description  : null,
                  truckVolume  : null,
                },
              } as MTCellRentalMachineryData,
            },
            column,
            api,
          }} /> :
          (
            node.data.type === FuelRegistryOperationTypeValue.OTHER ?
              <MTCellBase {...{ cellNode, node, column, api }}>{node.data.equipment}</MTCellBase> :
              null
          ),
    },
    { field: 'liters', headerName: 'Litros', width: 70, minWidth: 70, comparator: numberComparator },
    { field: 'hourmeter', headerName: 'Horómetro', width: 100, comparator: numberComparator },
    { field: 'guideNumber', headerName: 'Número de guía', width: 150, comparator: numberComparator },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 76,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <FuelRegistriesActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    setTitle('Registros de suministro de combustible')
    setActionsSlot(actionsComponent)
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // when search changes update query
  useEffect(() => {
    query({
      params: {
        search,
        page: 1,
      },
    })
  }, [ search ])

  // helps to keep actions slot updated with refresh callback
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ lastUpdate ])

  return (
    <Maintainer>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default FuelRegistries
