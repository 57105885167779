import { CreateRentalRequest } from '@/api/rentals/request.types.ts'
import { CreateRentalResponse } from '@/api/rentals/response.types.ts'
import { useRentalsApiClient } from '@/api/rentals/useRentalsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'


export const useCreateRental = () => {
  const repository = useRentalsApiClient()
  const createItem = useCreateItem<CreateRentalRequest, CreateRentalResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el arriendo',
      alreadyExists : 'Ya existe un arriendo',
      success       : 'Arriendo creado con éxito',
      loading       : 'Creando arriendo',
    },
  })

  return {
    ...createItem,
  }
}
