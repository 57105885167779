import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { UpdateProductsTemplateFormProps } from '@/presentation/productsTemplates/forms/types.ts'
import {
  QueryProductsTemplatesForTableResult,
} from '@/presentation/productsTemplates/hooks/useQueryProductsTemplatesForTable.ts'
import { useUpdateProductsTemplate } from '@/presentation/productsTemplates/hooks/useUpdateProductsTemplate.ts'

type UpdateProductsTemplateFormFields = {
  description: string
}

export const UpdateProductsTemplateForm: FC<UpdateProductsTemplateFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateProductsTemplateForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateProductsTemplate()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryProductsTemplatesForTableResult['items'][0]> = Object.values(selectionNodes)[0]

  const initialValues = {
    ...(selectedNode?.data || {}),
  }

  const onSubmit = async (values: UpdateProductsTemplateFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateProductsTemplateFormFields

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: editedValues,
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la plantilla para mantención">
            <Form.Item
              label="Descripción"
              name="description"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
