export const routes = {
  login           : '/',
  settings        : '/settings',
  forgotPassword  : '/forgot-password',
  recoverPassword : '/recover-password',
  modules         : '/modules',
  dashboards      : '/dashboards',
  tenants         : '/tenants',
  users           : '/users',
  clients         : '/clients',
  machinery       : '/machinery',
  fuel            : '/fuel',
  reports         : '/reports',
  inventory       : '/inventory',
}
