import { UpdateClientRequest } from '@/api/clients/request.types.ts'
import { UpdateClientResponse } from '@/api/clients/response.types.ts'
import { useClientsApiClient } from '@/api/clients/useClientsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateClient = () => {
  const repository = useClientsApiClient()
  const updateItem = useUpdateItem<UpdateClientRequest, UpdateClientResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el cliente',
      alreadyExists : 'Un cliente con ese nombre ya existe',
      success       : 'Cliente actualizado con éxito',
      loading       : 'Actualizando cliente',
    },
  })

  return {
    ...updateItem,
  }
}
