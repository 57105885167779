import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { CreateEppProductForm } from '@/presentation/epp/forms/CreateEppProductForm.tsx'
import { DeleteEppProductsForm } from '@/presentation/epp/forms/DeleteEppProductsForm.tsx'
import { LoadEppProductsTemplateForm } from '@/presentation/epp/forms/LoadEppProductsTemplateForm.tsx'
import { EppProductsActionsProps } from '@/presentation/epp/forms/types.ts'
import { UpdateEppProductForm } from '@/presentation/epp/forms/UpdateEppProductForm.tsx'
import { useEppsMaintainerStore } from '@/presentation/epp/store/eppsMaintainers.ts'

export const EppProductsActions: FC<EppProductsActionsProps> = ({ onAction }) => {
  const { isSigned } = useEppsMaintainerStore()
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canCreate, canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'TEMPLATE' | 'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'
  const isTemplate = option === 'TEMPLATE'

  let title = ''
  switch (option) {
    case 'TEMPLATE':
      title = 'Pre-cargar plantilla'
      break
    case 'POST':
      title = 'Registrar producto'
      break
    case 'PUT':
      title = 'Actualizar producto'
      break
    case 'DELETE':
      title = 'Eliminar selección'
      break
    default:
      break
  }

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  return (
    <>
      {!isSigned && (
        <>
          <MaintainerModal title={title} open={open}>
            {isPost && <CreateEppProductForm onCancel={closeModal} onFinish={onFinish}/>}
            {isPut && <UpdateEppProductForm onCancel={closeModal} onFinish={onFinish}/>}
            {isDelete && <DeleteEppProductsForm onCancel={closeModal} onFinish={onFinish}/>}
            {isTemplate && <LoadEppProductsTemplateForm onCancel={closeModal} onFinish={onFinish}/>}
          </MaintainerModal>

          <SidebarButton label="Precargar plantilla" type="TEMPLATE" onClick={() => {
            setOption('TEMPLATE')
            setOpen(true)
          }}/>

          {canCreate && (
            <SidebarButton label="Registrar producto" type="POST" onClick={() => {
              setOption('POST')
              setOpen(true)
            }}/>
          )}

          {canUpdate && (
            <SidebarButton label="Actualizar producto" type="PUT" onClick={() => {
              setOption('PUT')
              setOpen(true)
            }}/>
          )}

          {canDelete && (
            <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
              setOption('DELETE')
              setOpen(true)
            }}/>
          )}
        </>
      )}
    </>
  )
}
