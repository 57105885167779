import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteProductsFormProps } from '@/presentation/products/forms/types.ts'
import { useDeleteProducts } from '@/presentation/products/hooks/useDeleteProducts.ts'
import { QueryProductsForTableResult } from '@/presentation/products/hooks/useQueryProductsForTable.ts'

export const DeleteProductsForm: FC<DeleteProductsFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteProductsForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteProducts()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = selectionNodes.map((node: RowNode<QueryProductsForTableResult['items'][0]>) => node.data.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.()
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los productos seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}
          disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
