import { FC, useEffect } from 'react'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { MaintainerTabs } from '@/presentation/common/components/maintainer/MaintainerTabs.tsx'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DownloadRentalExcelForm } from '@/presentation/rentals/forms/DownloadRentalExcelForm.tsx'
import { useTabs } from '@/presentation/rentals/hooks/useTabs.ts'
import { RentalsMachineryTable } from '@/presentation/rentals/RentalsMachineryTable.tsx'
import { RentalsReceiversTable } from '@/presentation/rentals/RentalsReceiversTable.tsx'
import { RentalsTable } from '@/presentation/rentals/RentalsTable.tsx'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

const Rentals: FC = () => {
  const { selectedTab, setSelectedTab } = useRentalsMaintainerStore()
  const { tabs } = useTabs()
  const { reset, setTitle } = useMaintainerStore()
  const { open, setOpen } = useActionsModal()

  const onChangeTab= (tab: string) => {
    setSelectedTab(tab as 'rentals' | 'machinery' | 'receivers')
  }

  const closeDownloadExcelModal = () => {
    setOpen(false)
  }

  useEffect(() => {
    return () => {
      reset()
      setTitle('Arriendos')
      setSelectedTab('rentals')
    }
  }, [])

  return (
    <>
      <MaintainerModal title="Descargar archivo Excel" open={open}>
        <DownloadRentalExcelForm onCancel={closeDownloadExcelModal} onFinish={closeDownloadExcelModal}/>
      </MaintainerModal>

      <Maintainer
        headerSlot={(
          <MaintainerTabs items={tabs} value={selectedTab} onChange={onChangeTab}/>
        )}
        showExcelDownloadButton
        onExcelDownloadButtonClick={() => setOpen(true)}
      >
        <RentalsTable/>
        <RentalsMachineryTable/>
        <RentalsReceiversTable/>
      </Maintainer>
    </>
  )
}

export default Rentals
