import { create } from 'zustand'

export interface MaintenancesMaintainerState {
  selectedTab: 'maintenances' | 'products',
  selectedMaintenanceId: string | null,

  setSelectedTab: (tab: 'maintenances' | 'products') => void,
  setSelectedMaintenanceId: (id: string | null) => void,
}

export const useMaintenancesMaintainerStore = create<MaintenancesMaintainerState>()(
  (set, _get) => ({
    selectedTab           : 'maintenances',
    selectedMaintenanceId : null,

    setSelectedTab           : (tab: 'maintenances' | 'products') => set({ selectedTab: tab }),
    setSelectedMaintenanceId : (id: string | null) => set({ selectedMaintenanceId: id }),
  }),
)
