import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryTenantsResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

type QueryTenantsForTableResult = PaginatedData<{
  id: string
  cdn: string
  name: string
  dni: string
  address: string
  activity: string
  contactPhone: string
  contactEmail: string
  picture: string | null
  subscription: {
    status: string
  }
  representativeSignature: string | null
}>

export const useQueryTenantsForTable = () => {
  const repository = useTenantsApiClient()
  const queryItemsHook = useQueryItems<QueryTenantsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron inquilinos',
    },
  })

  const [ data, setData ] = useState<QueryTenantsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryTenantsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items,
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
