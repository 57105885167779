import dayjs, { Dayjs } from 'dayjs'

type Props = {
  date?: Dayjs | Date | string,
  startOf?: 'day' | 'month' | 'year',
}

export const getLocalDateStartOf = ({ date, startOf }: Props = {}): Dayjs => {
  return dayjs(date).local().startOf(startOf ?? 'day')
}
