import { Button, DatePicker, Form } from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useMemo } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { getLocalDateEndOf } from '@/presentation/common/utils/getLocalDateEndOf.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { useDownloadReport } from '@/presentation/fuelPerformanceReport/hooks/useDownloadReport.ts'

type FuelPerformanceReportFields = {
  dates: [Dayjs, Dayjs]
}

export const FuelPerformanceReport = () => {
  const initialValues = useMemo(() => ({
    dates: [ dayjs().startOf('month'), dayjs().endOf('month') ] as [Dayjs, Dayjs],
  }), [])

  const messageApiKey = 'FuelPerformanceReport-alert'
  const { submit, isLoading, isError, message, isSuccess, lastUpdate, data: file } = useDownloadReport()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: FuelPerformanceReportFields) => {
    await submit({
      since : getLocalDateStartOf({ date: values.dates[0] }).format(),
      until : getLocalDateEndOf({ date: values.dates[1] }).format(),
    })
  }

  useEffect(() => {
    if (!isLoading && file) {
      window.open(URL.createObjectURL(file))
    }
  }, [ lastUpdate, file ])

  return (
    <FormLayout title="Reporte rendimiento de combustible">
      <section className="app-card">
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Filtros">
            <FormItem
              label="Rango de fechas"
              name="dates"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker format="DD-MM-YYYY" className="w-full"/>
            </FormItem>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Obtener reporte</Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}
