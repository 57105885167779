import Joi from 'joi'

const envVarsSchema = Joi.object()
  .keys({
    VITE_API_URL               : Joi.string().required().description('API url'),
    VITE_GCP_PUBLIC_BUCKET_URL : Joi.string().required().description('URL of public bucket (images, pdfs, etc.)'),
  })
  .unknown()

const { value: envVars, error } = envVarsSchema.prefs({ errors: { label: 'key' } }).validate(import.meta.env)

if (error) {
  throw new Error(`Config validation error: ${error.message}`)
}

const config = {
  apiUrl          : envVars.VITE_API_URL,
  publicBucketUrl : envVars.VITE_GCP_PUBLIC_BUCKET_URL,
}

export default config
