import { Checkbox, Pagination } from 'antd'
import { Fragment, ReactNode, useEffect, useMemo, useRef } from 'react'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import { useDataGridApi } from '@/presentation/common/components/dataGrid/lib/api.ts'
import {
  CellNode,
  DataGridApi,
  DataGridApiConstructorParams,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import styles from './DataGrid.module.scss'

export const DataGrid = (props: DataGridApiConstructorParams) => {
  const api= useDataGridApi(props)

  return (
    <>
      {api.config.useMobileView ?
        <DataGridMobile api={api} isLoading={props.isLoading}/> :
        <DataGridDesktop api={api} isLoading={props.isLoading}/>
      }
    </>
  )
}

const DataGridDesktop = ({ api, isLoading }: {api: DataGridApi, isLoading?: boolean}) => {
  const tableScroll = useRef<HTMLDivElement>(null)

  const initialColumnsWidth = useMemo(() => {
    const columnsWithFixedWidth = api.config.columnDefs.filter((column) => column.width !== undefined)
    const tableScrollWidth = (tableScroll.current?.clientWidth ?? 0) - columnsWithFixedWidth.reduce((acc, column) => acc + (column.width ?? 0), 0)
    return tableScrollWidth / ((api.config.columnDefs.length || 1) - columnsWithFixedWidth.length)
  }, [ tableScroll.current, tableScroll.current?.clientWidth ])

  return (
    <div className={`${styles.tableWrapper} surface`}>
      <div ref={tableScroll} className={styles.tableScroll}>
        <table className={styles.table}>
          <thead>
            <tr>
              {api.config.columnDefs.map((column) => (
                <DataGridDesktopColumnHeader key={column.field} api={api} column={column} initialColumnsWidth={initialColumnsWidth}/>
              ))}
            </tr>
          </thead>

          {!isLoading && (
            <tbody>
              {api.nodes.map((row) => (
                <tr key={row.id}>
                  {api.config.columnDefs.map((column) => (
                    <DataGridDesktopCell key={column.field} api={api} column={column} node={row}/>
                  ))}
                </tr>
              ))}
            </tbody>
          )}

          {isLoading && (
            <tbody>
              <tr>
                <td colSpan={api.config.columnDefs.length}>
                  <div className="flex justify-center gap-2 py-4 px-2 cursor-default">
                    <i className="ri-loader-4-line animate-spin cursor-default"></i> Cargando...
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {api.config.pagination?.enabled && (
        <div className="flex justify-center mt-5">
          <Pagination
            current={api.config.pagination.currentPage || 1}
            total={api.config.pagination.totalItems}
            pageSize={api.config.pagination.pageSize}
            disabled={isLoading}
            showTotal={(total) => `Total de registros: ${total}`}
            onChange={api.config.pagination.onPageChange}
          />
        </div>
      )}
    </div>
  )
}

const DataGridDesktopColumnHeader = ({ api, column, initialColumnsWidth }: {api: DataGridApi, column: DataGridColumnDefs, initialColumnsWidth: number}) => {
  const th = useRef<HTMLTableCellElement>(null)
  const dragHandler = useRef<HTMLDivElement>(null)

  const headerName = column.headerRenderer?.(column) ?? column.headerName ?? column.field

  const onSort = () => {
    if (!api.config.defaultColDef.sortable || !column.sortable) {return}
    api.toggleSort(column.field)
  }

  useEffect(() => {
    const minWidth = column.minWidth ?? api.config.defaultColDef.minWidth
    let initialThLeft = 0

    // configuramos inicialmente el width del th para evitar que el redimensionado del resto de columnas achique a esta
    if (th.current) {
      if (column.width) {
        th.current.style.width = Math.max(minWidth, column.width) + 'px'
      }
      else {
        th.current.style.width = Math.max(minWidth, initialColumnsWidth) + 'px'
      }
    }

    if (column.resizable === false) {return}

    // función para redimensionar la columna
    function resizeColumn(e: MouseEvent) {
      if (!th.current) {return}
      const offset = th.current?.offsetWidth - ((initialThLeft + th.current?.offsetWidth) - e.pageX)
      th.current.style.width = Math.max(minWidth, offset) + 'px'
    }

    // función para detener la redimensión
    function stopResize() {
      document.removeEventListener('mousemove', resizeColumn)
    }

    function onResize(e: MouseEvent) {
      e.preventDefault()
      initialThLeft = th.current?.getBoundingClientRect().left || 0
      document.addEventListener('mousemove', resizeColumn)
      document.addEventListener('mouseup', stopResize)
    }

    // agregar evento de resize cuando el mouse presiona y mantiene arrastrando el after del th
    dragHandler.current?.addEventListener('mousedown', onResize)

    return () => {
      stopResize()
      dragHandler.current?.removeEventListener('mousedown', onResize)
    }
  }, [ initialColumnsWidth ])

  return (
    <th ref={th}>
      <div className={styles.tableHeaderWrapper}>
        {column.headerCheckboxSelection === true && api.config.rowSelection === 'multiple' && (
          <Checkbox
            checked={api.config.rowsSelectionStatus === 'all'}
            indeterminate={api.config.rowsSelectionStatus === 'partial'}
            onChange={(e) => api.onGlobalSelection(e.target.checked)}
            className="mr-2"
          />
        )}

        <div className={styles.tableHeaderContent} onClick={onSort}>
          {headerName}
        </div>

        {api.config.defaultColDef.sortable && column.sortable && (
          <div className={styles.tableHeaderSort} onClick={onSort}>
            {column.sort === 'asc' && (
              <i className="ri-sort-asc"></i>
            )}
            {column.sort === 'desc' && (
              <i className="ri-sort-desc"></i>
            )}
          </div>
        )}

        {column.resizable !== false && (
          <div className={styles.tableHeaderDragHandler} ref={dragHandler}>
            <span/>
          </div>
        )}
      </div>
    </th>
  )
}

const DataGridDesktopCell = ({ api, column, node }: {api: DataGridApi, column: DataGridColumnDefs, node: RowNode}) => {
  const {
    field,
    cellRenderer,
  } = column

  const cellNode: CellNode = useMemo(() => node.cellNodes[field] ?? (node.data[field] ? {
    value: node.data[field],
  } : {}) ?? {}, [ node.cellNodes, field ])

  const cellValue: ReactNode = useMemo(() => {
    if (cellRenderer) {
      return cellRenderer(cellNode, node, column, api)
    }

    return <MTCellBase {...{ api, column, node, cellNode }}>{cellNode.formattedValue ?? cellNode.value}</MTCellBase>
  }, [ cellRenderer, cellNode ])

  return (
    <td style={{
      height: api.config.rowHeight,
    }}>
      <div className={styles.tableCellWrapper}>
        {column.checkboxSelection === true && (
          <Checkbox
            checked={node.selected}
            onChange={(e) => api.onRowSelected(node, e.target.checked)}
            className="mr-2"
          />
        )}

        <div className={styles.tableCellContent}>
          {cellValue}
        </div>
      </div>
    </td>
  )
}

const DataGridMobile = ({ api, isLoading }: {api: DataGridApi, isLoading?: boolean}) => {
  const headerCheckboxSelection = api.config.columnDefs.some((column) => column.headerCheckboxSelection === true)

  return (
    <div className={`${styles.tableWrapper} surface`}>
      {!isLoading && (
        <div>
          {headerCheckboxSelection && (
            <div className="pt-2 pb-4 border-b border-b-outline">
              <Checkbox
                checked={api.config.rowsSelectionStatus === 'all'}
                indeterminate={api.config.rowsSelectionStatus === 'partial'}
                onChange={(e) => api.onGlobalSelection(e.target.checked)}
              >
                Selección global
              </Checkbox>
            </div>
          )}

          {api.nodes.map((node) => {
            return (
              <div key={node.id} className="relative grid grid-cols-[auto,_1fr] gap-x-1.5 overflow-x-hidden border-b border-b-outline py-2">
                {api.config.columnDefs?.map((column) => {
                  const headerName = column.headerRenderer?.(column) ?? column.headerName ?? column.field
                  const cellNode: CellNode = node.cellNodes[column.field] ?? (node.data[column.field] ? {
                    value: node.data[column.field],
                  } : {}) ?? {}
                  const cellValue: ReactNode =  column.cellRenderer?.(cellNode, node, column, api) ?? cellNode.formattedValue ?? cellNode.value

                  return (
                    <Fragment key={`${node.id}-${column.field}`}>
                      {column.checkboxSelection === true && (
                        <div className="absolute top-3.5 right-2 z-10">
                          <Checkbox
                            checked={node.selected}
                            onChange={(e) => api.onRowSelected(node, e.target.checked)}
                          />
                        </div>
                      )}

                      <div className="text-sm font-medium text-primary py-3">
                        <span className="bg-tertiary-min bg-opacity-50 px-1.5 py-1 rounded-md block">{headerName}</span>
                      </div>

                      {!column.cellRenderer && (
                        <MTCellBase {...{
                          cellNode,
                          node,
                          column,
                          api,
                        }}>
                          {cellValue}
                        </MTCellBase>
                      )}

                      {column.cellRenderer && cellValue}
                    </Fragment>
                  )
                })}
              </div>
            )
          })}
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center gap-2 py-4 px-2 cursor-default">
          <i className="ri-loader-4-line animate-spin cursor-default"></i> Cargando...
        </div>
      )}

      {api.config.pagination?.enabled && (
        <div className="flex justify-center mt-5">
          <Pagination
            current={api.config.pagination.currentPage || 1}
            total={api.config.pagination.totalItems}
            pageSize={api.config.pagination.pageSize}
            disabled={isLoading}
            showTotal={(total) => `Total de registros: ${total}`}
            onChange={api.config.pagination.onPageChange}
          />
        </div>
      )}
    </div>
  )
}
