import { FC, useEffect } from 'react'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MaintainerTabs } from '@/presentation/common/components/maintainer/MaintainerTabs.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { useTabs } from '@/presentation/maintenance/hooks/useTabs.ts'
import MaintenancesProductsTable from '@/presentation/maintenance/MaintenancesProductsTable.tsx'
import MaintenancesTable from '@/presentation/maintenance/MaintenancesTable.tsx'
import { useMaintenancesMaintainerStore } from '@/presentation/maintenance/store/maintenancesMaintainers.ts'

const Maintenances: FC = () => {
  const { selectedTab, setSelectedTab } = useMaintenancesMaintainerStore()
  const { tabs } = useTabs()
  const { reset, setTitle } = useMaintainerStore()

  const onChangeTab= (tab: string) => {
    setSelectedTab(tab as 'maintenances' | 'products')
  }

  useEffect(() => {
    return () => {
      reset()
      setTitle('Mantenciones')
      setSelectedTab('maintenances')
    }
  }, [])

  return (
    <Maintainer
      headerSlot={(
        <MaintainerTabs items={tabs} value={selectedTab} onChange={onChangeTab}/>
      )}
    >
      <MaintenancesTable/>
      <MaintenancesProductsTable/>
    </Maintainer>
  )
}

export default Maintenances
