import { useEppsMaintainerStore } from '@/presentation/epp/store/eppsMaintainers.ts'

export const useTabs = () => {
  const { selectedEppId } = useEppsMaintainerStore()

  const tabsSetup = {
    epps     : { id: 'epps', label: 'EPPs' },
    products : { id: 'products', label: 'Productos entregados' },
  }

  const visibleTabs: Record<string, boolean> = {
    epps     : true,
    products : false,
  }

  if (selectedEppId) {
    visibleTabs.products = true
  }
  else {
    visibleTabs.products = false
  }

  const tabs = Object.values(tabsSetup).filter(tab => visibleTabs[tab.id])

  return {
    tabs,
  }
}
