import { Button, Form, Input, UploadFile } from 'antd'
import { UploadFileStatus } from 'antd/es/upload/interface'
import { useContext, useEffect, useMemo, useState } from 'react'
import config from '@/config.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'
import { UploadAvatarImage } from '@/presentation/tenants/components/UploadAvatarImage.tsx'
import { useUpdateTenantSettings } from '@/presentation/tenants/hooks/useUpdateTenantSettings.ts'

type UpdateTenantFormFields = {
  picture?: { uid: string; name: string; status: UploadFileStatus; url: string; }[]
  name?: string
  dni?: string
  address?: string
  activity?: string
  contactPhone?: string
  contactEmail?: string
  representativeName?: string
  representativePosition?: string
  representativeSignature?: string | null
}

const TenantSettings = () => {
  const { render } = useContext(HelpContext)
  const messageApiKey = 'TenantSettings-alert'
  const [ form ] = Form.useForm<UpdateTenantFormFields>()
  const [ isUploadingPicture, setIsUploadingPicture ] = useState<boolean>(false)
  const [ fileList, setFileList ] = useState<UploadFile[]>([])
  const [ fileListChanged, setFileListChanged ] = useState<boolean>(false)
  const [ fileTmpPath, setFileTmpPath ] = useState<string | null>()
  const { submit, isSuccess, isLoading, isError, message } = useUpdateTenantSettings()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { tenant, setTenant } = useSessionStore()

  const initialValues: UpdateTenantFormFields = useMemo(() => ({
    ...(tenant ? tenant : {}),
    picture: tenant?.picture ? [{
      uid    : '-1',
      name   : tenant?.picture?.split('/').pop() || 'avatar',
      status : 'done',
      url    : tenant?.picture,
    }] : undefined,
  }), [ tenant ])

  useEffect(() => {
    setFileList(initialValues.picture || [])
  }, [])

  const onSubmit = async (values: UpdateTenantFormFields) => {
    if (isLoading || isUploadingPicture || !tenant?.id) {
      return
    }

    delete initialValues.picture
    const editedValues = getModifiedFields(initialValues, values) as UpdateTenantFormFields

    const response = await submit({
      query: {
        id: tenant?.id,
      },
      body: {
        picture                 : fileListChanged ? fileTmpPath : undefined,
        name                    : editedValues.name,
        dni                     : editedValues.dni,
        address                 : editedValues.address,
        activity                : editedValues.activity,
        contactPhone            : editedValues.contactPhone,
        contactEmail            : editedValues.contactEmail,
        representativeName      : editedValues.representativeName,
        representativePosition  : editedValues.representativePosition,
        representativeSignature : editedValues.representativeSignature,
      },
    })

    if (response) {
      setTenant(response as any)
      form.setFieldsValue({
        ...(tenant ? tenant : {}),
        ...response,
        picture: response?.picture ? [{
          uid    : '-1',
          name   : response?.picture?.split('/').pop() || 'avatar',
          status : 'done',
          url    : `${config.publicBucketUrl}/${response?.picture}`,
        }] : undefined,
      })

      setFileListChanged(false)
    }
  }

  const onPictureUploaded = ({ file }: { file: UploadFile }) => {
    setIsUploadingPicture(true)
    setFileList([ file ])
    setFileListChanged(true)

    if (file.status === 'done') {
      setFileTmpPath(file.response)
      setIsUploadingPicture(false)
    }

    if (file.status === 'removed') {
      setFileTmpPath(null)
      setFileList([])
      setIsUploadingPicture(false)
    }

    if (file.status === 'error') {
      setFileTmpPath(null)
      setIsUploadingPicture(false)
    }
  }

  useEffect(() => {
    render(false)
  }, [])

  return (
    <FormLayout title="Configuraciones de la empresa">
      <section className="app-card">
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la empresa">
            <Form.Item<UpdateTenantFormFields>
              label="Logo"
              valuePropName="fileList"
              rules={[{ required: false }]}
            >
              <UploadAvatarImage fileList={fileList} onChange={onPictureUploaded}/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Nombre"
              name="name"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="RUT"
              name="dni"
              rules={[
                { required: true },
                { validator: rutValidator },
              ]}
            >
              <RutInput/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Actividad económica"
              name="activity"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Dirección"
              name="address"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Teléfono de contacto"
              name="contactPhone"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Correo de contacto"
              name="contactEmail"
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <FormSection title="Datos del representante">
            <Form.Item<UpdateTenantFormFields>
              label="Nombre"
              name="representativeName"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item<UpdateTenantFormFields>
              label="Cargo"
              name="representativePosition"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Firma digital"
              name="representativeSignature"
              rules={[{ required: false }]}
            >
              <SignPad/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button
              size="small"
              type="primary"
              htmlType="submit"
              disabled={isLoading || isUploadingPicture}
            >
              Actualizar
            </Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}

export default TenantSettings
