import { UpdateEppProductRequest } from '@/api/eppProducts/request.types.ts'
import { UpdateEppProductResponse } from '@/api/eppProducts/response.types.ts'
import { useEppProductsApiClient } from '@/api/eppProducts/useEppProductsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateEppProduct = () => {
  const repository = useEppProductsApiClient()
  const updateItem = useUpdateItem<UpdateEppProductRequest, UpdateEppProductResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el producto',
      alreadyExists : 'Ya existe un registro con el mismo producto e inventario',
      success       : 'Producto actualizado con éxito',
      loading       : 'Actualizando producto',
    },
  })

  return {
    ...updateItem,
  }
}
