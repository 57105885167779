import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryRentalsResponse } from '@/api/rentals/response.types.ts'
import { useRentalsApiClient } from '@/api/rentals/useRentalsApiClient.ts'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryRentalsForTableResult = PaginatedData<{
  id: string
  type: RentalTypeValue
  client: {
    id: string
    name: string
  }
  constructionManager: {
    id: string
    fullName: string
  } | null
  building: string
  address: string
  externalCompany: string | null
  startDate: string
  endDate: string
}>

export const useQueryRentalsForTable = () => {
  const repository = useRentalsApiClient()
  const queryItemsHook = useQueryItems<QueryRentalsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron arriendos',
    },
  })

  const [ data, setData ] = useState<QueryRentalsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryRentalsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id     : item.id.toString(),
        type   : RentalType.fromString(item.type).value,
        client : {
          id   : item.client.id.toString(),
          name : item.client.name,
        },
        constructionManager: item.constructionManager ? {
          id       : item.constructionManager.id.toString(),
          fullName : item.constructionManager.fullName,
        } : null,
        building        : item.building,
        address         : item.address,
        externalCompany : item.externalCompany ?? null,
        startDate       : item.startDate,
        endDate         : item.endDate,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
