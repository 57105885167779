import { FC, useEffect, useMemo } from 'react'
import { WorkingDay } from '@/domain/models/workingDay.ts'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MTCellSignedStatus } from '@/presentation/common/components/maintainer/table/cells/MTCellSignedStatus.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { dateComparator } from '@/presentation/common/components/table/comparators/dateComparator.ts'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { signatureComparator } from '@/presentation/common/components/table/comparators/signatureComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { onlyDateFormatter } from '@/presentation/common/utils/dateFormatter.ts'
import {
  MTCellUseRecordIncidencesFlag,
} from '@/presentation/machineryUseRecords/components/MTCellUseRecordIncidencesFlag.tsx'
import { MTCellUseRecordRental } from '@/presentation/machineryUseRecords/components/MTCellUseRecordRental.tsx'
import { MachineryUseRecordsActions } from '@/presentation/machineryUseRecords/forms/MachineryUseRecordsActions.tsx'
import {
  useQueryMachineryUseRecordsForTable,
} from '@/presentation/machineryUseRecords/hooks/useQueryMachineryUseRecordsForTable.ts'

const MachineryUseRecords: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading, lastUpdate } = useQueryMachineryUseRecordsForTable()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field              : 'hadIncidences',
      headerName         : 'Incidencias',
      sortable           : false,
      resizable          : false,
      minWidth           : 30,
      width              : 30,
      headerRenderer     : () => <span className="flex justify-center"><i className="ri-flag-2-fill cursor-default text-[1.2rem]"></i></span>,
      cellRenderer       : MTCellUseRecordIncidencesFlag,
      cellRendererParams : { justify: 'center', align: 'middle' },
    },
    {
      field                   : 'folio',
      headerName              : 'Folio',
      comparator              : numberComparator,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      width                   : 100,
    },
    {
      field       : 'date',
      headerName  : 'Fecha',
      comparator  : dateComparator,
      formatValue : ({ value }) => onlyDateFormatter(value),
      width       : 140,
    },
    {
      field        : 'isSigned',
      headerName   : 'Estado',
      width        : 140,
      comparator   : signatureComparator,
      cellRenderer : MTCellSignedStatus,
    },
    { field: 'rental', headerName: 'Arriendo', sortable: false, cellRenderer: MTCellUseRecordRental, width: 420 },
    { field: 'machinery', headerName: 'Maquinaria', comparator: stringComparator },
    { field: 'machineryConditionValues.startHourmeter', headerName: 'Hor. Inicial', comparator: numberComparator, width: 100 },
    { field: 'machineryConditionValues.endHourmeter', headerName: 'Hor. Final', comparator: numberComparator, width: 100 },
    { field: 'machineryConditionValues.totalHours', headerName: 'Total Horas', comparator: numberComparator, width: 100 },
    { field: 'rentalCondition.load', headerName: 'Carga', comparator: stringComparator },
    { field: 'machineryConditionValues.totalTravels', headerName: 'Total Viajes', comparator: numberComparator, width: 100 },
    {
      field       : 'machineryConditionValues.workingDay',
      headerName  : 'Jornada',
      comparator  : stringComparator,
      formatValue : ({ value }) => value ? WorkingDay.fromString(value).label() : '',
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 76,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <MachineryUseRecordsActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    setTitle('Registros de uso')
    setActionsSlot(actionsComponent)
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // when search changes update query
  useEffect(() => {
    query({
      params: {
        search,
        page: 1,
      },
    })
  }, [ search ])

  // helps to keep actions slot updated with refresh callback
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ lastUpdate ])

  return (
    <Maintainer>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default MachineryUseRecords
