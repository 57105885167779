import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryProductsTemplatesResponse } from '@/api/productsTemplates/response.types.ts'
import { useProductsTemplatesApiClient } from '@/api/productsTemplates/useProductsTemplatesApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryProductsTemplatesForTableResult = PaginatedData<{
  id: string
  description: string
}>

export const useQueryProductsTemplatesForTable = () => {
  const repository = useProductsTemplatesApiClient()
  const queryItemsHook = useQueryItems<QueryProductsTemplatesResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron plantillas para mantención',
      success : 'Plantillas para mantención encontradas',
      loading : 'Buscando plantillas',
    },
  })

  const [ data, setData ] = useState<QueryProductsTemplatesForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryProductsTemplatesResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id          : item.id.toString(),
        description : item.description,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
