import { DeleteManyMachineryRequest } from '@/api/machinery/request.types.ts'
import { DeleteManyMachineryResponse } from '@/api/machinery/response.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteMachinery = () => {
  const repository = useMachineryApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyMachineryRequest, DeleteManyMachineryResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar las maquinarias',
      success : 'Maquinarias eliminadas',
      loading : 'Eliminando maquinarias',
    },
  })

  return {
    ...deleteItems,
  }
}
