import { Typography } from 'antd'
import { FC } from 'react'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

type Props = {
  cellNode: CellNode<{
    id: string | null
    type: MachineryTypeValue
    code: string | null
    brand: string | null
    model: string | null
    serialNumber: string | null
    description: string | null
    truckVolume: number | null
  }>
  node: RowNode
  column: DataGridColumnDefs
  api: DataGridApi
  className?: string
}

export const MTCellMachinery : FC<Props> = ({ cellNode, node, column, api }) => {
  const machinery = cellNode.value
  const description = `${MachineryType.fromString(machinery.type).label()} ${machinery.brand} ${machinery.model} (${machinery.serialNumber})`

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <div className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[300px]">
        <section className="flex flex-col flex-1 overflow-hidden">
          <div className="flex flex-nowrap items-baseline gap-2">
            {machinery.code && (
              <div className="flex-1">
                <Typography.Text className="font-medium uppercase select-text">{machinery.code}</Typography.Text>
              </div>
            )}
            <div className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-all">
              {machinery.id}
            </div>
          </div>

          <Typography.Text className="text-sm uppercase select-text">{description}</Typography.Text>
        </section>
      </div>
    </MTCellBase>
  )
}
