import { FC, useEffect } from 'react'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MaintainerTabs } from '@/presentation/common/components/maintainer/MaintainerTabs.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import EppsProductsTable from '@/presentation/epp/EppsProductsTable.tsx'
import EppsTable from '@/presentation/epp/EppsTable.tsx'
import { useTabs } from '@/presentation/epp/hooks/useTabs.ts'
import { useEppsMaintainerStore } from '@/presentation/epp/store/eppsMaintainers.ts'

const Epps: FC = () => {
  const { selectedTab, setSelectedTab } = useEppsMaintainerStore()
  const { tabs } = useTabs()
  const { reset, setTitle } = useMaintainerStore()

  const onChangeTab= (tab: string) => {
    setSelectedTab(tab as 'epps' | 'products')
  }

  useEffect(() => {
    return () => {
      reset()
      setTitle('EPPs')
      setSelectedTab('epps')
    }
  }, [])

  return (
    <Maintainer
      headerSlot={(
        <MaintainerTabs items={tabs} value={selectedTab} onChange={onChangeTab}/>
      )}
    >
      <EppsTable/>
      <EppsProductsTable/>
    </Maintainer>
  )
}

export default Epps
