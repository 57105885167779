import { FC, useEffect } from 'react'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MaintainerTabs } from '@/presentation/common/components/maintainer/MaintainerTabs.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { useTabs } from '@/presentation/productsTemplates/hooks/useTabs.ts'
import { ProductsTemplatesProductsTable } from '@/presentation/productsTemplates/ProductsTemplatesProductsTable.tsx'
import { ProductsTemplatesTable } from '@/presentation/productsTemplates/ProductsTemplatesTable.tsx'
import {
  useProductsTemplatesMaintainerStore,
} from '@/presentation/productsTemplates/store/productsTemplatesMaintainers.ts'

const ProductsTemplates: FC = () => {
  const { selectedTab, setSelectedTab } = useProductsTemplatesMaintainerStore()
  const { tabs } = useTabs()
  const { reset, setTitle } = useMaintainerStore()

  const onChangeTab= (tab: string) => {
    setSelectedTab(tab as 'templates' | 'products')
  }

  useEffect(() => {
    return () => {
      reset()
      setTitle('Plantillas de productos')
      setSelectedTab('templates')
    }
  }, [])

  return (
    <Maintainer
      headerSlot={(
        <MaintainerTabs items={tabs} value={selectedTab} onChange={onChangeTab}/>
      )}
    >
      <ProductsTemplatesTable/>
      <ProductsTemplatesProductsTable/>
    </Maintainer>
  )
}

export default ProductsTemplates
