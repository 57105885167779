import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryInventoriesResponse } from '@/api/inventories/response.types.ts'
import { useInventoriesApiClient } from '@/api/inventories/useInventoriesApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryInventoriesForTableResult = PaginatedData<{
  id: string
  name: string
  description: string | null
  location: {
    address: string | null
    city: string | null
    country: string | null
  }
  contact: {
    name: string | null
    email: string | null
    phone: string | null
  }
}>

export const useQueryInventoriesForTable = () => {
  const repository = useInventoriesApiClient()
  const queryItemsHook = useQueryItems<QueryInventoriesResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron espacios',
    },
  })

  const [ data, setData ] = useState<QueryInventoriesForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryInventoriesResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id          : item.id.toString(),
        name        : item.name,
        description : item.description,
        location    : {
          address : item.location.address,
          city    : item.location.city,
          country : item.location.country,
        },
        contact: {
          name  : item.contact.name,
          email : item.contact.email,
          phone : item.contact.phone,
        },
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
