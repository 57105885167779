export enum SignatureStatusValue {
  SIGNED = 'SIGNED',
  NOT_SIGNED = 'NOT_SIGNED',
}

export class SignatureStatus {
  readonly value!: SignatureStatusValue

  constructor(value: SignatureStatusValue) {
    this.value = value
  }

  static fromString(value: string): SignatureStatus {
    if (!this.toList().includes(value as SignatureStatusValue)) {
      throw new Error(`Invalid SignatureStatus: ${value}`)
    }

    return new SignatureStatus(value as SignatureStatusValue)
  }

  static toList(): SignatureStatusValue[] {
    return Object.values(SignatureStatusValue)
  }

  static toEntries(): [SignatureStatusValue, SignatureStatus][] {
    return this.toList().map((value) => [ value, new SignatureStatus(value) ])
  }

  static readonly labels: Record<SignatureStatusValue, string> = {
    SIGNED     : 'FIRMADO',
    NOT_SIGNED : 'PENDIENTE',
  }

  label(): string {
    return SignatureStatus.labels[this.value]
  }
}
