import { Button, Upload, UploadFile } from 'antd'
import { FC, useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import { SignColorPickerProps, SignPadProps } from '@/presentation/common/components/types.ts'

export const SignPad: FC<SignPadProps> = ({ value, onChange, disableUploadImage, disabled }) => {
  const wrapper = useRef<HTMLDivElement | null>(null)
  const sigPad = useRef<SignaturePad | null>(null)
  const [ canvasSizes, setCanvasSizes ] = useState<{ width: number, height: number }>({ width: 0, height: 0 })
  const [ selectedColor, setSelectedColor ] = useState<SignColorPickerProps['color']>('#1f2937')

  const onSelectColor = (color: SignColorPickerProps['color']) => {
    setSelectedColor(color)
  }

  const onClear = () => {
    sigPad?.current?.clear()
    onChange?.(null)
  }

  const loadSignature = (data: string) => {
    const i = new Image()

    i.onload = () => {
      const isLandscape = i.width > i.height
      const maxWidth = (wrapper?.current?.offsetWidth || 0) - 2
      const maxHeight = (wrapper?.current?.offsetHeight || 0) - 40
      const ratio = i.width / i.height
      let width = isLandscape ? maxWidth : maxHeight * ratio
      let height = isLandscape ? maxWidth / ratio : maxHeight

      if (isLandscape) {
        if (height > maxHeight) {
          height = maxHeight
          width = height * ratio
        }
      }
      else {
        if (width > maxWidth) {
          width = maxWidth
          height = width / ratio
        }
      }

      onChange?.(data)
      sigPad?.current?.clear()
      sigPad?.current?.fromDataURL(data, {
        ratio  : ratio,
        width  : width,
        height : height,
        callback(error) {
          if (error) {
            sigPad?.current?.clear()
            onChange?.(null)
          }
        },
      })
    }

    i.src = data
  }

  const onUpload = ({ file }: { file: UploadFile }) => {
    if (file.status === 'done') {
      const reader = new FileReader()

      reader.onload = () => {
        const data = reader.result as string
        loadSignature(data)
      }

      reader.readAsDataURL(file.originFileObj as File)
    }
  }

  useEffect(() => {
    if (!wrapper?.current) {
      return
    }

    const observer = new ResizeObserver(() => {
      setCanvasSizes({
        width  : (wrapper?.current?.offsetWidth || 0) - 2,
        height : (wrapper?.current?.offsetHeight || 0) - 40,
      })
    })

    observer.observe(wrapper?.current)

    return () => {
      observer.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!sigPad?.current) {
      return
    }

    const canvas = sigPad.current.getCanvas()
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return
    }

    ctx.imageSmoothingEnabled = true
    ctx.imageSmoothingQuality = 'high'
    ctx.lineCap = 'round'
    ctx.lineJoin = 'round'
  }, [])

  useEffect(() => {
    if (value) {
      loadSignature(value)
    }
  }, [ value ])

  return (
    <div data-disabled={disabled} className="app-signpad max-w-[350px]">
      {disabled && <div className="app-signpad-disabler-fill"/>}

      <div ref={wrapper} className="flex flex-col justify-end border rounded-md relative h-[240px]">
        <section
          className="flex justify-end absolute top-0 left-0 w-full p-[3px] bg-secondary-min rounded-tl-[0.3rem] rounded-tr-[0.3rem] z-10 border-b">
          <div className="flex-1 flex gap-1 items-center">
            <SignColorPicker color="#1f2937" selected={selectedColor === '#1f2937'}
              onClick={onSelectColor}/>
            <SignColorPicker color="#0369a1" selected={selectedColor === '#0369a1'}
              onClick={onSelectColor}/>
            <SignColorPicker color="#064e3b" selected={selectedColor === '#064e3b'}
              onClick={onSelectColor}/>
          </div>

          <Button danger type="text" size="small" onClick={onClear}>Limpiar</Button>
        </section>

        <SignaturePad
          ref={sigPad}
          penColor={selectedColor}
          minWidth={1}
          maxWidth={2}
          canvasProps={{
            width     : canvasSizes.width,
            height    : canvasSizes.height,
            className : 'absolute bottom-0 left-0 border-box rounded-bl-[0.3rem] rounded-br-[0.3rem]',
          }}
          onEnd={() => {
            onChange?.(sigPad?.current?.toDataURL())
          }}
        />
      </div>

      {!disableUploadImage && (
        <div className="flex justify-start mt-2">
          <Upload
            maxCount={1}
            multiple={false}
            showUploadList={false}
            onChange={onUpload}
            customRequest={({ onSuccess }) => {
              onSuccess?.call(this, 'Ok')
            }}>
            <Button size="small" block>
              <i className="ri-upload-line icon-sm mr-2"/> Subir imagen desde dispositivo
            </Button>
          </Upload>
        </div>
      )}
    </div>
  )
}

const SignColorPicker: FC<SignColorPickerProps> = ({ color, selected, onClick }) => {
  return (
    <div
      onClick={() => onClick?.(color)}
      style={{
        backgroundColor : color,
        borderColor     : selected ? '#a1a1aa' : '#f8fafc',
      }}
      className="rounded-full w-[20px] h-[20px] cursor-pointer border-2"
    />
  )
}
