import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateProductRequest,
  DeleteManyProductsRequest,
  UpdateProductRequest,
} from '@/api/products/request.types.ts'
import {
  CreateProductResponse,
  DeleteManyProductsResponse,
  DownloadExcelResumeResponse,
  QueryProductsResponse,
  UpdateProductResponse,
} from '@/api/products/response.types.ts'

export type ProductsApiClient = {
  query: (options: QueryOptions) => Promise<QueryProductsResponse>
  create: (req: CreateProductRequest) => Promise<CreateProductResponse>
  update: (req: UpdateProductRequest) => Promise<UpdateProductResponse>
  deleteMany: (req: DeleteManyProductsRequest) => Promise<DeleteManyProductsResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useProductsApiClient = (): ProductsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryProductsResponse, QueryParams>({
    path   : '/products',
    params : options.params,
  }),

  create: async (body: CreateProductRequest) => await postMapping<CreateProductResponse, CreateProductRequest>({
    path : '/products',
    body : body,
  }),

  update: async (req: UpdateProductRequest) => await putMapping<UpdateProductResponse, UpdateProductRequest['body']>({
    path : `/products/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyProductsRequest) => await deleteMapping<DeleteManyProductsResponse, DeleteManyProductsRequest>({
    path : '/products',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/products/excel',
  }),
})
