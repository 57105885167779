export enum RentalTypeValue {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export class RentalType {
  readonly value!: RentalTypeValue

  constructor(value: RentalTypeValue) {
    this.value = value
  }

  static fromString(value: string): RentalType {
    if (!this.toList().includes(value as RentalTypeValue)) {
      throw new Error(`Invalid RentalType: ${value}`)
    }

    return new RentalType(value as RentalTypeValue)
  }

  static toList(): RentalTypeValue[] {
    return Object.values(RentalTypeValue)
  }

  static toEntries(): [RentalTypeValue, RentalType][] {
    return this.toList().map((value) => [ value, new RentalType(value) ])
  }

  static readonly labels: Record<RentalTypeValue, string> = {
    INTERNAL : 'Interna',
    EXTERNAL : 'Externa',
  }

  label(): string {
    return RentalType.labels[this.value]
  }
}
