import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateProductsTemplateRequest,
  DeleteManyProductsTemplatesRequest,
  UpdateProductsTemplateRequest,
} from '@/api/productsTemplates/request.types.ts'
import {
  CreateProductsTemplateResponse,
  DeleteManyProductsTemplatesResponse,
  DownloadExcelResumeResponse,
  QueryProductsTemplatesResponse,
  UpdateProductsTemplateResponse,
} from '@/api/productsTemplates/response.types.ts'

export type ProductsTemplatesApiClient = {
  query: (options: QueryOptions) => Promise<QueryProductsTemplatesResponse>
  create: (req: CreateProductsTemplateRequest) => Promise<CreateProductsTemplateResponse>
  update: (req: UpdateProductsTemplateRequest) => Promise<UpdateProductsTemplateResponse>
  deleteMany: (req: DeleteManyProductsTemplatesRequest) => Promise<DeleteManyProductsTemplatesResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useProductsTemplatesApiClient = (): ProductsTemplatesApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryProductsTemplatesResponse, QueryParams>({
    path   : '/products-templates',
    params : options.params,
  }),

  create: async (body: CreateProductsTemplateRequest) => await postMapping<CreateProductsTemplateResponse, CreateProductsTemplateRequest>({
    path : '/products-templates',
    body : body,
  }),

  update: async (req: UpdateProductsTemplateRequest) => await putMapping<UpdateProductsTemplateResponse, UpdateProductsTemplateRequest['body']>({
    path : `/products-templates/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyProductsTemplatesRequest) => await deleteMapping<DeleteManyProductsTemplatesResponse, DeleteManyProductsTemplatesRequest>({
    path : '/products-templates',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/products-templates/excel',
  }),
})
