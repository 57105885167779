import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export const MTCellUidV2 = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const cellValue = cellNode.formattedValue ?? cellNode.value

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <div
        className="py-1 px-3 pt-[0.33rem] bg-orange-50 font-mono text-sm rounded-md inline-block select-all">
        {cellValue}
      </div>
    </MTCellBase>
  )
}
