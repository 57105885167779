import { useEffect, useMemo, useState } from 'react'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MTCellRentalMachinery } from '@/presentation/rentals/components/MTCellRentalMachinery.tsx'
import { RentalsMachineryActions } from '@/presentation/rentals/forms/RentalsMachineryActions.tsx'
import { useQueryRentalsMachineryForTable } from '@/presentation/rentals/hooks/useQueryRentalsMachineryForTable.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'
import { rentalMachinerySortComparator } from '@/presentation/rentals/utils/rentalMachinerySortComparator.ts'

export const RentalsMachineryTable = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, selectedRentalId } = useRentalsMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryRentalsMachineryForTable(selectedRentalId)

  // ayuda a mantener la selección cuando se cambia de tab
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'machinery',
      headerName              : 'Maquinaria',
      comparator              : rentalMachinerySortComparator,
      cellRenderer            : (cellNode, node, column, api) => <MTCellRentalMachinery {...{ cellNode, node, column, api }}/>,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
    },
    {
      field       : 'operator',
      headerName  : 'Operador',
      comparator  : stringComparator,
      formatValue : ({ value }: { value: { fullName: string } }) => value.fullName,
    },
    {
      field       : 'rentalCondition.type',
      headerName  : 'Condición de trabajo',
      comparator  : stringComparator,
      formatValue : ({ value }: { value: RentalConditionTypeValue}) => RentalConditionType.fromString(value).label(),
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled     : (data?.totalItems || 0) > 0,
      pageSize    : data?.limit || 10,
      currentPage : data?.page || 1,
      totalItems  : data?.totalItems || 0,
      onChange    : (page: number, limit: number) => query({
        params: {
          page,
          limit,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      if (selectedTab === 'machinery') {
        setSelectionV2(selectedRows)
        setCurrentSelection(selectedRows)
      }
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow, selectedTab ])

  const actionsComponent = useMemo(() => <RentalsMachineryActions onAction={() => refresh(selectedRentalId)}/>, [ refresh, selectedRentalId ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'machinery') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // carga cada vez que cambia el rental id
  useEffect(() => {
    setCurrentSelection([])
    query({
      params: {
        page: 1,
      },
    })
  }, [ selectedRentalId ])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'machinery') {
      reset()
      setTitle('Maquinarias')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'machinery') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'machinery' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}
