import { useState } from 'react'
import { DownloadPdfResumeRequest } from '@/api/epps/request.types.ts'
import { useEppsApiClient } from '@/api/epps/useEppsApiClient.ts'
import { useDownloadPdfFile } from '@/presentation/common/hooks/crud/v2/useDownloadPdfFile.ts'

export const useDownloadReport = () => {
  const repository = useEppsApiClient()
  const query = useDownloadPdfFile<DownloadPdfResumeRequest>({
    filename : 'epp.pdf',
    callable : repository.downloadPdfResume,
    messages : {
      default : 'No se pudo descargar el archivo',
      loading : 'Obteniendo archivo',
      success : 'Archivo descargado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (req: DownloadPdfResumeRequest) => {
    const response = await query.submit(req)
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
