import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalConditionType } from '@/domain/models/rentalConditionType.ts'

type Props = SelectProps & {
  machineryType?: MachineryType | null
}

export const RentalConditionTypeSelector: FC<Props> = ({ machineryType, ...props }) => {
  const options = machineryType ?
    machineryType.allowedRentalConditionTypes().map((value) => {
      const rentalConditionType = RentalConditionType.fromString(value)
      return { value: rentalConditionType.value, label: rentalConditionType.label() }
    }) :
    RentalConditionType.toEntries().map(([ key, value ]) => ({
      value : key,
      label : value.label(),
    }))

  return (
    <Select
      placeholder="Selecciona una condición de arrendamiento"
      {...props}
      options={options}
    />
  )
}
