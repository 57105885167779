import { Layout, message } from 'antd'
import { FC, ReactNode, useState } from 'react'
import { Header } from '@/presentation/common/components/Header.tsx'
import { HelpBar } from '@/presentation/common/components/HelpBar.tsx'
import { Sidebar } from '@/presentation/common/components/Sidebar.tsx'
import { SidebarRight } from '@/presentation/common/components/SidebarRight.tsx'
import { ModulesLayoutProps } from '@/presentation/common/layout/types.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { MessageContext } from '@/presentation/common/store/apiContext/MessageContext.tsx'
import { SidebarContext } from '@/presentation/common/store/apiContext/SidebarContext.tsx'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const ModulesLayout: FC<ModulesLayoutProps> = ({ children, renderSidebar, fullMode }) => {
  const [ sidebarIsOpen, setSidebarIsOpen ] = useState<boolean>(!fullMode)
  const [ sidebarRightIsOpen, setSidebarRightIsOpen ] = useState<boolean>(false)
  const [ sidebarRightContent, setSidebarRightContent ] = useState<ReactNode | null>(null)
  const [ lastTriggerKey, setLastTriggerKey ] = useState<symbol>()
  const [ helpContent, setHelpContent ] = useState<ReactNode | null>(null)
  const [ renderHelpBar, setRenderHelpBar ] = useState<boolean>(false)
  const [ messageApi, contextHolder ] = message.useMessage()

  const sidebarCtxValue = {
    isOpen     : sidebarIsOpen,
    toggle     : () => setSidebarIsOpen(!sidebarIsOpen),
    forceClose : () => setSidebarIsOpen(false),
  }

  const sidebarRightCtxValue = {
    isOpen     : sidebarRightIsOpen,
    toggle     : () => setSidebarRightIsOpen(!sidebarRightIsOpen),
    lastTriggerKey,
    setLastTriggerKey,
    content    : sidebarRightContent,
    setContent : (content: ReactNode | null) => setSidebarRightContent(content),
    forceClose : () => setSidebarRightIsOpen(false),
  }

  const helpCtxValue = {
    content    : helpContent,
    setContent : (content: ReactNode | null) => setHelpContent(content),
    render     : setRenderHelpBar,
  }

  return (
    <>
      {contextHolder}
      <MessageContext.Provider value={messageApi}>
        <SidebarContext.Provider value={sidebarCtxValue}>
          <SidebarRightContext.Provider value={sidebarRightCtxValue}>
            <HelpContext.Provider value={helpCtxValue}>
              <Layout className="h-full">
                <Layout.Header className="flex sticky top-0 p-0 h-fit min-h-[55px]">
                  <Header renderSidebarToggle={renderSidebar} fullMode={fullMode}/>
                </Layout.Header>

                <Layout hasSider className="overflow-x-auto relative">
                  {renderSidebar && (<Sidebar fullMode={fullMode}/>)}

                  <Layout.Content className="relative overflow-auto bg-background min-w-[350px]">
                    <div className="flex flex-col absolute top-0 bottom-0 left-0 right-0">
                      {renderHelpBar && (
                        <section className="sticky top-0 z-50">
                          <HelpBar/>
                        </section>
                      )}

                      <section className="flex-grow overflow-auto relative">
                        {children}
                      </section>
                    </div>
                  </Layout.Content>

                  <SidebarRight/>
                </Layout>

              </Layout>
            </HelpContext.Provider>
          </SidebarRightContext.Provider>
        </SidebarContext.Provider>
      </MessageContext.Provider>
    </>
  )
}
