import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateMachineryRequest,
  DeleteManyMachineryRequest,
  DownloadMachineryUsageExcelRequest,
  UpdateMachineryRequest,
} from '@/api/machinery/request.types.ts'
import {
  CreateMachineryResponse,
  DeleteManyMachineryResponse,
  DownloadExcelResumeResponse,
  QueryMachineryResponse,
  QueryPickupsResponse,
  UpdateMachineryResponse,
} from '@/api/machinery/response.types.ts'

export type MachineryApiClient = {
  query: (options: QueryOptions) => Promise<QueryMachineryResponse>
  queryPickups: (options: QueryOptions) => Promise<QueryPickupsResponse>
  create: (req: CreateMachineryRequest) => Promise<CreateMachineryResponse>
  update: (req: UpdateMachineryRequest) => Promise<UpdateMachineryResponse>
  deleteMany: (req: DeleteManyMachineryRequest) => Promise<DeleteManyMachineryResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
  downloadMachineryUsageExcelResume: (req: DownloadMachineryUsageExcelRequest) => Promise<DownloadExcelResumeResponse>
}

export const useMachineryApiClient = (): MachineryApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryMachineryResponse, QueryParams>({
    path   : '/machinery',
    params : options.params,
  }),

  queryPickups: async (options: QueryOptions) => await getMapping<QueryPickupsResponse, QueryParams>({
    path   : '/machinery/pickups',
    params : options.params,
  }),

  create: async (body: CreateMachineryRequest) => await postMapping<CreateMachineryResponse, CreateMachineryRequest>({
    path : '/machinery',
    body : body,
  }),

  update: async (req: UpdateMachineryRequest) => await putMapping<UpdateMachineryResponse, UpdateMachineryRequest['body']>({
    path : `/machinery/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyMachineryRequest) => await deleteMapping<DeleteManyMachineryResponse, DeleteManyMachineryRequest>({
    path : '/machinery',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/machinery/excel',
  }),

  downloadMachineryUsageExcelResume: async (req: DownloadMachineryUsageExcelRequest) => await getFileMapping<DownloadExcelResumeResponse, DownloadMachineryUsageExcelRequest>({
    path   : '/machinery/usage-report/excel',
    params : req,
  }),
})
