import { CreateEppProductRequest } from '@/api/eppProducts/request.types.ts'
import { CreateEppProductResponse } from '@/api/eppProducts/response.types.ts'
import { useEppProductsApiClient } from '@/api/eppProducts/useEppProductsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateEppProduct = () => {
  const repository = useEppProductsApiClient()
  const createItem = useCreateItem<CreateEppProductRequest, CreateEppProductResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar el producto',
      alreadyExists : 'Ya existe un registro con el mismo producto e inventario',
      success       : 'Producto registrado con éxito',
      loading       : 'Registrando producto',
    },
  })

  return {
    ...createItem,
  }
}
