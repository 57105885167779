import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { QueryOptions } from '@/api/common/types.ts'
import { PreviewPayStatesResponse } from '@/api/payStates/response.types.ts'
import { usePayStatesApiClient } from '@/api/payStates/usePayStatesApiClient.ts'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { useGetItems } from '@/presentation/common/hooks/crud/v2/useGetItems.ts'

export type PreviewPayStatesForTableResult = {
  query: {
    since: string
    until: string
    client: string
    building: string | null
    rentalMachinery: string[]
    includeVat: boolean
    includeFuel: boolean
  },
  resume: {
    detail: {
      id: string
      item: string
      quantity: number
      unitPrice: number
      total: number
    }[],
    summary: {
      total: number
      vat: number
      totalWithVat: number
    }
  },
  detail: Record<RentalTypeValue, Record<RentalConditionTypeValue, {
    items: {
      folio: number
      date: string
      client: string
      machinery: string
      operator: string
      building: string
      rentalCondition: {
        type: string
        load: string | null
        minHours: number | null
        amountPerHour: number | null
        amountPerDay: number | null,
        loadOrigin: string | null,
        amountPerTravel: number | null,
      }
      machineryConditionValues: {
        startHourmeter: number | null
        endHourmeter: number | null
        totalHours: number | null
        hoursToFacture: number | null
        workingDay: string | null
        totalTravels: number | null
      }
      truckVolume: number | null
      totalAmount: number
    }[],
    summary: {
      total: number
      vat: number
      totalWithVat: number
    }
  }>>
}

export const usePreviewPayState = () => {
  const repository = usePayStatesApiClient()
  const queryItemsHook = useGetItems<PreviewPayStatesResponse>({
    callable : repository.preview,
    messages : {
      default : 'Error al obtener los registros',
      loading : 'Obteniendo registros',
    },
  })

  const [ data, setData ] = useState<PreviewPayStatesForTableResult>()

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: PreviewPayStatesResponse) => {
    if (!response) {
      return setData(undefined)
    }
    setData({
      ...response,
      resume: {
        ...response.resume,
        detail: response.resume.detail.map((item) => ({
          ...item,
          id: uuid(),
        })),
      },
    })
  }

  return {
    ...queryItemsHook,
    query,
    data,
  }
}
