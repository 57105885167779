import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import { DownloadMaintenancePdfRequest, UpdateMaintenanceRequest } from '@/api/maintenances/request.types.ts'
import {
  DownloadPdfResponse,
  QueryMaintenancesResponse,
  UpdateMaintenanceResponse,
} from '@/api/maintenances/response.types.ts'

export type MaintenancesApiClient = {
  query: (options: QueryOptions) => Promise<QueryMaintenancesResponse>
  update: (req: UpdateMaintenanceRequest) => Promise<UpdateMaintenanceResponse>
  downloadPdf: (req: DownloadMaintenancePdfRequest) => Promise<DownloadPdfResponse>
}

export const useMaintenancesApiClient = (): MaintenancesApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryMaintenancesResponse, QueryParams>({
    path   : '/maintenances',
    params : options.params,
  }),

  update: async (req: UpdateMaintenanceRequest) => await putMapping<UpdateMaintenanceResponse, UpdateMaintenanceRequest['body']>({
    path : `/maintenances/${req.query.id}`,
    body : req.body,
  }),

  downloadPdf: async (req: DownloadMaintenancePdfRequest) => await getFileMapping<DownloadPdfResponse, QueryParams>({
    path   : `/maintenances/${req.id}/pdf`,
    params : {},
  }),
})
