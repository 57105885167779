import { useAuthApiClient } from '@/api/auth/useAuthApiClient.ts'
import { ApiError } from '@/api/common/apiError.ts'
import { BaseException } from '@/domain/exceptions/baseException.ts'
import { UserRole } from '@/domain/models/userRole.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

export const useLogin = () => {
  const authRepository = useAuthApiClient()
  const sessionStore = useSessionStore.getState()
  const status = useUiStatus()

  const login = async (username: string, password: string, cdn: string) => {
    try {
      status.setStatus(UiStateStatus.LOADING)
      status.setMessage('Cargando')

      const response = await authRepository.login({ username, password, cdn })

      sessionStore.setUser({
        ...response.user,
        role: UserRole.fromString(response.user.role).value,
      })
      sessionStore.setCdn(cdn)
      sessionStore.setAccessToken(response.tokens.access.token)
      sessionStore.setRefreshToken(response.tokens.refresh.token)
      sessionStore.setTenant(response.tenant)

      status.setStatus(UiStateStatus.SUCCESS)
      status.setMessage('Inicio de sesión exitoso')
    }
    catch (error) {
      const apiError = (error as BaseException).originalError

      if (apiError?.internalStatusCode === ApiError.TENANT_IS_NOT_ACTIVE) {
        status.setMessage('Su empresa no se encuentra activa, por favor comuníquese con el administrador')
      }
      else if (apiError?.internalStatusCode === ApiError.ENTITY_NOT_FOUND && apiError.meta?.entity === 'TENANT') {
        status.setMessage('El CDN ingresado no existe, por favor verifique e intente nuevamente')
      }
      else if (apiError?.internalStatusCode === ApiError.INVALID_CREDENTIALS) {
        status.setMessage('El usuario o la contraseña son incorrectos, por favor verifique e intente nuevamente')
      }
      else {
        status.setMessage('Ocurrió un error al intentar iniciar sesión, por favor intente nuevamente')
        console.error(error)
      }

      status.setStatus(UiStateStatus.ERROR)
    }
  }

  return {
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    message   : status.message,
    login,
  }
}
