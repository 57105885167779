import { UpdateMachineryRequest } from '@/api/machinery/request.types.ts'
import { UpdateMachineryResponse } from '@/api/machinery/response.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateMachinery = () => {
  const repository = useMachineryApiClient()
  const updateItem = useUpdateItem<UpdateMachineryRequest, UpdateMachineryResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar la maquinaria',
      alreadyExists : 'Ya existe una maquinaria con el código o matrícula',
      success       : 'Maquinaria actualizada con éxito',
      loading       : 'Actualizando maquinaria',
    },
  })

  return {
    ...updateItem,
  }
}
