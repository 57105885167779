import { Button, Form } from 'antd'
import { FC, useState } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { MachinerySelector } from '@/presentation/machinery/components/MachinerySelector.tsx'
import { QueryMachineryForSelectorResult } from '@/presentation/machinery/hooks/useQueryMachineryForSelector.ts'
import { RentalMachineryConditionsFormChunk } from '@/presentation/rentals/forms/RentalMachineryConditionsFormChunk.tsx'
import { CreateRentalInternalMachineryFormProps } from '@/presentation/rentals/forms/types.ts'
import { useCreateRentalMachinery } from '@/presentation/rentals/hooks/useCreateRentalMachinery.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'
import { UserSelector } from '@/presentation/users/components/UserSelector.tsx'

type CreateRentalInternalMachineryFormFields = {
  machinery: string,
  operator: string,
  rentalCondition: {
    type: RentalConditionTypeValue,
    minHours        : number | undefined,
    amountPerHour   : number | undefined,
    amountPerDay    : number | undefined,
    load            : string | undefined,
    loadOrigin      : string | undefined,
    amountPerTravel : number | undefined,
  },
}

export const CreateRentalInternalMachineryForm: FC<CreateRentalInternalMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateRentalInternalMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateRentalMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedRentalId, selectedRentalType } = useRentalsMaintainerStore()

  const [ form ] = Form.useForm()

  const [ selectedMachinery, setSelectedMachinery ] = useState<QueryMachineryForSelectorResult>()
  const machineryTypeValue = selectedMachinery?.type
  const machineryType = machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : undefined

  const onSubmit = async (values: CreateRentalInternalMachineryFormFields) => {
    if (isLoading || !selectedRentalId || !selectedRentalType) {
      return
    }

    const response = await submit({
      query: {
        rentalId: selectedRentalId,
      },
      body: {
        rentalType      : selectedRentalType,
        machinery       : values.machinery,
        operator        : values.operator,
        rentalCondition : {
          type            : values.rentalCondition.type,
          minHours        : values.rentalCondition.minHours ?? null,
          amountPerHour   : values.rentalCondition.amountPerHour ?? null,
          amountPerDay    : values.rentalCondition.amountPerDay ?? null,
          load            : values.rentalCondition.load ?? null,
          loadOrigin      : values.rentalCondition.loadOrigin ?? null,
          amountPerTravel : values.rentalCondition.amountPerTravel ?? null,
        },
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Maquinaria arrendada">
        <Form.Item
          name="machinery"
          label='Maquinaria'
          rules={[{ required: true }]}
        >
          <MachinerySelector onCustomSelect={setSelectedMachinery} excludePickups/>
        </Form.Item>

        <Form.Item
          name="operator"
          label='Operador'
          rules={[{ required: true }]}
        >
          <UserSelector role={UserRoleValue.MACHINE_OPERATOR}/>
        </Form.Item>

        <RentalMachineryConditionsFormChunk form={form} machineryType={machineryType}/>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
