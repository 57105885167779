import { DeleteManyClientsRequest } from '@/api/clients/request.types.ts'
import { DeleteManyClientsResponse } from '@/api/clients/response.types.ts'
import { useClientsApiClient } from '@/api/clients/useClientsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteClients = () => {
  const repository = useClientsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyClientsRequest, DeleteManyClientsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los clientes',
      success : 'Clientes eliminados',
      loading : 'Eliminando clientes',
    },
  })

  return {
    ...deleteItems,
  }
}
