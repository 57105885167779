import { DeleteManyMaintenanceProductsRequest } from '@/api/maintenanceProducts/request.types.ts'
import { DeleteManyMaintenanceProductsResponse } from '@/api/maintenanceProducts/response.types.ts'
import { useMaintenanceProductsApiClient } from '@/api/maintenanceProducts/useMaintenanceProductsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteMaintenanceProducts = () => {
  const repository = useMaintenanceProductsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyMaintenanceProductsRequest, DeleteManyMaintenanceProductsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los productos',
      success : 'Productos eliminados',
      loading : 'Eliminando productos',
    },
  })

  return {
    ...deleteItems,
  }
}
