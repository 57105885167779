import { Tooltip, Typography } from 'antd'
import { FC, useEffect } from 'react'
import { HR } from '@/presentation/common/components/HR.tsx'
import {
  MaintainerActionsContentProps,
  MaintainerActionsProps,
} from '@/presentation/common/components/maintainer/types.ts'
import { useSidebarRightToggle } from '@/presentation/common/hooks/useSidebarRightToggle.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

const actionsSymbol = Symbol('actions')

export const MaintainerActions: FC<MaintainerActionsProps> = ({ visible = true, actionSymbol = actionsSymbol }) => {
  const { title, actionsSlot } = useMaintainerStore()
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())

  const { toggle, refreshContent, isOpen, forceClose } = useSidebarRightToggle(actionSymbol, (
    <MaintainerActionsContent title={title}>
      {actionsSlot}
    </MaintainerActionsContent>
  ))

  useEffect(() => {
    refreshContent()
  }, [ title, actionsSlot ])

  useEffect(() => {
    if (!visible && isOpen) {
      forceClose()
    }
  }, [ visible ])

  return (
    <>
      {visible && (
        <Tooltip placement="left" title="Acciones">
          <div className="relative h-[32px] w-[32px]" onClick={toggle}>
            <i
              className='ri-command-line flex items-center justify-center absolute top-0 left-0 h-full w-full z-10 before:content-["\ebb8"]'
            />

            {selectionCount > 0 && (
              <div
                className="animate-pulse absolute top-0 left-0 h-full w-full bg-yellow-100 border-yellow-200 rounded-md border"
              />
            )}
          </div>
        </Tooltip>
      )}
    </>
  )
}

const MaintainerActionsContent: FC<MaintainerActionsContentProps> = ({ children, title }) => {
  return (
    <section className="py-2">
      <Typography.Title level={5}>Acciones del mantenedor de {title}</Typography.Title>
      <HR/>
      {children}
    </section>
  )
}
