import { FC, useState } from 'react'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { CreateRentalExternalMachineryForm } from '@/presentation/rentals/forms/CreateRentalExternalMachineryForm.tsx'
import { CreateRentalInternalMachineryForm } from '@/presentation/rentals/forms/CreateRentalInternalMachineryForm.tsx'
import { DeleteRentalMachineryForm } from '@/presentation/rentals/forms/DeleteRentalMachineryForm.tsx'
import { RentalsActionsProps } from '@/presentation/rentals/forms/types.ts'
import { UpdateRentalExternalMachineryForm } from '@/presentation/rentals/forms/UpdateRentalExternalMachineryForm.tsx'
import { UpdateRentalInternalMachineryForm } from '@/presentation/rentals/forms/UpdateRentalInternalMachineryForm.tsx'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

export const RentalsMachineryActions: FC<RentalsActionsProps> = ({ onAction }) => {
  const { selectedRentalType } = useRentalsMaintainerStore()
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())

  const { canCreate, canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'

  const title = isPost ? 'Registrar maquinaria' : isPut ? 'Actualizar maquinaria' : 'Eliminar selección'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPost && selectedRentalType === RentalTypeValue.INTERNAL && <CreateRentalInternalMachineryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPost && selectedRentalType === RentalTypeValue.EXTERNAL && <CreateRentalExternalMachineryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && selectedRentalType === RentalTypeValue.INTERNAL && <UpdateRentalInternalMachineryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && selectedRentalType === RentalTypeValue.EXTERNAL && <UpdateRentalExternalMachineryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteRentalMachineryForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canCreate && (
        <SidebarButton label="Registrar maquinaria" type="POST" onClick={() => {
          setOption('POST')
          setOpen(true)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar maquinaria" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
