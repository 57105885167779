import { useState } from 'react'
import { PaginatedData } from '@/presentation/common/types.ts'

export const useCachedPaginatedData = <T>() => {
  const [ cache, setCache ] = useState<Record<number, PaginatedData<T>>>({})

  const lastCachedPage = Object.keys(cache).length > 0 ? Math.max(...Object.keys(cache).map(Number)) : 0
  const hasMorePages = lastCachedPage > 0 && lastCachedPage < cache[lastCachedPage].totalPages
  const totalCachedPages = Object.keys(cache).length
  const mergedData = Object.values(cache).reduce((acc, curr) => {
    return [ ...acc, ...curr.items ]
  }, [] as T[])

  const resetCache = () => {
    setCache({})
  }

  const addPageToCache = (page: number, data: PaginatedData<T>, resetFirst = false) => {
    setCache({
      ...(resetFirst ? {} : cache),
      [page]: data,
    })
  }

  return {
    cache,
    mergedData,
    lastCachedPage,
    hasMorePages,
    totalCachedPages,
    resetCache,
    addPageToCache,
  }
}
