import { Button, Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalConditionType } from '@/domain/models/rentalConditionType.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { MachineryTypeSelector } from '@/presentation/machinery/components/MachineryTypeSelector.tsx'
import { RentalMachineryConditionsFormChunk } from '@/presentation/rentals/forms/RentalMachineryConditionsFormChunk.tsx'
import { UpdateRentalExternalMachineryFormProps } from '@/presentation/rentals/forms/types.ts'
import { QueryRentalsMachineryForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsMachineryForTable.ts'
import { useUpdateRentalMachinery } from '@/presentation/rentals/hooks/useUpdateRentalMachinery.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

type UpdateRentalExternalMachineryFormFields = Partial<{
  machinery: {
    type: string,
    description: string,
    truckVolume: number | undefined,
  },
  operator: string,
  rentalCondition: {
    type: string,
    minHours: number | undefined,
    amountPerHour: number | undefined,
    amountPerDay: number | undefined,
    load: string | undefined,
    loadOrigin: string | undefined,
    amountPerTravel: number | undefined,
  },
}>

export const UpdateRentalExternalMachineryForm: FC<UpdateRentalExternalMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateRentalExternalMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateRentalMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedRentalId, selectedRentalType } = useRentalsMaintainerStore()

  const selections = useMaintainerStore(state => state.selectionV2)
  const selectedNode: RowNode<QueryRentalsMachineryForTableResult['items'][0]> = selections[0]
  const initialValues = { ...({
    machinery       : selectedNode?.data.machinery,
    operator        : selectedNode?.data.operator?.fullName,
    rentalCondition : selectedNode?.data.rentalCondition,
  } || {}) }

  const [ form ] = Form.useForm()
  const machineryTypeValue = Form.useWatch([ 'machinery', 'type' ], form)
  const machineryType = machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : undefined

  const onSubmit = async (values: UpdateRentalExternalMachineryFormFields) => {
    if (isLoading || !selectedRentalId || !selectedRentalType) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateRentalExternalMachineryFormFields

    const response = await submit({
      query: {
        id       : selectedNode?.data.id,
        rentalId : selectedRentalId,
      },
      body: {
        machinery: editedValues.machinery ? {
          truckVolume: editedValues.machinery.truckVolume ?? null,
        } : undefined,
        operator        : editedValues.operator,
        rentalCondition : editedValues.rentalCondition ? {
          type            : RentalConditionType.fromString(editedValues.rentalCondition.type).value,
          minHours        : editedValues.rentalCondition.minHours ?? null,
          amountPerHour   : editedValues.rentalCondition.amountPerHour ?? null,
          amountPerDay    : editedValues.rentalCondition.amountPerDay ?? null,
          load            : editedValues.rentalCondition.load ?? null,
          loadOrigin      : editedValues.rentalCondition.loadOrigin ?? null,
          amountPerTravel : editedValues.rentalCondition.amountPerTravel ?? null,
        } : undefined,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 5 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Maquinaria arrendada">
            <Form.Item
              name={[ 'machinery', 'type' ]}
              label='Tipo de maquinaria'
              rules={[{ required: false }]}
            >
              <MachineryTypeSelector excludePickup disabled/>
            </Form.Item>

            <Form.Item
              name={[ 'machinery', 'description' ]}
              label='Nombre de maquinaria'
              rules={[{ required: false }]}
            >
              <Input disabled/>
            </Form.Item>

            {machineryType?.hasVolume() && (
              <Form.Item
                name={[ 'machinery', 'truckVolume' ]}
                label='Volumen m³'
                rules={[{ required: false }]}
              >
                <InputNumber min={0}/>
              </Form.Item>
            )}

            <Form.Item
              name="operator"
              label='Operador'
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <RentalMachineryConditionsFormChunk form={form} machineryType={machineryType} />
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
