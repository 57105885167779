import { useState } from 'react'
import { DownloadPdfRequest } from '@/api/machineryUseRecords/request.types.ts'
import { useMachineryUseRecordsApiClient } from '@/api/machineryUseRecords/useMachineryUseRecordsApiClient.ts'
import { useDownloadPdfFile } from '@/presentation/common/hooks/crud/v2/useDownloadPdfFile.ts'

export const useDownloadUseRecordPdf = () => {
  const repository = useMachineryUseRecordsApiClient()
  const query = useDownloadPdfFile<DownloadPdfRequest>({
    filename : 'use-record.pdf',
    callable : repository.downloadUseRecordPdf,
    messages : {
      default : 'No se pudo descargar el archivo',
      loading : 'Obteniendo archivo',
      success : 'Archivo descargado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (id: string) => {
    const response = await query.submit({ id })
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
