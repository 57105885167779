import { useEffect } from 'react'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useDownloadUseRecordPdf } from '@/presentation/machineryUseRecords/hooks/useDownloadUseRecordPdf.ts'

export const DownloadUseRecordButton = ({ id }: {id: string}) => {
  const messageApiKey = 'DownloadUseRecordButton-alert'
  const { submit, isLoading, isError, message, isSuccess, lastUpdate, data: pdf } = useDownloadUseRecordPdf()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  useEffect(() => {
    if (!isLoading && pdf) {
      window.open(URL.createObjectURL(pdf))
    }
  }, [ lastUpdate, pdf ])

  return (
    <SidebarButton label="Descargar reporte PDF" type="DOWNLOAD_PDF" onClick={() => {
      if (isLoading) {return}
      submit(id)
    }}/>
  )
}
