import { UpdateMyTenantSettingsRequest } from '@/api/tenants/request.types.ts'
import { UpdateMyTenantSettingsResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateTenantSettings = () => {
  const repository = useTenantsApiClient()
  const updateItem = useUpdateItem<UpdateMyTenantSettingsRequest, UpdateMyTenantSettingsResponse>({
    callable : repository.updateProfileSettings,
    messages : {
      default       : 'No se pudieron actualizar los datos',
      alreadyExists : 'Ya existe un inquilino con el mismo CDN o RUT',
      success       : 'Datos actualizados con éxito',
      loading       : 'Actualizando datos',
    },
  })

  return {
    ...updateItem,
  }
}
