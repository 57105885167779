import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryTenantsResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { SubscriptionStatus, SubscriptionStatusValue } from '@/domain/models/subscriptionStatus.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QuerySubscriptionsForTableResult = PaginatedData<{
  tenant: {
    id: string
    name: string
    cdn: string
    picture: string | null
  }
  subscriptionStatus: SubscriptionStatusValue
}>

export const useQuerySubscriptionsForTable = () => {
  const repository = useTenantsApiClient()
  const queryItemsHook = useQueryItems<QueryTenantsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron subscripciones',
    },
  })

  const [ data, setData ] = useState<QuerySubscriptionsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryTenantsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        tenant: {
          id      : item.id.toString(),
          name    : item.name,
          cdn     : item.cdn,
          picture : item.picture,
        },
        subscriptionStatus: SubscriptionStatus.fromString(item.subscription.status).value,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
