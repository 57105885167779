import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { WorkingDay } from '@/domain/models/workingDay.ts'

export const WorkingDaySelector: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Seleccione una jornada"
      {...props}
    >
      {WorkingDay.toEntries().map(([ key, value ]) => (
        <Select.Option key={key} value={value.value}>{value.label()}</Select.Option>
      ))}
    </Select>
  )
}
