import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { RentalsReceiversActions } from '@/presentation/rentals/forms/RentalsReceiversActions.tsx'
import { useQueryRentalsReceiversForTable } from '@/presentation/rentals/hooks/useQueryRentalsReceiversForTable.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

export const RentalsReceiversTable = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, selectedRentalId } = useRentalsMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryRentalsReceiversForTable(selectedRentalId)

  // ayuda a mantener la selección cuando se cambia de tab
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'email',
      headerName              : 'Correo electrónico',
      comparator              : stringComparator,
      headerCheckboxSelection : true,
      checkboxSelection       : true,
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled     : (data?.totalItems || 0) > 0,
      pageSize    : data?.limit || 10,
      currentPage : data?.page || 1,
      totalItems  : data?.totalItems || 0,
      onChange    : (page: number, limit: number) => query({
        params: {
          page,
          limit,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      if (selectedTab === 'receivers') {
        setSelectionV2(selectedRows)
        setCurrentSelection(selectedRows)
      }
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow, selectedTab ])

  const actionsComponent = useMemo(() => <RentalsReceiversActions onAction={() => refresh(selectedRentalId)}/>, [ refresh, selectedRentalId ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'receivers') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // carga cada vez que cambia el rental id
  useEffect(() => {
    setCurrentSelection([])
    query({
      params: {
        page: 1,
      },
    })
  }, [ selectedRentalId ])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'receivers') {
      reset()
      setTitle('Receptores de reportes')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'receivers') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'receivers' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}
