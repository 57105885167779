import { Layout } from 'antd'
import classNames from 'classnames'
import { FC, useContext } from 'react'
import { SidebarContent } from '@/presentation/common/components/SidebarContent.tsx'
import { SidebarProps } from '@/presentation/common/components/types.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { SidebarContext } from '@/presentation/common/store/apiContext/SidebarContext.tsx'

export const Sidebar: FC<SidebarProps> = ({ fullMode }) => {
  const { isTabletAndBelow } = useMQ()
  const { isOpen } = useContext(SidebarContext)

  const clasName = classNames('app-layout-sider border-r-2', {
    '!fixed z-[2000] h-[calc(100%_-_55px)]': isTabletAndBelow,
  })

  return (
    <Layout.Sider
      breakpoint="md"
      collapsedWidth="0"
      collapsed={isTabletAndBelow || fullMode ? !isOpen : false}
      collapsible={isTabletAndBelow}
      trigger={null}
      width={250}
      className={clasName}
    >
      <div className="app-sidebar-content-wrapper">
        <SidebarContent/>
      </div>
    </Layout.Sider>
  )
}
