import { useEffect, useMemo, useState } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MTCellProductCard } from '@/presentation/products/components/MTCellProductCard.tsx'
import { productComparator } from '@/presentation/products/utils/productComparator.ts'
import {
  ProductsTemplatesProductsActions,
} from '@/presentation/productsTemplates/forms/ProductsTemplatesProductsActions.tsx'
import {
  useQueryProductsTemplateProductsForTable,
} from '@/presentation/productsTemplates/hooks/useQueryProductsTemplateProductsForTable.ts'
import {
  useProductsTemplatesMaintainerStore,
} from '@/presentation/productsTemplates/store/productsTemplatesMaintainers.ts'

export const ProductsTemplatesProductsTable = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, selectedTemplateId } = useProductsTemplatesMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryProductsTemplateProductsForTable(selectedTemplateId)

  // ayuda a mantener la selección cuando se cambia de tab
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'product',
      headerName              : 'Producto',
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      comparator              : productComparator,
      cellRenderer            : MTCellProductCard,
    },
    {
      field       : 'inventory',
      headerName  : 'Inventario',
      comparator  : stringComparator,
      formatValue : ({ value }) => value.name,
    },
    {
      field      : 'quantity',
      headerName : 'Cantidad',
      comparator : numberComparator,
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 76,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled     : (data?.totalItems || 0) > 0,
      pageSize    : data?.limit || 10,
      currentPage : data?.page || 1,
      totalItems  : data?.totalItems || 0,
      onChange    : (page: number, limit: number) => query({
        params: {
          page,
          limit,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      if (selectedTab === 'products') {
        setSelectionV2(selectedRows)
        setCurrentSelection(selectedRows)
      }
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow, selectedTab ])

  const actionsComponent = useMemo(() => <ProductsTemplatesProductsActions onAction={() => refresh(selectedTemplateId)}/>, [ refresh, selectedTemplateId ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'products') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // carga cada vez que cambia el rental id
  useEffect(() => {
    setCurrentSelection([])
    query({
      params: {
        page: 1,
      },
    })
  }, [ selectedTemplateId ])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'products') {
      reset()
      setTitle('Productos de la plantilla')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'products') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'products' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}
