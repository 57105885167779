export enum FuelRegistryOperationTypeValue {
  REFILL = 'REFILL',
  REFILL_PICKUP = 'REFILL_PICKUP',
  BUY = 'BUY',
  OTHER = 'OTHER',
}

export class FuelRegistryOperationType {
  readonly value!: FuelRegistryOperationTypeValue

  constructor(value: FuelRegistryOperationTypeValue) {
    this.value = value
  }

  static fromString(value: string): FuelRegistryOperationType {
    if (!this.toList().includes(value as FuelRegistryOperationTypeValue)) {
      throw new Error(`Invalid RentalType: ${value}`)
    }

    return new FuelRegistryOperationType(value as FuelRegistryOperationTypeValue)
  }

  static toList(): FuelRegistryOperationTypeValue[] {
    return Object.values(FuelRegistryOperationTypeValue)
  }

  static toEntries(): [FuelRegistryOperationTypeValue, FuelRegistryOperationType][] {
    return this.toList().map((value) => [ value, new FuelRegistryOperationType(value) ])
  }

  static readonly labels: Record<FuelRegistryOperationTypeValue, string> = {
    REFILL        : 'Recarga',
    REFILL_PICKUP : 'Recarga camioneta',
    BUY           : 'Compra',
    OTHER         : 'Recarga (otros)',
  }

  label(): string {
    return FuelRegistryOperationType.labels[this.value]
  }
}
