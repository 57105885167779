import { FC, useEffect, useState } from 'react'
import { MaintenanceStatusValue } from '@/domain/models/maintenanceStatus.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MaintenanceActionsProps } from '@/presentation/maintenance/forms/types.ts'
import { UpdateMaintenanceForm } from '@/presentation/maintenance/forms/UpdateMaintenanceForm.tsx'
import { useDownloadMaintenancePdf } from '@/presentation/maintenance/hooks/useDownloadMaintenancePdf.ts'
import { QueryMaintenancesForTableResult } from '@/presentation/maintenance/hooks/useQueryMaintenancesForTable.ts'

export const MaintenanceActions: FC<MaintenanceActionsProps> = ({ onAction }) => {
  const messageApiKey = 'MaintenanceActions-alert'
  const { selectionV2 } = useMaintainerStore()
  const { canUpdate } = useCrudAuth()
  const { submit: downloadFile, isLoading, isError, message, isSuccess, lastUpdate, data: pdf } = useDownloadMaintenancePdf()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'PUT' | null>(null)

  const isPut = option === 'PUT'

  const title = isPut ? 'Actualizar Mantención' : ''

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const selection = selectionV2[0] as RowNode<QueryMaintenancesForTableResult['items'][0]>

  const canDownloadPdf = selectionCount === 1 && selection.data.status === MaintenanceStatusValue.DONE

  useEffect(() => {
    if (!isLoading && pdf) {
      window.open(URL.createObjectURL(pdf))
    }
  }, [ lastUpdate, pdf ])

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPut && <UpdateMaintenanceForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canDownloadPdf && (
        <SidebarButton label="Descargar reporte PDF" type="DOWNLOAD_PDF" onClick={() => {
          if (isLoading) {return}
          downloadFile(selection.data.id)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar Mantención" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
