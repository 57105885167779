import { DeleteManyProductsRequest } from '@/api/products/request.types.ts'
import { DeleteManyProductsResponse } from '@/api/products/response.types.ts'
import { useProductsApiClient } from '@/api/products/useProductsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteProducts = () => {
  const repository = useProductsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyProductsRequest, DeleteManyProductsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los productos',
      success : 'Productos eliminados',
      loading : 'Eliminando productos',
    },
  })

  return {
    ...deleteItems,
  }
}
