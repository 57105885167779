import { DeleteManyEppsRequest } from '@/api/epps/request.types.ts'
import { DeleteManyEppsResponse } from '@/api/epps/response.types.ts'
import { useEppsApiClient } from '@/api/epps/useEppsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteEpps = () => {
  const repository = useEppsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyEppsRequest, DeleteManyEppsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los EPPs',
      success : 'EPPs eliminados',
      loading : 'Eliminando EPPs',
    },
  })

  return {
    ...deleteItems,
  }
}
