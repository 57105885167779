import { AlreadyExistsException } from '@/domain/exceptions/alreadyExistsException.ts'
import { TenantIsNotActiveException } from '@/domain/exceptions/tenantIsNotActiveException.ts'
import { ErrorHandlerProps } from '@/presentation/common/utils/types.ts'

export const errorHandler = ({ error, messages }: ErrorHandlerProps): string => {
  const errorName = error?.constructor.name

  if (errorName === TenantIsNotActiveException.name) {
    return 'La empresa no se encuentra activa, contacte con el administrador'
  }
  else if (errorName === AlreadyExistsException.name) {
    return messages.alreadyExists || messages.default
  }

  // default error
  return messages.default
}
