import { Button, Upload, UploadProps } from 'antd'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import config from '@/config.ts'

export const UploadAvatarImage = ({ fileList, onChange }: UploadProps) => {
  const { uploadPicture } = useTenantsApiClient()

  const onRequest = async (data: any) => {
    if (data.file) {
      try {
        const filePath = await uploadPicture({ file: data.file })
        const newFile = {
          ...data.file,
          name     : data.file.name,
          status   : 'done',
          response : filePath,
          url      : `${config.publicBucketUrl}/${filePath}`,
        }

        onChange?.call(this, {
          file     : newFile,
          fileList : [ newFile ],
        })
      }
      catch (error) {
        onChange?.call(this, {
          file: {
            ...data.file,
            name   : data.file.name,
            status : 'error',
          },
          fileList: [{
            ...data.file,
            name   : data.file.name,
            status : 'error',
          }],
        })
      }
    }
  }

  return (
    <Upload
      listType="picture"
      accept=".png,.jpeg,.jpg"
      maxCount={1}
      customRequest={onRequest}
      fileList={fileList}
      onChange={onChange}
    >
      <Button size="small"><i className="ri-upload-line icon-sm mr-2"></i> Subir Logo</Button>
    </Upload>
  )
}
