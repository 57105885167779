import { FC, useEffect, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MTCellSubscriptionStatus } from '@/presentation/subscriptions/components/MTCellSubscriptionStatus.tsx'
import { MTCellSubscriptionTenantCard } from '@/presentation/subscriptions/components/MTCellSubscriptionTenantCard.tsx'
import { SubscriptionsActions } from '@/presentation/subscriptions/forms/SubscriptionsActions.tsx'
import { useQuerySubscriptionsForTable } from '@/presentation/subscriptions/hooks/useQuerySubscriptionsForTable.ts'

const Subscriptions: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQuerySubscriptionsForTable()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable           : true,
    resizable          : true,
    suppressMovable    : true,
    autoHeaderHeight   : true,
    minWidth           : 150,
    cellRendererParams : { align: 'center' },
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field             : 'tenant',
      headerName        : 'Inquilino',
      sortable          : false,
      cellRenderer      : MTCellSubscriptionTenantCard,
      minWidth          : 400,
      width             : 400,
      checkboxSelection : true,
    },
    {
      field        : 'subscriptionStatus',
      headerName   : 'Estado',
      minWidth     : 200,
      comparator   : stringComparator,
      cellRenderer : MTCellSubscriptionStatus,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'single',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <SubscriptionsActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    setTitle('Suscripciones')
    setActionsSlot(actionsComponent)
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // when search changes update query
  useEffect(() => {
    query({
      params: {
        search,
        page: 1,
      },
    })
  }, [ search ])

  return (
    <Maintainer>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default Subscriptions
