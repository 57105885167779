import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateTenantRequest,
  DeleteManyTenantsRequest,
  UpdateMyTenantSettingsRequest,
  UpdateTenantRequest,
  UpdateTenantSubscriptionRequest,
  UploadPictureRequest,
} from '@/api/tenants/request.types.ts'
import {
  CreateTenantResponse,
  DeleteManyTenantsResponse,
  DownloadExcelResumeResponse,
  QueryTenantsResponse,
  UpdateMyTenantSettingsResponse,
  UpdateTenantResponse,
  UpdateTenantSubscriptionResponse,
  UploadPictureResponse,
} from '@/api/tenants/response.types.ts'

export type TenantsApiClient = {
  query: (options: QueryOptions) => Promise<QueryTenantsResponse>
  create: (req: CreateTenantRequest) => Promise<CreateTenantResponse>
  update: (req: UpdateTenantRequest) => Promise<UpdateTenantResponse>
  updateProfileSettings: (req: UpdateMyTenantSettingsRequest) => Promise<UpdateMyTenantSettingsResponse>
  deleteMany: (req: DeleteManyTenantsRequest) => Promise<DeleteManyTenantsResponse>
  uploadPicture: (req: UploadPictureRequest) => Promise<UploadPictureResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>

  updateSubscription: (req: UpdateTenantSubscriptionRequest) => Promise<UpdateTenantSubscriptionResponse>
}

export const useTenantsApiClient = (): TenantsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryTenantsResponse, QueryParams>({
    path   : '/tenants',
    params : options.params,
  }),

  create: async (body: CreateTenantRequest) => await postMapping<CreateTenantResponse, CreateTenantRequest>({
    path : '/tenants',
    body : body,
  }),

  update: async (req: UpdateTenantRequest) => await putMapping<UpdateTenantResponse, UpdateTenantRequest['body']>({
    path : `/tenants/${req.query.id}`,
    body : req.body,
  }),

  updateProfileSettings: async (req: UpdateMyTenantSettingsRequest) => await putMapping<UpdateMyTenantSettingsResponse, UpdateMyTenantSettingsRequest['body']>({
    path : `/tenants/${req.query.id}/settings`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyTenantsRequest) => await deleteMapping<DeleteManyTenantsResponse, DeleteManyTenantsRequest>({
    path : '/tenants',
    body : req,
  }),

  uploadPicture: async (body: UploadPictureRequest) => {
    const formData = new FormData()
    formData.append('file', body.file)

    return await postMapping<UploadPictureResponse, FormData>({
      path    : '/tenants/picture',
      body    : formData,
      headers : {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/tenants/excel',
  }),

  updateSubscription: async (req: UpdateTenantSubscriptionRequest) => await putMapping<UpdateTenantSubscriptionResponse, UpdateTenantSubscriptionRequest['body']>({
    path : `/tenants/${req.query.id}`,
    body : req.body,
  }),
})
