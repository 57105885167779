import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { RentalType } from '@/domain/models/rentalType.ts'

export const RentalTypeSelector: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Seleccione un tipo"
      {...props}
    >
      {RentalType.toEntries().map(([ key, value ]) => (
        <Select.Option key={key} value={value.value}>{value.label()}</Select.Option>
      ))}
    </Select>
  )
}
