import { FC } from 'react'
import { MaintainerTabsProps } from '@/presentation/common/components/maintainer/types.ts'

export const MaintainerTabs: FC<MaintainerTabsProps> = ({ items, value, onChange }) => {
  return (
    <div className="flex items-center gap-1.5">
      {items.map((item) => (
        <div key={item.id}
          data-selected={item.id === value}
          className="bg-secondary-light data-[selected=true]:bg-secondary-dark data-[selected=true]:text-white py-1.5 px-2 rounded-[4px] cursor-pointer transition-colors duration-300"
          onClick={() => onChange?.(item.id)}
        >
          {item.label}
        </div>
      ))}
    </div>
  )
}
