import { useContext } from 'react'
import { MessageContext } from '@/presentation/common/store/apiContext/MessageContext.tsx'

export const useAlert = ({ messageApiKey }: { messageApiKey: string }) => {
  const messageApi = useContext(MessageContext)

  const showLoading = (message: string) => {
    messageApi?.open({
      key      : messageApiKey,
      type     : 'loading',
      content  : message,
      duration : 0,
    })
  }

  const closeAlert = () => {
    messageApi?.destroy(messageApiKey)
  }

  const showError = (message: string) => {
    messageApi?.open({
      key      : messageApiKey,
      type     : 'error',
      content  : message,
      duration : 3,
    })
  }

  const showSuccess = (message: string) => {
    messageApi?.open({
      key      : messageApiKey,
      type     : 'success',
      content  : message,
      duration : 3,
    })
  }

  return {
    closeAlert,
    showLoading,
    showError,
    showSuccess,
  }
}
