import { FC, useState } from 'react'
import { FormSectionProps } from '@/presentation/common/components/types.ts'

export const FormSection: FC<FormSectionProps> = ({ children, title, collapsable }) => {
  const [ isCollapsed, setIsCollapsed ] = useState(false)

  const onClickHandler = () => {
    if (!collapsable) {return}
    setIsCollapsed(!isCollapsed)
  }

  return (
    <section>
      <div className="relative text-center bg-tertiary-min rounded-md py-1.5 px-2 mb-2" style={{
        cursor: collapsable ? 'pointer' : 'default',
      }} onClick={onClickHandler}>
        <span className="text-base font-medium">{title}</span>

        {collapsable && (
          <div className="absolute right-0 top-0 h-full px-1.5 flex items-center">
            {isCollapsed ? (
              <i className="ri-arrow-down-s-line"></i>
            ) : (
              <i className="ri-arrow-up-s-line"></i>
            )}
          </div>
        )}
      </div>

      <div className="py-2 px-2" style={{
        display: isCollapsed ? 'none' : 'block',
      }}>
        {children}
      </div>
    </section>
  )
}
