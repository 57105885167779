import { ConfigProvider } from 'antd'
import esES from 'antd/locale/es_ES'
import dayjs from 'dayjs'
import { FC, ReactNode } from 'react'
import { validateMessages } from '@/presentation/common/utils/form/validateMessages.ts'

dayjs.locale('es')

type Props = {
    children: ReactNode,
}

export const AntdTheme: FC<Props> = ({ children }) => {
  return (
    <ConfigProvider
      locale={esES}
      form={{ validateMessages }}
    >
      {children}
    </ConfigProvider>
  )
}
