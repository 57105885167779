import { Button, Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { CreateProductFormProps } from '@/presentation/products/forms/types.ts'
import { useCreateProduct } from '@/presentation/products/hooks/useCreateProduct.ts'

type CreateProductFormFields = {
  name: string,
  description?: string,
  sku?: string,
  sizes: {
    height?: string,
    width?: string,
    depth?: string,
  },
  weight?: string,
  volume?: string,
  unitsPerPackage?: number,
}

export const CreateProductForm: FC<CreateProductFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateProductForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateProduct()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateProductFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      name        : values.name,
      description : values.description ?? null,
      sku         : values.sku ?? null,
      sizes       : {
        height : values.sizes?.height ?? null,
        width  : values.sizes?.width ?? null,
        depth  : values.sizes?.depth ?? null,
      },
      weight          : values.weight ?? null,
      volume          : values.volume ?? null,
      unitsPerPackage : values.unitsPerPackage ?? null,
    })

    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del producto">
        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="SKU"
          name="sku"
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <FormSection title="Dimensiones y peso">
        <Form.Item
          label="Alto"
          name={[ 'sizes', 'height' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Ancho"
          name={[ 'sizes', 'width' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Profundidad"
          name={[ 'sizes', 'depth' ]}
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Peso"
          name="weight"
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Volumen"
          name="volume"
          rules={[{ required: false }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Unidades por lote"
          name="unitsPerPackage"
          rules={[{ required: false }]}
        >
          <InputNumber min={0}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
