import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { CreateInventoryForm } from '@/presentation/inventories/forms/CreateInventoryForm.tsx'
import { DeleteInventoriesForm } from '@/presentation/inventories/forms/DeleteInventoriesForm.tsx'
import { InventoriesActionsProps } from '@/presentation/inventories/forms/types.ts'
import { UpdateInventoryForm } from '@/presentation/inventories/forms/UpdateInventoryForm.tsx'

export const InventoriesActions: FC<InventoriesActionsProps> = ({ onAction }) => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canCreate, canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'

  const title = isPost ? 'Registrar espacio' : isPut ? 'Actualizar espacio' : 'Eliminar selección'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.call(this)
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPost && <CreateInventoryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && <UpdateInventoryForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteInventoriesForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canCreate && (
        <SidebarButton label="Registrar espacio" type="POST" onClick={() => {
          setOption('POST')
          setOpen(true)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar espacio" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
