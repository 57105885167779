import {
  useProductsTemplatesMaintainerStore,
} from '@/presentation/productsTemplates/store/productsTemplatesMaintainers.ts'

export const useTabs = () => {
  const { selectedTemplateId } = useProductsTemplatesMaintainerStore()

  const tabsSetup = {
    templates : { id: 'templates', label: 'Plantillas' },
    products  : { id: 'products', label: 'Productos' },
  }

  const visibleTabs: Record<string, boolean> = {
    templates : true,
    products  : false,
  }

  if (selectedTemplateId) {
    visibleTabs.products = true
  }
  else {
    visibleTabs.products = false
  }

  const tabs = Object.values(tabsSetup).filter(tab => visibleTabs[tab.id])

  return {
    tabs,
  }
}
