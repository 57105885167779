import { UserRole } from '@/domain/models/userRole.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export const MTCellUserRole = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const cellValue = UserRole.fromString(cellNode.value).label()

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      {cellValue}
    </MTCellBase>
  )
}
