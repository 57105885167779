import { UpdateInventoryProductStockRequest } from '@/api/inventoryProductsStocks/request.types.ts'
import { UpdateInventoryProductStockResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import {
  useInventoryProductsStocksApiClient,
} from '@/api/inventoryProductsStocks/useInventoryProductsStocksApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useIncrementInventoryStock = () => {
  const repository = useInventoryProductsStocksApiClient()
  const createItem = useCreateItem<UpdateInventoryProductStockRequest, UpdateInventoryProductStockResponse>({
    callable : repository.increment,
    messages : {
      default : 'No se pudo incrementar el stock',
      success : 'Stock incrementado con éxito',
      loading : 'Incrementando stock',
    },
  })

  return {
    ...createItem,
  }
}
