import { UpdateMaintenanceProductRequest } from '@/api/maintenanceProducts/request.types.ts'
import { UpdateMaintenanceProductResponse } from '@/api/maintenanceProducts/response.types.ts'
import { useMaintenanceProductsApiClient } from '@/api/maintenanceProducts/useMaintenanceProductsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateMaintenanceProduct = () => {
  const repository = useMaintenanceProductsApiClient()
  const updateItem = useUpdateItem<UpdateMaintenanceProductRequest, UpdateMaintenanceProductResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el producto',
      alreadyExists : 'Ya existe un registro con el mismo producto e inventario',
      success       : 'Producto actualizado con éxito',
      loading       : 'Actualizando producto',
    },
  })

  return {
    ...updateItem,
  }
}
