import { Button, Form, InputNumber } from 'antd'
import { FC, useEffect } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { InventorySelector } from '@/presentation/inventories/components/InventorySelector.tsx'
import { ProductStockSelector } from '@/presentation/inventoryProductsStocks/components/ProductStockSelector.tsx'
import { CreateMaintenanceProductFormProps } from '@/presentation/maintenance/forms/types.ts'
import { useCreateMaintenanceProduct } from '@/presentation/maintenance/hooks/useCreateMaintenanceProduct.ts'
import { useMaintenancesMaintainerStore } from '@/presentation/maintenance/store/maintenancesMaintainers.ts'

type CreateMaintenanceProductFormFields = {
    inventory: string
    product: string
    quantity: number
}

export const CreateMaintenanceProductForm: FC<CreateMaintenanceProductFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateMaintenanceProductForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateMaintenanceProduct()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm<CreateMaintenanceProductFormFields>()
  const inventory = Form.useWatch('inventory', form)

  const { selectedMaintenanceId } = useMaintenancesMaintainerStore()

  const onSubmit = async (values: CreateMaintenanceProductFormFields) => {
    if (isLoading || !selectedMaintenanceId) {
      return
    }

    const response = await submit({
      query: {
        maintenanceId: selectedMaintenanceId,
      },
      body: {
        product   : values.product,
        inventory : values.inventory,
        quantity  : values.quantity,
      },
    })
    if (response) {
      onFinish?.()
    }
  }

  useEffect(() => {
    form.resetFields([ 'product' ])
  }, [ inventory ])

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del producto">
        <Form.Item
          label="Inventario"
          name="inventory"
          rules={[{ required: true }]}
        >
          <InventorySelector/>
        </Form.Item>

        <Form.Item
          label="Producto"
          name="product"
          rules={[{ required: true }]}
        >
          <ProductStockSelector filters={{ inventoryId: inventory }}/>
        </Form.Item>

        <Form.Item
          label="Cantidad"
          name="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Registrar</Button>
      </div>
    </Form>
  )
}
