import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { MaintenanceStatusValue } from '@/domain/models/maintenanceStatus.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { MaintenanceStatusSelector } from '@/presentation/maintenance/components/MaintenanceStatusSelector.tsx'
import { UpdateMaintenanceFormProps } from '@/presentation/maintenance/forms/types.ts'
import { QueryMaintenancesForTableResult } from '@/presentation/maintenance/hooks/useQueryMaintenancesForTable.ts'
import { useUpdateMaintenance } from '@/presentation/maintenance/hooks/useUpdateMaintenance.ts'

type UpdateMaintenanceFormFields = {
  status: MaintenanceStatusValue,
  observations: string,
}

export const UpdateMaintenanceForm: FC<UpdateMaintenanceFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateMaintenanceForm-alert'

  const [ form ] = Form.useForm<UpdateMaintenanceFormFields>()

  const { submit, isSuccess, isLoading, isError, message } = useUpdateMaintenance()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMaintenancesForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateMaintenanceFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateMaintenanceFormFields

    const response = await submit({
      query: {
        id: selectedNode.data.id,
      },
      body: {
        status       : editedValues.status,
        observations : editedValues.observations,
      },
    })

    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <FormSection title="Datos de la mantención">
        <Form.Item
          label="Estado"
          name="status"
          rules={[{ required: true }]}
        >
          <MaintenanceStatusSelector/>
        </Form.Item>
      </FormSection>

      <FormSection title="Observaciones">
        <Form.Item
          label="Observaciones"
          name="observations"
        >
          <Input.TextArea/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        {onCancel && <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>}
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Guardar</Button>
      </div>
    </Form>
  )
}
