import { Alert, Button, Form, Switch } from 'antd'
import { FC, useState } from 'react'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { UpdateEppFormProps } from '@/presentation/epp/forms/types.ts'
import { QueryEppsForTableResult } from '@/presentation/epp/hooks/useQueryEppsForTable.ts'
import { useUpdateEpp } from '@/presentation/epp/hooks/useUpdateEpp.ts'
import { RentalMachinerySelector } from '@/presentation/rentals/components/RentalMachinerySelector.tsx'
import {
  QueryRentalsMachineryForSelectorResult,
} from '@/presentation/rentals/hooks/useQueryRentalsMachineryForSelector.ts'

type UpdateEppFormFields = {
  rentalMachineryId: string,
  operator: string,
  signature?: string,
}

export const UpdateEppForm: FC<UpdateEppFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateEppForm-alert'

  const [ form ] = Form.useForm<UpdateEppFormFields>()
  const [ machinery, setMachinery ] = useState<QueryRentalsMachineryForSelectorResult['data']>()
  const [ isSignActive, setIsSignActive ] = useState<boolean>(false)

  const { submit, isSuccess, isLoading, isError, message } = useUpdateEpp()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryEppsForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateEppFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, {
      ...values,
      operator: machinery?.rentalType === RentalTypeValue.INTERNAL ? machinery.operator.id as string : machinery?.operator.fullName,
    }) as UpdateEppFormFields

    const response = await submit({
      query: {
        id: selectedNode.data.id,
      },
      body: {
        rentalMachineryId : editedValues.rentalMachineryId,
        operator          : editedValues.operator,
        signature         : editedValues.signature,
      },
    })

    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <FormSection title="Datos de registro">
        <Form.Item
          label="Operador de maquinaria"
          name="rentalMachineryId"
          rules={[{ required: true }]}
        >
          <RentalMachinerySelector displayOperator onCustomSelect={setMachinery} filters={{
            date       : getLocalDateStartOf().format(), // TODO: potencial problema lógico. Debería ser la fecha de creación del registro EPP.
            rentalType : RentalTypeValue.INTERNAL,
          }}/>
        </Form.Item>
      </FormSection>

      <FormSection title="Firma del operador">
        {initialValues.isSigned && (
          <Alert type="info" message="Este registro ya se encuentra firmado por el operador" className="mb-3"/>
        )}

        {!initialValues.isSigned && (
          <Form.Item
            label={(
              <span className="flex flex-wrap gap-1.5 items-center">
                    Firma digital <Switch checked={isSignActive} onChange={setIsSignActive} checkedChildren="Habilitada" unCheckedChildren="Desactivada"/>
              </span>
            )}
            help="Al desactivar la firma digital, no significa que se elimina la firma, solo que no se podrá editar. Es decir, que este registro quedará como firmado. Si desea enviar el registro como no firmado, deberá eliminar la firma usando el botón dispuesto en la barra superiór."
            name="signature"
            rules={[{ required: false }]}
          >
            <SignPad disableUploadImage={true} disabled={!isSignActive}/>
          </Form.Item>
        )}
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        {onCancel && <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>}
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Guardar</Button>
      </div>
    </Form>
  )
}
