import { QueryClientsResponse } from '@/api/clients/response.types.ts'
import { useClientsApiClient } from '@/api/clients/useClientsApiClient.ts'
import { QueryOptions } from '@/api/common/types.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryClientOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryClientsForSelectorResult = {
  id: string
  dni: string
  name: string
}

export const useQueryClientsForSelector = () => {
  const repository = useClientsApiClient()
  const queryItemsHook = useQueryItems<QueryClientsResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron clientes',
      success : 'Clientes encontrados',
      loading : 'Buscando clientes',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryClientsForSelectorResult>()

  const query = async (options: QueryClientOptions = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryClientOptions, response?: QueryClientsResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        id   : item.id.toString(),
        dni  : item.billing.dni,
        name : item.name,
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
