import { useState } from 'react'
import { UseDownloadExcelFileProps } from '@/presentation/common/hooks/crud/v2/types.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { errorHandler } from '@/presentation/common/utils/errorHandler.ts'

export const useDownloadExcelFile = <TRequest>({ messages, callable, filename }: UseDownloadExcelFileProps<TRequest>) => {
  const status = useUiStatus()
  const [ lastUpdate, setLastUpdate ] = useState<Date>(new Date())

  const submit = async (body: TRequest) => {
    status.resetStatus()

    try {
      status.setStatus(UiStateStatus.LOADING)
      status.setMessage(messages.loading ?? 'Cargando')

      const response = await callable(body)
      const blob = new Blob([ response ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      const file = new File([ blob ], filename, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      status.setStatus(UiStateStatus.SUCCESS)
      status.setMessage(messages.success ?? 'Archivo descargado')
      setLastUpdate(new Date())
      return file as File
    }
    catch (error) {
      const message = errorHandler({
        error,
        messages: messages,
      })

      status.setStatus(UiStateStatus.ERROR)
      status.setMessage(message)
    }
  }

  return {
    message   : status.message,
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    lastUpdate,
    submit,
  }
}
