import { Button, DatePicker, Form, Input, InputNumber, TimePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { FuelRegistryOperationTypeValue } from '@/domain/models/fuelOperationType.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { hideDatesAfterToday } from '@/presentation/common/utils/hideDatesAfterToday.ts'
import { hideHoursAfterNow, hideMinutesAfterNow } from '@/presentation/common/utils/hideTimesAfterNow.ts'
import { FuelOperationTypeSelector } from '@/presentation/fuelRegistries/components/FuelOperationTypeSelector.tsx'
import {
  FuelRegistryMachinerySelector,
} from '@/presentation/fuelRegistries/components/FuelRegistryMachinerySelector.tsx'
import { useCreateFuelRegistry } from '@/presentation/fuelRegistries/hooks/useCreateFuelRegistry.ts'
import { PickupSelector } from '@/presentation/machinery/components/PickupSelector.tsx'

type CreateFuelRegistryFormFields = {
  type: FuelRegistryOperationTypeValue
  date: Dayjs
  time: Dayjs
  machinery?: string
  equipment?: string
  operator?: string
  hourmeter?: number
  liters: number
  guideNumber?: number
}

export const CreateFuelRegistryForm = () => {
  const messageApiKey = 'CreateFuelRegistryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateFuelRegistry()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const initialValues = useMemo(() => ({
    date : getLocalDateStartOf(),
    time : dayjs().local(),
  }), [])

  const [ form ] = Form.useForm<CreateFuelRegistryFormFields>()
  const date = Form.useWatch('date', form)
  const type = Form.useWatch('type', form)

  const onSubmit = async (values: CreateFuelRegistryFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      type        : values.type,
      date        : getLocalDateStartOf({ date: values.date }).format(),
      time        : dayjs(values.time).local().format(),
      machinery   : values.machinery ?? null,
      equipment   : values.equipment ?? null,
      operator    : values.operator ?? null,
      hourmeter   : values.hourmeter ?? null,
      liters      : values.liters,
      guideNumber : values.guideNumber ?? null,
    })

    if (response) {
      form.resetFields()
    }
  }

  return (
    <FormLayout title="Registro de suministro de combustible">
      <section className="app-card">
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          <FormSection title="Datos del registro">
            <Form.Item
              label="Tipo de operación"
              name="type"
              rules={[{ required: true }]}
            >
              <FuelOperationTypeSelector/>
            </Form.Item>

            <Form.Item
              label="Fecha"
              name="date"
              rules={[{ required: true }]}
            >
              <DatePicker inputReadOnly className="w-full" format="DD-MM-YYYY" disabledDate={hideDatesAfterToday}/>
            </Form.Item>

            <Form.Item
              label="Hora"
              name="time"
              rules={[{ required: true }]}
            >
              <TimePicker
                inputReadOnly
                className="w-full"
                format="HH:mm"
                disabledHours={hideHoursAfterNow}
                disabledMinutes={hideMinutesAfterNow}
              />
            </Form.Item>

            {type === FuelRegistryOperationTypeValue.REFILL && (
              <Form.Item
                label="Maquinaria"
                name="machinery"
                rules={[{ required: true }]}
              >
                <FuelRegistryMachinerySelector date={date}/>
              </Form.Item>
            )}

            {type === FuelRegistryOperationTypeValue.REFILL_PICKUP && (
              <Form.Item
                label="Camioneta"
                name="machinery"
                rules={[{ required: true }]}
              >
                <PickupSelector/>
              </Form.Item>
            )}

            {type === FuelRegistryOperationTypeValue.OTHER && (
              <Form.Item
                label="Equipo"
                name="equipment"
                rules={[{ required: true }]}
              >
                <Input/>
              </Form.Item>
            )}

            {type === FuelRegistryOperationTypeValue.OTHER && (
              <Form.Item
                label="Operador"
                name="operator"
                rules={[{ required: false }]}
              >
                <Input/>
              </Form.Item>
            )}

            {type !== FuelRegistryOperationTypeValue.BUY && (
              <Form.Item
                label="Horómetro / Odómetro"
                name="hourmeter"
                rules={[{ required: type !== FuelRegistryOperationTypeValue.OTHER }]}
              >
                <InputNumber min={1}/>
              </Form.Item>
            )}

            <Form.Item
              label="Litros"
              name="liters"
              rules={[{ required: true }]}
            >
              <InputNumber min={1}/>
            </Form.Item>

            {type === FuelRegistryOperationTypeValue.BUY && (
              <Form.Item
                label="Número de guía"
                name="guideNumber"
                rules={[{ required: true }]}
              >
                <InputNumber min={0}/>
              </Form.Item>
            )}
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" htmlType="reset" disabled={isLoading}>Limpiar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}
