export enum MaintenanceStatusValue {
  PENDING = 'PENDING',
  DONE = 'DONE',
}

export class MaintenanceStatus {
  readonly value!: MaintenanceStatusValue

  constructor(value: MaintenanceStatusValue) {
    this.value = value
  }

  static fromString(value: string): MaintenanceStatus {
    // check if value is a valid UserRole
    if (!this.toList().includes(value as MaintenanceStatusValue)) {
      throw new Error(`Invalid MaintenanceStatus: ${value}`)
    }

    return new MaintenanceStatus(value as MaintenanceStatusValue)
  }

  static toList(): MaintenanceStatusValue[] {
    return Object.values(MaintenanceStatusValue)
  }

  static toEntries(): [MaintenanceStatusValue, MaintenanceStatus][] {
    return this.toList().map((value) => [ value, new MaintenanceStatus(value) ])
  }

  static readonly labels: Record<MaintenanceStatusValue, string> = {
    PENDING : 'Pendiente',
    DONE    : 'Realizado',
  }

  label(): string {
    return MaintenanceStatus.labels[this.value]
  }
}
