import { UpdateMaintenanceRequest } from '@/api/maintenances/request.types.ts'
import { UpdateMaintenanceResponse } from '@/api/maintenances/response.types.ts'
import { useMaintenancesApiClient } from '@/api/maintenances/useMaintenancesApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateMaintenance = () => {
  const repository = useMaintenancesApiClient()
  const updateItem = useUpdateItem<UpdateMaintenanceRequest, UpdateMaintenanceResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el mantención',
      alreadyExists : 'Ya existe una mantención',
      success       : 'Mantención actualizada con éxito',
      loading       : 'Actualizando mantención',
    },
  })

  return {
    ...updateItem,
  }
}
