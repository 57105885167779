import { FC, useState } from 'react'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { CreateEppForm } from '@/presentation/epp/forms/CreateEppForm.tsx'
import { DeleteEppForm } from '@/presentation/epp/forms/DeleteEppForm.tsx'
import { EppActionsProps } from '@/presentation/epp/forms/types.ts'
import { UpdateEppForm } from '@/presentation/epp/forms/UpdateEppForm.tsx'

export const EppActions: FC<EppActionsProps> = ({ onAction }) => {
  const { user } = useSessionStore()
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canCreate, canUpdate, canDelete } = useCrudAuth()

  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'

  let title
  switch (option) {
    case 'POST':
      title = 'Registrar EPP'
      break
    case 'PUT':
      title = 'Actualizar EPP'
      break
    case 'DELETE':
      title = 'Eliminar selección'
      break
    default:
      title = ''
      break
  }

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPost && <CreateEppForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && <UpdateEppForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteEppForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canCreate && (user?.role === UserRoleValue.MECHANIC || user?.role === UserRoleValue.FUEL_SUPPLIER) && (
        <SidebarButton label="Registrar EPP" type="POST" onClick={() => {
          setOption('POST')
          setOpen(true)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar EPP" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
