import { UseCreateItemProps } from '@/presentation/common/hooks/crud/v2/types.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { errorHandler } from '@/presentation/common/utils/errorHandler.ts'

export const useCreateItem = <TRequest, TResponse>({ messages, callable }: UseCreateItemProps<TRequest, TResponse>) => {
  const status = useUiStatus()

  const submit = async (body: TRequest) => {
    status.resetStatus()

    try {
      status.setStatus(UiStateStatus.LOADING)
      status.setMessage(messages.loading ?? 'Cargando')

      const response = await callable(body)

      status.setStatus(UiStateStatus.SUCCESS)
      status.setMessage(messages.success ?? 'Creación exitosa')
      return response as TResponse
    }
    catch (error) {
      const message = errorHandler({
        error,
        messages: messages,
      })

      status.setStatus(UiStateStatus.ERROR)
      status.setMessage(message)
    }
  }

  return {
    message   : status.message,
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    submit,
  }
}
