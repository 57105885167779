import { createContext } from 'react'
import { SidebarRightContextProps } from '@/presentation/common/store/apiContext/types.ts'

export const SidebarRightContext = createContext<SidebarRightContextProps>({
  isOpen            : false,
  toggle            : () => void 0,
  content           : null,
  setContent        : () => void 0,
  setLastTriggerKey : () => void 0,
  forceClose        : () => void 0,
})
