import { UserRoleValue } from '@/domain/models/userRole.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'

export const useRoleAuth = () => {
  const { user } = useSessionStore()

  const checkRoleAuth = (allowedRoles?: UserRoleValue[]) => {
    return !allowedRoles || (user?.role && allowedRoles.includes(user?.role))
  }

  return {
    checkRoleAuth,
  }
}
