import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateRentalMachineryRequest,
  DeleteManyRentalsMachineryRequest,
  QueryRentalMachineryRequest,
  UpdateRentalMachineryRequest,
} from '@/api/rentalsMachinery/request.types.ts'
import {
  CreateRentalMachineryResponse,
  DeleteManyRentalsMachineryResponse,
  QueryRentalsMachineryResponse,
  UpdateRentalMachineryResponse,
} from '@/api/rentalsMachinery/response.types.ts'

export type RentalsMachineryApiClient = {
  query: (options: QueryOptions<void, QueryRentalMachineryRequest>) => Promise<QueryRentalsMachineryResponse>
  create: (req: CreateRentalMachineryRequest) => Promise<CreateRentalMachineryResponse>
  update: (req: UpdateRentalMachineryRequest) => Promise<UpdateRentalMachineryResponse>
  deleteMany: (req: DeleteManyRentalsMachineryRequest) => Promise<DeleteManyRentalsMachineryResponse>
}

export const useRentalsMachineryApiClient = (): RentalsMachineryApiClient => ({
  query: async (options: QueryOptions<void, QueryRentalMachineryRequest>) => await getMapping<QueryRentalsMachineryResponse, QueryParams>({
    path   : '/rentals/machinery',
    params : options.params,
  }),

  create: async (req: CreateRentalMachineryRequest) => await postMapping<CreateRentalMachineryResponse, CreateRentalMachineryRequest['body']>({
    path : `/rentals/${req.query.rentalId}/machinery`,
    body : req.body,
  }),

  update: async (req: UpdateRentalMachineryRequest) => await putMapping<UpdateRentalMachineryResponse, UpdateRentalMachineryRequest['body']>({
    path : `/rentals/${req.query.rentalId}/machinery/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyRentalsMachineryRequest) => await deleteMapping<DeleteManyRentalsMachineryResponse, DeleteManyRentalsMachineryRequest>({
    path : '/rentals/machinery',
    body : req,
  }),
})
