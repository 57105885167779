import { Button, Form, Input, UploadFile } from 'antd'
import { FC, useEffect, useState } from 'react'
import { QueryTenantsResponse } from '@/api/tenants/response.types.ts'
import config from '@/config.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'
import { UploadAvatarImage } from '@/presentation/tenants/components/UploadAvatarImage.tsx'
import { UpdateTenantFormProps } from '@/presentation/tenants/forms/types.ts'
import { useUpdateTenant } from '@/presentation/tenants/hooks/useUpdateTenant.ts'

type UpdateTenantFormFields = {
  name?: string,
  cdn?: string,
  dni?: string,
  activity?: string,
  address?: string,
  contactPhone?: string,
  contactEmail?: string,
  picture?: UploadFile[]
}

export const UpdateTenantForm: FC<UpdateTenantFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateTenantForm-alert'
  const [ isUploadingPicture, setIsUploadingPicture ] = useState<boolean>(false)
  const [ fileList, setFileList ] = useState<UploadFile[]>([])
  const [ fileTmpPath, setFileTmpPath ] = useState<string | null>()
  const { submit, isSuccess, isLoading, isError, message } = useUpdateTenant()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryTenantsResponse['items'][0]> = selectionNodes[0]

  const initialValues: UpdateTenantFormFields = {
    ...selectedNode?.data,
    picture: selectedNode?.data?.picture ? [{
      uid    : '-1',
      name   : selectedNode?.data?.picture?.split('/').pop() || 'avatar',
      status : 'done',
      url    : `${config.publicBucketUrl}/${selectedNode?.data?.picture}`,
    }] : undefined,
  }

  useEffect(() => {
    setFileList(initialValues.picture || [])
  }, [])

  const onSubmit = async (values: UpdateTenantFormFields) => {
    if (isLoading || isUploadingPicture) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values)

    const response = await submit({
      query : { id: selectedNode?.data?.id },
      body  : {
        ...editedValues as UpdateTenantFormFields,
        picture: fileTmpPath ?? null,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  const onPictureUploaded = ({ file }: { file: UploadFile }) => {
    setIsUploadingPicture(true)
    setFileList([ file ])

    if (file.status === 'done') {
      setFileTmpPath(file.response)
      setIsUploadingPicture(false)
    }

    if (file.status === 'removed') {
      setFileTmpPath(null)
      setFileList([])
      setIsUploadingPicture(false)
    }

    if (file.status === 'error') {
      setFileTmpPath(null)
      setIsUploadingPicture(false)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la empresa">
            <Form.Item
              label="Logo"
              valuePropName="fileList"
              rules={[{ required: false }]}
            >
              <UploadAvatarImage fileList={fileList} onChange={onPictureUploaded}/>
            </Form.Item>

            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="CDN"
              name="cdn"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="RUT"
              name="dni"
              rules={[
                { required: true },
                { validator: rutValidator },
              ]}
            >
              <RutInput/>
            </Form.Item>

            <Form.Item
              label="Actividad económica"
              name="activity"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Dirección"
              name="address"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Teléfono de contacto"
              name="contactPhone"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Correo de contacto"
              name="contactEmail"
              rules={[{ required: true }, { type: 'email' }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit"
              disabled={isLoading || isUploadingPicture}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
