import { UpdateProductRequest } from '@/api/products/request.types.ts'
import { UpdateProductResponse } from '@/api/products/response.types.ts'
import { useProductsApiClient } from '@/api/products/useProductsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateProduct = () => {
  const repository = useProductsApiClient()
  const updateItem = useUpdateItem<UpdateProductRequest, UpdateProductResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el producto',
      alreadyExists : 'Ya existe un producto con el mismo nombre o sku',
      success       : 'Producto actualizado con éxito',
      loading       : 'Actualizando producto',
    },
  })

  return {
    ...updateItem,
  }
}
