import { CreateTenantRequest } from '@/api/tenants/request.types.ts'
import { CreateTenantResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateTenant = () => {
  const repository = useTenantsApiClient()
  const create = useCreateItem<CreateTenantRequest, CreateTenantResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el inquilino',
      alreadyExists : 'Ya existe un inquilino con el mismo CDN',
      success       : 'Inquilino creado con éxito',
      loading       : 'Creando inquilino',
    },
  })

  return {
    ...create,
  }
}
