import { FC, useState } from 'react'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { DownloadUseRecordButton } from '@/presentation/machineryUseRecords/components/DownloadUseRecordButton.tsx'
import {
  DeleteMachineryUseRecordsForm,
} from '@/presentation/machineryUseRecords/forms/DeleteMachineryUseRecordsForm.tsx'
import { MachineryUseRecordsActionsProps } from '@/presentation/machineryUseRecords/forms/types.ts'
import { UpdateMachineryUseRecordForm } from '@/presentation/machineryUseRecords/forms/UpdateMachineryUseRecordForm.tsx'
import {
  QueryMachineryUseRecordsForTableResult,
} from '@/presentation/machineryUseRecords/hooks/useQueryMachineryUseRecordsForTable.ts'

export const MachineryUseRecordsActions: FC<MachineryUseRecordsActionsProps> = ({ onAction }) => {
  const { user } = useSessionStore()
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'PUT' | 'DELETE' | 'DOWNLOAD_EXCEL' | null>(null)

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMachineryUseRecordsForTableResult['items'][0]> = Object.values(selectionNodes)[0]
  const selectionId = selectedNode?.data.id

  const canDownloadPdf = selectionCount === 1 && selectedNode.data?.isSigned
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'

  const title = isPut ? 'Actualizar registro de uso' : 'Eliminar selección'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.call(this)
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPut && <UpdateMachineryUseRecordForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteMachineryUseRecordsForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canDownloadPdf && (
        <DownloadUseRecordButton id={selectionId}/>
      )}

      {canUpdate && (user?.role === UserRoleValue.ADMIN ? true : !selectedNode.data?.isSigned) && (
        <SidebarButton label="Actualizar registro de uso" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && user && user.role === UserRoleValue.ADMIN && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
