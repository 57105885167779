import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryRentalReceiversRequest } from '@/api/rentalsReceivers/request.types.ts'
import { QueryRentalsReceiversResponse } from '@/api/rentalsReceivers/response.types.ts'
import { useRentalsReceiversApiClient } from '@/api/rentalsReceivers/useRentalsReceiversApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryRentalsReceiversForTableResult = PaginatedData<{
  id: string
  email: string
}>

export const useQueryRentalsReceiversForTable = (rentalId: string | null) => {
  const repository = useRentalsReceiversApiClient()
  const queryItemsHook = useQueryItems<QueryRentalsReceiversResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron receptores',
    },
  })

  const [ data, setData ] = useState<QueryRentalsReceiversForTableResult>()

  const refresh = useCallback(async (rentalId: string | null) => {
    const response = await queryItemsHook.refresh(undefined, { rentalId })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<QueryRentalReceiversRequest>> = {}) => {
    if (!rentalId) {
      return
    }
    const response = await queryItemsHook.query({
      ...options,
      pathParams: {
        rentalId,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryRentalsReceiversResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id    : item.id.toString(),
        email : item.email,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
