import { Button, Form, Input, InputNumber } from 'antd'
import numeral from 'numeral'
import { FC, useEffect, useMemo } from 'react'
import { v4 as uuid } from 'uuid'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { FormProps } from '@/presentation/payStates/forms/types.ts'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'
import 'numeral/locales/es'

numeral.locale('es')

type CreatePayStateResumeFormFields = {
  id: string,
  item: string,
  quantity: number,
  unitPrice: number,
  total: string,
}

export const CreatePayStateResumeForm: FC<FormProps> = ({ onCancel, onFinish }) => {
  const { addNewResumeItem } = usePayStatePreviewStore()

  const initialValues =useMemo(() => ({
    id: uuid(),
  }), [])

  const [ form ] = Form.useForm()
  const quantity = Form.useWatch('quantity', form)
  const unitPrice = Form.useWatch('unitPrice', form)

  const onSubmit = async (values: CreatePayStateResumeFormFields) => {
    addNewResumeItem({
      id        : values.id,
      item      : values.item,
      quantity  : values.quantity,
      unitPrice : values.unitPrice,
      total     : (quantity ?? 0) * (unitPrice ?? 0),
    })

    onFinish?.call(this)
  }

  useEffect(() => {
    form.setFieldsValue({
      total: numeral((quantity ?? 0) * (unitPrice ?? 0)).format('$0,0'),
    })
  }, [ quantity, unitPrice ])

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <FormSection title="Datos del registro">
        <Form.Item
          label="Descripción"
          name="item"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Unidades"
          name="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>

        <Form.Item
          label="Monto Unitario"
          name="unitPrice"
          rules={[{ required: true }]}
        >
          <InputNumber/>
        </Form.Item>

        <Form.Item
          label="Monto Total"
          name="total"
          rules={[{ required: false }]}
        >
          <Input disabled/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit">Agregar</Button>
      </div>
    </Form>
  )
}
