import { CreateProductRequest } from '@/api/products/request.types.ts'
import { CreateProductResponse } from '@/api/products/response.types.ts'
import { useProductsApiClient } from '@/api/products/useProductsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateProduct = () => {
  const repository = useProductsApiClient()
  const createItem = useCreateItem<CreateProductRequest, CreateProductResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el producto',
      alreadyExists : 'Ya existe un producto con el mismo nombre o sku',
      success       : 'Producto creado con éxito',
      loading       : 'Creando producto',
    },
  })

  return {
    ...createItem,
  }
}
