import { Button, Form, Input, InputNumber } from 'antd'
import { FC, useMemo } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { MaintenanceType } from '@/domain/models/maintenanceType.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { MaintenanceClassSelector } from '@/presentation/machinery/components/MaintenanceClassSelector.tsx'
import { TypeSelector } from '@/presentation/machinery/components/TypeSelector.tsx'
import { UpdateMachineryFormProps } from '@/presentation/machinery/forms/types.ts'
import { QueryMachineryForTableResult } from '@/presentation/machinery/hooks/useQueryMachineryForTable.ts'
import { useUpdateMachinery } from '@/presentation/machinery/hooks/useUpdateMachinery.ts'

type UpdateMachineryFormFields = Partial<{
  type: string
  code: string
  brand: string
  model: string
  year: number
  serialNumber: string
  truckVolume?: number
  maintenanceType?: string
}>

export const UpdateMachineryForm: FC<UpdateMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm<UpdateMachineryFormFields>()
  const machineryTypeValue = Form.useWatch('type', form)
  const machineryType = useMemo(() =>
    machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : null, [ machineryTypeValue ])

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMachineryForTableResult['items'][0]> = Object.values(selectionNodes)[0]
  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateMachineryFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateMachineryFormFields

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        ...editedValues,
        type            : editedValues.type !== undefined ? MachineryType.fromString(editedValues.type).value : undefined,
        maintenanceType : editedValues.maintenanceType !== undefined ? MaintenanceType.fromString(editedValues.maintenanceType).value : undefined,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la maquinaria">
            <Form.Item
              label="Tipo"
              name="type"
              rules={[{ required: true }]}
            >
              <TypeSelector/>
            </Form.Item>

            <Form.Item
              label="Código"
              name="code"
              rules={[{ required: true }]}
              normalize={(value: string) => value.toUpperCase()}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Marca"
              name="brand"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Modelo"
              name="model"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Año"
              name="year"
              rules={[{ required: true }]}
            >
              <InputNumber className="w-full"/>
            </Form.Item>

            <Form.Item
              label="Matrícula"
              name="serialNumber"
              rules={[{ required: true }]}
              normalize={(value: string) => value.toUpperCase()}
            >
              <Input/>
            </Form.Item>

            {machineryType?.hasVolume() && (
              <Form.Item
                label='Volumen m³'
                name="truckVolume"
              >
                <InputNumber min={0}/>
              </Form.Item>
            )}
          </FormSection>

          {machineryType && machineryType.allowedMaintenances().length > 0 && (
            <FormSection title="Mantenimiento">
              <Form.Item
                label="Clase de mantenimiento"
                name="maintenanceType"
                labelCol={{ span: 6 }}
                rules={[{ required: true }]}
                normalize={(value: string) => value ? value : null}
              >
                <MaintenanceClassSelector machineryType={machineryType}/>
              </Form.Item>
            </FormSection>
          )}

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
