import classNames from 'classnames'
import { FC } from 'react'
import { MaintenanceStatusValue } from '@/domain/models/maintenanceStatus.ts'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellBadge } from '@/presentation/common/components/maintainer/table/cells/MTCellBadge.tsx'

type Props = {
  cellNode: CellNode<MaintenanceStatusValue>
  node: RowNode
  column: DataGridColumnDefs
  api: DataGridApi
}

export const MTCellMaintenanceStatus: FC<Props> = ({ cellNode, node, column, api }) => {
  const statusClassName = classNames('uppercase', {
    'bg-green-50' : cellNode.value === MaintenanceStatusValue.DONE,
    'bg-blue-50'  : cellNode.value === MaintenanceStatusValue.PENDING,
  })

  return (
    <MTCellBadge {...{ cellNode, node, column, api }} className={statusClassName}/>
  )
}
