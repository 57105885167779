import { Button, Form, InputNumber } from 'antd'
import { FC } from 'react'
import { QueryInventoryProductsStocksResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { InventorySelector } from '@/presentation/inventories/components/InventorySelector.tsx'
import { UpdateInventoryStockFormProps } from '@/presentation/inventoryProductsStocks/forms/types.ts'
import { useUpdateInventoryStock } from '@/presentation/inventoryProductsStocks/hooks/useUpdateInventoryStock.ts'
import { useInventoryProductsStocksStore } from '@/presentation/inventoryProductsStocks/store/inventoryProductsStocks.ts'
import { ProductSelector } from '@/presentation/products/components/ProductSelector.tsx'

type UpdateInventoryStockFormFields = {
  inventory: string
  product: string
  quantity: number
}

export const UpdateInventoryStockForm: FC<UpdateInventoryStockFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateInventoryStockForm-alert'
  const { selectedInventoryId } = useInventoryProductsStocksStore()
  const { submit, isSuccess, isLoading, isError, message } = useUpdateInventoryStock()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryInventoryProductsStocksResponse['items'][0]> = selectionNodes[0]

  const initialValues = {
    ...(selectedNode?.data || {}),
    inventory : selectedInventoryId,
    product   : selectedNode?.data?.product?.id,
  }

  const onSubmit = async (values: UpdateInventoryStockFormFields) => {
    if (isLoading || !selectedInventoryId) {
      return
    }

    const response = await submit({
      inventory : selectedInventoryId,
      product   : selectedNode?.data?.product?.id,
      quantity  : values.quantity,
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del stock">
            <Form.Item
              label="Inventario"
              name="inventory"
              rules={[{ required: true }]}
            >
              <InventorySelector disabled={true}/>
            </Form.Item>

            <Form.Item
              label="Producto"
              name="product"
              rules={[{ required: true }]}
            >
              <ProductSelector disabled={!!initialValues.product}/>
            </Form.Item>

            <Form.Item
              label="Cantidad a ingresar"
              name="quantity"
              rules={[{ required: true }]}
            >
              <InputNumber min={0}/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
