import { QueryOptions } from '@/api/common/types.ts'
import { QueryUsersResponse } from '@/api/users/response.types.ts'
import { useUsersApiClient } from '@/api/users/useUsersApiClient.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryUsersOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryUsersForSelectorResult = {
  id: string
  firstName?: string
  lastName?: string
}

export const useQueryUserForSelector = (role?: UserRoleValue) => {
  const repository = useUsersApiClient()
  const queryItemsHook = useQueryItems<QueryUsersResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron usuarios',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryUsersForSelectorResult>()

  const query = async (options: QueryUsersOptions = {}) => {
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        role,
      },
    })
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryUsersOptions, response?: QueryUsersResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        id        : item.id.toString(),
        firstName : item.firstName,
        lastName  : item.lastName,
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
