import { UpdateTenantRequest } from '@/api/tenants/request.types.ts'
import { UpdateTenantResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateTenant = () => {
  const repository = useTenantsApiClient()
  const updateItem = useUpdateItem<UpdateTenantRequest, UpdateTenantResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el inquilino',
      alreadyExists : 'Ya existe un inquilino con el mismo CDN o RUT',
      success       : 'Inquilino actualizado con éxito',
      loading       : 'Actualizando inquilino',
    },
  })

  return {
    ...updateItem,
  }
}
