import { FC } from 'react'
import { matchPath } from 'react-router-dom'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import Clients from '@/presentation/clients/Clients.tsx'
import { ModulesLayout } from '@/presentation/common/layout/ModulesLayout.tsx'
import { PrivateRoute } from '@/presentation/common/router/privateRoute.tsx'
import { routes } from '@/presentation/common/router/routes.ts'
import { ModuleBaseLayoutProps, ModuleLinkNode } from '@/presentation/common/router/types.ts'
import Epps from '@/presentation/epp/Epps.tsx'
import EppReport from '@/presentation/eppReport/EppReport.tsx'
import { FuelConsumeReport } from '@/presentation/fuelConsumeReport/FuelConsumeReport.tsx'
import { FuelPerformanceReport } from '@/presentation/fuelPerformanceReport/FuelPerformanceReport.tsx'
import { CreateFuelRegistryForm } from '@/presentation/fuelRegistries/forms/CreateFuelRegistryForm.tsx'
import FuelRegistries from '@/presentation/fuelRegistries/FuelRegistries.tsx'
import Inventories from '@/presentation/inventories/Inventories.tsx'
import InventoryProductsStocks from '@/presentation/inventoryProductsStocks/InventoryProductsStocks.tsx'
import Machinery from '@/presentation/machinery/Machinery.tsx'
import MachineryUsageReport from '@/presentation/machineryUsageReport/MachineryUsageReport.tsx'
import { MachineryUseDashboard } from '@/presentation/machineryUseDashboard/MachineryUseDashboard.tsx'
import { CreateMachineryUseRecord } from '@/presentation/machineryUseRecords/forms/CreateMachineryUseRecord.tsx'
import MachineryUseRecords from '@/presentation/machineryUseRecords/MachineryUseRecords.tsx'
import Maintenances from '@/presentation/maintenance/Maintenances.tsx'
import { MaintenanceDashboard } from '@/presentation/maintenanceDashboard/MaintenanceDashboard.tsx'
import { Module, ModuleLink } from '@/presentation/modules/types.ts'
import OperatorHoursWorkedReport from '@/presentation/operatorHoursWorkedReport/OperatorHoursWorkedReport.tsx'
import { PayStates } from '@/presentation/payStates/PayStates.tsx'
import Products from '@/presentation/products/Products.tsx'
import ProductsTemplates from '@/presentation/productsTemplates/ProductsTemplates.tsx'
import Rentals from '@/presentation/rentals/Rentals.tsx'
import Subscriptions from '@/presentation/subscriptions/Subscriptions.tsx'
import Tenants from '@/presentation/tenants/Tenants.tsx'
import TenantSettings from '@/presentation/tenants/TenantSettings.tsx'
import Users from '@/presentation/users/Users.tsx'
import UserSettings from '@/presentation/users/UserSettings.tsx'

// eslint-disable-next-line react-refresh/only-export-components
const ModuleBaseLayout: FC<ModuleBaseLayoutProps> = ({ fullMode }) => {
  return (
    <ModulesLayout renderSidebar={true} fullMode={fullMode}>
      <PrivateRoute/>
    </ModulesLayout>
  )
}

export const modules: Module[] = [
  {
    id           : 'c530fb80be9544e2ac11ca8271f5cc5f',
    title        : 'Tableros',
    originPath   : routes.dashboards,
    allowedRoles : [ UserRoleValue.ADMIN ],
    links        : [
      {
        id      : '441648d760b340529cb7865c621c9fe7',
        label   : 'Uso de maquinarias',
        path    : `${routes.dashboards}/machinery-use`,
        layout  : () => <ModuleBaseLayout fullMode/>,
        element : () => <MachineryUseDashboard/>,
      },
      {
        id      : '0d360db35f7a47479f220e6af5ddb6cc',
        label   : 'Mantenimiento',
        path    : `${routes.dashboards}/machinery-maintenance`,
        layout  : () => <ModuleBaseLayout fullMode/>,
        element : () => <MaintenanceDashboard/>,
      },
    ],
  },
  {
    id           : '54c1ef8a522a4afcb22f91ba16c58430',
    title        : 'Inquilinos del Sistema',
    originPath   : routes.tenants,
    allowedRoles : [ UserRoleValue.ADMIN ],
    onlySysAdmin : true,
    links        : [
      {
        id      : 'cfa6e86b2be845d7a7f4a9305f789af3',
        label   : 'Mantenedor de inquilinos',
        path    : `${routes.tenants}/maintainer`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <Tenants/>,
      },
      {
        id      : '3a7a4f3f1c1a4fda8bf002747e00fb13',
        label   : 'Suscripciones',
        path    : `${routes.tenants}/subscriptions`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <Subscriptions/>,
      },
      // {
      //   id      : 'ee022362ba774c76a72bd155bab60f95',
      //   label   : 'Facturación',
      //   path    : `${routes.tenants}/billing`,
      //   layout  : () => <ModuleBaseLayout/>,
      //   element : () => <Fragment/>,
      // },
    ],
  },
  {
    id           : '21c9a04ca80441a3993b1dde3107dff8',
    title        : 'Usuarios',
    originPath   : routes.users,
    allowedRoles : [ UserRoleValue.ADMIN ],
    links        : [
      {
        id      : '3a2f188a2eaf47e2ac35c1c5be78403c',
        label   : 'Mantenedor de usuarios',
        path    : `${routes.users}/maintainer`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <Users/>,
      },
    ],
  },
  {
    id           : '9012068ed81f4253b2585605d5bed7aa',
    title        : 'Clientes',
    originPath   : routes.clients,
    allowedRoles : [ UserRoleValue.ADMIN ],
    links        : [
      {
        id      : '5adc0d09edc44fbaa514020895dabd90',
        label   : 'Mantenedor de clientes',
        path    : `${routes.clients}/maintainer`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <Clients/>,
      },
      {
        id      : 'b84f136fbc4146e8a8c849cbe77a4e4c',
        label   : 'Arriendos',
        path    : `${routes.clients}/rentals`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <Rentals/>,
      },
      {
        id      : '9e8bd318d5c348f6b98c3875e1c94d1e',
        label   : 'Estados de pago',
        path    : `${routes.clients}/pay-states`,
        layout  : () => <ModuleBaseLayout fullMode={true}/>,
        element : () => <PayStates/>,
      },
    ],
  },
  {
    id         : 'f00df8b66b884b7dbbe7e4743f1b407a',
    title      : 'Maquinarias',
    originPath : routes.machinery,
    links      : [
      {
        id           : 'a7a986d38e154f09ab2e6de3b2b1532c',
        label        : 'Mantenedor de maquinarias',
        path         : `${routes.machinery}/maintainer`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <Machinery/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id           : '2dc6a9a071a640a2abe2a87881844539',
        label        : 'Registro de uso',
        path         : `${routes.machinery}/use-record`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <CreateMachineryUseRecord/>,
        allowedRoles : [ UserRoleValue.MACHINE_OPERATOR, UserRoleValue.FUEL_SUPPLIER, UserRoleValue.CONSTRUCTION_MANAGER ],
      },
      {
        id           : 'b55243068bd44144a8b23e40ea15c335',
        label        : 'Mantenedor de registros de uso',
        path         : `${routes.machinery}/use-record-maintainer`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <MachineryUseRecords/>,
        allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.MACHINE_OPERATOR, UserRoleValue.FUEL_SUPPLIER, UserRoleValue.CONSTRUCTION_MANAGER ],
      },
      {
        id           : '5c2f8d52da1343749ad657e88a582080',
        label        : 'Mantenedor de mantenciones',
        path         : `${routes.machinery}/maintenance`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <Maintenances/>,
        allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.MECHANIC ],
      },
    ],
  },
  {
    id           : '272328cf9b1b4075a2ff5e6ff9f389b4',
    title        : 'Combustible',
    originPath   : routes.fuel,
    allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.FUEL_SUPPLIER ],
    links        : [
      {
        id           : 'e1f08974345c4be8b90e0f0ed43b6ed9',
        label        : 'Registro de combustible',
        path         : `${routes.fuel}/record-registry`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <CreateFuelRegistryForm/>,
        allowedRoles : [ UserRoleValue.FUEL_SUPPLIER ],
      },
      {
        id           : 'f995eaf7cd624501a9fa2bf35a16dfdf',
        label        : 'Mantenedor de registros de suministro de combustible',
        path         : `${routes.fuel}/fuel-maintainer`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <FuelRegistries/>,
        allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.FUEL_SUPPLIER ],
      },
    ],
  },
  {
    id           : 'b4810858c48b4392b86d810b1a0c34db',
    title        : 'Inventario',
    originPath   : routes.inventory,
    allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.MECHANIC, UserRoleValue.FUEL_SUPPLIER ],
    links        : [
      {
        id           : '13cfd24db54546d3bd2fa7b875d6662a',
        label        : 'Mantenedor de espacios',
        path         : `${routes.inventory}/maintainer`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <Inventories/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id           : 'efef7ea074cb4750a66454cb1b0501f3',
        label        : 'Mantenedor de productos',
        path         : `${routes.inventory}/products`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <Products/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id           : 'cc789385f5d1490a8222191f6f133b2a',
        label        : 'Mantenedor de stock',
        path         : `${routes.inventory}/stocks`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <InventoryProductsStocks/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id           : 'd87340ab09fe41d4a929cc5840583f51',
        label        : 'Mantenedor de plantillas',
        path         : `${routes.inventory}/products-templates`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <ProductsTemplates/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id           : 'f3e4b89aeaa54250a975b76c434e9b8b',
        label        : 'Mantenedor de registros EPP',
        path         : `${routes.inventory}/epp-maintainer`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <Epps/>,
        allowedRoles : [ UserRoleValue.ADMIN, UserRoleValue.MECHANIC, UserRoleValue.FUEL_SUPPLIER ],
      },
    ],
  },
  {
    id           : '11ddc4ef7cf847bd97150cb5629c07c3',
    title        : 'Reportes',
    originPath   : routes.reports,
    allowedRoles : [ UserRoleValue.ADMIN ],
    links        : [
      {
        id      : '0f96be7022e04409ad28772d38ecb119',
        label   : 'Horas del operador',
        path    : `${routes.reports}/operator-hours`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <OperatorHoursWorkedReport/>,
      },
      {
        id      : '8003cf4e28374fe6bfee598ec98f3e9d',
        label   : 'Estado de maquinarias',
        path    : `${routes.reports}/machinery-state`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <MachineryUsageReport/>,
      },
      {
        id      : '469354ee537d492aa1f213cc5a66b32e',
        label   : 'Combustible (consumo)',
        path    : `${routes.reports}/fuel-consumption`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <FuelConsumeReport/>,
      },
      {
        id      : 'a844de0f98ad4ccc86f8245a1769bcd2',
        label   : 'Combustible (rendimiento)',
        path    : `${routes.reports}/fuel-performance`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <FuelPerformanceReport/>,
      },
      {
        id      : 'fc449809de714e52bffe4ba93d17fefa',
        label   : 'EPP',
        path    : `${routes.reports}/epp`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <EppReport/>,
      },
    ],
  },
  {
    id         : '0b5f8efa29d241589b6a5e84280dac06',
    title      : '⚙️ Configuraciones',
    originPath : routes.settings,
    links      : [
      {
        id           : 'ae0002f566b04461afe37502de81955b',
        label        : 'Configuraciones de la empresa',
        path         : `${routes.settings}/company`,
        layout       : () => <ModuleBaseLayout/>,
        element      : () => <TenantSettings/>,
        allowedRoles : [ UserRoleValue.ADMIN ],
      },
      {
        id      : '5e9fdb2dca64415599b2b3f41b279614',
        label   : 'Configuraciones de mi cuenta',
        path    : `${routes.settings}/account`,
        layout  : () => <ModuleBaseLayout/>,
        element : () => <UserSettings/>,
      },
    ],
  },
]

/**
 * Creates a tree of links based on the current pathname
 * @param pathname
 */
export const createModuleTreeNodes = (pathname: string): ModuleLinkNode | null => {
  const module = modules.find((mod) => pathname.startsWith(mod.originPath))

  if (!module) {
    return null
  }

  const rootNode: ModuleLinkNode = {
    parent  : null,
    current : {
      id           : module.id,
      label        : module.title,
      path         : module.originPath,
      links        : module.links,
      allowedRoles : module.allowedRoles,
    },
    children: [],
  }

  if (module.links && module.links.length > 0) {
    module.links.forEach((link) => {
      const node = createTreeNode(link, rootNode)
      rootNode.children.push(node)
    })
  }

  return rootNode
}

/**
 * Creates recursively a tree of links
 * @param link
 * @param parent
 */
const createTreeNode = (link: ModuleLink, parent: ModuleLinkNode | null): ModuleLinkNode => {
  const node: ModuleLinkNode = {
    parent,
    current  : link,
    children : [],
  }

  if (link.links && link.links.length > 0) {
    link.links.forEach((child) => {
      const childNode = createTreeNode(child, node)
      node.children.push(childNode)
    })
  }

  return node
}

/**
 * Finds a node by path
 * @param pathname
 * @param node
 */
export const findLinkNodeByPath = (pathname: string, node: ModuleLinkNode): ModuleLinkNode | null => {
  if (!node.current.path && !node.children) {
    return null
  }

  if (node.current.path) {
    const match = matchPath(node.current.path, pathname)
    if (match) {
      return node
    }
  }

  if (node.children && node.children.length > 0) {
    for (const childNode of node.children) {
      const foundNode = findLinkNodeByPath(pathname, childNode)
      if (foundNode) {
        return foundNode
      }
    }
  }

  return null
}
