import { Button, Form } from 'antd'
import { FC, useState } from 'react'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { CreateEppFormProps } from '@/presentation/epp/forms/types.ts'
import { useCreateEpp } from '@/presentation/epp/hooks/useCreateEpp.ts'
import { QueryMaintenancesForTableResult } from '@/presentation/maintenance/hooks/useQueryMaintenancesForTable.ts'
import { RentalMachinerySelector } from '@/presentation/rentals/components/RentalMachinerySelector.tsx'
import {
  QueryRentalsMachineryForSelectorResult,
} from '@/presentation/rentals/hooks/useQueryRentalsMachineryForSelector.ts'

type CreateEppFormFields = {
  rentalMachinery: string,
}

export const CreateEppForm: FC<CreateEppFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateEppForm-alert'

  const [ form ] = Form.useForm<CreateEppFormFields>()
  const [ machinery, setMachinery ] = useState<QueryRentalsMachineryForSelectorResult['data']>()

  const { submit, isSuccess, isLoading, isError, message } = useCreateEpp()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryMaintenancesForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async () => {
    if (isLoading || !machinery) {
      return
    }

    const response = await submit({
      operator          : machinery.rentalType === RentalTypeValue.INTERNAL ? machinery.operator.id as string : machinery.operator.fullName,
      rentalMachineryId : machinery.id,
    })

    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <FormSection title="Datos de registro">
        <Form.Item
          label="Operador de maquinaria"
          name="rentalMachinery"
          rules={[{ required: true }]}
        >
          <RentalMachinerySelector displayOperator onCustomSelect={setMachinery} filters={{
            date       : getLocalDateStartOf().format(),
            rentalType : RentalTypeValue.INTERNAL,
          }}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        {onCancel && <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>}
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Guardar</Button>
      </div>
    </Form>
  )
}
