import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'

const { Option } = Select

export const TypeSelector: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Seleccione un tipo de maquinaria"
      {...props}
    >
      {MachineryType.toEntries().map(([ key, value ]) => (
        <Option key={key} value={value.value}>{value.label()}</Option>
      ))}
    </Select>
  )
}
