import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteFuelRegistriesForm } from '@/presentation/fuelRegistries/forms/DeleteFuelRegistriesForm.tsx'
import { FuelRegistriesActionsProps } from '@/presentation/fuelRegistries/forms/types.ts'

export const FuelRegistriesActions: FC<FuelRegistriesActionsProps> = ({ onAction }) => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())
  const { canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'DELETE' | null>(null)

  const isDelete = option === 'DELETE'

  const title = 'Eliminar selección'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.call(this)
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isDelete && <DeleteFuelRegistriesForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
