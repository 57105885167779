import dayjs, { Dayjs } from 'dayjs'

type Props = {
  date?: Dayjs | Date | string,
  endOf?: 'day' | 'month' | 'year',
}

export const getLocalDateEndOf = ({ date, endOf }: Props = {}): Dayjs => {
  return dayjs(date).local().endOf(endOf ?? 'day')
}
