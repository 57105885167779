import { CreateInventoryRequest } from '@/api/inventories/request.types.ts'
import { CreateInventoryResponse } from '@/api/inventories/response.types.ts'
import { useInventoriesApiClient } from '@/api/inventories/useInventoriesApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateInventory = () => {
  const repository = useInventoriesApiClient()
  const createItem = useCreateItem<CreateInventoryRequest, CreateInventoryResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el espacio',
      alreadyExists : 'Ya existe un espacio con ese nombre',
      success       : 'Espacio creado con éxito',
      loading       : 'Creando espacio',
    },
  })

  return {
    ...createItem,
  }
}
