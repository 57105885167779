import cx from 'classnames'
import { FC } from 'react'
import { MTCellBaseProps } from '@/presentation/common/components/dataGrid/components/types.ts'

export const MTCellBase: FC<MTCellBaseProps> = (props) => {
  const { justify, align } = props.column?.cellRendererParams || {}

  const className = cx('p-3 flex h-full text-base min-w-full whitespace-nowrap overflow-auto', props.className, {
    'justify-start'  : !justify || justify === 'left',
    'justify-center' : justify === 'center',
    'justify-end'    : justify === 'right',
    'items-start'    : !align || align === 'top',
    'items-center'   : align === 'middle',
    'items-end'      : align === 'bottom',
  })

  return (
    <div className={className}>
      {props.children || (
        <span className="select-text cursor-text">
          {props.cellNode.formattedValue ?? props.cellNode.value}
        </span>
      )}
    </div>
  )
}
