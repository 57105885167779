import { QueryOptions } from '@/api/common/types.ts'
import { QueryPickupsResponse } from '@/api/machinery/response.types.ts'
import { useMachineryApiClient } from '@/api/machinery/useMachineryApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryPickupsOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryPickupsForSelectorResult = {
  id: string
  code: string
  brand: string
  model: string
  serialNumber: string
}

export const useQueryPickupsForSelector = () => {
  const repository = useMachineryApiClient()
  const queryItemsHook = useQueryItems<QueryPickupsResponse>({
    callable : repository.queryPickups,
    messages : {
      default: 'No se encontraron camionetas',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryPickupsForSelectorResult>()

  const query = async (options: QueryPickupsOptions = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryPickupsOptions, response?: QueryPickupsResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        id           : item.id.toString(),
        code         : item.code,
        brand        : item.brand,
        model        : item.model,
        serialNumber : item.serialNumber,
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
