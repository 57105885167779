export enum WorkingDayValue {
  COMPLETE = 'COMPLETE',
  HALF = 'HALF',
}

export class WorkingDay {
  readonly value!: WorkingDayValue

  constructor(value: WorkingDayValue) {
    this.value = value
  }

  static fromString(value: string): WorkingDay {
    if (!this.toList().includes(value as WorkingDayValue)) {
      throw new Error(`Invalid RentalType: ${value}`)
    }

    return new WorkingDay(value as WorkingDayValue)
  }

  static toList(): WorkingDayValue[] {
    return Object.values(WorkingDayValue)
  }

  static toEntries(): [WorkingDayValue, WorkingDay][] {
    return this.toList().map((value) => [ value, new WorkingDay(value) ])
  }

  static readonly labels: Record<WorkingDayValue, string> = {
    COMPLETE : 'Completa',
    HALF     : 'Media',
  }

  label(): string {
    return WorkingDay.labels[this.value]
  }
}
