import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateUserRequest,
  DeleteManyUsersRequest,
  UpdateUserRequest,
  UpdateUserSettingsRequest,
} from '@/api/users/request.types.ts'
import {
  CreateUserResponse,
  DeleteManyUsersResponse,
  DownloadExcelResumeResponse,
  QueryUsersResponse,
  UpdateUserResponse,
  UpdateUserSettingsResponse,
} from '@/api/users/response.types.ts'

export type UsersApiClient = {
  query: (options: QueryOptions) => Promise<QueryUsersResponse>
  create: (req: CreateUserRequest) => Promise<CreateUserResponse>
  update: (req: UpdateUserRequest) => Promise<UpdateUserResponse>
  updateSettings: (req: UpdateUserSettingsRequest) => Promise<UpdateUserSettingsResponse>
  deleteMany: (req: DeleteManyUsersRequest) => Promise<DeleteManyUsersResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useUsersApiClient = (): UsersApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryUsersResponse, QueryParams>({
    path   : '/users',
    params : options.params,
  }),

  create: async (req: CreateUserRequest) => await postMapping<CreateUserResponse, CreateUserRequest>({
    path : '/users',
    body : req,
  }),

  update: async (req: UpdateUserRequest) => await putMapping<UpdateUserResponse, UpdateUserRequest['body']>({
    path : `/users/${req.params.id}`,
    body : req.body,
  }),

  updateSettings: async (req: UpdateUserSettingsRequest) => await putMapping<UpdateUserSettingsResponse, UpdateUserSettingsRequest['body']>({
    path : `/users/${req.params.id}/settings`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyUsersRequest) => await deleteMapping<DeleteManyUsersResponse, DeleteManyUsersRequest>({
    path : '/users',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/users/excel',
  }),
})
