import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryEppsResponse } from '@/api/epps/response.types.ts'
import { useEppsApiClient } from '@/api/epps/useEppsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryEppsForTableResult = PaginatedData<{
  id: string
  rentalMachineryId: string
  operator: string
  operatorId: string | null
  author: {
    id: string
    name: string
    role: string
  }
  date: string
  isSigned: boolean
}>

export const useQueryEppsForTable = () => {
  const repository = useEppsApiClient()
  const queryItemsHook = useQueryItems<QueryEppsResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron EPPs',
      loading : 'Buscando EPPs',
      success : 'EPPs encontradas',
    },
  })

  const [ data, setData ] = useState<QueryEppsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryEppsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id                : item.id.toString(),
        rentalMachineryId : item.rentalMachineryId,
        operator          : item.operator,
        operatorId        : item.operatorId ?? null,
        author            : {
          id   : item.author.id,
          name : item.author.name,
          role : item.author.role,
        },
        date     : item.date ?? null,
        isSigned : item.signature !== null,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
