import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateMachineryUseRecordRequest,
  DeleteManyMachineryUseRecordsRequest,
  DownloadOperatorHoursWorkedExcelRequest,
  DownloadPdfRequest,
  QueryMachineryUseRecordsForDashboardRequest,
  UpdateMachineryUseRecordRequest,
} from '@/api/machineryUseRecords/request.types.ts'
import {
  CreateMachineryUseRecordResponse,
  DeleteManyMachineryUseRecordsResponse,
  DownloadOperatorHoursWorkedExcelResponse,
  DownloadPdfResponse,
  QueryMachineryUseRecordsForDashboardResponse,
  QueryMachineryUseRecordsResponse,
  UpdateMachineryUseRecordResponse,
} from '@/api/machineryUseRecords/response.types.ts'

export type MachineryUseRecordsApiClient = {
  query: (options: QueryOptions) => Promise<QueryMachineryUseRecordsResponse>
  queryForDashboard: (options: QueryOptions<any, QueryMachineryUseRecordsForDashboardRequest>) => Promise<QueryMachineryUseRecordsForDashboardResponse>
  create: (req: CreateMachineryUseRecordRequest) => Promise<CreateMachineryUseRecordResponse>
  update: (req: UpdateMachineryUseRecordRequest) => Promise<UpdateMachineryUseRecordResponse>
  deleteMany: (req: DeleteManyMachineryUseRecordsRequest) => Promise<DeleteManyMachineryUseRecordsResponse>
  downloadUseRecordPdf: (req: DownloadPdfRequest) => Promise<DownloadPdfResponse>
  downloadOperatorHoursWorkedReport: (req: DownloadOperatorHoursWorkedExcelRequest) => Promise<DownloadOperatorHoursWorkedExcelResponse>
}

export const useMachineryUseRecordsApiClient = (): MachineryUseRecordsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryMachineryUseRecordsResponse, QueryParams>({
    path   : '/machinery/use-records',
    params : options.params,
  }),

  queryForDashboard: async (options: QueryOptions<any, QueryMachineryUseRecordsForDashboardRequest>) => await getMapping<QueryMachineryUseRecordsForDashboardResponse, QueryParams>({
    path   : '/machinery/use-records/dashboard',
    params : options.params,
  }),

  create: async (body: CreateMachineryUseRecordRequest) => await postMapping<CreateMachineryUseRecordResponse, CreateMachineryUseRecordRequest>({
    path : '/machinery/use-records',
    body : body,
  }),

  update: async (req: UpdateMachineryUseRecordRequest) => await putMapping<UpdateMachineryUseRecordResponse, UpdateMachineryUseRecordRequest['body']>({
    path : `/machinery/use-records/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyMachineryUseRecordsRequest) => await deleteMapping<DeleteManyMachineryUseRecordsResponse, DeleteManyMachineryUseRecordsRequest>({
    path : '/machinery/use-records',
    body : req,
  }),

  downloadUseRecordPdf: async (req: DownloadPdfRequest) => await getFileMapping<DownloadPdfResponse, QueryParams>({
    path   : `/machinery/use-records/${req.id}/pdf`,
    params : {},
  }),

  downloadOperatorHoursWorkedReport: async (params: DownloadOperatorHoursWorkedExcelRequest) => await getFileMapping<DownloadOperatorHoursWorkedExcelResponse, QueryParams>({
    path   : '/machinery/use-records/operator-hours-worked/excel',
    params : params,
  }),
})
