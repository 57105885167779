import { DeleteManyProductsTemplatesRequest } from '@/api/productsTemplates/request.types.ts'
import { DeleteManyProductsTemplatesResponse } from '@/api/productsTemplates/response.types.ts'
import { useProductsTemplatesApiClient } from '@/api/productsTemplates/useProductsTemplatesApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteProductsTemplates = () => {
  const repository = useProductsTemplatesApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyProductsTemplatesRequest, DeleteManyProductsTemplatesResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a las plantillas para mantención',
      success : 'Plantillas para mantención eliminadas',
      loading : 'Eliminando plantillas para mantención',
    },
  })

  return {
    ...deleteItems,
  }
}
