import { CreateProductsTemplateProductRequest } from '@/api/productsTemplateProducts/request.types.ts'
import { CreateProductsTemplateProductResponse } from '@/api/productsTemplateProducts/response.types.ts'
import {
  useProductsTemplateProductsApiClient,
} from '@/api/productsTemplateProducts/useProductsTemplateProductsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateProductsTemplateProduct = () => {
  const repository = useProductsTemplateProductsApiClient()
  const createItem = useCreateItem<CreateProductsTemplateProductRequest, CreateProductsTemplateProductResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar el producto para la plantilla',
      alreadyExists : 'Ya existe este producto para la plantilla',
      success       : 'Producto registrado con éxito',
      loading       : 'Registrando producto',
    },
  })

  return {
    ...createItem,
  }
}
