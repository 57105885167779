import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteRentalsFormProps } from '@/presentation/rentals/forms/types.ts'
import { useDeleteRentals } from '@/presentation/rentals/hooks/useDeleteRentals.ts'
import { QueryRentalsForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsForTable.ts'

export const DeleteRentalsForm: FC<DeleteRentalsFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteRentalsForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteRentals()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = selectionNodes.map((node: RowNode<QueryRentalsForTableResult['items'][0]>) => node.data.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.call(this)
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los arriendos seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}
          disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
