import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateFuelRegistryRequest,
  DeleteManyFuelRegistriesRequest,
  DownloadConsumeReportRequest,
  DownloadPerformanceReportRequest,
} from '@/api/fuelRegistries/request.types.ts'
import {
  CreateFuelRegistryResponse,
  DeleteManyFuelRegistriesResponse,
  DownloadExcelResumeResponse,
  QueryFuelRegistriesResponse,
} from '@/api/fuelRegistries/response.types.ts'

export type FuelRegistriesApiClient = {
  query: (options: QueryOptions) => Promise<QueryFuelRegistriesResponse>
  create: (req: CreateFuelRegistryRequest) => Promise<CreateFuelRegistryResponse>
  deleteMany: (req: DeleteManyFuelRegistriesRequest) => Promise<DeleteManyFuelRegistriesResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
  downloadConsumeResume: (req: DownloadConsumeReportRequest) => Promise<DownloadExcelResumeResponse>
  downloadPerformanceResume: (req: DownloadPerformanceReportRequest) => Promise<DownloadExcelResumeResponse>
}

export const useFuelRegistriesApiClient = (): FuelRegistriesApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryFuelRegistriesResponse, QueryParams>({
    path   : '/fuel-registries',
    params : options.params,
  }),

  create: async (body: CreateFuelRegistryRequest) => await postMapping<CreateFuelRegistryResponse, CreateFuelRegistryRequest>({
    path : '/fuel-registries',
    body : body,
  }),

  deleteMany: async (req: DeleteManyFuelRegistriesRequest) => await deleteMapping<DeleteManyFuelRegistriesResponse, DeleteManyFuelRegistriesRequest>({
    path : '/fuel-registries',
    body : req,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/fuel-registries/excel',
  }),

  downloadConsumeResume: async (req: DownloadConsumeReportRequest) => await getFileMapping<DownloadExcelResumeResponse, DownloadConsumeReportRequest>({
    path   : '/fuel-registries/consume-report',
    params : req,
  }),

  downloadPerformanceResume: async (req: DownloadPerformanceReportRequest) => await getFileMapping<DownloadExcelResumeResponse, DownloadPerformanceReportRequest>({
    path   : '/fuel-registries/performance-report',
    params : req,
  }),
})
