import { Button, Form } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { SubscriptionStateSelector } from '@/presentation/subscriptions/components/SubscriptionStateSelector.tsx'
import { UpdateSubscriptionFormFields, UpdateSubscriptionFormProps } from '@/presentation/subscriptions/forms/types.ts'
import { QuerySubscriptionsForTableResult } from '@/presentation/subscriptions/hooks/useQuerySubscriptionsForTable.ts'
import { useUpdateSubscription } from '@/presentation/subscriptions/hooks/useUpdateSubscription.ts'

export const UpdateSubscriptionForm: FC<UpdateSubscriptionFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateSubscriptionForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateSubscription()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QuerySubscriptionsForTableResult['items'][0]> = Object.values(selectionNodes)[0]

  const initialValues: UpdateSubscriptionFormFields = {
    status: selectedNode?.data?.subscriptionStatus,
  }

  const onSubmit = async (values: UpdateSubscriptionFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values)

    const response = await submit({
      query: {
        id: selectedNode?.data?.tenant?.id,
      },
      body: {
        subscription: {
          status: (editedValues as UpdateSubscriptionFormFields).status || initialValues.status,
        },
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos de la suscripción">
            <Form.Item
              label="Estado"
              name="status"
              rules={[{ required: true }]}
            >
              <SubscriptionStateSelector/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit"
              disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
