import { FC, useState } from 'react'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { SubscriptionsActionsProps } from '@/presentation/subscriptions/forms/types.ts'
import { UpdateSubscriptionForm } from '@/presentation/subscriptions/forms/UpdateSubscriptionForm.tsx'

export const SubscriptionsActions: FC<SubscriptionsActionsProps> = ({ onAction }) => {
  const { canUpdate } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPut = option === 'PUT'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  return (
    <>
      <MaintainerModal title="Actualizar Suscripción" open={open}>
        {isPut && <UpdateSubscriptionForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canUpdate && (
        <SidebarButton label="Actualizar Suscripción" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
