import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryProductsResponse } from '@/api/products/response.types.ts'
import { useProductsApiClient } from '@/api/products/useProductsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryProductsForTableResult = PaginatedData<{
  id: string
  name: string
  description: string | null
  sku: string | null
  sizes: {
    height: string | null
    width: string | null
    depth: string | null
  }
  weight: string | null
  volume: string | null
  unitsPerPackage: number | null
}>

export const useQueryProductsForTable = () => {
  const repository = useProductsApiClient()
  const queryItemsHook = useQueryItems<QueryProductsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const [ data, setData ] = useState<QueryProductsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryProductsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id              : item.id.toString(),
        name            : item.name,
        description     : item.description ?? null,
        sku             : item.sku ?? null,
        sizes           : item.sizes,
        weight          : item.weight ?? null,
        volume          : item.volume ?? null,
        unitsPerPackage : item.unitsPerPackage ?? null,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
