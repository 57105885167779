import { Pagination } from 'antd'
import { FC, useEffect, useState } from 'react'
import { MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { MachineryUseCard } from '@/presentation/machineryUseDashboard/components/MachineryUseCard.tsx'
import {
  useQueryMachineryUseRecordsForDashboard,
} from '@/presentation/machineryUseDashboard/hooks/useQueryMachineryUseRecordsForDashboard.ts'

type Props = {
  title: string,
  machineryType: MachineryTypeValue[]
}

export const MachineryList: FC<Props> = ({ title, machineryType }) => {
  const [ page, setPage ] = useState(1)
  const [ limit, setLimit ] = useState(7)
  const { data, query, isLoading } = useQueryMachineryUseRecordsForDashboard()

  useEffect(() => {
    query({
      params: {
        page          : page,
        limit         : limit,
        date          : getLocalDateStartOf().format(),
        machineryType : machineryType,
      },
    })

    const interval = setInterval(() => {
      query({
        params: {
          page          : page,
          limit         : limit,
          date          : getLocalDateStartOf().format(),
          machineryType : machineryType,
        },
      })
    }, 1000 * 60 * 5) // 5 minutes

    return () => {
      clearInterval(interval)
    }
  }, [ page, limit ])

  return (
    <section className="flex flex-col">
      <header className="w-full app-def-p bg-surface border-b border-primary-dark text-sm font-semibold">
        <i className="ri-file-list-line font-medium cursor-default"></i> {title.toUpperCase()}
      </header>

      <div className="flex-1 flex flex-col w-full relative">
        <div className="flex flex-col gap-3 items-start absolute top-0 left-0 bottom-0 right-0 overflow-auto app-def-py app-def-px">
          {isLoading && (
            <div className="w-full flex justify-center items-center h-[100px] p-3 border border-tertiary-light rounded-md bg-surface">
              <i className="ri-loader-4-line animate-spin cursor-default mr-1"></i> Cargando...
            </div>
          )}
          {!isLoading && data?.items?.map((record) => (
            <MachineryUseCard key={record.id} record={record} />
          ))}
        </div>
      </div>

      <div className="app-def-p bg-surface border-t-2 border-primary-dark flex justify-center w-full">
        <Pagination
          defaultCurrent={page}
          total={data?.totalItems}
          pageSize={data?.limit || 7}
          pageSizeOptions={[ 5, 7, 10, 15, 20, 40, 50 ]}
          disabled={isLoading}
          showSizeChanger={true}
          defaultPageSize={7}
          onChange={(newPage, newLimit) => {
            if (newPage) {setPage(newPage)}
            if (newLimit) {setLimit(newLimit)}
          }}
        />
      </div>
    </section>
  )
}
