import { LoadMaintenanceTemplateProductsRequest } from '@/api/maintenanceProducts/request.types.ts'
import { LoadMaintenanceTemplateProductsResponse } from '@/api/maintenanceProducts/response.types.ts'
import { useMaintenanceProductsApiClient } from '@/api/maintenanceProducts/useMaintenanceProductsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useLoadProductsTemplateProducts = () => {
  const repository = useMaintenanceProductsApiClient()
  const updateItem = useUpdateItem<LoadMaintenanceTemplateProductsRequest, LoadMaintenanceTemplateProductsResponse>({
    callable : repository.loadTemplate,
    messages : {
      default : 'No se pudieron actualizar los productos',
      success : 'Productos cargados con éxito',
      loading : 'Actualizando productos',
    },
  })

  return {
    ...updateItem,
  }
}
