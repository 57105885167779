import { Layout } from 'antd'
import classNames from 'classnames'
import { useContext } from 'react'
import { ToggleSidebarRight } from '@/presentation/common/components/ToggleSidebarRight.tsx'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { SidebarRightContext } from '@/presentation/common/store/apiContext/SidebarRightContext.tsx'

export const SidebarRight = () => {
  const { isTabletAndBelow } = useMQ()
  const { isOpen, content } = useContext(SidebarRightContext)

  const clasName = classNames('app-layout-sider border-l-2', {
    '!fixed z-[2000] h-[calc(100%_-_55px)] right-0': isTabletAndBelow,
  })

  return (
    <Layout.Sider
      breakpoint="md"
      collapsedWidth="0"
      collapsed={!isOpen}
      collapsible={true}
      trigger={null}
      width={250}
      className={clasName}
    >
      <div className="app-sidebar-content-wrapper">
        {isTabletAndBelow && <ToggleSidebarRight/>}
        {content}
      </div>
    </Layout.Sider>
  )
}
