export enum RentalConditionTypeValue {
  HOURS = 'HOURS',
  WORKING_DAY = 'WORKING_DAY',
  TRAVELS = 'TRAVELS',
  TRAVELS_WITHOUT_LOAD = 'TRAVELS_WITHOUT_LOAD',
  SUMINISTRO_Y_TRASLADO = 'SUMINISTRO_Y_TRASLADO',
}

export class RentalConditionType {
  readonly value!: RentalConditionTypeValue

  constructor(value: RentalConditionTypeValue) {
    this.value = value
  }

  static fromString(value: string): RentalConditionType {
    if (!this.toList().includes(value as RentalConditionTypeValue)) {
      throw new Error(`Invalid RentalType: ${value}`)
    }

    return new RentalConditionType(value as RentalConditionTypeValue)
  }

  static toList(): RentalConditionTypeValue[] {
    return Object.values(RentalConditionTypeValue)
  }

  static toEntries(): [RentalConditionTypeValue, RentalConditionType][] {
    return this.toList().map((value) => [ value, new RentalConditionType(value) ])
  }

  static readonly labels: Record<RentalConditionTypeValue, string> = {
    HOURS                 : 'Horas',
    WORKING_DAY           : 'Jornada',
    TRAVELS               : 'Viajes con carga',
    TRAVELS_WITHOUT_LOAD  : 'Viajes sin carga',
    SUMINISTRO_Y_TRASLADO : 'Suministro y Traslado (con carga)',
  }

  label(): string {
    return RentalConditionType.labels[this.value]
  }
}
