import { Alert, Button, Checkbox, DatePicker, Form } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useContext, useEffect } from 'react'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { ClientSelector } from '@/presentation/clients/components/ClientSelector.tsx'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useAlert } from '@/presentation/common/hooks/useAlert.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getLocalDateEndOf } from '@/presentation/common/utils/getLocalDateEndOf.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { PayStatesDetailsTableByHours } from '@/presentation/payStates/components/PayStatesDetailsTableByHours.tsx'
import { PayStatesDetailsTableByTravels } from '@/presentation/payStates/components/PayStatesDetailsTableByTravels.tsx'
import {
  PayStatesDetailsTableByWorkingDay,
} from '@/presentation/payStates/components/PayStatesDetailsTableByWorkingDay.tsx'
import { PayStatesResumeTable } from '@/presentation/payStates/components/PayStatesResumeTable.tsx'
import { useGeneratePayState } from '@/presentation/payStates/hooks/useGeneratePayState.ts'
import { usePreviewPayState } from '@/presentation/payStates/hooks/usePreviewPayState.ts'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'
import { RentalBuildingSelector } from '@/presentation/rentals/components/RentalBuildingSelector.tsx'
import { RentalMachinerySelector } from '@/presentation/rentals/components/RentalMachinerySelector.tsx'


type FormFields = {
  dates: [Dayjs, Dayjs],
  client: string,
  building?: string,
  rentalMachinery: string[],
  includeFuel: boolean,
  includeVat: boolean,
}

export const PayStates: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const helpCtx = useContext(HelpContext)
  const { reset, setTitle } = useMaintainerStore()
  const { setData, clear, data: editedData } = usePayStatePreviewStore()

  const initialValues = {
    dates       : [ dayjs().startOf('month'), dayjs().endOf('month') ],
    includeFuel : true,
    includeVat  : true,
  }

  const [ form ] = Form.useForm()
  const selectedDates = Form.useWatch('dates', form)
  const selectedClient = Form.useWatch('client', form)

  const { query: preview, data: previewData, isLoading, message, isSuccess, isError } = usePreviewPayState()
  useCrud({ messageApiKey: 'PayStatesPreview-alert', message, isSuccess, isLoading, isError })

  const queryPreview = async () => {
    const values = await form.validateFields().catch(() => null) as FormFields | null
    if (!values) {return}

    const { dates, client, rentalMachinery, includeFuel, includeVat } = values

    clear()

    preview({
      params: {
        since    : getLocalDateStartOf({ date: dates[0] }).format(),
        until    : getLocalDateEndOf({ date: dates[1] }).format(),
        client,
        building : values.building ?? null,
        rentalMachinery,
        includeFuel,
        includeVat,
      },
    })
  }

  // actualiza datos en el store luego de una query
  useEffect(() => {
    setData(previewData)
  }, [ previewData ])

  // resetea datos en el store cuando se desmonta el componente
  useEffect(() => {
    return () => {
      clear()
    }
  }, [])

  // hides help bar
  useEffect(() => {
    helpCtx?.render(false)
  }, [])

  // sets title for maintainer
  useEffect(() => {
    setTitle('Previsualización del Estado de Pago')

    return () => {
      reset()
    }
  }, [])

  // resets rental machinery selector when client changes
  useEffect(() => {
    form.resetFields([ 'rentalMachinery' ])
  }, [ selectedClient ])

  // DOWNLOAD PDF
  const {
    submit: downloadPdf,
    data: pdfFile,
    isLoading: isGeneratingPdf,
    message: pdfMessage,
    isSuccess: pdfSuccess,
    isError: pdfError,
    lastUpdate: lastSubmitTimestamp,
  } = useGeneratePayState()

  const downloadFile = () => {
    if (!previewData || !editedData) {return}

    downloadPdf({
      query: {
        since           : previewData.query.since,
        until           : previewData.query.until,
        client          : previewData.query.client,
        building        : previewData.query.building,
        rentalMachinery : previewData.query.rentalMachinery ?? [],
        includeVat      : previewData.query.includeVat,
        includeFuel     : previewData.query.includeFuel,
      },
      resume: editedData.resume,
    })
  }

  const {
    showLoading: showPdfLoading,
    showError: showPdfError,
    closeAlert: closePdfAlert,
  } = useAlert({ messageApiKey: 'PayStatesGenerate-Alert' })

  // Mostrar alerta
  useEffect(() => {
    if (isGeneratingPdf) {
      showPdfLoading(pdfMessage)
    }
    else if (pdfError) {
      showPdfError(pdfMessage)
    }
    else {
      closePdfAlert()
    }
  }, [ isGeneratingPdf, pdfError, pdfSuccess, pdfMessage ])

  // Abrir pdf cuando se descargue
  useEffect(() => {
    if (!isGeneratingPdf && pdfFile) {
      window.open(URL.createObjectURL(pdfFile))
    }
  }, [ lastSubmitTimestamp ])

  return (
    <>
      {isTabletAndBelow && <Alert type="warning" message="Lo sentimos, esta vista está disponible únicamente en computadoras." className="app-def-m"/>}

      {!isTabletAndBelow && (
        <div className="absolute top-0 left-0 w-full h-full flex">
          <aside className="bg-surface w-[20vw] min-w-[400px] p-3 border-r border-r-outline overflow-auto">
            <Form
              form={form}
              layout="vertical"
              labelAlign="left"
              labelWrap
              colon={false}
              initialValues={initialValues}
            >
              <Alert
                type="info"
                message={<span className="text-base font-semibold">Instrucciones de utilización</span>}
                description="Utilice los filtros siguientes para previsualizar el estado de pago. Una vez que esté conforme con los resultados, puede descargar el estado de pago en formato PDF."
                className="p-4 mb-5"
              />

              <Form.Item label="Fechas" name="dates" rules={[{ required: true }]}>
                <DatePicker.RangePicker
                  inputReadOnly
                  format="DD-MM-YYYY"
                  className="w-full"
                />
              </Form.Item>

              <Form.Item label="Cliente" name="client" rules={[{ required: true }]}>
                <ClientSelector/>
              </Form.Item>

              <Form.Item label="Obra" name="building" rules={[{ required: false }]}>
                <RentalBuildingSelector filters={{
                  client : selectedClient,
                  since  : selectedDates?.[0] ? getLocalDateStartOf({ date: selectedDates?.[0] }).format() : undefined,
                  until  : selectedDates?.[1] ? getLocalDateEndOf({ date: selectedDates?.[1] }).format() : undefined }}
                />
              </Form.Item>

              <Form.Item label="Maquinaria" name="rentalMachinery">
                <RentalMachinerySelector filters={{ client: selectedClient }} mode="multiple"/>
              </Form.Item>

              <Form.Item name="includeFuel" valuePropName="checked">
                <Checkbox>Tarifa incluye petróleo</Checkbox>
              </Form.Item>

              <Form.Item name="includeVat" valuePropName="checked">
                <Checkbox>Incluir IVA</Checkbox>
              </Form.Item>

              <Form.Item>
                <div className="flex gap-2.5">
                  <Button type="primary" htmlType="button" onClick={queryPreview} disabled={isLoading}>Previsualizar</Button>
                  <Button type="primary" htmlType="button" onClick={downloadFile} disabled={isGeneratingPdf || !previewData}>Exportar archivo PDF</Button>
                </div>
              </Form.Item>
            </Form>
          </aside>

          <div className="w-full overflow-x-hidden">
            <Maintainer showSearch={false}>
              <div className="flex flex-col gap-10">
                <PayStatesResumeTable/>
                {previewData?.detail[RentalTypeValue.INTERNAL][RentalConditionTypeValue.HOURS] && <PayStatesDetailsTableByHours rentalType={RentalTypeValue.INTERNAL}/> }
                {previewData?.detail[RentalTypeValue.EXTERNAL][RentalConditionTypeValue.HOURS] && <PayStatesDetailsTableByHours rentalType={RentalTypeValue.EXTERNAL}/> }

                {previewData?.detail[RentalTypeValue.INTERNAL][RentalConditionTypeValue.WORKING_DAY] && <PayStatesDetailsTableByWorkingDay rentalType={RentalTypeValue.INTERNAL}/> }
                {previewData?.detail[RentalTypeValue.EXTERNAL][RentalConditionTypeValue.WORKING_DAY] && <PayStatesDetailsTableByWorkingDay rentalType={RentalTypeValue.EXTERNAL}/> }

                {previewData?.detail[RentalTypeValue.INTERNAL][RentalConditionTypeValue.TRAVELS] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.INTERNAL} conditionType={RentalConditionTypeValue.TRAVELS}/> }
                {previewData?.detail[RentalTypeValue.EXTERNAL][RentalConditionTypeValue.TRAVELS] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.EXTERNAL} conditionType={RentalConditionTypeValue.TRAVELS}/> }

                {previewData?.detail[RentalTypeValue.INTERNAL][RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.INTERNAL} conditionType={RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD}/> }
                {previewData?.detail[RentalTypeValue.EXTERNAL][RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.EXTERNAL} conditionType={RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD}/> }

                {previewData?.detail[RentalTypeValue.INTERNAL][RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.INTERNAL} conditionType={RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO}/> }
                {previewData?.detail[RentalTypeValue.EXTERNAL][RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO] && <PayStatesDetailsTableByTravels rentalType={RentalTypeValue.EXTERNAL} conditionType={RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO}/> }
              </div>
            </Maintainer>
          </div>
        </div>
      )}
    </>
  )
}
