import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { CreateRentalReceiverFormProps } from '@/presentation/rentals/forms/types.ts'
import { useCreateRentalReceiver } from '@/presentation/rentals/hooks/useCreateRentalReceiver.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

type CreateRentalReceiverFormFields = {
  email: string,
}

export const CreateRentalReceiverForm: FC<CreateRentalReceiverFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateRentalReceiverForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateRentalReceiver()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedRentalId } = useRentalsMaintainerStore()

  const onSubmit = async (value: CreateRentalReceiverFormFields) => {
    if (isLoading || !selectedRentalId) {
      return
    }

    const response = await submit({
      query: {
        rentalId: selectedRentalId,
      },
      body: {
        email: value.email,
      },
    })
    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del receptor">
        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[{ required: true }]}
        >
          <Input type="email"/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
