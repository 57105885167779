import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useState } from 'react'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'
import {
  QueryInventoryProductsForSelectorResult,
  QueryInventoryProductsStocksFilters,
  useQueryInventoryStocksForSelector,
} from '@/presentation/inventoryProductsStocks/hooks/useQueryInventoryStocksForSelector.ts'

type Props = SelectProps & {
  filters?: QueryInventoryProductsStocksFilters,
  onCustomSelect?: (item?: QueryInventoryProductsForSelectorResult['data']) => void,
}

export const ProductStockSelector: FC<Props> = ({ filters, onCustomSelect, value, disabled, ...props }) => {
  const { data, query, hasMorePages, lastPage, isLoading } = useQueryInventoryStocksForSelector(filters)
  const [ lastSearch, setLastSearch ] = useState<string>()

  const limit = 100

  useEffect(() => {
    query({
      params: {
        page: 1,
        limit,
      },
    })
  }, [ filters ])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
          },
        })
      }
    }
  }

  const handleOnSelect = (value: string) => {
    const item = data?.find((item) => item.value === value)
    onCustomSelect?.(item?.data)
  }

  const options = data.map(({ value, label }) => ({ value, label })) || []

  return (
    <Select
      value={value}
      allowClear
      showSearch
      onClear={() => onSearch('')}
      filterOption={false}
      placeholder="Seleccione un producto"
      loading={isLoading}
      options={options}
      onSearch={onSearch}
      onSelect={handleOnSelect}
      disabled={disabled}
      onPopupScroll={onScroll}
      {...props}
    />
  )
}
