import { DeleteManyUsersRequest } from '@/api/users/request.types.ts'
import { DeleteManyUsersResponse } from '@/api/users/response.types.ts'
import { useUsersApiClient } from '@/api/users/useUsersApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteUsers = () => {
  const repository = useUsersApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyUsersRequest, DeleteManyUsersResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los usuarios',
      success : 'Usuarios eliminados',
      loading : 'Eliminando usuarios',
    },
  })

  return {
    ...deleteItems,
  }
}
