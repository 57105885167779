import { CreateRentalMachineryRequest } from '@/api/rentalsMachinery/request.types.ts'
import { CreateRentalMachineryResponse } from '@/api/rentalsMachinery/response.types.ts'
import { useRentalsMachineryApiClient } from '@/api/rentalsMachinery/useRentalsMachineryApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateRentalMachinery = () => {
  const repository = useRentalsMachineryApiClient()
  const createItem = useCreateItem<CreateRentalMachineryRequest, CreateRentalMachineryResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo registrar la maquinaria',
      alreadyExists : 'Ya existe un registro para esta maquinaria',
      success       : 'Maquinaria registrada con éxito',
      loading       : 'Registrando maquinaria',
    },
  })

  return {
    ...createItem,
  }
}
