import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteFuelRegistriesFormProps } from '@/presentation/fuelRegistries/forms/types.ts'
import { useDeleteFuelRegistries } from '@/presentation/fuelRegistries/hooks/useDeleteFuelRegistries.ts'
import { QueryFuelRegistriesForTableResult } from '@/presentation/fuelRegistries/hooks/useQueryFuelRegistriesForTable.ts'

export const DeleteFuelRegistriesForm: FC<DeleteFuelRegistriesFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteFuelRegistriesForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteFuelRegistries()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = Object.values(selectionNodes).map((node: RowNode<QueryFuelRegistriesForTableResult['items'][0]>) => node.data?.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.()
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los registros de suministro de combustible seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}
          disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
