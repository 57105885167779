import { Typography } from 'antd'
import { FC, useContext, useEffect } from 'react'
import { HR } from '@/presentation/common/components/HR.tsx'
import { MaintainerActions } from '@/presentation/common/components/maintainer/MaintainerActions.tsx'
import { MaintainerDownloadExcel } from '@/presentation/common/components/maintainer/MaintainerDownloadExcel.tsx'
import { MaintainerSearch } from '@/presentation/common/components/maintainer/MaintainerSearch.tsx'
import { MaintainerProps } from '@/presentation/common/components/maintainer/types.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

export const Maintainer: FC<MaintainerProps> = ({
  children,
  headerSlot,
  showSearch = true,
  showActions = true,
  showExcelDownloadButton = false,
  onExcelDownloadButtonClick,
  actionSymbol,
}) => {
  const { title } = useMaintainerStore()
  const helpCtx = useContext(HelpContext)

  // setup help content
  useEffect(() => {
    const helpContent = <MaintainerHelpInfo/>
    helpCtx.render(true)
    helpCtx.setContent(helpContent)
  }, [])

  return (
    <div className="flex flex-col app-def-pb">
      <section
        className="flex flex-col gap-2 sticky top-0 z-[100] app-def-px app-def-pt pb-3 mb-2 border-b border-outline bg-background"
      >
        <div className="flex flex-wrap justify-end items-center gap-2">
          <div className="flex-1">
            <Typography.Title level={4} className="!m-0 break-normal">{title || ''}</Typography.Title>
          </div>
          <div>
            <div className="flex items-center flex-nowrap gap-2">
              {showSearch && <MaintainerSearch/>}
              {showExcelDownloadButton && <MaintainerDownloadExcel onClick={onExcelDownloadButtonClick}/>}
              <MaintainerActions visible={showActions} actionSymbol={actionSymbol}/>
            </div>
          </div>
        </div>

        {headerSlot}
      </section>

      <section className="app-def-px">
        {children}
      </section>
    </div>
  )
}

const MaintainerHelpInfo: FC = () => {
  return (
    <div className="py-2">
      <section>
        <Typography.Title level={5}>¿Qué es un mantenedor?</Typography.Title>
        <HR/>
        <Typography.Paragraph>
                    Un mantenedor es una sección de la aplicación que permite realizar operaciones CRUD (Crear, Leer,
                    Actualizar, Eliminar) sobre un recurso.
        </Typography.Paragraph>
      </section>

      <section>
        <Typography.Title level={5}>¿Cómo funciona el buscador?</Typography.Title>
        <HR/>
        <Typography.Paragraph>
                    El buscador realiza una búsqueda por todos los campos de la tabla que sean de tipo texto y
                    numéricos. Cada búsqueda se realiza enviando una consulta directa a la base de datos y no se realiza
                    sobre los datos que se encuentran en la tabla.<br/><br/>

          <i>NOTA:</i> Algunas veces podrá observar que hay columnas de la tabla que poseen un
                    ícono <i className="ri-question-fill"/> al costado derecho del nombre de la columna. Esto es una
                    ayuda para que usted sepa como se debe ingresar el valor en el campo de búsqueda para dicho campo.
                    Para ver su contenido debe simplemente acercar el puntero sobre el ícono.
        </Typography.Paragraph>
      </section>

      <section>
        <Typography.Title level={5}>¿Cómo funciona el ordenamiento?</Typography.Title>
        <HR/>
        <Typography.Paragraph>
                    Cuando desee ordenar los resultados de la tabla por un campo en específico, debe hacer click sobre
                    el nombre de la columna en la tabla de datos.<br/><br/>

                    Para cambiar el sentido del ordenamiento (ascendente o descendente) se debe hacer click nuevamente
                    en la columna.
        </Typography.Paragraph>
      </section>

      <section>
        <Typography.Title level={5}>Botón de acciones</Typography.Title>
        <HR/>
        <Typography.Paragraph>
                    Este botón <i className="ri-command-line"/> que se encuentra al costado derecho del campo de
                    búsqueda, abrirá un panel lateral con 3 posibles botones que veremos
                    a continuación.<br/><br/>

          <i>NOTA:</i> El botón de acciones empezará a parpadear de color amarillo cuando se seleccione uno o
                    más elementos de la tabla. Esto indica que hay acciones que se pueden realizar sobre los elementos
                    seleccionados.
        </Typography.Paragraph>

        <Typography.Text className="font-semibold">Crear nuevo elemento:</Typography.Text>
        <Typography.Paragraph>
                    Este botón permite crear un nuevo elemento en la tabla. Al hacer click en él, se abrirá un panel
                    flotante con un formulario para crear un nuevo elemento. Una vez que se haya completado el
                    formulario, se debe hacer click en el botón <code>Guardar</code> para guardar el nuevo elemento en
                    la base de datos.
        </Typography.Paragraph>

        <Typography.Text className="font-semibold">Actualizar selección:</Typography.Text>
        <Typography.Paragraph>
                    Cuando se selecciona un solo elemento de la tabla, este botón se habilita y permite actualizar
                    el registro. Al hacer click en él, se abrirá un panel flotante con un formulario para actualizar
                    el elemento seleccionado. Una vez que se haya completado el formulario, se debe hacer click en el
                    botón <code>Guardar</code> para guardar los cambios en la base de datos.
        </Typography.Paragraph>

        <Typography.Text className="font-semibold">Eliminar elemento(s):</Typography.Text>
        <Typography.Paragraph>
                    Cuando se selecciona uno o más elementos de la tabla, este botón se habilita y permite eliminar
                    el/los registro/s. Al hacer click en él, se abrirá un mensaje de confirmación. Al confirmar la
                    eliminación, se eliminará el/los registro/s de la base de datos.
        </Typography.Paragraph>
      </section>
    </div>
  )
}
