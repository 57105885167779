import { apiErrorWrapper } from '@/api/common/apiErrorWrapper.ts'
import { client } from '@/api/common/client.ts'
import { QueryParams } from '@/api/common/types.ts'
import { apiErrorMapper } from '@/api/common/utils/apiErrorMapper.ts'

type GetMappingProps<TRequest> = {
  path: string
  params: TRequest
}

export async function getMapping<TResponse, TRequest extends QueryParams>({
  path,
  params,
}: GetMappingProps<TRequest>) {
  const { data, error } = await apiErrorWrapper(
    async () => await client.get(path, {
      params,
    }),
  )
  if (error) {
    apiErrorMapper(error)
  }
  return data as TResponse
}
