import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { CreateProductsTemplateFormProps } from '@/presentation/productsTemplates/forms/types.ts'
import { useCreateProductsTemplate } from '@/presentation/productsTemplates/hooks/useCreateProductsTemplate.ts'

type CreateProductsTemplateFormFields = {
  description: string
}

export const CreateProductsTemplateForm: FC<CreateProductsTemplateFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateProductsTemplateForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateProductsTemplate()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const onSubmit = async (values: CreateProductsTemplateFormFields) => {
    if (isLoading) {
      return
    }

    const response = await submit({
      description: values.description,
    })
    if (response) {
      onFinish?.()
    }
  }

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 4 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos de la plantilla para mantención">
        <Form.Item
          label="Descripción"
          name="description"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
