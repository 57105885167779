import { Button, DatePicker, Form, Input } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useMemo } from 'react'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { ClientSelector } from '@/presentation/clients/components/ClientSelector.tsx'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { RentalTypeSelector } from '@/presentation/rentals/components/RentalTypeSelector.tsx'
import { CreateRentalFormProps } from '@/presentation/rentals/forms/types.ts'
import { useCreateRental } from '@/presentation/rentals/hooks/useCreateRental.ts'
import { UserSelector } from '@/presentation/users/components/UserSelector.tsx'

type CreateRentalFormFields = {
  type: RentalTypeValue,
  client: string,
  externalCompany: string,
  building: string,
  constructionManager: string,
  dates: [Dayjs, Dayjs],
  address: string,
}

export const CreateRentalForm: FC<CreateRentalFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateRentalForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateRental()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm()
  const type = Form.useWatch('type', form)

  const isExternalType = useMemo(() => type === RentalTypeValue.EXTERNAL, [ type ])

  const onSubmit = async (values: CreateRentalFormFields) => {
    if (isLoading) {
      return
    }

    const cloneValues = {
      ...values,
      startDate : values['dates'][0],
      endDate   : values['dates'][1],
    }

    const response = await submit({
      type                : cloneValues.type,
      client              : cloneValues.client,
      externalCompany     : cloneValues.externalCompany,
      building            : cloneValues.building,
      constructionManager : cloneValues.constructionManager,
      address             : cloneValues.address,
      startDate           : dayjs(cloneValues.startDate).format(),
      endDate             : dayjs(cloneValues.endDate).format(),
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del arriendo">
        <Form.Item
          label="Tipo de arriendo"
          tooltip={(
            <div className="flex flex-col text-white">
              <span>{RentalType.fromString(RentalTypeValue.INTERNAL).label()}: maquinaria de la empresa</span>
              <span>{RentalType.fromString(RentalTypeValue.EXTERNAL).label()}: maquinaria de terceros</span>
            </div>
          )}
          name="type"
          rules={[{ required: true }]}
        >
          <RentalTypeSelector/>
        </Form.Item>

        <Form.Item
          label="Cliente"
          name="client"
          rules={[{ required: true }]}
        >
          <ClientSelector/>
        </Form.Item>
      </FormSection>

      <FormSection title="Datos de la obra">
        {isExternalType && (
          <Form.Item
            label="Compañía externa"
            name="externalCompany"
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>
        )}

        <Form.Item
          label="Obra"
          name="building"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>

        {isExternalType && (
          <Form.Item
            label="Jefe de obra"
            name="constructionManager"
            rules={[{ required: true }]}
          >
            <UserSelector role={UserRoleValue.CONSTRUCTION_MANAGER}/>
          </Form.Item>
        )}

        <Form.Item
          label="Fechas"
          name="dates"
          rules={[{ required: true }]}
        >
          <DatePicker.RangePicker inputReadOnly format="DD-MM-YYYY" className="w-full"/>
        </Form.Item>

        <Form.Item
          label="Dirección"
          name="address"
          rules={[{ required: true }]}
        >
          <Input/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Crear</Button>
      </div>
    </Form>
  )
}
