import { useContext, useEffect } from 'react'
import { UseCrudProps } from '@/presentation/common/hooks/types.ts'
import { MessageContext } from '@/presentation/common/store/apiContext/MessageContext.tsx'

export const useCrud = ({ messageApiKey, message, isLoading, isError, isSuccess }: UseCrudProps) => {
  const messageApi = useContext(MessageContext)

  useEffect(() => {
    if (isLoading) {
      messageApi?.open({
        key      : messageApiKey,
        type     : 'loading',
        content  : message,
        duration : 0,
      })
    }

    if (isError) {
      messageApi?.open({
        key      : messageApiKey,
        type     : 'error',
        content  : message,
        duration : 3,
      })
    }

    if (isSuccess) {
      messageApi?.open({
        key      : messageApiKey,
        type     : 'success',
        content  : message,
        duration : 3,
      })
    }
  }, [ messageApi, isLoading, isSuccess, isError ])

  return {}
}
