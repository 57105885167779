import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryMaintenanceProductsRequest } from '@/api/maintenanceProducts/request.types.ts'
import { QueryMaintenanceProductsResponse } from '@/api/maintenanceProducts/response.types.ts'
import { useMaintenanceProductsApiClient } from '@/api/maintenanceProducts/useMaintenanceProductsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryMaintenanceProductsForTableResult = PaginatedData<{
  id: string
  product: {
    id: string
    name: string
    description: string | null
    sku: string | null
  }
  quantity: number
  inventory: {
    id: string
    name: string
  }
}>

export const useQueryMaintenanceProductsForTable = (maintenanceId: string | null) => {
  const repository = useMaintenanceProductsApiClient()
  const queryItemsHook = useQueryItems<QueryMaintenanceProductsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const [ data, setData ] = useState<QueryMaintenanceProductsForTableResult>()

  const refresh = useCallback(async (maintenanceId: string | null) => {
    const response = await queryItemsHook.refresh(undefined, { maintenanceId })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<QueryMaintenanceProductsRequest>> = {}) => {
    if (!maintenanceId) {
      return
    }
    const response = await queryItemsHook.query({
      ...options,
      pathParams: {
        maintenanceId,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryMaintenanceProductsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id      : item.id.toString(),
        product : {
          id          : item.product.id,
          name        : item.product.name,
          description : item.product.description ?? null,
          sku         : item.product.sku ?? null,
        },
        quantity  : item.quantity,
        inventory : {
          id   : item.inventory.id,
          name : item.inventory.name,
        },
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
