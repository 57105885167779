import { Button } from 'antd'
import { FC } from 'react'
import { DeleteClientsFormProps } from '@/presentation/clients/forms/types.ts'
import { useDeleteClients } from '@/presentation/clients/hooks/useDeleteClients.ts'
import { QueryClientsForTableResult } from '@/presentation/clients/hooks/useQueryClientsForTable.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

export const DeleteClientsForm: FC<DeleteClientsFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteClientsForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteClients()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = Object.values(selectionNodes).map((node: RowNode<QueryClientsForTableResult['items'][0]>) => node.data?.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.call(this)
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los clientes seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}
          disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
