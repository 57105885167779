import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useState } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'
import {
  QueryMachineryForSelectorResult,
  useQueryMachineryForSelector,
} from '@/presentation/machinery/hooks/useQueryMachineryForSelector.ts'

type Props = SelectProps & {
  onCustomSelect?: (machinery?: QueryMachineryForSelectorResult) => void,
  excludePickups?: boolean,
}

export const MachinerySelector: FC<Props> = ({ onCustomSelect, excludePickups, ...props }) => {
  const { data, query, hasMorePages, lastPage, isLoading } = useQueryMachineryForSelector()
  const [ lastSearch, setLastSearch ] = useState<string>()

  const limit = 100

  useEffect(() => {
    handleOnSelect(props.value as string)
  }, [ props.value, data ])

  useEffect(() => {
    query({
      params: {
        page: 1,
        limit,
        excludePickups,
      },
    })
  }, [])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
        excludePickups,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
            excludePickups,
          },
        })
      }
    }
  }

  const handleOnSelect = (value: string) => {
    const machinery = data?.find((item) => item.id === value)
    onCustomSelect?.(machinery)
  }

  return (
    <Select
      placeholder="Seleccione una maquinaria"
      allowClear
      showSearch
      onSearch={onSearch}
      onPopupScroll={onScroll}
      onSelect={handleOnSelect}
      filterOption={false}
      loading={isLoading}
      {...props}
      options={data.map((machinery) => ({
        label : <MachinerySelectorOption {...machinery} />,
        value : machinery.id,
      }))}
    />
  )
}

export const MachinerySelectorOption: FC<QueryMachineryForSelectorResult> = ({ code, serialNumber, brand, model, type }) => {
  return (
    <div className="!leading-none flex flex-nowrap items-center gap-1.5 overflow-hidden">
      {code && (
        <div className="py-1 px-1.5 bg-blue-100 rounded-md inline-block font-mono font-normal text-xs">{code}</div>
      )}

      {serialNumber && (
        <span className="font-medium font-mono text-sm">{serialNumber}</span>
      )}

      {brand && (
        <span className="font-normal">{brand}</span>
      )}

      {model && (
        <span className="font-normal">{model}</span>
      )}

      {type && (
        <span className="font-normal">{MachineryType.fromString(type).label()}</span>
      )}
    </div>
  )
}
