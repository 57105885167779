import { getMapping } from '@/api/common/getMapping.ts'
import { postFileMapping } from '@/api/common/postFileMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import { GeneratePayStateRequest, PreviewPayStateRequest } from '@/api/payStates/request.types.ts'
import { GeneratePayStateResponse, PreviewPayStatesResponse } from '@/api/payStates/response.types.ts'

export type PayStatesApiClient = {
  preview: (options: QueryOptions<void, PreviewPayStateRequest>) => Promise<PreviewPayStatesResponse>
  generate: (req: GeneratePayStateRequest) => Promise<GeneratePayStateResponse>
}

export const usePayStatesApiClient = (): PayStatesApiClient => ({
  preview: async (options: QueryOptions<void, PreviewPayStateRequest>) => await getMapping<PreviewPayStatesResponse, QueryParams>({
    path   : '/pay-states',
    params : options.params,
  }),

  generate: async (req: GeneratePayStateRequest) => await postFileMapping<GeneratePayStateResponse, GeneratePayStateRequest>({
    path : '/pay-states',
    body : req,
  }),
})
