import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAuthApiClient } from '@/api/auth/useAuthApiClient.ts'
import { ApiError } from '@/api/common/apiError.ts'
import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'
import { UiStateStatus, useUiStatus } from '@/presentation/common/hooks/useUiStatus.ts'
import { routes } from '@/presentation/common/router/routes.ts'

const initialTimeout = 5
let timer = initialTimeout

export const useRecoverPassword = () => {
  const auth = useAuthApiClient()
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const status = useUiStatus()
  const [ goToLoginTimeout, setGoToLoginTimeout ] = useState(initialTimeout)
  const [ isGoToLoginTimeoutRunning, setIsGoToLoginTimeoutRunning ] = useState(false)

  const runToLoginTimeout = () => {
    setIsGoToLoginTimeoutRunning(true)

    const interval = setInterval(() => {
      if (timer <= 0) {
        timer = initialTimeout
        clearInterval(interval)
        setGoToLoginTimeout(initialTimeout)
        setIsGoToLoginTimeoutRunning(false)
        goToLogin()
      }
      else {
        timer--
        setGoToLoginTimeout(timer)
      }
    }, 1000)
  }

  const goToLogin = () => navigate(routes.login)

  const recoverPassword = async (password: string) => {
    const token = searchParams.get('token')

    if (!token) {
      status.setMessage('El token de recuperación de contraseña no es válido')
      status.setStatus(UiStateStatus.ERROR)
      return
    }

    try {
      status.setStatus(UiStateStatus.LOADING)
      await auth.resetPassword({
        query: {
          token,
        },
        body: {
          password,
        },
      })
      status.setStatus(UiStateStatus.SUCCESS)
      runToLoginTimeout()
    }
    catch (error) {
      const apiError = error as ApiErrorResponse

      if (apiError.internalStatusCode === ApiError.TENANT_IS_NOT_ACTIVE) {
        status.setMessage('Su empresa no se encuentra activa, por favor comuníquese con el administrador')
      }
      else if (apiError.internalStatusCode === ApiError.INVALID_REQUEST_SCHEMA) {
        status.setMessage('La contraseña ingresada no es válida')
      }
      else if (apiError.internalStatusCode === ApiError.ENTITY_NOT_FOUND && apiError.meta?.entity === 'TOKEN') {
        status.setMessage('El token de recuperación de contraseña no es válido')
      }
      else {
        status.setMessage('Ocurrió un error al intentar cambiar su contraseña, por favor intente nuevamente')
        console.error(error)
      }

      status.setStatus(UiStateStatus.ERROR)
    }
  }

  return {
    message   : status.message,
    isLoading : status.isLoading,
    isSuccess : status.isSuccess,
    isError   : status.isError,
    goToLogin,
    recoverPassword,
    goToLoginTimeout,
    isGoToLoginTimeoutRunning,
  }
}
