import { useEffect, useMemo, useState } from 'react'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { onlyDateFormatter } from '@/presentation/common/utils/dateFormatter.ts'
import { RentalsActions } from '@/presentation/rentals/forms/RentalsActions.tsx'
import { useQueryRentalsForTable } from '@/presentation/rentals/hooks/useQueryRentalsForTable.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

export const RentalsTable = () => {
  const { isTabletAndBelow } = useMQ()
  const { selectedTab, setSelectedRentalId, setSelectedRentalType } = useRentalsMaintainerStore()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryRentalsForTable()

  // ayuda a mantener la selección cuando se cambia de tab, cuando el tab vuelva a ser rentals, se configura la selección del mantenedor con esta variable
  const [ currentSelection, setCurrentSelection ] = useState<RowNode[]>([])

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'type',
      headerName              : 'Tipo',
      comparator              : stringComparator,
      formatValue             : ({ value }: { value: RentalTypeValue }) => RentalType.fromString(value).label() || '',
      headerCheckboxSelection : true,
      checkboxSelection       : true,
    },
    {
      field       : 'client',
      headerName  : 'Cliente',
      comparator  : stringComparator,
      formatValue : ({ value }) => value.name,
    },
    { field: 'externalCompany', headerName: 'Compañía externa', comparator: stringComparator },
    { field: 'building', headerName: 'Obra', comparator: stringComparator },
    {
      field       : 'constructionManager',
      headerName  : 'Jefe de Obra',
      comparator  : stringComparator,
      formatValue : ({ value }) => value?.fullName ?? '',
    },
    {
      field       : 'startDate',
      headerName  : 'Fecha inicio',
      comparator  : stringComparator,
      formatValue : ({ value }) => onlyDateFormatter(value),
    },
    {
      field       : 'endDate',
      headerName  : 'Fecha término',
      comparator  : stringComparator,
      formatValue : ({ value }) => onlyDateFormatter(value),
    },
    {
      field        : 'id',
      headerName   : 'ID',
      cellRenderer : MTCellUidV2,
      comparator   : stringComparator,
    },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => {
      setCurrentSelection(selectedRows)
      setSelectionV2(selectedRows)
      setSelectedRentalId(selectedRows.length === 1 ? selectedRows[0]?.data.id : null)
      setSelectedRentalType(selectedRows.length === 1 ? selectedRows[0]?.data.type : null)
    },
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <RentalsActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    if (selectedTab === 'rentals') {
      setActionsSlot(actionsComponent)
    }
  }, [ actionsComponent, selectedTab ])

  // first load
  useEffect(() => {
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // cuando cambia el tab
  useEffect(() => {
    if (selectedTab === 'rentals') {
      reset()
      setTitle('Arriendos')
      setActionsSlot(actionsComponent)
      setSelectionV2(currentSelection)
    }
  }, [ selectedTab ])

  // when search changes update query
  useEffect(() => {
    if (selectedTab === 'rentals') {
      query({
        params: {
          search,
          page: 1,
        },
      })
    }
  }, [ search ])

  return (
    <div style={{
      display: selectedTab === 'rentals' ? 'block' : 'none',
    }}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </div>
  )
}
