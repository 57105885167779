const formatter = (value: any) => {
  const price = parseFloat(value)

  if (!isNaN(price)) {
    return new Intl.NumberFormat('de-DE', {
      style                 : 'currency',
      currency              : 'EUR',
      maximumFractionDigits : 0,
      minimumFractionDigits : 0,
    }).format(parseFloat(value)).replace(/€/g, '').trim()
  }

  return ''
}

const parser = (value: any) => {
  return `${value}`.replace(/[^0-9.,]/g, '').replace(/\./g, '').replace(/,/g, '.')
}

export const priceFormatter = {
  formatter,
  parser,
  decimalSeparator : ',',
  addonBefore      : '$',
  addonAfter       : 'CLP',
  step             : 1000,
  min              : 0,
}
