import { create } from 'zustand'

export interface EppsMaintainerState {
  selectedTab: 'epps' | 'products',
  selectedEppId: string | null,
  isSigned: boolean,

  setSelectedTab: (tab: 'epps' | 'products') => void,
  setSelectedEppId: (id: string | null) => void,
  setIsSigned: (value: boolean) => void,
}

export const useEppsMaintainerStore = create<EppsMaintainerState>()(
  (set, _get) => ({
    selectedTab   : 'epps',
    selectedEppId : null,
    isSigned      : false,

    setSelectedTab   : (tab: 'epps' | 'products') => set({ selectedTab: tab }),
    setSelectedEppId : (id: string | null) => set({ selectedEppId: id }),
    setIsSigned      : (value: boolean) => set({ isSigned: value }),
  }),
)
