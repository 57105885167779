import { Button, Form, Input } from 'antd'
import { FC } from 'react'
import { ContactInput } from '@/presentation/clients/components/ContactInput.tsx'
import { UpdateClientFormProps } from '@/presentation/clients/forms/types.ts'
import { QueryClientsForTableResult } from '@/presentation/clients/hooks/useQueryClientsForTable.ts'
import { useUpdateClient } from '@/presentation/clients/hooks/useUpdateClient.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { RutInput } from '@/presentation/common/components/RutInput.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { rutValidator } from '@/presentation/common/utils/form/validators.ts'

type UpdateClientFormFields = Partial<{
  name: string,
  paymentCondition: string,
  contacts: string[],
  billing: {
    dni: string,
    name: string,
    activity: string,
    address: string,
    phone: string,
  },
}>

export const UpdateClientForm: FC<UpdateClientFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateClientForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateClient()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryClientsForTableResult['items'][0]> = Object.values(selectionNodes)[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateClientFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values)

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        ...editedValues as UpdateClientFormFields,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del cliente">
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Condición de pago"
              name="paymentCondition"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <FormSection title="Receptores de Estado de Pago">
            <Form.List name="contacts">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Form.Item
                      {...field}
                      label='Correo electrónico'
                      rules={[{ required: true }]}
                    >
                      <ContactInput field={field} onRemove={remove}/>
                    </Form.Item>
                  ))}

                  <div className="flex mb-5">
                    <Button size="small" block onClick={() => add('')}>Agregar correo de
                                            recepción</Button>
                  </div>
                </>
              )}
            </Form.List>
          </FormSection>

          <FormSection title="Datos de facturación">
            <Form.Item
              label="RUT"
              name={[ 'billing', 'dni' ]}
              rules={[
                { required: true },
                { validator: rutValidator },
              ]}
            >
              <RutInput/>
            </Form.Item>

            <Form.Item
              label="Nombre"
              name={[ 'billing', 'name' ]}
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Giro"
              name={[ 'billing', 'activity' ]}
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Dirección"
              name={[ 'billing', 'address' ]}
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Teléfono"
              name={[ 'billing', 'phone' ]}
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
