import { apiErrorWrapper } from '@/api/common/apiErrorWrapper.ts'
import { client } from '@/api/common/client.ts'
import { apiErrorMapper } from '@/api/common/utils/apiErrorMapper.ts'

type GetFileMappingProps<TRequest> = {
  path: string
  params?: TRequest
}

export async function getFileMapping<TResponse, TRequest>({
  path,
  params,
}: GetFileMappingProps<TRequest>) {
  const { data, error } = await apiErrorWrapper(
    async () => await client.get(path, {
      params,
      responseType: 'blob',
    }),
  )
  if (error) {
    apiErrorMapper(error)
  }
  return data as TResponse
}
