import { Button, Form } from 'antd'
import { FC, useState } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { MachinerySelector } from '@/presentation/machinery/components/MachinerySelector.tsx'
import { QueryMachineryForSelectorResult } from '@/presentation/machinery/hooks/useQueryMachineryForSelector.ts'
import { RentalMachineryConditionsFormChunk } from '@/presentation/rentals/forms/RentalMachineryConditionsFormChunk.tsx'
import { UpdateRentalInternalMachineryFormProps } from '@/presentation/rentals/forms/types.ts'
import { QueryRentalsMachineryForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsMachineryForTable.ts'
import { useUpdateRentalMachinery } from '@/presentation/rentals/hooks/useUpdateRentalMachinery.ts'
import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'
import { UserSelector } from '@/presentation/users/components/UserSelector.tsx'

type UpdateRentalInternalMachineryFormFields = Partial<{
  machinery: string,
  operator: string,
  rentalCondition: {
    type: RentalConditionTypeValue,
    minHours: number | undefined,
    amountPerHour: number | undefined,
    amountPerDay: number | undefined,
    load: string | undefined,
    loadOrigin: string | undefined,
    amountPerTravel: number | undefined,
  },
}>

export const UpdateRentalInternalMachineryForm: FC<UpdateRentalInternalMachineryFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateRentalInternalMachineryForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateRentalMachinery()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedRentalId, selectedRentalType } = useRentalsMaintainerStore()

  const selections = useMaintainerStore(state => state.selectionV2)
  const selectedNode: RowNode<QueryRentalsMachineryForTableResult['items'][0]> = selections[0]

  const initialValues = { ...({
    machinery       : selectedNode?.data.machinery.id,
    operator        : selectedNode?.data.operator?.id,
    rentalCondition : selectedNode?.data.rentalCondition,
  } || {}) }

  const [ form ] = Form.useForm()

  const [ selectedMachinery, setSelectedMachinery ] = useState<QueryMachineryForSelectorResult>()
  const machineryTypeValue = selectedMachinery?.type
  const machineryType = machineryTypeValue ? MachineryType.fromString(machineryTypeValue) : undefined

  const onSubmit = async (values: UpdateRentalInternalMachineryFormFields) => {
    if (isLoading || !selectedRentalId || !selectedRentalType) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateRentalInternalMachineryFormFields

    const response = await submit({
      query: {
        id       : selectedNode?.data.id,
        rentalId : selectedRentalId,
      },
      body: {
        machinery       : editedValues.machinery,
        operator        : editedValues.operator,
        rentalCondition : editedValues.rentalCondition ? {
          type            : editedValues.rentalCondition.type,
          minHours        : editedValues.rentalCondition.minHours ?? null,
          amountPerHour   : editedValues.rentalCondition.amountPerHour ?? null,
          amountPerDay    : editedValues.rentalCondition.amountPerDay ?? null,
          load            : editedValues.rentalCondition.load ?? null,
          loadOrigin      : editedValues.rentalCondition.loadOrigin ?? null,
          amountPerTravel : editedValues.rentalCondition.amountPerTravel ?? null,
        } : undefined,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 5 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Maquinaria arrendada">
            <Form.Item
              name="machinery"
              label='Maquinaria'
              rules={[{ required: true }]}
            >
              <MachinerySelector onCustomSelect={setSelectedMachinery} excludePickups/>
            </Form.Item>

            <Form.Item
              name="operator"
              label='Operador'
              rules={[{ required: true }]}
            >
              <UserSelector role={UserRoleValue.MACHINE_OPERATOR}/>
            </Form.Item>

            <RentalMachineryConditionsFormChunk form={form} machineryType={machineryType}/>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
