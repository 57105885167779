import { UpdateEppRequest } from '@/api/epps/request.types.ts'
import { UpdateEppResponse } from '@/api/epps/response.types.ts'
import { useEppsApiClient } from '@/api/epps/useEppsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateEpp = () => {
  const repository = useEppsApiClient()
  const updateItem = useUpdateItem<UpdateEppRequest, UpdateEppResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el EPP',
      alreadyExists : 'Ya existe un EPP',
      success       : 'EPP actualizado con éxito',
      loading       : 'Actualizando EPP',
    },
  })

  return {
    ...updateItem,
  }
}
