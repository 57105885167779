import { apiErrorWrapper } from '@/api/common/apiErrorWrapper.ts'
import { client } from '@/api/common/client.ts'
import { apiErrorMapper } from '@/api/common/utils/apiErrorMapper.ts'

type PostFileMappingProps<TRequest> = {
  path: string
  body?: TRequest
}

export async function postFileMapping<TResponse, TRequest>({
  path,
  body,
}: PostFileMappingProps<TRequest>) {
  const { data, error } = await apiErrorWrapper(
    async () => await client.post(path, { ...body }, {
      responseType: 'blob',
    }),
  )
  if (error) {
    apiErrorMapper(error)
  }
  return data as TResponse
}
