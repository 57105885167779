import { UpdateRentalMachineryRequest } from '@/api/rentalsMachinery/request.types.ts'
import { UpdateRentalMachineryResponse } from '@/api/rentalsMachinery/response.types.ts'
import { useRentalsMachineryApiClient } from '@/api/rentalsMachinery/useRentalsMachineryApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateRentalMachinery = () => {
  const repository = useRentalsMachineryApiClient()
  const updateItem = useUpdateItem<UpdateRentalMachineryRequest, UpdateRentalMachineryResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar la maquinaria',
      alreadyExists : 'Ya existe un registro con esta maquinaria y operador',
      success       : 'Maquinaria actualizada con éxito',
      loading       : 'Actualizando maquinaria',
    },
  })

  return {
    ...updateItem,
  }
}
