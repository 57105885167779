import { QueryOptions } from '@/api/common/types.ts'
import { QueryInventoriesResponse } from '@/api/inventories/response.types.ts'
import { useInventoriesApiClient } from '@/api/inventories/useInventoriesApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

type QueryInventoryOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryInventoriesForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    name: string
    description: string | null
    location: {
      address: string | null
      city: string | null
      country: string | null
    }
    contact: {
      name: string | null
      email: string | null
      phone: string | null
    }
  }
}

export const useQueryInventoriesForSelector = () => {
  const repository = useInventoriesApiClient()
  const queryItemsHook = useQueryItems<QueryInventoriesResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron espacios',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryInventoriesForSelectorResult>()

  const query = async (options: QueryInventoryOptions = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryInventoryOptions, response?: QueryInventoriesResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        value : item.id.toString(),
        label : item.name,
        data  : {
          id          : item.id.toString(),
          name        : item.name,
          description : item.description,
          location    : {
            address : item.location.address,
            city    : item.location.city,
            country : item.location.country,
          },
          contact: {
            name  : item.contact.name,
            email : item.contact.email,
            phone : item.contact.phone,
          },
        },
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
