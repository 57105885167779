import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useState } from 'react'
import { UserRole, UserRoleValue } from '@/domain/models/userRole.ts'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'
import { fullName } from '@/presentation/common/utils/fullName.ts'
import { useQueryUserForSelector } from '@/presentation/users/hooks/useQueryUserForSelector.ts'

type Props = SelectProps & {
  role?: UserRoleValue
}

export const UserSelector: FC<Props> = ({ role, ...props }) => {
  const { data, query, isLoading, hasMorePages, lastPage } = useQueryUserForSelector(role)
  const [ lastSearch, setLastSearch ] = useState<string>()

  const limit = 100

  useEffect(() => {
    query({
      params: {
        page: 1,
        limit,
      },
    })
  }, [])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
          },
        })
      }
    }
  }
  return (
    <Select
      placeholder={role ? UserRole.fromString(role).label() : 'Seleccione un usuario'}
      showSearch
      onSearch={onSearch}
      onPopupScroll={onScroll}
      filterOption={false}
      {...props}
      options={data.map((manager) => ({
        label : fullName(manager.firstName, manager.lastName),
        value : manager.id,
      }))}
    />
  )
}
