import { UpdateInventoryProductStockRequest } from '@/api/inventoryProductsStocks/request.types.ts'
import { UpdateInventoryProductStockResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import {
  useInventoryProductsStocksApiClient,
} from '@/api/inventoryProductsStocks/useInventoryProductsStocksApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateInventoryStock = () => {
  const repository = useInventoryProductsStocksApiClient()
  const updateItem = useUpdateItem<UpdateInventoryProductStockRequest, UpdateInventoryProductStockResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el stock',
      alreadyExists : 'Ya existe un stock',
      success       : 'Stock actualizado con éxito',
      loading       : 'Actualizando stock',
    },
  })

  return {
    ...updateItem,
  }
}
