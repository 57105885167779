import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryProductsTemplateProductsResponse } from '@/api/productsTemplateProducts/response.types.ts'
import {
  useProductsTemplateProductsApiClient,
} from '@/api/productsTemplateProducts/useProductsTemplateProductsApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryProductsTemplateProductsForTableResult = PaginatedData<{
  id: string
  product: {
    id: string
    sku?: string | null
    name: string
  }
  inventory: {
    id: string
    name: string
  }
  quantity: number
}>

export const useQueryProductsTemplateProductsForTable = (productsTemplateId: string | null) => {
  const repository = useProductsTemplateProductsApiClient()
  const queryItemsHook = useQueryItems<QueryProductsTemplateProductsResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const [ data, setData ] = useState<QueryProductsTemplateProductsForTableResult>()

  const refresh = useCallback(async (productsTemplateId: string | null) => {
    const response = await queryItemsHook.refresh({ templateId: productsTemplateId })
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<QueryProductsTemplateProductsResponse>> = {}) => {
    if (!productsTemplateId) {
      return
    }
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        templateId: productsTemplateId,
      },
    })
    mapResponse(response)
  }

  const mapResponse = (response?: QueryProductsTemplateProductsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id      : item.id.toString(),
        product : item.product ? {
          id   : item.product.id,
          sku  : item.product.sku,
          name : item.product.name,
        } : {
          id   : 'no-product',
          sku  : null,
          name : 'no-product',
        },
        inventory: item.inventory ? {
          id   : item.inventory.id,
          name : item.inventory.name,
        } : {
          id   : 'no-inventory',
          name : 'no-inventory',
        },
        quantity: item.quantity,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
