import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  QueryInventoryProductsStocksRequest,
  UpdateInventoryProductStockRequest,
} from '@/api/inventoryProductsStocks/request.types.ts'
import {
  DownloadExcelResumeResponse,
  QueryInventoryProductsStocksResponse,
  UpdateInventoryProductStockResponse,
} from '@/api/inventoryProductsStocks/response.types.ts'

export type InventoryProductsStocksApiClient = {
  query: (options: QueryOptions<void, QueryInventoryProductsStocksRequest>) => Promise<QueryInventoryProductsStocksResponse>
  increment: (req: UpdateInventoryProductStockRequest) => Promise<UpdateInventoryProductStockResponse>
  decrease: (req: UpdateInventoryProductStockRequest) => Promise<UpdateInventoryProductStockResponse>
  update: (req: UpdateInventoryProductStockRequest) => Promise<UpdateInventoryProductStockResponse>
  downloadExcelResume: () => Promise<DownloadExcelResumeResponse>
}

export const useInventoryProductsStocksApiClient = (): InventoryProductsStocksApiClient => ({
  query: async (options: QueryOptions<void, QueryInventoryProductsStocksRequest>) => await getMapping<QueryInventoryProductsStocksResponse, QueryParams>({
    path   : '/inventory-products-stocks',
    params : options.params,
  }),

  increment: async (body: UpdateInventoryProductStockRequest) => await putMapping<UpdateInventoryProductStockResponse, UpdateInventoryProductStockRequest>({
    path : '/inventory-products-stocks/increase',
    body : body,
  }),

  decrease: async (body: UpdateInventoryProductStockRequest) => await putMapping<UpdateInventoryProductStockResponse, UpdateInventoryProductStockRequest>({
    path : '/inventory-products-stocks/decrease',
    body : body,
  }),

  update: async (body: UpdateInventoryProductStockRequest) => await putMapping<UpdateInventoryProductStockResponse, UpdateInventoryProductStockRequest>({
    path : '/inventory-products-stocks',
    body : body,
  }),

  downloadExcelResume: async () => await getFileMapping<DownloadExcelResumeResponse, void>({
    path: '/inventory-products-stocks/excel',
  }),
})
