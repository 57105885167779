import { UpdateTenantSubscriptionRequest } from '@/api/tenants/request.types.ts'
import { UpdateTenantSubscriptionResponse } from '@/api/tenants/response.types.ts'
import { useTenantsApiClient } from '@/api/tenants/useTenantsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateSubscription = () => {
  const repository = useTenantsApiClient()
  const updateItem = useUpdateItem<UpdateTenantSubscriptionRequest, UpdateTenantSubscriptionResponse>({
    callable : repository.updateSubscription,
    messages : {
      default : 'No se pudo actualizar la suscripción',
      success : 'Suscripción actualizada con éxito',
      loading : 'Actualizando suscripción',
    },
  })

  return {
    ...updateItem,
  }
}
