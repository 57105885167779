import { Button, DatePicker, Form, InputNumber, Switch } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useMemo, useState } from 'react'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { WorkingDayValue } from '@/domain/models/workingDay.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { SignPad } from '@/presentation/common/components/SignPad.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { FormLayout } from '@/presentation/common/layout/FormLayout.tsx'
import { hideDatesAfterToday } from '@/presentation/common/utils/hideDatesAfterToday.ts'
import { WorkingDaySelector } from '@/presentation/machineryUseRecords/components/WorkingDaySelector.tsx'
import { useCreateMachineryUseRecord } from '@/presentation/machineryUseRecords/hooks/useCreateMachineryUseRecord.ts'
import { RentalMachinerySelector } from '@/presentation/rentals/components/RentalMachinerySelector.tsx'
import {
  QueryRentalsMachineryForSelectorResult,
} from '@/presentation/rentals/hooks/useQueryRentalsMachineryForSelector.ts'

type CreateMachineryUseRecordFields = {
  date: Dayjs
  rental: string
  machinery: string
  startHourmeter?: number
  endHourmeter?: number
  totalHours?: number
  workingDay?: WorkingDayValue
  totalTravels?: number
  hadIncidences?: boolean
  observations?: string
  signature?: string
}

export const CreateMachineryUseRecord: FC = () => {
  const messageApiKey = 'CreateMachineryUseRecord-alert'
  const { submit, isLoading, isSuccess, isError, message } = useCreateMachineryUseRecord()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm()
  const date: Dayjs | undefined = Form.useWatch('date', form)
  const startHourmeter = Form.useWatch('startHourmeter', form)
  const endHourmeter = Form.useWatch('endHourmeter', form)
  const hadIncidences = Form.useWatch('hadIncidences', form)

  const [ rentalMachinery, setRentalMachinery ] = useState<QueryRentalsMachineryForSelectorResult['data']>()
  const [ isSignActive, setIsSignActive ] = useState<boolean>(false)

  const initialValues = useMemo(() => ({
    date: dayjs().local(),
  }), [])

  const rentalMachinerySelectorFilters = useMemo(() => ({
    date: date ? dayjs(date).local().format() : initialValues.date.format(),
  }), [ date ])

  const handleRentalMachineryChange = (value?: QueryRentalsMachineryForSelectorResult['data']) => {
    setRentalMachinery(value)
    form.setFieldValue('machinery', value?.id as string)
  }

  const onSubmit = async (values: CreateMachineryUseRecordFields) => {
    if (isLoading || !rentalMachinery?.rentalCondition?.type) {
      return
    }

    const response = await submit({
      date                     : dayjs(values.date).local().format(),
      machinery                : values.machinery,
      machineryConditionValues : {
        startHourmeter : values.startHourmeter ?? null,
        endHourmeter   : values.endHourmeter ?? null,
        totalHours     : values.totalHours ?? null,
        workingDay     : values.workingDay ?? null,
        totalTravels   : values.totalTravels ?? null,
      },
      hadIncidences : values.hadIncidences ?? false,
      observations  : values.observations ?? null,
      signature     : values.signature ?? null,
    })

    if (response) {
      form.resetFields()
      setIsSignActive(false)
      setRentalMachinery(undefined)
    }
  }

  useEffect(() => {
    form.setFieldValue('startHourmeter', rentalMachinery?.lastHourmeter ?? undefined)
  }, [ rentalMachinery ])

  useEffect(() => {
    if (endHourmeter < startHourmeter) {
      form.setFieldValue('endHourmeter', undefined)
    }

    const totalHours = endHourmeter - startHourmeter
    form.setFieldValue('totalHours', isNaN(totalHours) ? undefined : totalHours)
  }, [ startHourmeter, endHourmeter ])

  return (
    <FormLayout title="Registro de uso">
      <section className="app-card">
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 5 }}
          labelAlign="left"
          labelWrap
          colon={false}
          onFinish={onSubmit}
          initialValues={initialValues}
        >
          <Form.Item
            label="Fecha"
            name="date"
            rules={[{ required: true }]}
          >
            <DatePicker inputReadOnly className="w-full" format="DD-MM-YYYY" disabledDate={hideDatesAfterToday}/>
          </Form.Item>

          <Form.Item
            label="Maquinaria"
            name="machinery"
            rules={[{ required: true }]}
          >
            <RentalMachinerySelector filters={rentalMachinerySelectorFilters} onCustomSelect={handleRentalMachineryChange}/>
          </Form.Item>

          {rentalMachinery?.machinery && (
            <FormSection title="Registro de utilización">
              {rentalMachinery?.rentalCondition?.type === RentalConditionTypeValue.HOURS && (
                <>
                  <Form.Item
                    label="Horómetro inicial"
                    name="startHourmeter"
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={0}/>
                  </Form.Item>

                  <Form.Item
                    label="Horómetro final"
                    name="endHourmeter"
                    rules={[{ required: true }]}
                  >
                    <InputNumber min={startHourmeter}/>
                  </Form.Item>

                  <Form.Item
                    label="Total de horas"
                    name="totalHours"
                    rules={[{ required: false }]}
                  >
                    <InputNumber disabled={true}/>
                  </Form.Item>
                </>
              )}

              {rentalMachinery?.rentalCondition?.type === RentalConditionTypeValue.WORKING_DAY && (
                <Form.Item
                  label="Jornada"
                  name="workingDay"
                  rules={[{ required: true }]}
                >
                  <WorkingDaySelector/>
                </Form.Item>
              )}

              {(rentalMachinery?.rentalCondition?.type === RentalConditionTypeValue.TRAVELS ||
                rentalMachinery?.rentalCondition?.type === RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD ||
                rentalMachinery?.rentalCondition?.type === RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO) && (
                <Form.Item
                  label="Total de viajes"
                  name="totalTravels"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0}/>
                </Form.Item>
              )}
            </FormSection>
          )}

          <FormSection title="Observaciones y Firma">
            <Form.Item
              label="¿Ocurrió alguna incidencia?"
              name="hadIncidences"
              help="Si tuvo una incidencia marque esta casilla para que el administrador sea notificado. Luego deje sus observaciones."
              valuePropName="checked"
              rules={[{ required: false }]}
            >
              <Switch checkedChildren="Si" unCheckedChildren="No"/>
            </Form.Item>

            <Form.Item
              label="Observaciones"
              name="observations"
              help="Máximo 500 caracteres"
              rules={[{ required: hadIncidences }]}
            >
              <TextArea maxLength={500}/>
            </Form.Item>

            <Form.Item
              label={(
                <span className="flex flex-wrap gap-1.5 items-center">
                    Firma digital <Switch checked={isSignActive} onChange={setIsSignActive} checkedChildren="Habilitada" unCheckedChildren="Desactivada"/>
                </span>
              )}
              help="Al desactivar la firma digital, no significa que se elimina la firma, solo que no se podrá editar. Es decir, que este registro quedará como firmado. Si desea enviar el registro como no firmado, deberá eliminar la firma usando el botón dispuesto en la barra superiór."
              name="signature"
              rules={[{ required: false }]}
            >
              <SignPad disableUploadImage={true} disabled={!isSignActive}/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" htmlType="reset" disabled={isLoading}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Registrar uso</Button>
          </div>
        </Form>
      </section>
    </FormLayout>
  )
}
