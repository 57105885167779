import { Button, DatePicker, Form, Input } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import { FC, useMemo } from 'react'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'
import { ClientSelector } from '@/presentation/clients/components/ClientSelector.tsx'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { RentalTypeSelector } from '@/presentation/rentals/components/RentalTypeSelector.tsx'
import { UpdateRentalFormProps } from '@/presentation/rentals/forms/types.ts'
import { QueryRentalsForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsForTable.ts'
import { useUpdateRental } from '@/presentation/rentals/hooks/useUpdateRental.ts'
import { UserSelector } from '@/presentation/users/components/UserSelector.tsx'

dayjs.extend(weekday)
dayjs.extend(localeData)

type UpdateRentalFormValues = Partial<{
  type: RentalTypeValue,
  client: string,
  constructionManager: string,
  dates?: [Dayjs, Dayjs],
  address: string,
  building: string,
  externalCompany: string,
}>

export const UpdateRentalForm: FC<UpdateRentalFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateRentalForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateRental()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selection = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryRentalsForTableResult['items'][0]> = selection[0]

  const [ form ] = Form.useForm()
  const type = Form.useWatch('type', form)

  const isExternalType = useMemo(() => type === RentalTypeValue.EXTERNAL, [ type ])

  const initialValues = { ...({
    ...selectedNode?.data,
    client              : selectedNode?.data?.client?.id,
    constructionManager : selectedNode?.data?.constructionManager?.id,
    dates               : [
      dayjs.utc(selectedNode?.data?.startDate),
      dayjs.utc(selectedNode?.data?.endDate),
    ],
  } || {}) }

  const onSubmit = async (values: UpdateRentalFormValues) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateRentalFormValues

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        client              : editedValues.client,
        constructionManager : editedValues.constructionManager,
        address             : editedValues.address,
        building            : editedValues.building,
        externalCompany     : editedValues.externalCompany,
        startDate           : editedValues.dates?.[0] ? dayjs(editedValues.dates[0]).format() : undefined,
        endDate             : editedValues.dates?.[1] ? dayjs(editedValues.dates[1]).format() : undefined,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          form={form}
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del arriendo">
            <Form.Item
              label="Tipo de arriendo"
              tooltip={(
                <div className="flex flex-col text-white">
                  <span>{RentalType.fromString(RentalTypeValue.INTERNAL).label()}: maquinaria de la empresa</span>
                  <span>{RentalType.fromString(RentalTypeValue.EXTERNAL).label()}: maquinaria de terceros</span>
                </div>
              )}
              name="type"
              rules={[{ required: false }]}
            >
              <RentalTypeSelector disabled/>
            </Form.Item>

            <Form.Item
              label="Cliente"
              name="client"
              rules={[{ required: true }]}
            >
              <ClientSelector/>
            </Form.Item>
          </FormSection>

          <FormSection title="Datos de la obra">
            {isExternalType && (
              <Form.Item
                label="Compañía externa"
                name="externalCompany"
                rules={[{ required: true }]}
              >
                <Input/>
              </Form.Item>
            )}

            <Form.Item
              label="Obra"
              name="building"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            {isExternalType && (
              <Form.Item
                label="Jefe de obra"
                name="constructionManager"
                rules={[{ required: true }]}
              >
                <UserSelector role={UserRoleValue.CONSTRUCTION_MANAGER}/>
              </Form.Item>
            )}

            <Form.Item
              label="Fechas"
              name="dates"
              rules={[{ required: true }]}
            >
              <DatePicker.RangePicker inputReadOnly format="DD-MM-YYYY" className="w-full"/>
            </Form.Item>

            <Form.Item
              label="Dirección"
              name="address"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
