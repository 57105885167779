import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { UserRole } from '@/domain/models/userRole.ts'

const { Option } = Select

export const RoleSelector: FC<SelectProps> = (props) => {
  return (
    <Select
      placeholder="Seleccione un rol"
      {...props}
    >
      {UserRole.toEntries().map(([ key, role ]) => (
        <Option key={key} value={role.value}>{role.label()}</Option>
      ))}
    </Select>
  )
}
