import { UpdateInventoryRequest } from '@/api/inventories/request.types.ts'
import { UpdateInventoryResponse } from '@/api/inventories/response.types.ts'
import { useInventoriesApiClient } from '@/api/inventories/useInventoriesApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateInventory = () => {
  const repository = useInventoriesApiClient()
  const updateItem = useUpdateItem<UpdateInventoryRequest, UpdateInventoryResponse>({
    callable : repository.update,
    messages : {
      default       : 'No se pudo actualizar el espacio',
      alreadyExists : 'Ya existe un espacio con ese nombre',
      success       : 'Espacio actualizado con éxito',
      loading       : 'Actualizando espacio',
    },
  })

  return {
    ...updateItem,
  }
}
