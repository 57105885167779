import { Button } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { DeleteProductsTemplatesFormProps } from '@/presentation/productsTemplates/forms/types.ts'
import { useDeleteProductsTemplates } from '@/presentation/productsTemplates/hooks/useDeleteProductsTemplates.ts'
import {
  QueryProductsTemplatesForTableResult,
} from '@/presentation/productsTemplates/hooks/useQueryProductsTemplatesForTable.ts'

export const DeleteProductsTemplatesForm: FC<DeleteProductsTemplatesFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DeleteProductsTemplatesForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useDeleteProductsTemplates()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const ids = Object.values(selectionNodes).map((node: RowNode<QueryProductsTemplatesForTableResult['items'][0]>) => node.data?.id)

  const onConfirmDelete = async () => {
    if (isLoading) {
      return
    }

    await submit({ ids })
    onFinish?.()
  }

  return (
    <>
      <p className="text-center mb-6">
                Está apunto de eliminar los plantillas para mantención seleccionados ({ids.length}).<br/>
                ¿Desea continuar con esta operación?
      </p>
      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" type="primary" onClick={onCancel}>Cancelar</Button>
        <Button size="small" type="primary" danger onClick={onConfirmDelete}
          disabled={isLoading}>Eliminar</Button>
      </div>
    </>
  )
}
