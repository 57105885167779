import { Typography } from 'antd'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { QueryRentalsMachineryForTableResult } from '@/presentation/rentals/hooks/useQueryRentalsMachineryForTable.ts'

type Props = {
  cellNode: CellNode
  node: RowNode<MTCellRentalMachineryData>
  column: DataGridColumnDefs
  api: DataGridApi
  className?: string
}

export type MTCellRentalMachineryData = Pick<QueryRentalsMachineryForTableResult['items'][0], 'rentalType' | 'machinery'>

export const MTCellRentalMachinery : FC<Props> = ({ cellNode, node, column, api }) => {
  const data = node.data
  const machinery = data.machinery as MTCellRentalMachineryData['machinery']
  const rentalType = data.rentalType

  const tableDescription = rentalType === RentalTypeValue.INTERNAL ?
    `${MachineryType.fromString(machinery.type).label()} ${machinery.brand} ${machinery.model} (${machinery.serialNumber})`.trim() :
    `${machinery.type ? MachineryType.fromString(machinery.type).label() : ''} ${machinery.description ?? ''}`.trim()

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      {rentalType === RentalTypeValue.INTERNAL && (
        <div className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[300px]">
          <section className="flex flex-col flex-1 overflow-hidden">
            <div className="flex flex-nowrap items-baseline gap-2">
              {data.machinery.code && (
                <div className="flex-1">
                  <Typography.Text className="font-medium uppercase select-text">{data.machinery.code}</Typography.Text>
                </div>
              )}
              <div className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-all">
                {data.machinery.id}
              </div>
            </div>

            <Typography.Text className="text-sm uppercase select-text">{tableDescription}</Typography.Text>
          </section>
        </div>
      )}

      {rentalType === RentalTypeValue.EXTERNAL && (
        <Typography.Text className="uppercase select-text">{tableDescription}</Typography.Text>
      )}
    </MTCellBase>
  )
}
