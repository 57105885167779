import { Avatar } from 'antd'
import cx from 'classnames'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export const MTCellAvatarV2 = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const cellValue = cellNode.formattedValue ?? cellNode.value

  const classes = cx('flex w-full', {
    ['justify-center']: column.cellRendererParams?.justify === 'center',
  })

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      {cellValue && (
        <div
          className={classes}>
          <img alt="avatar" src={cellValue} className="h-[42px] w-[42px] min-w-[42px] rounded-md"/>
        </div>
      )}

      {!cellValue && (
        <Avatar className="bg-secondary-light min-w-[42px]" shape="square" size={42}>E</Avatar>
      )}
    </MTCellBase>
  )
}
