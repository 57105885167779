import { create } from 'zustand'
import { PreviewPayStatesForTableResult } from '@/presentation/payStates/hooks/usePreviewPayState.ts'

interface PayStatePreviewState {
  data?: PreviewPayStatesForTableResult
  clear: () => void,
  setData: (value?: PreviewPayStatesForTableResult) => void,

  // resume crud
  addNewResumeItem: (value: {
    id: string
    item: string
    quantity: number
    unitPrice: number
    total: number
  }) => void,

  deleteResumeItem: (value: {
    id: string
  }) => void,

  updateResumeItem: (value: {
    id: string
    item: string
    quantity: number
    unitPrice: number
    total: number
  }) => void,
}

export const createPayStatePreviewSlice = (set: CallableFunction): PayStatePreviewState => ({
  data: undefined,

  clear: () => set({
    data: undefined,
  }),

  setData: (value?: PreviewPayStatesForTableResult) => set({ data: value }),

  addNewResumeItem: (value: {
    id: string
    item: string
    quantity: number
    unitPrice: number
    total: number
  }) => set((state: PayStatePreviewState) => {
    if (!state.data) {
      return
    }

    const newSummaryTotal = state.data.resume.summary.total + value.total
    const newSummaryVat = state.data.query.includeVat ? newSummaryTotal * 0.19 : 0 // chilean vat
    const newSummaryTotalWithVat = state.data.query.includeVat ? newSummaryTotal + newSummaryVat : newSummaryTotal

    const newData = {
      ...state.data,
      resume: {
        ...state.data.resume,
        detail: [
          ...state.data.resume.detail,
          value,
        ],
        summary: {
          ...state.data.resume.summary,
          total        : newSummaryTotal,
          vat          : newSummaryVat,
          totalWithVat : newSummaryTotalWithVat,
        },
      },
    }

    return {
      ...state,
      data: newData,
    }
  }),

  deleteResumeItem: (value: { id: string }) => set((state: PayStatePreviewState) => {
    if (!state.data) {
      return
    }

    const itemToDelete = state.data.resume.detail.find(item => item.id === value.id)

    const newSummaryTotal = state.data.resume.summary.total - (itemToDelete?.total ?? 0)
    const newSummaryVat = state.data.query.includeVat ? newSummaryTotal * 0.19 : 0 // chilean vat
    const newSummaryTotalWithVat = state.data.query.includeVat ? newSummaryTotal + newSummaryVat : newSummaryTotal

    const newData = {
      ...state.data,
      resume: {
        ...state.data.resume,
        detail  : state.data.resume.detail.filter(item => item.id !== value.id),
        summary : {
          ...state.data.resume.summary,
          total        : newSummaryTotal,
          vat          : newSummaryVat,
          totalWithVat : newSummaryTotalWithVat,
        },
      },
    }

    return {
      ...state,
      data: newData,
    }
  }),

  updateResumeItem: (value: {
    id: string
    item: string
    quantity: number
    unitPrice: number
    total: number
  }) => set((state: PayStatePreviewState) => {
    if (!state.data) {
      return
    }

    const itemToUpdate = state.data.resume.detail.find(item => item.id === value.id)

    const newSummaryTotal = state.data.resume.summary.total - (itemToUpdate?.total ?? 0) + value.total
    const newSummaryVat = state.data.query.includeVat ? newSummaryTotal * 0.19 : 0 // chilean vat
    const newSummaryTotalWithVat = state.data.query.includeVat ? newSummaryTotal + newSummaryVat : newSummaryTotal

    const newData = {
      ...state.data,
      resume: {
        ...state.data.resume,
        detail: state.data.resume.detail.map(item => {
          if (item.id === value.id) {
            return { ...value }
          }
          return item
        }),
        summary: {
          ...state.data.resume.summary,
          total        : newSummaryTotal,
          vat          : newSummaryVat,
          totalWithVat : newSummaryTotalWithVat,
        },
      },
    }

    return {
      ...state,
      data: newData,
    }
  }),
})

export const usePayStatePreviewStore = create<PayStatePreviewState>()(createPayStatePreviewSlice)
