import { FC, useState } from 'react'
import { CreateClientForm } from '@/presentation/clients/forms/CreateClientForm.tsx'
import { DeleteClientsForm } from '@/presentation/clients/forms/DeleteClientsForm.tsx'
import { ClientsActionsProps } from '@/presentation/clients/forms/types.ts'
import { UpdateClientForm } from '@/presentation/clients/forms/UpdateClientForm.tsx'
import { MaintainerModal } from '@/presentation/common/components/maintainer/MaintainerModal.tsx'
import { SidebarButton } from '@/presentation/common/components/SidebarButton.tsx'
import { useCrudAuth } from '@/presentation/common/hooks/crud/useCrudAuth.ts'
import { useActionsModal } from '@/presentation/common/hooks/useActionsModal.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'

export const ClientsActions: FC<ClientsActionsProps> = ({ onAction }) => {
  const selectionCount = useMaintainerStore((state) => state.selectionCountV2())

  const { canCreate, canUpdate, canDelete } = useCrudAuth()
  const { open, setOpen } = useActionsModal()
  const [ option, setOption ] = useState<'POST' | 'PUT' | 'DELETE' | null>(null)

  const isPost = option === 'POST'
  const isPut = option === 'PUT'
  const isDelete = option === 'DELETE'

  const title = isPost ? 'Registrar Cliente' : isPut ? 'Actualizar Cliente' : 'Eliminar selección'

  const closeModal = () => {
    setOpen(false)
    setOption(null)
  }

  const onFinish = () => {
    closeModal()
    onAction?.()
  }

  return (
    <>
      <MaintainerModal title={title} open={open}>
        {isPost && <CreateClientForm onCancel={closeModal} onFinish={onFinish}/>}
        {isPut && <UpdateClientForm onCancel={closeModal} onFinish={onFinish}/>}
        {isDelete && <DeleteClientsForm onCancel={closeModal} onFinish={onFinish}/>}
      </MaintainerModal>

      {canCreate && (
        <SidebarButton label="Registrar Cliente" type="POST" onClick={() => {
          setOption('POST')
          setOpen(true)
        }}/>
      )}

      {canUpdate && (
        <SidebarButton label="Actualizar Cliente" type="PUT" onClick={() => {
          setOption('PUT')
          setOpen(true)
        }}/>
      )}

      {canDelete && (
        <SidebarButton label={`Eliminar selección (${selectionCount})`} type="DELETE" onClick={() => {
          setOption('DELETE')
          setOpen(true)
        }}/>
      )}
    </>
  )
}
