import { Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { priceFormatter } from '@/presentation/common/utils/priceFormatter.ts'
import { RentalConditionTypeSelector } from '@/presentation/rentals/components/RentalConditionTypeSelector.tsx'
import { RentalMachineryConditionsFormChunkProps } from '@/presentation/rentals/forms/types.ts'

export const RentalMachineryConditionsFormChunk: FC<RentalMachineryConditionsFormChunkProps> = ({ form, machineryType }) => {
  const rentalConditionType = Form.useWatch([ 'rentalCondition', 'type' ], form)

  // useEffect(() => {
  //   if (machineryType && !machineryType.allowedRentalConditionTypes().includes(rentalConditionType)) {
  //     form.setFieldValue([ 'rentalCondition', 'type' ], machineryType.allowedRentalConditionTypes()[0])
  //   }
  //   else if (!machineryType) {
  //     form.setFieldValue([ 'rentalCondition', 'type' ], null)
  //   }
  // }, [ machineryType, rentalConditionType ])

  return (
    <>
      <Form.Item
        name={[ 'rentalCondition', 'type' ]}
        label='Condición de arrendamiento'
        rules={[{ required: true }]}
      >
        <RentalConditionTypeSelector machineryType={machineryType}/>
      </Form.Item>

      {rentalConditionType === RentalConditionTypeValue.HOURS && (
        <>
          <Form.Item
            name={[ 'rentalCondition', 'minHours' ]}
            label='Horas mínimas'
            rules={[{ required: true }]}
          >
            <InputNumber min={0}/>
          </Form.Item>

          <Form.Item
            name={[ 'rentalCondition', 'amountPerHour' ]}
            label='Monto por hora'
            rules={[{ required: true }]}
          >
            <InputNumber {...priceFormatter} className="w-full"/>
          </Form.Item>
        </>
      )}

      {rentalConditionType === RentalConditionTypeValue.WORKING_DAY && (
        <Form.Item
          name={[ 'rentalCondition', 'amountPerDay' ]}
          label='Monto por jornada'
          rules={[{ required: true }]}
        >
          <InputNumber {...priceFormatter} className="w-full"/>
        </Form.Item>
      )}

      {(rentalConditionType === RentalConditionTypeValue.TRAVELS || rentalConditionType === RentalConditionTypeValue.SUMINISTRO_Y_TRASLADO) && (
        <>
          <Form.Item
            name={[ 'rentalCondition', 'load' ]}
            label='Carga'
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            name={[ 'rentalCondition', 'loadOrigin' ]}
            label='Origen de la carga'
            rules={[{ required: true }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            name={[ 'rentalCondition', 'amountPerTravel' ]}
            label='Monto por viaje'
            rules={[{ required: true }]}
          >
            <InputNumber {...priceFormatter} className="w-full"/>
          </Form.Item>
        </>
      )}

      {rentalConditionType === RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD && (
        <>
          <Form.Item
            name={[ 'rentalCondition', 'amountPerTravel' ]}
            label='Monto por viaje'
            rules={[{ required: true }]}
          >
            <InputNumber {...priceFormatter} className="w-full"/>
          </Form.Item>
        </>
      )}
    </>
  )
}
