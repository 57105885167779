import { Button, Col, Divider, Form, Input, message, Row, Typography } from 'antd'
import { FC, useEffect } from 'react'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { useForgotPassword } from '@/presentation/forgotPassword/hooks/useForgotPassword.ts'
import { FormProps } from '@/presentation/forgotPassword/types.ts'

const ForgotPassword: FC = () => {
  const [ messageApi, contextHolder ] = message.useMessage()
  const session = useSessionStore()
  const {
    isLoading,
    isSuccess,
    isError,
    data,
    message: error,
    goToLogin,
    recoverPassword,
  } = useForgotPassword()

  const isDisabled = isLoading || data.isTimeoutRunning

  const handleFormSubmit = async (values: FormProps) => {
    await recoverPassword(values.email, values.cdn)
  }

  useEffect(() => {
    if (isError && error) {
      messageApi.open({
        type    : 'error',
        content : error,
      })
    }

    if (isSuccess) {
      messageApi.open({
        type    : 'success',
        content : 'Instrucciones enviadas a su correo',
      })
    }
  }, [ error, isError, isSuccess, messageApi ])

  return (
    <>
      {contextHolder}

      <Row justify="center" className="items-baseline">
        <Col>
          <i className="ri-arrow-left-line" onClick={() => goToLogin()}></i>
        </Col>
        <Col flex={1}>
          <Typography.Title level={4} className="text-center">Recupere su contraseña</Typography.Title>
        </Col>
      </Row>

      <Form
        layout="vertical"
        initialValues={{ cdn: session.cdn }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          label="Correo electrónico"
          extra="Le enviaremos un correo con el link para recuperar su contraseña"
          name="email"
          rules={[{ required: true }, {
            type: 'email',
          }]}
        >
          <Input disabled={isDisabled}/>
        </Form.Item>

        <Form.Item
          label="CDN"
          name="cdn"
          rules={[{ required: true }]}
        >
          <Input disabled={isDisabled}/>
        </Form.Item>

        <Divider/>

        {data.isTimeoutRunning && data.sendEmailAgainTimeout > 0 &&
                    <Form.Item className="text-center">
                      <span>Si no le ha llegado el correo con las instrucciones, espere {data.sendEmailAgainTimeout} segundos para volver a intentar</span>
                    </Form.Item>
        }

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            disabled={isDisabled}
          >
                        Recuperar contraseña
          </Button>
        </Form.Item>
      </Form>

    </>
  )
}

export default ForgotPassword
