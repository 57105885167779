import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { MaintenanceType } from '@/domain/models/maintenanceType.ts'

type Props = SelectProps & {
  machineryType?: MachineryType | null // se puede pasar este prop para filtrar las opciones
}

export const MaintenanceClassSelector: FC<Props> = ({ machineryType, ...props }) => {
  const options = machineryType ?
    machineryType.allowedMaintenances().map((maintenanceType) => {
      const maintenance = MaintenanceType.fromString(maintenanceType)
      return { value: maintenance.value, label: maintenance.label() }
    }) :
    MaintenanceType.toEntries().map(([ key, value ]) => ({ value: key, label: value.label() }))

  return (
    <Select
      placeholder="Seleccione una clase de mantenimiento"
      allowClear
      {...props}
      options={options}
    />
  )
}
