import { useState } from 'react'
import { DownloadMaintenancePdfRequest } from '@/api/maintenances/request.types.ts'
import { useMaintenancesApiClient } from '@/api/maintenances/useMaintenancesApiClient.ts'
import { useDownloadPdfFile } from '@/presentation/common/hooks/crud/v2/useDownloadPdfFile.ts'

export const useDownloadMaintenancePdf = () => {
  const repository = useMaintenancesApiClient()
  const query = useDownloadPdfFile<DownloadMaintenancePdfRequest>({
    filename : 'maintenance.pdf',
    callable : repository.downloadPdf,
    messages : {
      default : 'No se pudo descargar el archivo',
      loading : 'Generando archivo',
      success : 'Archivo generado',
    },
  })

  const [ data, setData ] = useState<File>()

  const submit = async (id: string) => {
    const response = await query.submit({ id })
    setData(response)
  }

  return {
    ...query,
    submit,
    data,
  }
}
