import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC, UIEvent, useCallback, useEffect, useRef, useState } from 'react'
import { MachineryType } from '@/domain/models/machineryType.ts'
import { RentalConditionType } from '@/domain/models/rentalConditionType.ts'
import { useSearch } from '@/presentation/common/hooks/useSearch.ts'
import {
  QueryRentalsMachineryFilters,
  QueryRentalsMachineryForSelectorResult,
  useQueryRentalsMachineryForSelector,
} from '@/presentation/rentals/hooks/useQueryRentalsMachineryForSelector.ts'
import type { BaseSelectRef } from 'rc-select'

type Props = SelectProps & {
  filters?: QueryRentalsMachineryFilters,
  onCustomSelect?: (item?: QueryRentalsMachineryForSelectorResult['data']) => void,
  displayOperator?: boolean,
}

export const RentalMachinerySelector: FC<Props> = ({ filters, onCustomSelect, displayOperator, ...props }) => {
  const ref = useRef<BaseSelectRef>(null)

  const { data, query, hasMorePages, lastPage, isLoading } = useQueryRentalsMachineryForSelector(filters)
  const [ lastSearch, setLastSearch ] = useState<string>()

  const [ value, setValue ] = useState<string | undefined>(props.value as string)
  const [ userManuallySelected, setUserManuallySelected ] = useState(false)

  const limit = 100

  useEffect(() => {
    query({
      params: {
        page: 1,
        limit,
      },
    })
  }, [ filters ])

  useEffect(() => {
    if (!userManuallySelected && data?.length === 1 && !value) {
      setValue(data[0].value)
      handleOnSelect(data[0].value)
    }
  }, [ data, userManuallySelected, value ])

  const sendQuery = useCallback((value: string) => {
    query({
      params: {
        search : value,
        page   : 1,
        limit,
      },
    })
  }, [])

  const search = useSearch(sendQuery)
  const onSearch = (value: string) => {
    setLastSearch(value)
    search(value)
  }

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement
    if (!isLoading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
      target.scrollTo(0, target.scrollHeight)
      if (hasMorePages) {
        query({
          params: {
            search : lastSearch,
            page   : lastPage + 1,
            limit,
          },
        })
      }
    }
  }

  const handleOnSelect = (value: string) => {
    const item = data?.find((item) => item.value === value)
    onCustomSelect?.(item?.data)
    ref.current?.blur()
  }

  return (
    <Select
      ref={ref}
      placeholder="Seleccione una maquinaria"
      allowClear
      showSearch
      onSearch={onSearch}
      onPopupScroll={onScroll}
      onSelect={(value) => {
        setUserManuallySelected(true)
        handleOnSelect(value)
      }}
      filterOption={false}
      loading={isLoading}
      {...props}
      value={value || props.value}
    >
      {data?.map((item) => (
        <Select.Option key={item.value} value={item.value}>
          <div className="text-sm font-medium">{item.label}</div>
          <div className="text-xs">Tipo maquinaria: {MachineryType.fromString(item.data.machinery.type).label()}</div>
          <div className="text-xs">Condición: {RentalConditionType.fromString(item.data.rentalCondition.type).label()}</div>
          {item.data.rentalCondition.load && <div className="text-xs">Carga: {item.data.rentalCondition.load}</div>}
          <div className="text-xs">Obra: {item.data.rental.building} - {item.data.rental.client.name}</div>
          {displayOperator && <div className="text-xs">Operador: {item.data.operator.fullName}</div>}
        </Select.Option>
      ))}
    </Select>
  )
}
