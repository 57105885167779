import { useCallback, useState } from 'react'
import { QueryClientsResponse } from '@/api/clients/response.types.ts'
import { useClientsApiClient } from '@/api/clients/useClientsApiClient.ts'
import { QueryOptions } from '@/api/common/types.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryClientsForTableResult = PaginatedData<{
  id: string
  name: string
  paymentCondition: string
  contacts: string[]
  billing: {
    dni: string
    name: string
    activity: string
    address: string
    phone: string
  }
}>

export const useQueryClientsForTable = () => {
  const repository = useClientsApiClient()
  const queryItemsHook = useQueryItems<QueryClientsResponse>({
    callable : repository.query,
    messages : {
      default : 'No se encontraron clientes',
      success : 'Clientes encontrados',
      loading : 'Buscando clientes',
    },
  })

  const [ data, setData ] = useState<QueryClientsForTableResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryClientsResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id               : item.id.toString(),
        name             : item.name,
        paymentCondition : item.paymentCondition,
        contacts         : item.contacts,
        billing          : {
          dni      : item.billing.dni,
          name     : item.billing.name,
          activity : item.billing.activity,
          address  : item.billing.address,
          phone    : item.billing.phone,
        },
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
