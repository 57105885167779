import { useRentalsMaintainerStore } from '@/presentation/rentals/store/rentalsMaintainers.ts'

export const useTabs = () => {
  const { selectedRentalId } = useRentalsMaintainerStore()

  const tabsSetup = {
    rentals   : { id: 'rentals', label: 'Arriendos' },
    machinery : { id: 'machinery', label: 'Maquinarias' },
    receivers : { id: 'receivers', label: 'Receptores' },
  }

  const visibleTabs: Record<string, boolean> = {
    rentals   : true,
    machinery : false,
    receivers : false,
  }

  if (selectedRentalId) {
    visibleTabs.machinery = true
    visibleTabs.receivers = true
  }
  else {
    visibleTabs.machinery = false
    visibleTabs.receivers = false
  }

  const tabs = Object.values(tabsSetup).filter(tab => visibleTabs[tab.id])

  return {
    tabs,
  }
}
