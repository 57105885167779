import { ApiErrorResponse } from '@/api/common/apiErrorResponse.ts'
import { ApiErrorWrapper } from '@/api/common/types.ts'

export const apiErrorWrapper: ApiErrorWrapper = async (fn: any) => {
  try {
    const { data } = await fn()
    return { data, error: null }
  }
  catch (error) {
    return { data: null, error: error as unknown as ApiErrorResponse }
  }
}
