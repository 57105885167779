import * as R from 'ramda'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CellNode,
  DataGridApi,
  DataGridApiConstructorParams,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { flatten } from '@/presentation/common/components/dataGrid/lib/utils.ts'

export const useDataGridApi = (params: DataGridApiConstructorParams): DataGridApi => {
  const [ columnDefs, setColumnDefs ] = useState<DataGridColumnDefs[]>(
    params.config.columnDefs.map((column) => ({
      ...column,
      sortable: column.sortable ?? true,
    })),
  )
  const [ nodes, setNodes ] = useState<RowNode[]>(params.config.rowData ?? [])
  const [ sort, setSort ] = useState<string | undefined>()
  const [ rowsSelectionStatus, setRowsSelectionStatus ] = useState<'all' | 'partial' | 'none'>('none')

  const setupNode = (data: any) => {
    return {
      id        : uuidv4(),
      data      : data,
      cellNodes : Object.entries({ ...data, ...flatten(data) }).reduce((acc, [ key, value ]) => ({
        ...acc,
        [key]: {
          value,
          formattedValue: params.config.columnDefs.find(({ field }) => field === key)?.formatValue?.({
            field: key,
            value,
            data,
          }),
        } as CellNode,
      }), {}),
      selected: false,
    } as RowNode
  }

  const sortNodes = (nodes: RowNode[]) => {
    const field = sort?.split(':')[0]
    const sortType = sort?.split(':')[1]

    if (sort && field && sortType) {
      const comparator = columnDefs.find(({ field: columnField }) => columnField === field)?.comparator
      if (comparator) {
        return R.sort((nodeA, nodeB) => {
          const valueA = nodeA.cellNodes[field]?.formattedValue ?? nodeA.cellNodes[field]?.value
          const valueB = nodeB.cellNodes[field]?.formattedValue ?? nodeB.cellNodes[field]?.value

          if (sortType === 'asc') {
            return comparator(valueA, valueB, nodeA, nodeB)
          }
          else {
            return comparator(valueB, valueA, nodeB, nodeA)
          }
        }, nodes,
        )
      }
    }
    else {
      return params.config.rowData?.map((data) => setupNode(data)) ?? []
    }

    return nodes
  }

  const toggleSort = (field: string) => {
    setColumnDefs((prevColumnDefs) => prevColumnDefs.map((column) => {
      if (column.field === field) {
        switch (column.sort) {
          case 'asc':
            column.sort = 'desc'
            break
          case 'desc':
            column.sort = undefined
            break
          default:
            column.sort = 'asc'
            break
        }

        setSort(column.sort ? `${field}:${column.sort}` : undefined)
      }
      else {
        column.sort = undefined
      }
      return column
    }))
  }

  const onRowSelected = (node: RowNode, selected: boolean) => {
    const newSelectionNodes = nodes.map((prevNode) => {
      if (prevNode.id === node.id) {
        prevNode.selected = selected
      }

      if (params.config.rowSelection === 'single' && prevNode.id !== node.id) {
        prevNode.selected = false
      }

      return prevNode
    })

    const rowsSelection = newSelectionNodes.every((node) => node.selected)
      ? 'all'
      : newSelectionNodes.some((node) => node.selected)
        ? 'partial'
        : 'none'

    setRowsSelectionStatus(rowsSelection)
    setNodes(newSelectionNodes)
    params.config.onSelectionChange?.(node, newSelectionNodes.filter((node) => node.selected))
  }

  const onGlobalSelection = (selected: boolean) => {
    const newSelectionNodes = nodes.map((prevNode) => {
      prevNode.selected = selected
      return prevNode
    })
    setRowsSelectionStatus(selected ? 'all' : 'none')
    setNodes(newSelectionNodes)
    params.config.onSelectionChange?.(null, newSelectionNodes.filter((node) => node.selected))
  }

  const onPageChange = (page: number, pageSize: number) => {
    setRowsSelectionStatus('none')
    params.config.pagination?.onPageChange?.(page, pageSize)
    params.config.onSelectionChange?.(null, [])
  }

  useEffect(() => {
    const nodes = params.config.rowData?.map((data) => setupNode(data)) ?? []
    const sortedNodes = sortNodes(nodes)
    setNodes(sortedNodes)
    setRowsSelectionStatus('none')
    params.config.onSelectionChange?.(null, [])
  }, [ params.config.rowData ])

  useEffect(() => {
    const nodesSorted = sortNodes(nodes)
    setNodes(nodesSorted)
  }, [ sort ])

  useEffect(() => {
    setColumnDefs(params.config.columnDefs.map((column) => ({
      ...column,
      sortable: column.sortable ?? true,
    })))
  }, [ params.config.columnDefs ])

  return {
    config: {
      defaultColDef: {
        ...params.config.defaultColDef,
        minWidth: params.config.defaultColDef.minWidth ?? 100,
      },
      columnDefs,
      rowHeight    : params.config.rowHeight ?? 35,
      rowSelection : params.config.rowSelection ?? 'multiple',
      rowData      : params.config.rowData,
      pagination   : params.config.pagination ? {
        ...params.config.pagination,
        onPageChange,
      } : undefined,
      useMobileView: params.config.useMobileView ?? false,
      rowsSelectionStatus,
    },
    nodes,
    toggleSort,
    onRowSelected,
    onGlobalSelection,
  }
}
