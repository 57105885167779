export enum MaintenanceTypeValue {
  CLASS_A = 'CLASS_A',
  CLASS_B = 'CLASS_B',
  CLASS_C = 'CLASS_C',
  CLASS_D = 'CLASS_D',
}

export class MaintenanceType {
  readonly value!: MaintenanceTypeValue

  constructor(value: MaintenanceTypeValue) {
    this.value = value
  }

  static fromString(value: string): MaintenanceType {
    if (!this.toList().includes(value as MaintenanceTypeValue)) {
      throw new Error(`Invalid RentalType: ${value}`)
    }

    return new MaintenanceType(value as MaintenanceTypeValue)
  }

  static toList(): MaintenanceTypeValue[] {
    return Object.values(MaintenanceTypeValue)
  }

  static toEntries(): [MaintenanceTypeValue, MaintenanceType][] {
    return this.toList().map((value) => [ value, new MaintenanceType(value) ])
  }

  static readonly labels: Record<MaintenanceTypeValue, string> = {
    CLASS_A : 'Clase A: 4 tramos cada 250hrs',
    CLASS_B : 'Clase B: 3 tramos cada 500hrs',
    CLASS_C : 'Clase C: cada 10.000kms',
    CLASS_D : 'Clase D: cada 25.000kms',
  }

  label(): string {
    return MaintenanceType.labels[this.value]
  }
}
