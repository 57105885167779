import { LoadEppTemplateProductsRequest } from '@/api/eppProducts/request.types.ts'
import { LoadEppTemplateProductsResponse } from '@/api/eppProducts/response.types.ts'
import { useEppProductsApiClient } from '@/api/eppProducts/useEppProductsApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useLoadEppProductsTemplateProducts = () => {
  const repository = useEppProductsApiClient()
  const updateItem = useUpdateItem<LoadEppTemplateProductsRequest, LoadEppTemplateProductsResponse>({
    callable : repository.loadTemplate,
    messages : {
      default : 'No se pudieron actualizar los productos',
      success : 'Productos cargados con éxito',
      loading : 'Actualizando productos',
    },
  })

  return {
    ...updateItem,
  }
}
