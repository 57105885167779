import { DeleteManyRentalsReceiversRequest } from '@/api/rentalsReceivers/request.types.ts'
import { DeleteManyRentalsReceiversResponse } from '@/api/rentalsReceivers/response.types.ts'
import { useRentalsReceiversApiClient } from '@/api/rentalsReceivers/useRentalsReceiversApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteRentalsReceivers = () => {
  const repository = useRentalsReceiversApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyRentalsReceiversRequest, DeleteManyRentalsReceiversResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los receptores',
      success : 'Receptores eliminados',
      loading : 'Eliminando receptores',
    },
  })

  return {
    ...deleteItems,
  }
}
