import { DeleteManyMachineryUseRecordsRequest } from '@/api/machineryUseRecords/request.types.ts'
import { DeleteManyMachineryUseRecordsResponse } from '@/api/machineryUseRecords/response.types.ts'
import { useMachineryUseRecordsApiClient } from '@/api/machineryUseRecords/useMachineryUseRecordsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteMachineryUseRecords = () => {
  const repository = useMachineryUseRecordsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyMachineryUseRecordsRequest, DeleteManyMachineryUseRecordsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar a los registros de uso',
      success : 'Registros de uso eliminados',
      loading : 'Eliminando registros de uso',
    },
  })

  return {
    ...deleteItems,
  }
}
