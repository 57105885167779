import { QueryOptions } from '@/api/common/types.ts'
import { QueryRentalsMachineryResponse } from '@/api/rentalsMachinery/response.types.ts'
import { useRentalsMachineryApiClient } from '@/api/rentalsMachinery/useRentalsMachineryApiClient.ts'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

export type QueryRentalsMachineryFilters = {
  rentalId?: string
  date?: string
  rentalType?: RentalTypeValue
  client?: string
}

type QueryRentalsMachineryOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryRentalsMachineryForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    rentalType: RentalTypeValue
    machinery: {
      id: string | null
      type: MachineryTypeValue
      code: string | null
      description: string | null
      truckVolume: number | null
    }
    operator: {
      id: string | null
      fullName: string
    }
    rentalCondition: {
      type: RentalConditionTypeValue
      minHours: number | null
      amountPerHour: number | null
      amountPerDay: number | null
      load: string | null
      loadOrigin: string | null
      amountPerTravel: number | null
    }
    rental: {
      client: {
        name: string | null
      }
      building: string | null
      address: string | null
      externalCompany: string | null
    }
    lastHourmeter: number | null
  }
}

export const useQueryRentalsMachineryForSelector = (filters: QueryRentalsMachineryFilters = {}) => {
  const repository = useRentalsMachineryApiClient()
  const queryItemsHook = useQueryItems<QueryRentalsMachineryResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron maquinarias',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryRentalsMachineryForSelectorResult>()

  const query = async (options: QueryRentalsMachineryOptions = {}) => {
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        ...filters,
      },
    })
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryRentalsMachineryOptions, response?: QueryRentalsMachineryResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map(({ machinery, ...item }) => {
        const machineryType = MachineryType.fromString(machinery.type)
        const description = item.rentalType === RentalTypeValue.INTERNAL ?
          `${machinery.code} ${machinery.brand} ${machinery.model} (${machinery.serialNumber})` :
          `${machinery.description}`

        return {
          value : item.id.toString(),
          label : description,
          data  : {
            id         : item.id.toString(),
            rentalType : RentalType.fromString(item.rentalType).value,
            machinery  : {
              id          : machinery.id,
              type        : machineryType.value,
              code        : machinery.code ?? null,
              description : description,
              truckVolume : machinery.truckVolume ?? null,
            },
            operator: {
              id       : item.operator.id,
              fullName : item.operator.fullName,
            },
            rentalCondition: {
              type            : RentalConditionType.fromString(item.rentalCondition.type).value,
              minHours        : item.rentalCondition.minHours,
              amountPerHour   : item.rentalCondition.amountPerHour,
              amountPerDay    : item.rentalCondition.amountPerDay,
              load            : item.rentalCondition.load,
              loadOrigin      : item.rentalCondition.loadOrigin,
              amountPerTravel : item.rentalCondition.amountPerTravel,
            },
            rental: {
              client: {
                name: item.rental?.client?.name ?? null,
              },
              building        : item.rental?.building ?? null,
              address         : item.rental?.address ?? null,
              externalCompany : item.rental?.externalCompany ?? null,
            },
            lastHourmeter: item.lastHourmeter ?? null,
          },
        }
      }),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
