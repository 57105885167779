import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { MaintenanceStatus } from '@/domain/models/maintenanceStatus.ts'

export const MaintenanceStatusSelector: FC<SelectProps> = (props) => {
  return (
    <Select
      showSearch={false}
      filterOption={false}
      placeholder="Seleccione un estado"
      {...props}
    >
      {MaintenanceStatus.toEntries().map(([ key, value ]) => (
        <Select.Option key={key} value={value.value}>{value.label()}</Select.Option>
      ))}
    </Select>
  )
}
