import {
  ForgotPasswordRequest,
  LoginRequest,
  LogoutRequest,
  RefreshTokensRequest,
  ResetPasswordRequest,
} from '@/api/auth/request.types.ts'
import {
  ForgotPasswordResponse,
  LoginResponse,
  LogoutResponse,
  RefreshTokensResponse,
  ResetPasswordResponse,
} from '@/api/auth/response.types.ts'
import { postMapping } from '@/api/common/postMapping.ts'

export type AuthApiClient = {
  login: (req: LoginRequest) => Promise<LoginResponse>
  logout: (req: LogoutRequest) => Promise<LogoutResponse>
  refreshTokens: (req: RefreshTokensRequest) => Promise<RefreshTokensResponse>
  forgotPassword: (req: ForgotPasswordRequest) => Promise<ForgotPasswordResponse>
  resetPassword: (req: ResetPasswordRequest) => Promise<ResetPasswordResponse>
}

export const useAuthApiClient = (): AuthApiClient => ({
  login: async (req: LoginRequest) => await postMapping<LoginResponse, LoginRequest>({
    path : '/auth/login',
    body : req,
  }),

  logout: async (req: LogoutRequest) => await postMapping<LogoutResponse, LogoutRequest>({
    path : '/auth/logout',
    body : req,
  }),

  refreshTokens: async (req: RefreshTokensRequest) => await postMapping<RefreshTokensResponse, RefreshTokensRequest['body']>({
    path    : '/auth/refresh-tokens',
    body    : req.body,
    headers : {
      Authorization: `Bearer ${req.headers.accessToken}`,
    },
  }),

  forgotPassword: async (req: ForgotPasswordRequest) => await postMapping<ForgotPasswordResponse, ForgotPasswordRequest>({
    path : '/auth/forgot-password',
    body : req,
  }),

  resetPassword: async (req: ResetPasswordRequest) => await postMapping<ResetPasswordResponse, ResetPasswordRequest['body']>({
    path  : '/auth/reset-password',
    query : req.query,
    body  : req.body,
  }),
})
