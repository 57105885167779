import numeral from 'numeral'
import { FC, useMemo } from 'react'
import { RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { WorkingDay } from '@/domain/models/workingDay.ts'
import { DataGridColumnDefs } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import 'numeral/locales/es'
import { PayStatesDetailsTable } from '@/presentation/payStates/components/PayStatesDetailsTable.tsx'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'

numeral.locale('es')

type Props = {
  rentalType: RentalTypeValue
}

export const PayStatesDetailsTableByWorkingDay: FC<Props> = ({ rentalType }) => {
  const { data } = usePayStatePreviewStore()

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field      : 'date',
      headerName : 'Fecha',
      comparator : stringComparator,
    },
    {
      field      : 'building',
      headerName : 'Obra',
      comparator : stringComparator,
    },
    {
      field              : 'folio',
      headerName         : 'Nro. Reporte',
      comparator         : numberComparator,
      cellRendererParams : {
        justify: 'center',
      },
      width: 110,
    },
    {
      field      : 'machinery',
      headerName : 'Equipo',
      comparator : stringComparator,
    },
    {
      field      : 'truckVolume',
      headerName : 'Volumen',
      comparator : numberComparator,
      width      : 100,
    },
    {
      field      : 'operator',
      headerName : 'Operador',
      comparator : stringComparator,
    },
    {
      field              : 'machineryConditionValues.workingDay',
      headerName         : 'Tipo Jornada',
      comparator         : stringComparator,
      formatValue        : ({ value }) => WorkingDay.fromString(value).label().toUpperCase(),
      cellRendererParams : {
        justify: 'center',
      },
      width: 120,
    },
    {
      field              : 'rentalCondition.amountPerDay',
      headerName         : 'Monto',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
    {
      field              : 'totalAmount',
      headerName         : 'Cobro',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
  ]), [])

  const details = data?.detail[rentalType][RentalConditionTypeValue.WORKING_DAY]
  const rowData = useMemo(() => details?.['items'] ?? [], [ details ])
  const summary = useMemo(() => details?.['summary'] ?? {
    total        : 0,
    vat          : 0,
    totalWithVat : 0,
  }, [ details ])

  return (
    <PayStatesDetailsTable
      title="Detalle por Jornada"
      rentalType={rentalType}
      columnDefs={columnDefs}
      rowData={rowData}
      summary={summary}
    />
  )
}
