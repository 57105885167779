import { FC, useEffect, useMemo } from 'react'
import { DataGrid } from '@/presentation/common/components/dataGrid/DataGrid.tsx'
import {
  DataGridApiConfig,
  DataGridColumnDefs,
  DataGridDefaultColDef,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { Maintainer } from '@/presentation/common/components/maintainer/Maintainer.tsx'
import { MTCellUidV2 } from '@/presentation/common/components/maintainer/table/cells/MTCellUidV2.tsx'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import { useAlert } from '@/presentation/common/hooks/useAlert.ts'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { MTCellUserRole } from '@/presentation/users/components/MTCellUserRole.tsx'
import { UsersActions } from '@/presentation/users/forms/UsersActions.tsx'
import { useDownloadUsersExcel } from '@/presentation/users/hooks/useDownloadUsersExcel.ts'
import { useQueryUsersForTable } from '@/presentation/users/hooks/useQueryUsersForTable.ts'
import { roleSortComparator } from '@/presentation/users/utils/roleSortComparator.ts'

const Users: FC = () => {
  const { isTabletAndBelow } = useMQ()
  const { reset, setTitle, setSelectionV2, setActionsSlot, search } = useMaintainerStore()
  const { data, query, refresh, isLoading } = useQueryUsersForTable()

  const defaultColDef: DataGridDefaultColDef = useMemo(() => ({
    sortable  : true,
    resizable : true,
    minWidth  : 100,
  }), [])

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field                   : 'username',
      headerName              : 'Usuario',
      headerCheckboxSelection : true,
      checkboxSelection       : true,
      comparator              : stringComparator,
    },
    { field: 'firstName', headerName: 'Nombre', comparator: stringComparator },
    { field: 'lastName', headerName: 'Apellido', comparator: stringComparator },
    { field: 'dni', headerName: 'RUT', comparator: stringComparator },
    { field: 'role', headerName: 'Rol', comparator: roleSortComparator, cellRenderer: MTCellUserRole },
    { field: 'email', headerName: 'Correo Electrónico', comparator: stringComparator },
    { field: 'id', headerName: 'ID', cellRenderer: MTCellUidV2, comparator: stringComparator },
  ]), [])

  const tableConfig = useMemo(() => ({
    useMobileView : isTabletAndBelow,
    defaultColDef,
    columnDefs,
    rowHeight     : 50,
    rowData       : data?.items || [],
    rowSelection  : 'multiple',
    pagination    : {
      enabled      : true,
      pageSize     : data?.limit || 10,
      currentPage  : data?.page || 1,
      totalItems   : data?.totalItems || 0,
      onPageChange : (page: number, limit: number) => query({
        params: {
          page,
          limit,
          search,
        },
      }),
    },
    onSelectionChange: (_selectedRow, selectedRows) => setSelectionV2(selectedRows),
  } as DataGridApiConfig), [ data, data?.items, data?.limit, data?.page, data?.totalItems, isTabletAndBelow ])

  const actionsComponent = useMemo(() => <UsersActions onAction={refresh}/>, [ refresh ])

  // actualizar actions slot
  useEffect(() => {
    setActionsSlot(actionsComponent)
  }, [ actionsComponent ])

  // first load
  useEffect(() => {
    reset()
    setTitle('Usuarios')
    setActionsSlot(actionsComponent)
    query({
      params: {
        page: 1,
      },
    })
  }, [])

  // when search changes update query
  useEffect(() => {
    query({
      params: {
        search,
        page: 1,
      },
    })
  }, [ search ])

  // DOWNLOAD EXCEL
  const {
    submit: downloadFile,
    isLoading: isExcelLoading,
    isError: isExcelError,
    isSuccess: isExcelSuccess,
    message: excelMessage,
    data: excelFile,
    lastUpdate: lastSubmitTimestamp,
  } = useDownloadUsersExcel()

  const {
    showLoading: showExcelLoading,
    showError: showExcelError,
    closeAlert: closeExcelAlert,
  } = useAlert({ messageApiKey: 'UsersExcel-Alert' })

  // Mostrar alerta de excel
  useEffect(() => {
    if (isExcelLoading) {
      showExcelLoading(excelMessage)
    }
    else if (isExcelError) {
      showExcelError(excelMessage)
    }
    else {
      closeExcelAlert()
    }
  }, [ isExcelLoading, isExcelError, isExcelSuccess, excelMessage ])

  // Abrir excel cuando se descargue
  useEffect(() => {
    if (!isExcelLoading && excelFile) {
      window.open(URL.createObjectURL(excelFile))
    }
  }, [ lastSubmitTimestamp ])

  return (
    <Maintainer showExcelDownloadButton onExcelDownloadButtonClick={downloadFile}>
      <DataGrid config={tableConfig} isLoading={isLoading}/>
    </Maintainer>
  )
}

export default Users
