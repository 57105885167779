import { DeleteManyRentalsRequest } from '@/api/rentals/request.types.ts'
import { DeleteManyRentalsResponse } from '@/api/rentals/response.types.ts'
import { useRentalsApiClient } from '@/api/rentals/useRentalsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteRentals = () => {
  const repository = useRentalsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyRentalsRequest, DeleteManyRentalsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los arriendos',
      success : 'Arriendos eliminados',
      loading : 'Eliminando arriendos',
    },
  })

  return {
    ...deleteItems,
  }
}
