import { Select } from 'antd'
import { FC } from 'react'
import countries from '@/presentation/common/assets/countries.json'
import { CountrySelectorProps } from '@/presentation/common/components/types.ts'

export const CountrySelector: FC<CountrySelectorProps> = ({ value, onChange }) => {
  const countriesMap = countries.map((country) => ({
    value  : country.cca3,
    label  : country.translations.spa.common,
    origin : country,
  })).sort((a, b) => a.label.localeCompare(b.label))

  return (
    <Select
      value={value}
      onChange={onChange}
      showSearch
      placeholder="Buscar país"
      optionFilterProp="children"
      allowClear={true}
    >
      {countriesMap.map((country) => (
        <Select.Option key={country.value} value={country.value}>
          {country.label}
        </Select.Option>
      ))}
    </Select>
  )
}
