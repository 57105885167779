import { useMediaQuery } from 'react-responsive'

export const useMQ = () => ({
  is4k              : useMediaQuery({ minWidth: 1921 }),
  isDesktopAndAbove : useMediaQuery({ minWidth: 992 }),
  isDesktop         : useMediaQuery({ minWidth: 992, maxWidth: 1920 }),
  isTablet          : useMediaQuery({ minWidth: 768, maxWidth: 991 }),
  isTabletAndBelow  : useMediaQuery({ maxWidth: 991 }),
  isMobile          : useMediaQuery({ maxWidth: 767 }),
})
