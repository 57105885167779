import { IRowNode } from 'ag-grid-community'
import { ReactNode } from 'react'
import { create } from 'zustand'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'

export interface MaintainerState {
  title: string;
  search: string;
  isLoading: boolean;
  selection: { [id: string]: IRowNode }; // used primarily for actions button
  selectionV2: RowNode[];
  selectionNodes: () => IRowNode[];
  actionsSlot?: ReactNode;
  reset: () => void;

  setTitle: (title: string) => void;
  setSearch: (search: string) => void;
  setIsLoading: (isLoading: boolean) => void;
  setSelection: (selection: { [id: string]: IRowNode }) => void;
  setSelectionV2: (selection: RowNode[]) => void;
  setActionsSlot: (actionsSlot: ReactNode) => void;
  clearSelection: () => void;

  selectionCount: () => number;
  selectionCountV2: () => number;
}

export const createMaintainerSlice = (set: CallableFunction, get: CallableFunction): MaintainerState => ({
  title            : '',
  search           : '',
  selection        : {},
  selectionV2      : [],
  actionsSlot      : undefined,
  isLoading        : false,
  selectionNodes   : () => Object.values(get().selection),
  selectionCount   : () => get().selectionNodes().length,
  selectionCountV2 : () => get().selectionV2.length,

  reset: () => set({
    title       : '',
    search      : '',
    selection   : {},
    selectionV2 : [],
    actionsSlot : undefined,
    isLoading   : false,
  }),

  setTitle       : (title: string) => set({ title }),
  setSearch      : (search: string) => set({ search }),
  setSelection   : (selection: { [id: string]: IRowNode }) => set({ selection }),
  setSelectionV2 : (selection: RowNode[]) => set({ selectionV2: selection }),
  setActionsSlot : (actionsSlot: ReactNode) => set({ actionsSlot }),
  setIsLoading   : (isLoading: boolean) => set({ isLoading }),
  clearSelection : () => set({ selection: {} }),
})

export const useMaintainerStore = create<MaintainerState>()(createMaintainerSlice)
