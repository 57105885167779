import { Avatar, Typography } from 'antd'
import config from '@/config.ts'
import { MTCellBase } from '@/presentation/common/components/dataGrid/components/MTCellBase.tsx'
import {
  CellNode,
  DataGridApi,
  DataGridColumnDefs,
  RowNode,
} from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { QuerySubscriptionsForTableResult } from '@/presentation/subscriptions/hooks/useQuerySubscriptionsForTable.ts'

export const MTCellSubscriptionTenantCard = (cellNode: CellNode, node: RowNode, column: DataGridColumnDefs, api: DataGridApi) => {
  const tenant = cellNode.value as QuerySubscriptionsForTableResult['items'][0]['tenant']

  return (
    <MTCellBase {...{
      cellNode,
      node,
      column,
      api,
    }}>
      <div
        className="flex align-middle w-full gap-4 p-2 rounded-md bg-secondary-min overflow-hidden min-w-[100px]">
        <section className="flex flex-col justify-center">
          {tenant.picture ? (
            <img alt="avatar" src={`${config.publicBucketUrl}/${tenant.picture}`} className="h-[42px] w-[42px] min-w-[42px] rounded-md"/>
          ) : (
            <Avatar className="bg-secondary-light min-w-[42px]" shape="square" size={42}>E</Avatar>
          )}
        </section>

        <section className="flex flex-col flex-1 overflow-hidden">
          <Typography.Text className="font-medium">{tenant.name}</Typography.Text>
          <div className="flex flex-nowrap items-baseline gap-2">
            <div className="flex-1">

              <Typography.Text className="text-sm font-light">@{tenant.cdn}</Typography.Text>
            </div>
            <div
              className="py-1 px-2 bg-orange-50 font-mono text-xs rounded-md inline-block select-all">
              {tenant.id}
            </div>
          </div>
        </section>
      </div>
    </MTCellBase>
  )
}
