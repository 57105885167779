import { QueryOptions } from '@/api/common/types.ts'
import { QueryInventoryProductsStocksResponse } from '@/api/inventoryProductsStocks/response.types.ts'
import {
  useInventoryProductsStocksApiClient,
} from '@/api/inventoryProductsStocks/useInventoryProductsStocksApiClient.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { useCachedPaginatedData } from '@/presentation/common/hooks/useCachedPaginatedData.ts'

export type QueryInventoryProductsStocksFilters = {
  inventoryId?: string
}

type QueryInventoryProductsOptions = Partial<QueryOptions & {
  resetSearch: boolean
}>

export type QueryInventoryProductsForSelectorResult = {
  value: string
  label: string
  data: {
    id: string
    name: string
    sku: string | null
    description: string | null
  }
}

export const useQueryInventoryStocksForSelector = (filters: QueryInventoryProductsStocksFilters = {}) => {
  const repository = useInventoryProductsStocksApiClient()
  const queryItemsHook = useQueryItems<QueryInventoryProductsStocksResponse>({
    callable : repository.query,
    messages : {
      default: 'No se encontraron productos',
    },
  })

  const {
    mergedData: data,
    lastCachedPage,
    hasMorePages,
    resetCache,
    addPageToCache,
  } = useCachedPaginatedData<QueryInventoryProductsForSelectorResult>()

  const query = async (options: QueryInventoryProductsOptions = {}) => {
    const response = await queryItemsHook.query({
      ...options,
      params: {
        ...options.params,
        ...filters,
      },
    })
    mapResponse(options, response)
  }

  const mapResponse = (options: QueryInventoryProductsOptions, response?: QueryInventoryProductsStocksResponse) => {
    if (!response) {
      return resetCache()
    }

    addPageToCache(response.page ?? 1, {
      ...response,
      items: response.items.map((item) => ({
        value : item.id.toString(),
        label : `${item.product.sku ?? ''} ${item.product.name}`.trim(),
        data  : {
          id          : item.id.toString(),
          name        : item.product.name,
          sku         : item.product.sku,
          description : item.product.description,
        },
      })),
    }, options.resetSearch)
  }

  return {
    ...queryItemsHook,
    query,
    data,
    lastPage: lastCachedPage,
    hasMorePages,
  }
}
