import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import config from '@/config.ts'
import { UserRoleValue } from '@/domain/models/userRole.ts'

interface SessionUser {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  dni: string | null;
  email: string;
  role: UserRoleValue;
  isActive: boolean;
  signature: string | null;
}

interface SessionTenant {
  id: string
  cdn: string
  name: string
  dni: string
  address: string
  activity: string
  contactPhone: string
  contactEmail: string
  picture: string | null
  isSysAdmin: boolean
  representativeSignature: string | null
}

interface SessionState {
  user: SessionUser | null,
  cdn: string | null,
  accessToken: string | null,
  refreshToken: string | null,
  tenant: SessionTenant | null,
  clearSession: () => void,
  setUser: (user: SessionUser) => void,
  setCdn: (cdn: string) => void,
  setAccessToken: (accessToken: string | null) => void,
  setRefreshToken: (refreshToken: string | null) => void,
  setTenant: (tenant: SessionTenant) => void,
}

export const useSessionStore = create(persist<SessionState>(
  (set) => ({
    user         : null,
    cdn          : null,
    tenant       : null,
    accessToken  : null,
    refreshToken : null,

    clearSession: () => set({
      user         : null,
      accessToken  : null,
      refreshToken : null,
      tenant       : null,
    }),

    setUser         : (user: SessionUser) => set({ user }),
    setCdn          : (cdn: string) => set({ cdn }),
    setAccessToken  : (accessToken: string | null) => set({ accessToken }),
    setRefreshToken : (refreshToken: string | null) => set({ refreshToken }),
    setTenant       : (tenant: SessionTenant) => set({
      tenant: {
        ...tenant,
        picture: tenant.picture ? `${config.publicBucketUrl}/${tenant.picture}` : null,
      },
    }),
  }),
  {
    name: 'session',
  },
))
