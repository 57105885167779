import { apiErrorWrapper } from '@/api/common/apiErrorWrapper.ts'
import { client } from '@/api/common/client.ts'
import { apiErrorMapper } from '@/api/common/utils/apiErrorMapper.ts'

type PostMappingProps<TRequest> = {
  path: string
  body: TRequest
  query?: Record<string, string>
  headers?: Record<string, string>
}

export async function postMapping<TResponse, TRequest>({
  path,
  body,
  query,
  headers,
}: PostMappingProps<TRequest>) {
  const { data, error } = await apiErrorWrapper(
    async () => await client.post(path, body, {
      params: query,
      headers,
    }),
  )
  if (error) {
    apiErrorMapper(error)
  }
  return data as TResponse
}
