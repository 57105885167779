import { Card } from 'antd'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { HR } from '@/presentation/common/components/HR.tsx'
import { useRoleAuth } from '@/presentation/common/hooks/useRoleAuth.ts'
import { useSessionStore } from '@/presentation/common/store/zustand/session.ts'
import { ModuleCardProps, ModuleLink } from '@/presentation/modules/types.ts'

export const ModuleCard: FC<ModuleCardProps> = ({ title, links }) => {
  const { tenant } = useSessionStore()
  const { checkRoleAuth } = useRoleAuth()
  const authorizedLinks = links.filter(link => link.path && checkRoleAuth(link.allowedRoles) && (!link.onlySysAdmin || tenant?.isSysAdmin))
  const [ visibleLinks, setVisibleLinks ] = useState<ModuleLink[]>([])

  const showAllLinks = () => {
    setVisibleLinks(authorizedLinks)
  }

  useEffect(() => {
    setVisibleLinks(authorizedLinks.slice(0, 3))
  }, [])

  return (
    <Card title={title} className="min-w-[300px] max-w-[300px] h-full transition-all duration-75">
      <div className="flex flex-col gap-1.5">
        {visibleLinks.map((link, index) => (
          <div
            key={`${link.id}_${index}`}
            className="bg-secondary-min hover:bg-primary-min px-1 rounded-sm transition-colors duration-75"
          >
            <Link to={link.path as string} className="block">{link.label}</Link>
          </div>
        ))}

        {authorizedLinks.length > 3 && visibleLinks.length === 3 && (
          <div
            className="px-1 transition-colors duration-75"
          >
            <HR/>
            <Link to='#' className="block" onClick={showAllLinks}><i
              className="ri-expand-up-down-line icon-sm"></i> Ver más</Link>
          </div>
        )}
      </div>
    </Card>
  )
}
