import { Pagination, Typography } from 'antd'
import classNames from 'classnames'
import { FC, useContext, useEffect, useState } from 'react'
import { HR } from '@/presentation/common/components/HR.tsx'
import { useMQ } from '@/presentation/common/hooks/useMediaQuery.ts'
import { HelpContext } from '@/presentation/common/store/apiContext/HelpContext.tsx'
import { MaintenanceCard } from '@/presentation/maintenanceDashboard/components/MaintenanceCard.tsx'
import {
  useQueryMaintenancesForDashboard,
} from '@/presentation/maintenanceDashboard/hooks/useQueryMaintenancesForDashboard.ts'

export const MaintenanceDashboard: FC = () => {
  const { isTabletAndBelow, isDesktop, is4k } = useMQ()
  const helpCtx = useContext(HelpContext)
  const [ page, setPage ] = useState(1)
  const [ limit, setLimit ] = useState(20)
  const { data, query, isLoading } = useQueryMaintenancesForDashboard()

  useEffect(() => {
    helpCtx?.render(true)
    helpCtx?.setContent(<MaintenanceDashboardHelpInfo/>)
  }, [])

  // hides help bar
  useEffect(() => {
    query({
      params: {
        page  : page,
        limit : limit,
      },
    })

    const interval = setInterval(() => {
      query({
        params: {
          page  : page,
          limit : limit,
        },
      })
    }, 1000 * 60 * 5) // 5 minutes

    return () => {
      clearInterval(interval)
    }
  }, [ page, limit ])

  const gridClasses = classNames('app-def-p grid gap-3', {
    'grid-cols-1' : isTabletAndBelow,
    'grid-cols-2' : isDesktop,
    'grid-cols-3' : is4k,
  })

  return (
    <div className="absolute top-0 left-0 w-full h-full flex flex-col">
      <section className="flex-1 overflow-auto">
        <div className={gridClasses}>
          {data?.items?.map((record) => (
            <MaintenanceCard key={record.id} maintenance={record} />
          ))}
        </div>
      </section>

      <div className="app-def-p bg-surface border-t-2 border-primary-dark flex justify-center">
        <Pagination
          defaultCurrent={page}
          total={data?.totalItems}
          pageSize={data?.limit || 20}
          disabled={isLoading}
          showSizeChanger={true}
          defaultPageSize={20}
          onChange={(newPage, newLimit) => {
            if (newPage) {setPage(newPage)}
            if (newLimit) {setLimit(newLimit)}
          }}
        />
      </div>
    </div>
  )
}

const MaintenanceDashboardHelpInfo: FC = () => {
  return (
    <div className="py-2">
      <section>
        <Typography.Title level={5}>¿Qué es lo que veo aquí?</Typography.Title>
        <HR/>
        <Typography.Paragraph>
          Este tablero te permite observar las mantenciones tanto pendientes como completadas.
        </Typography.Paragraph>
      </section>

      <section>
        <Typography.Title level={5}>¿Cuál es el orden de las tarjetas?</Typography.Title>
        <HR/>
        <Typography.Paragraph>
          Las tarjetas que muestran las mantenciones siempre son ordenadas de manera tal que se muestren
          en primer lugar las mantenciones en estado <i>PENDIENTE</i>, y luego son ordenadas en orden descendente
          por fecha de creación, es decir, que las creadas últimamente serán listadas primero (siempre respetando
          el estado de estas).
        </Typography.Paragraph>
      </section>
    </div>
  )
}
