import { Button, DatePicker, Form } from 'antd'
import { Dayjs } from 'dayjs'
import { FC, useEffect } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { getLocalDateStartOf } from '@/presentation/common/utils/getLocalDateStartOf.ts'
import { DownloadRentalExcelFormProps } from '@/presentation/rentals/forms/types.ts'
import { useDownloadRentalsExcel } from '@/presentation/rentals/hooks/useDownloadRentalsExcel.ts'

type DownloadRentalExcelFormFields = {
  since: Dayjs
}

export const DownloadRentalExcelForm: FC<DownloadRentalExcelFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'DownloadRentalExcelForm-alert'
  const { submit, data: file, isSuccess, isLoading, isError, message, lastUpdate } = useDownloadRentalsExcel()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const [ form ] = Form.useForm()

  const onSubmit = async (values: DownloadRentalExcelFormFields) => {
    if (isLoading) {
      return
    }

    await submit({
      since: getLocalDateStartOf({ date: values.since }).format(),
    })
  }

  // Abrir excel cuando se descargue
  useEffect(() => {
    if (!isLoading && file) {
      window.open(URL.createObjectURL(file))
      onFinish?.()
    }
  }, [ lastUpdate ])

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Filtros">
        <Form.Item
          label="Fecha desde"
          name="since"
          rules={[{ required: true }]}
          help="Se buscarán los arriendos con fecha de término posterior o igual a la indicada."
        >
          <DatePicker inputReadOnly format="DD-MM-YYYY" className="w-full"/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Generar</Button>
      </div>
    </Form>
  )
}
