import { CreateMachineryUseRecordRequest } from '@/api/machineryUseRecords/request.types.ts'
import { CreateMachineryUseRecordResponse } from '@/api/machineryUseRecords/response.types.ts'
import { useMachineryUseRecordsApiClient } from '@/api/machineryUseRecords/useMachineryUseRecordsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateMachineryUseRecord = () => {
  const repository = useMachineryUseRecordsApiClient()
  const createItem = useCreateItem<CreateMachineryUseRecordRequest, CreateMachineryUseRecordResponse>({
    callable : repository.create,
    messages : {
      default : 'No se pudo crear el registro de uso',
      success : 'Registro de uso creado con éxito',
      loading : 'Creando registro de uso',
    },
  })

  return {
    ...createItem,
  }
}
