import { UpdateUserSettingsRequest } from '@/api/users/request.types.ts'
import { UpdateUserSettingsResponse } from '@/api/users/response.types.ts'
import { useUsersApiClient } from '@/api/users/useUsersApiClient.ts'
import { useUpdateItem } from '@/presentation/common/hooks/crud/v2/useUpdateItem.ts'

export const useUpdateUserSettings = () => {
  const repository = useUsersApiClient()
  const updateItem = useUpdateItem<UpdateUserSettingsRequest, UpdateUserSettingsResponse>({
    callable : repository.updateSettings,
    messages : {
      default       : 'No se pudo actualizar el usuario',
      alreadyExists : 'Ya existe un usuario con el mismo usuario',
      success       : 'Usuario actualizado con éxito',
      loading       : 'Actualizando usuario',
    },
  })

  return {
    ...updateItem,
  }
}
