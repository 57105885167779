import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getFileMapping } from '@/api/common/getFileMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateRentalRequest,
  DeleteManyRentalsRequest,
  DownloadExcelResumeRequest,
  UpdateRentalRequest,
} from '@/api/rentals/request.types.ts'
import {
  CreateRentalResponse,
  DeleteManyRentalsResponse,
  DownloadExcelResumeResponse,
  QueryRentalsResponse,
  UpdateRentalResponse,
} from '@/api/rentals/response.types.ts'

export type RentalsApiClient = {
  query: (options: QueryOptions) => Promise<QueryRentalsResponse>
  create: (req: CreateRentalRequest) => Promise<CreateRentalResponse>
  update: (req: UpdateRentalRequest) => Promise<UpdateRentalResponse>
  deleteMany: (req: DeleteManyRentalsRequest) => Promise<DeleteManyRentalsResponse>
  downloadExcelResume: (req: DownloadExcelResumeRequest) => Promise<DownloadExcelResumeResponse>
}

export const useRentalsApiClient = (): RentalsApiClient => ({
  query: async (options: QueryOptions) => await getMapping<QueryRentalsResponse, QueryParams>({
    path   : '/rentals',
    params : options.params,
  }),

  create: async (body: CreateRentalRequest) => await postMapping<CreateRentalResponse, CreateRentalRequest>({
    path : '/rentals',
    body : body,
  }),

  update: async (req: UpdateRentalRequest) => await putMapping<UpdateRentalResponse, UpdateRentalRequest['body']>({
    path : `/rentals/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyRentalsRequest) => await deleteMapping<DeleteManyRentalsResponse, DeleteManyRentalsRequest>({
    path : '/rentals',
    body : req,
  }),

  downloadExcelResume: async (req: DownloadExcelResumeRequest) => await getFileMapping<DownloadExcelResumeResponse, DownloadExcelResumeRequest>({
    path   : '/rentals/excel',
    params : req,
  }),
})
