import { Dropdown, MenuProps } from 'antd'
import { FC } from 'react'
import { LogoutButton } from '@/presentation/common/components/LogoutButton.tsx'

export const HeaderSettingsButton: FC = () => {
  const items: MenuProps['items'] = [
    { key: '1', label: (<LogoutButton/>) },
  ]

  return (
    <Dropdown menu={{ items }} placement="bottomRight" trigger={[ 'click' ]} overlayClassName="!top-16">
      <i className="ri-more-2-fill"></i>
    </Dropdown>
  )
}
