import { useCallback, useState } from 'react'
import { QueryOptions } from '@/api/common/types.ts'
import { QueryMachineryUseRecordsForDashboardRequest } from '@/api/machineryUseRecords/request.types.ts'
import { QueryMachineryUseRecordsForDashboardResponse } from '@/api/machineryUseRecords/response.types.ts'
import { useMachineryUseRecordsApiClient } from '@/api/machineryUseRecords/useMachineryUseRecordsApiClient.ts'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalType, RentalTypeValue } from '@/domain/models/rentalType.ts'
import { WorkingDay, WorkingDayValue } from '@/domain/models/workingDay.ts'
import { useQueryItems } from '@/presentation/common/hooks/crud/v2/useQueryItems.ts'
import { PaginatedData } from '@/presentation/common/types.ts'

export type QueryMachineryUseRecordsForDashboardResult = PaginatedData<{
  id: string
  folio: number
  date: string
  isSigned: boolean
  rental: {
    id: string
    type: RentalTypeValue
    building: string
    externalCompany: string | null
    client: {
      name: string
    }
  }
  machinery: string
  truckVolume: number | null
  operator: string
  rentalCondition: {
    type: RentalConditionTypeValue
    load: string | null
    minHours: number | null
  }
  machineryConditionValues: {
    startHourmeter: number | null
    endHourmeter: number | null
    totalHours: number | null
    workingDay: WorkingDayValue | null
    totalTravels: number | null
  }
  hadIncidences: boolean
  observations: string | null
  signature: string | null
  status: 'pending' | 'done'
}>

export const useQueryMachineryUseRecordsForDashboard = () => {
  const repository = useMachineryUseRecordsApiClient()
  const queryItemsHook = useQueryItems<QueryMachineryUseRecordsForDashboardResponse>({
    callable : repository.queryForDashboard,
    messages : {
      default : 'No se encontraron registros de uso',
      loading : 'Cargando registros',
      success : 'Registros cargados',
    },
  })

  const [ data, setData ] = useState<QueryMachineryUseRecordsForDashboardResult>()

  const refresh = useCallback(async () => {
    const response = await queryItemsHook.refresh()
    mapResponse(response)
  }, [ queryItemsHook.refresh ])

  const query = async (options: Partial<QueryOptions<any, QueryMachineryUseRecordsForDashboardRequest>> = {}) => {
    const response = await queryItemsHook.query(options)
    mapResponse(response)
  }

  const mapResponse = (response?: QueryMachineryUseRecordsForDashboardResponse) => {
    if (!response) {
      return setData(undefined)
    }

    setData({
      ...response,
      items: response.items.map((item) => ({
        id       : item.id,
        folio    : item.folio,
        date     : item.date,
        isSigned : !!item.signature,
        rental   : {
          id              : item.rental.id,
          type            : RentalType.fromString(item.rental.type).value,
          building        : item.rental.building,
          externalCompany : item.rental.externalCompany ?? null,
          client          : {
            name: item.client,
          },
        },
        machinery       : item.machinery,
        truckVolume     : item.truckVolume,
        operator        : item.operator,
        rentalCondition : {
          type     : RentalConditionType.fromString(item.rentalCondition.type).value,
          load     : item.rentalCondition.load ?? null,
          minHours : item.rentalCondition.minHours ?? null,
        },
        machineryConditionValues: {
          startHourmeter : item.machineryConditionValues.startHourmeter ?? null,
          endHourmeter   : item.machineryConditionValues.endHourmeter ?? null,
          totalHours     : item.machineryConditionValues.totalHours ?? null,
          workingDay     : item.machineryConditionValues.workingDay ? WorkingDay.fromString(item.machineryConditionValues.workingDay).value : null,
          totalTravels   : item.machineryConditionValues.totalTravels ?? null,
        },
        hadIncidences : item.hadIncidences,
        observations  : item.observations ?? null,
        signature     : item.signature ?? null,
        status        : item.status,
      })),
    })
  }

  return {
    ...queryItemsHook,
    query,
    refresh,
    data,
  }
}
