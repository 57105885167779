import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { FC } from 'react'
import { MachineryType, MachineryTypeValue } from '@/domain/models/machineryType.ts'

type Props = SelectProps & {
  excludePickup?: boolean
}

export const MachineryTypeSelector: FC<Props> = ({ excludePickup, ...props }) => {
  return (
    <Select
      placeholder="Seleccione un tipo"
      {...props}
    >
      {MachineryType.toEntries().map(([ key, value ]) => (
        key === MachineryTypeValue.CAMIONETA && excludePickup ?
          null :
          <Select.Option key={key} value={value.value}>{value.label()}</Select.Option>
      ))}
    </Select>
  )
}
