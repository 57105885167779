import { Button, Form, Input, InputNumber } from 'antd'
import { FC } from 'react'
import { RowNode } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { useMaintainerStore } from '@/presentation/common/store/zustand/maintainer.ts'
import { getModifiedFields } from '@/presentation/common/utils/form/getModifiedFields.ts'
import { UpdateProductFormProps } from '@/presentation/products/forms/types.ts'
import { QueryProductsForTableResult } from '@/presentation/products/hooks/useQueryProductsForTable.ts'
import { useUpdateProduct } from '@/presentation/products/hooks/useUpdateProduct.ts'

type UpdateProductFormFields = Partial<{
  name: string,
  description?: string,
  sku?: string,
  sizes: {
    height?: string,
    width?: string,
    depth?: string,
  },
  weight?: string,
  volume?: string,
  unitsPerPackage?: number,
}>

export const UpdateProductForm: FC<UpdateProductFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'UpdateProductForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useUpdateProduct()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const selectionNodes = useMaintainerStore((state) => state.selectionV2)
  const selectedNode: RowNode<QueryProductsForTableResult['items'][0]> = selectionNodes[0]

  const initialValues = { ...(selectedNode?.data || {}) }

  const onSubmit = async (values: UpdateProductFormFields) => {
    if (isLoading) {
      return
    }

    const editedValues = getModifiedFields(initialValues, values) as UpdateProductFormFields

    const response = await submit({
      query: {
        id: selectedNode?.data?.id,
      },
      body: {
        name        : editedValues.name,
        description : editedValues.description,
        sku         : editedValues.sku,
        sizes       : {
          height : editedValues.sizes?.height ?? initialValues.sizes?.height,
          width  : editedValues.sizes?.width ?? initialValues.sizes?.width,
          depth  : editedValues.sizes?.depth ?? initialValues.sizes?.depth,
        },
        weight          : editedValues.weight,
        volume          : editedValues.volume,
        unitsPerPackage : editedValues.unitsPerPackage,
      },
    })

    if (response) {
      onFinish?.call(this)
    }
  }

  return (
    <>
      {selectedNode && (
        <Form
          layout="horizontal"
          labelCol={{ span: 4 }}
          labelAlign="left"
          labelWrap
          colon={false}
          initialValues={initialValues}
          onFinish={onSubmit}
        >
          <FormSection title="Datos del producto">
            <Form.Item
              label="Nombre"
              name="name"
              rules={[{ required: true }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Descripción"
              name="description"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="SKU"
              name="sku"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>
          </FormSection>

          <FormSection title="Dimensiones y peso">
            <Form.Item
              label="Alto"
              name={[ 'sizes', 'height' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Ancho"
              name={[ 'sizes', 'width' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Profundidad"
              name={[ 'sizes', 'depth' ]}
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Peso"
              name="weight"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Volumen"
              name="volume"
              rules={[{ required: false }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
              label="Unidades por lote"
              name="unitsPerPackage"
              rules={[{ required: false }]}
            >
              <InputNumber min={0}/>
            </Form.Item>
          </FormSection>

          <div className="flex justify-center gap-2.5 pb-2">
            <Button size="small" onClick={onCancel}>Cancelar</Button>
            <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Actualizar</Button>
          </div>
        </Form>
      )}
    </>
  )
}
