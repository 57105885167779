import { CreateClientRequest } from '@/api/clients/request.types.ts'
import { CreateClientResponse } from '@/api/clients/response.types.ts'
import { useClientsApiClient } from '@/api/clients/useClientsApiClient.ts'
import { useCreateItem } from '@/presentation/common/hooks/crud/v2/useCreateItem.ts'

export const useCreateClient = () => {
  const repository = useClientsApiClient()
  const createUser = useCreateItem<CreateClientRequest, CreateClientResponse>({
    callable : repository.create,
    messages : {
      default       : 'No se pudo crear el cliente',
      alreadyExists : 'Un cliente con ese nombre ya existe',
      success       : 'Cliente creado con éxito',
      loading       : 'Creando cliente',
    },
  })

  return {
    ...createUser,
  }
}
