import { Button, Form, InputNumber } from 'antd'
import { FC, useEffect } from 'react'
import { FormSection } from '@/presentation/common/components/FormSection.tsx'
import { useCrud } from '@/presentation/common/hooks/crud/useCrud.ts'
import { InventorySelector } from '@/presentation/inventories/components/InventorySelector.tsx'
import { ProductStockSelector } from '@/presentation/inventoryProductsStocks/components/ProductStockSelector.tsx'
import { CreateProductsTemplateProductFormProps } from '@/presentation/productsTemplates/forms/types.ts'
import {
  useCreateProductsTemplateProduct,
} from '@/presentation/productsTemplates/hooks/useCreateProductsTemplateProduct.ts'
import {
  useProductsTemplatesMaintainerStore,
} from '@/presentation/productsTemplates/store/productsTemplatesMaintainers.ts'

type CreateMaintainerTemplateProductFormFields = {
  inventory: string,
  product: string,
  quantity: number,
}

export const CreateMaintainerTemplateProductForm: FC<CreateProductsTemplateProductFormProps> = ({ onCancel, onFinish }) => {
  const messageApiKey = 'CreateMaintainerTemplateProductForm-alert'
  const { submit, isSuccess, isLoading, isError, message } = useCreateProductsTemplateProduct()
  useCrud({ messageApiKey, message, isSuccess, isLoading, isError })

  const { selectedTemplateId } = useProductsTemplatesMaintainerStore()

  const [ form ] = Form.useForm<CreateMaintainerTemplateProductFormFields>()
  const inventory = Form.useWatch('inventory', form)

  const onSubmit = async (value: CreateMaintainerTemplateProductFormFields) => {
    if (isLoading || !selectedTemplateId) {
      return
    }

    const response = await submit({
      query: {
        templateId: selectedTemplateId,
      },
      body: {
        product  : value.product,
        quantity : value.quantity,
      },
    })
    if (response) {
      onFinish?.call(this)
    }
  }

  useEffect(() => {
    form.resetFields([ 'product' ])
  }, [ inventory ])

  return (
    <Form
      form={form}
      layout="horizontal"
      labelCol={{ span: 5 }}
      labelAlign="left"
      labelWrap
      colon={false}
      onFinish={onSubmit}
    >
      <FormSection title="Datos del producto">
        <Form.Item
          label="Inventario"
          name="inventory"
          rules={[{ required: true }]}
        >
          <InventorySelector/>
        </Form.Item>

        <Form.Item
          label="Producto"
          name="product"
          rules={[{ required: true }]}
        >
          <ProductStockSelector filters={{ inventoryId: inventory }}/>
        </Form.Item>

        <Form.Item
          label="Cantidad"
          name="quantity"
          rules={[{ required: true }]}
        >
          <InputNumber min={0}/>
        </Form.Item>
      </FormSection>

      <div className="flex justify-center gap-2.5 pb-2">
        <Button size="small" onClick={onCancel} disabled={isLoading}>Cancelar</Button>
        <Button size="small" type="primary" htmlType="submit" disabled={isLoading}>Registrar</Button>
      </div>
    </Form>
  )
}
