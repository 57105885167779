import { Typography } from 'antd'
import { FC } from 'react'
import { SidebarButtonProps } from '@/presentation/common/components/types.ts'

export const SidebarButton: FC<SidebarButtonProps> = ({ label, onClick, className, type }) => {
  return (
    <button
      onClick={onClick}
      className={`
        w-full
        text-start
        rounded-md 
        p-2 my-1
        cursor-pointer
        !text-onSurface
        transition-all
        duration-200
        hover:bg-tertiary-min
        ${type === 'DOWNLOAD_PDF' ? 'bg-primary-min' : ''}
        ${type === 'DOWNLOAD_EXCEL' ? 'outline' : ''}
        ${type === 'POST' ? 'bg-green-100' : ''}
        ${type === 'PUT' ? 'bg-orange-100' : ''}
        ${type === 'DELETE' ? 'bg-red-100 hover:!bg-red-400 hover:!text-white' : ''}
        ${type === 'TEMPLATE' ? 'bg-primary-min' : ''}
        ${className}
      `}
    >
      <Typography.Text>{label}</Typography.Text>
    </button>
  )
}
