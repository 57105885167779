import numeral from 'numeral'
import { FC, useMemo } from 'react'
import { RentalConditionType, RentalConditionTypeValue } from '@/domain/models/rentalConditionType.ts'
import { RentalTypeValue } from '@/domain/models/rentalType.ts'
import { DataGridColumnDefs } from '@/presentation/common/components/dataGrid/lib/api.types.ts'
import { numberComparator } from '@/presentation/common/components/table/comparators/numberComparator.ts'
import { stringComparator } from '@/presentation/common/components/table/comparators/stringComparator.ts'
import 'numeral/locales/es'
import { PayStatesDetailsTable } from '@/presentation/payStates/components/PayStatesDetailsTable.tsx'
import { usePayStatePreviewStore } from '@/presentation/payStates/store/preview.ts'

numeral.locale('es')

type Props = {
  rentalType: RentalTypeValue
  conditionType: RentalConditionTypeValue
}

export const PayStatesDetailsTableByTravels: FC<Props> = ({ rentalType, conditionType }) => {
  const { data } = usePayStatePreviewStore()

  const columnDefs: DataGridColumnDefs[] = useMemo(() => ([
    {
      field      : 'date',
      headerName : 'Fecha',
      comparator : stringComparator,
    },
    {
      field      : 'building',
      headerName : 'Obra',
      comparator : stringComparator,
    },
    {
      field              : 'folio',
      headerName         : 'Nro. Reporte',
      comparator         : numberComparator,
      cellRendererParams : {
        justify: 'center',
      },
      width: 110,
    },
    {
      field      : 'machinery',
      headerName : 'Equipo',
      comparator : stringComparator,
    },
    {
      field      : 'truckVolume',
      headerName : 'Volumen',
      comparator : numberComparator,
      width      : 100,
    },
    {
      field      : 'operator',
      headerName : 'Operador',
      comparator : stringComparator,
    },
    {
      field              : 'machineryConditionValues.totalTravels',
      headerName         : 'Nro. Viajes',
      comparator         : numberComparator,
      cellRendererParams : {
        justify: 'center',
      },
      width: 120,
    },
    ...(conditionType !== RentalConditionTypeValue.TRAVELS_WITHOUT_LOAD ? [{
      field      : 'rentalCondition.load',
      headerName : 'Tipo Carga',
      comparator : stringComparator,
    }] : []),
    {
      field              : 'rentalCondition.amountPerTravel',
      headerName         : 'Monto',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
    {
      field              : 'totalAmount',
      headerName         : 'Cobro',
      width              : 200,
      comparator         : stringComparator,
      formatValue        : ({ value }) => numeral(value).format('$0,0'),
      cellRendererParams : {
        justify: 'right',
      },
    },
  ]), [ conditionType ])

  const details = data?.detail[rentalType][conditionType]
  const rowData = useMemo(() => details?.['items'] ?? [], [ details ])
  const summary = useMemo(() => details?.['summary'] ?? {
    total        : 0,
    vat          : 0,
    totalWithVat : 0,
  }, [ details ])

  return (
    <PayStatesDetailsTable
      title={`Detalle por ${RentalConditionType.fromString(conditionType).label()}`}
      rentalType={rentalType}
      columnDefs={columnDefs}
      rowData={rowData}
      summary={summary}
    />
  )
}
