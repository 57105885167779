import { deleteMapping } from '@/api/common/deleteMapping.ts'
import { getMapping } from '@/api/common/getMapping.ts'
import { postMapping } from '@/api/common/postMapping.ts'
import { putMapping } from '@/api/common/putMapping.ts'
import { QueryOptions, QueryParams } from '@/api/common/types.ts'
import {
  CreateEppProductRequest,
  DeleteManyEppProductsRequest,
  LoadEppTemplateProductsRequest,
  QueryEppProductsRequest,
  UpdateEppProductRequest,
} from '@/api/eppProducts/request.types.ts'
import {
  CreateEppProductResponse,
  DeleteManyEppProductsResponse,
  LoadEppTemplateProductsResponse,
  QueryEppProductsResponse,
  UpdateEppProductResponse,
} from '@/api/eppProducts/response.types.ts'

export type EppProductsApiClient = {
  query: (options: QueryOptions<QueryEppProductsRequest>) => Promise<QueryEppProductsResponse>
  create: (req: CreateEppProductRequest) => Promise<CreateEppProductResponse>
  update: (req: UpdateEppProductRequest) => Promise<UpdateEppProductResponse>
  deleteMany: (req: DeleteManyEppProductsRequest) => Promise<DeleteManyEppProductsResponse>
  loadTemplate: (req: LoadEppTemplateProductsRequest) => Promise<LoadEppTemplateProductsResponse>
}

export const useEppProductsApiClient = (): EppProductsApiClient => ({
  query: async (options: QueryOptions<QueryEppProductsRequest>) => await getMapping<QueryEppProductsResponse, QueryParams>({
    path   : `/epps/${options.pathParams?.eppId}/products`,
    params : options.params,
  }),

  create: async (req: CreateEppProductRequest) => await postMapping<CreateEppProductResponse, CreateEppProductRequest['body']>({
    path : `/epps/${req.query.eppId}/products`,
    body : req.body,
  }),

  update: async (req: UpdateEppProductRequest) => await putMapping<UpdateEppProductResponse, UpdateEppProductRequest['body']>({
    path : `/epps/products/${req.query.id}`,
    body : req.body,
  }),

  deleteMany: async (req: DeleteManyEppProductsRequest) => await deleteMapping<DeleteManyEppProductsResponse, DeleteManyEppProductsRequest>({
    path : '/epps/products',
    body : req,
  }),

  loadTemplate: async (req: LoadEppTemplateProductsRequest) => await putMapping<LoadEppTemplateProductsResponse, LoadEppTemplateProductsRequest['body']>({
    path : `/epps/${req.query.eppId}/products/template`,
    body : req.body,
  }),
})
