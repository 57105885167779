import { DeleteManyEppProductsRequest } from '@/api/eppProducts/request.types.ts'
import { DeleteManyEppProductsResponse } from '@/api/eppProducts/response.types.ts'
import { useEppProductsApiClient } from '@/api/eppProducts/useEppProductsApiClient.ts'
import { useDeleteManyItems } from '@/presentation/common/hooks/crud/v2/useDeleteManyItems.ts'

export const useDeleteEppProducts = () => {
  const repository = useEppProductsApiClient()
  const deleteItems = useDeleteManyItems<DeleteManyEppProductsRequest, DeleteManyEppProductsResponse>({
    callable : repository.deleteMany,
    messages : {
      default : 'No se pudieron eliminar los productos',
      success : 'Productos eliminados',
      loading : 'Eliminando productos',
    },
  })

  return {
    ...deleteItems,
  }
}
