import { Col, Row } from 'antd'
import cx from 'classnames'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { routes } from '@/presentation/common/router/routes.ts'
import { PasswordLabelProps } from '@/presentation/login/components/types.ts'

export const PasswordLabel: FC<PasswordLabelProps> = ({ disabled = false }) => {
  const linkClasses = cx({
    'pointer-events-none': disabled,
  })

  return (
    <Row justify={'space-between'} className="w-full">
      <Col flex={1}>Contraseña</Col>
      <Col>
        <Link to={routes.forgotPassword} className={linkClasses}>
                    ¿Olvidaste tu contraseña?
        </Link>
      </Col>
    </Row>
  )
}
